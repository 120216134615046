module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', [_c('ba-password-field', {
    attrs: {
      "inputLabel": _vm.t('global.new_password')
    },
    on: {
      "onShowPasswordCheckboxChange": _vm.onShowPasswordCheckboxChange,
      "enterKeyPressed": _vm.enterKeyPressed
    },
    model: {
      value: (_vm.newPassword),
      callback: function($$v) {
        _vm.newPassword = $$v
      },
      expression: "newPassword"
    }
  }), _vm._v(" "), (_vm.showVerificationPassword) ? _c('ba-password-field', {
    attrs: {
      "inputLabel": _vm.t('enrollment.confirm_password'),
      "showPassword": _vm.showPassword,
      "displayShowPasswordCheckbox": false
    },
    on: {
      "enterKeyPressed": _vm.enterKeyPressed
    },
    model: {
      value: (_vm.repeatNewPassword),
      callback: function($$v) {
        _vm.repeatNewPassword = $$v
      },
      expression: "repeatNewPassword"
    }
  }) : _vm._e(), _vm._v(" "), (_vm.newPassword) ? _c('div', {
    staticClass: "progressbar-info u-mt3 u-mb3"
  }, [_c('el-progress', {
    attrs: {
      "fill-status": _vm.passwordValidationResult.passwordStrengthColor
    },
    model: {
      value: (_vm.passwordValidationResult.passwordStrengthValue),
      callback: function($$v) {
        _vm.$set(_vm.passwordValidationResult, "passwordStrengthValue", $$v)
      },
      expression: "passwordValidationResult.passwordStrengthValue"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "progressbar-info__strength u-color-secondary"
  }, [_vm._v("\n      " + _vm._s(_vm.t('global.password_strength')) + "\n    ")])], 1) : _vm._e(), _vm._v(" "), (!_vm.passwordValidationResultUi.valid) ? _c('div', {
    staticClass: "u-mt1"
  }, _vm._l((_vm.passwordValidationResultUi.messages), function(warningMessages) {
    return _c('div', {
      key: warningMessages,
      staticClass: "u-warning"
    }, [_c('i', {
      staticClass: "material-icons md-text",
      attrs: {
        "aria-hidden": "true"
      }
    }, [_vm._v("warning")]), _vm._v(" " + _vm._s(warningMessages) + "\n    ")])
  }), 0) : _vm._e(), _vm._v(" "), (_vm.showSubmitButton) ? _c('div', {
    staticClass: "u-mt3"
  }, [_c('el-button', {
    staticClass: "el-button--wide u-text-uppercase",
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.setNewPassword
    }
  }, [_vm._v(_vm._s(_vm._setNewPasswordButtonCaption))])], 1) : _vm._e()], 1)
},staticRenderFns: []}