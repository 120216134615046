<template>
  <svg width="22" height="17" id="icon-login" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.875 17C20.1523 17 22 15.1523 22 12.875V4.625C22 2.34766 20.1523 0.5 17.875 0.5H14.2656C13.9648 0.5 13.75 0.757812 13.75 1.01562V2.04688C13.75 2.34766 13.9648 2.5625 14.2656 2.5625H17.875C18.9922 2.5625 19.9375 3.50781 19.9375 4.625V12.875C19.9375 14.0352 18.9922 14.9375 17.875 14.9375H14.2656C13.9648 14.9375 13.75 15.1953 13.75 15.4531V16.4844C13.75 16.7852 13.9648 17 14.2656 17H17.875ZM7.17578 1.35938L6.31641 2.21875C6.10156 2.39062 6.10156 2.73438 6.31641 2.94922L11.1719 7.63281H0.515625C0.214844 7.63281 0 7.89062 0 8.14844V9.35156C0 9.65234 0.214844 9.86719 0.515625 9.86719H11.1719L6.31641 14.5938C6.10156 14.8086 6.10156 15.1523 6.31641 15.3242L7.17578 16.1836C7.34766 16.3984 7.69141 16.3984 7.90625 16.1836L14.9531 9.13672C15.168 8.92188 15.168 8.62109 14.9531 8.40625L7.90625 1.35938C7.69141 1.14453 7.34766 1.14453 7.17578 1.35938Z"
          :fill="iconColor"
    />
  </svg>
</template>

<script>
  export default {
    name: 'login-icon',
    props: {
      iconColor: {
        type: String,
        default: '#626262'
      }
    }
  }
</script>

<style scoped>

</style>
