<template>
  <div>
    <transition name="fade">
    <div class="claim-photos u-mb4">
      <h2 class="secondary-title u-mb3">{{ t('claims.photos_section_title') }}</h2>
      <el-row v-loading="loading" :gutter="20" class="claim-photos__content u-m0">
       <!-- 1st block Insured Photos -->
        <el-col :sm="24" :md="8" class="claim-photos__data">
          <h3 class="subheader u-text-trim u-mt2">{{ t('claims.loss_photos') }}</h3>
          <div v-if="insuredPhotos && insuredPhotos.length" class="claim-photos__block">
            <div class="photo-counter">
              <fa-icon :icon="[ 'fas', 'clone']" class="image-icon u-white" />
              <span class="image-amount">{{ insuredPhotos.length }}</span>
            </div>
            <bc-images-table
              class="claims-gallery"
              @removeImage="removeImage"
              :rows="insuredPhotos"
              :carouselCaption="t('claims.loss_photos')"
              :isDeletableFunction="isDeletableFunction"
              claimPreview
            />
          </div>
          <div v-else>
            <div class="u-pt4">{{ t('policies.no_photos') }}</div>
          </div>
        </el-col>
  
        <!-- 2nd block Carrier Photos -->
        <el-col v-if="carrierPhotos && carrierPhotos.length" :sm="24" :md="8" class="claim-photos__data">
          <h3 class="subheader u-text-trim u-mt2">{{ t('claims.loss_photos') }} {{ t('policies.added_by_your_agent') }}</h3>
          <div class="claim-photos__block">
            <div class="photo-counter">
              <fa-icon :icon="[ 'fas', 'clone']" class="image-icon u-white" />
              <span class="image-amount">{{ carrierPhotos.length }}</span>
            </div>
            <bc-images-table
              :rows="carrierPhotos"
              :carouselCaption="t('claims.loss_photos') + ' (' + t('policies.added_by_your_agent') + ')'"
              claimPreview
            />
          </div>
        </el-col>
      
       <!-- 3rd block Upload Photos -->
        <el-col v-if="!hideUploadPhotosAndUpdateClaim" :sm="24" :md="8" class="claim-photos__data">
          <drag-n-drop-photo @onPhotoUpload="onPhotoUpload"></drag-n-drop-photo>
        </el-col>
      </el-row>
      
    </div>
    </transition>
  </div>
</template>

<script>
  import {mapActions} from 'vuex'
  import BcImagesTable from '../../components/ImagesTable'
  
  import {urlForAttachment} from '@/utils/urls'
  import dragnDropPhotoComponent from '@/components/drag-n-drop-photo.component.vue'
  
  export default {
    components: {
      'bc-images-table': BcImagesTable,
      'drag-n-drop-photo': dragnDropPhotoComponent,
    },
    name: 'bc-claim-photo-preview',
    props: {
      photos: {type: Object},
      readonly: {type: Boolean, default: false},
      insuredPhotoPreview: {type: Boolean, default: false},
    },
    watch: {
      recentPhotos: {
        handler (recentPhotos) {
          if (recentPhotos.length) {
            this.saveClaimPhoto()
          }
        },
        immediate: true
      }
    },
    methods: {
      ...mapActions('policies', {reloadPolicies: 'reload'}),
      ...mapActions('claims', ['saveRecentClaimPhoto', 'removeRecentClaimPhoto']),
      urlForAttachment (fileId) {
        return urlForAttachment(fileId, false)
      },
      onPhotoUpload (photoDict) {
        this.recentPhotos.push(photoDict)
      },
      isDeletableFunction (item) {
        let loginTime = localStorage.getItem('loginTime')
        let dateAdded = item.date_added
        // Additional condition for preventing user's changing in markup
        return dateAdded > loginTime
      },
      removeImage (item) {
        if (!this.isDeletableFunction) {
          return
        }
        let fileId = item.id
        this.$elConfirm(
          this.t('policies.are_you_sure_you_want_to_delete_photo'),
          this.t('policies.confirm_removal'), {
            okCallback: () => {
              this.loading = true
              this.removeRecentClaimPhoto(fileId)
                .then(() => {
                  this.logInsuredAction('', 'custom_claim_photo_delete', {})
                  this.loading = false
                  this.$emit('reloadClaimImage')
                })
                .catch(() => {
                  this.loading = false
                  this.$elAlert(this.t('enrollment.some_error_happened_notification'), this.t('global.error'))
                })
            },
          },
        )
      },
      saveClaimPhoto () {
        if (!this.validateImageValues) {
          this.$elAlert(this.t('enrollment.some_error_happened_notification'), this.t('global.notice'))
          return
        }
        this.loading = true
        let promisesArray = []
        let uploadedImages = this.recentPhotos
        for (let i = 0; i < uploadedImages.length; i++) {
          let currentImage = uploadedImages[i]
          let payload = {
            ...currentImage,
            claimId: this.selectedClaimId,
            caption: this.caption,
          }
          let promise = this.saveRecentClaimPhoto(payload)
          promisesArray.push(promise)
        }
        Promise.all(promisesArray)
          .then(() => {
            this.$emit('reloadClaimImage')
            this.loading = false
          })
          .catch((e) => {
            console.log('catch', e)
            this.loading = false
            this.$elAlert(this.t('claims.claim_save_failure_alert_message'), this.t('global.error'))
          })
        .finally(() => {
          this.recentPhotos = []
        })
      }
    },
    computed: {
      carrierPhotos () {
        // Show only first photo
        return this.photos ? this.photos.carrier : []
      },
      insuredPhotos () {
        // Show only first photo
        return this.photos ? this.photos.insured : []
      },
      preferredPhoto () {
        return this.photos ? this.photos.preferred : null
      },
      validateImageValues () {
        return !!this.recentPhotos
      },
      hideUploadPhotosAndUpdateClaim () {
        return this.setting('claims.hide_upload_photos_and_update_claim')
      }
    },
    data () {
      return {
        selectedClaimId: this.$route.params.claimId,
        recentPhotos: [],
        caption: '',
        fileId: this.$route.params.fileId,
        preferred: false,
        loading: false,
      }
    },
  }
</script>

<style lang="scss">
  @import '../../sass/_variables.scss';
  
  .current-photo {
    position: relative;
    &__link {
      position: absolute;
      top: 0;
      right: 0;
      & > i {
        color: $color-primary--dark;
      }
    }
  }
  
  
  .claim-photos {
    &__content {
      padding: 10px;
      width: 100%;
      min-height: 200px;
      border: 2px solid #E5E5E5;
      border-radius: 4px;
  
      & > .claim-photos__data {
        
        @media only screen and (max-width: $m-screen) {
          margin-bottom: 20px;
        }
        .claim-gallery__image {
          width: 100%;
          & > img {
            width: 100%;
            max-height: 150px;
  
            @media only screen and (max-width: $l-screen) {
              max-height: none;
            }
          }
        }
      }
    }
    &__upload {
      width: 230px;
      border: 2px dashed #E5E5E5;
    }
    &__card {
      .el-card__body {
        padding: 0 !important;
      }
    }
    &__image {
      max-height: 140px;
      width: 100%;
      
      @media only screen and (max-width: $m-screen) {
        max-height: none;
      }
    }
    &__block {
      position: relative;
      cursor: pointer;
      .photo-counter {
        position: absolute;
        top: 10px;
        left: 10px;
        font-size: 24px;
        z-index: 10;
      }
      .image-amount {
        position: absolute;
        top: 3px;
        right: 4px;
        font-size: 12px;
        font-weight: 600;
        z-index: 11;
      }
    }
  }
  
</style>
