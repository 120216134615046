<template>
  <div class="missing-policy__success">
    <div class="u-text--bold missing-policy__success-text">
      <div v-loading="state.loading" class="u-mr4">
        <fa-icon class="fa-2x missing-policy__success-icon u-ml1"
                 :class="state.loading ? 'missing-policy__success-icon--pending' : ''"
                 :icon="['fa', 'check-circle']"></fa-icon>
      </div>
      <div>
        {{ t('enrollment.policies_were_attached_successfully') }}
      </div>
    </div>
    <div class="u-text--bold u-text-center u-mt4" v-if="state.loading">{{ t('global.loading') }}</div>
  </div>
</template>
<script>
  export default {
    name: 'ba-missing-policy-added',
    props: {
      state: Object
    }
  }
</script>
<style lang="scss" scoped>
  .missing-policy__success-text {
    font-size: 1rem;
    display: flex;
  }
</style>
<style lang="scss">
  @import '../../sass/_variables.scss';

  .missing-policy__success-icon {
    color: $color-success;
    transition: color 1.2s ease;
    will-change: color;
  }

  .missing-policy__success-icon--pending {
    color: $color-text--secondary;
  }
</style>
