module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    staticClass: "agent-block"
  }, [_c('div', {
    staticClass: "agent-block__image u-flex-vertical-center"
  }, [(_vm.agent.contact.photo_id) ? _c('div', [_c('bc-general-image', {
    staticClass: "u-img-responsive",
    attrs: {
      "fileId": _vm.agent.contact.photo_id
    }
  })], 1) : _vm._e(), _vm._v(" "), (!_vm.agent.contact.photo_id) ? _c('div', {
    staticClass: "agent-block__icon"
  }, [_c('fa-icon', {
    attrs: {
      "icon": ['far', 'user']
    }
  })], 1) : _vm._e()]), _vm._v(" "), _c('div', {
    staticClass: "agent-block__data u-width100"
  }, [_c('div', {
    staticClass: "contacts-block u-mb3"
  }, [_c('div', [_c('div', [_c('h2', {
    staticClass: "u-mt1 u-mb2"
  }, [_vm._v(_vm._s(_vm.agent.contact.name))])]), _vm._v(" "), (!_vm.shouldHideEmails && _vm.agent.contact && _vm.agent.contact.emails && _vm.agent.contact.emails.length > 0) ? _c('div', {
    staticClass: "agent-email"
  }, _vm._l((_vm.agent.contact.emails), function(data) {
    return _c('div', [_c('span', {
      staticClass: "u-pr1"
    }, [_vm._v(_vm._s(data.email))]), _vm._v(" "), _c('a', {
      attrs: {
        "href": _vm.composeEmail(data.email),
        "target": "_blank"
      }
    }, [_c('fa-icon', {
      staticClass: "u-primary",
      attrs: {
        "icon": ['far', 'copy']
      }
    })], 1)])
  }), 0) : _vm._e()]), _vm._v(" "), (_vm.agent.policies && _vm.agent.policies.length > 0) ? _c('div', _vm._l((_vm.agent.policies), function(policy) {
    return _c('div', [_c('router-link', {
      staticClass: "agent-policy",
      attrs: {
        "to": '/policies/' + policy.policy_id
      }
    }, [_vm._v("\n            " + _vm._s(_vm.t('policies.header_policy_number', {
      policy_number: policy.policy_number
    })) + "\n          ")])], 1)
  }), 0) : _vm._e()]), _vm._v(" "), _c('div', {
    staticClass: "agent-block__contacts u-width100"
  }, [_c('div', {
    staticClass: "agent-block__phone u-pr2"
  }, [_c('div', {
    staticClass: "agent-block__sub-title"
  }, [_vm._v(_vm._s(_vm.t('account.phone_numbers')))]), _vm._v(" "), _c('div', {
    staticClass: "u-mt2"
  }, _vm._l((_vm.agent.contact.phones), function(phone) {
    return _c('div', [(phone.type) ? _c('span', [_vm._v(_vm._s(phone.type) + ": ")]) : _vm._e(), _c('span', [_vm._v(_vm._s(phone.phone))])])
  }), 0)]), _vm._v(" "), _c('div', {
    staticClass: "agent-block__address"
  }, _vm._l((_vm.agent.contact.addresses), function(address) {
    return _c('div', {
      staticClass: "u-mt2"
    }, [_c('div', {
      staticClass: "agent-block__sub-title"
    }, [_vm._v("\n            " + _vm._s(_vm.getAddressLabel(address)) + " " + _vm._s(_vm.t('global.address')) + "\n          ")]), _vm._v(" "), _c('div', {
      staticClass: "u-mt2"
    }, [_c('div', [_vm._v(_vm._s(address.address_line1))]), _vm._v(" "), _c('div', [_vm._v(_vm._s(address.address_city) + ", " + _vm._s(address.address_state))]), _vm._v(" "), _c('div', [_vm._v(_vm._s(address.address_zip))])])])
  }), 0)])])])
},staticRenderFns: []}