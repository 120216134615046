<template>
    <div class="message-center--page" v-if="notifications">
      <div class="message-center__header">
        <div class="message-center__header_accented">
          {{ t('notifications.notifications', { amount: getNotificationsAmount }) }}
        </div>
        <div @click="dismiss_all" :class="['notifications', getNotificationsAmount ? 'notifications-active': '']">
         {{ t('notifications.delete_all') }}
        </div>
      </div>
      <div class="message-center__list">
        <div class="new-notifications" v-if="notifications.new.length > 0">
          <ba-notification-card v-for="notification in notifications.new" :key="notification.id" :message="notification" type="new"/>
        </div>
        <div class="seen-notifications" v-if="notifications.seen.length > 0">
          <ba-notification-card v-for="notification in notifications.seen" :key="notification.id" :message="notification" type="seen"/>
        </div>
      </div>
      <div class="message-center__no-messages u-text-center" v-if="!has_at_least_one_message">
        {{t('messaging.no_messages_to_show')}}
      </div>
  </div>
</template>

<script>
  import {mapActions, mapGetters, mapState} from 'vuex'
  import BaNotificationCard from './message-card.component'

  export default {
    name: 'ba-message-center',
    components: {BaNotificationCard},
    data () {
      return {
      }
    },
    mounted () {
      this.loadNotifications().then(() =>
        this.mark_all_as_read()
      )
    },
    computed: {
      ...mapState('messageCenter', ['notifications']),
      ...mapGetters('messageCenter', ['has_at_least_one_message']),
      getNotificationsAmount () {
        return this.notifications.new.length + this.notifications.seen.length
      }
    },
    methods: {
      ...mapActions('messageCenter', ['mark_all_as_read', 'dismiss_all', 'loadNotifications'])

    },
  }
</script>

<style lang="scss">
  @import '../../sass/_variables.scss';
  
  .message-center {
    &__header {
      display: flex;
      justify-content: space-between;
      padding: 10px 15px;
      border-bottom: 2px solid transparentize($color-text--disabled, .3);
      font-size: 16px;
      &_accented {
        font-weight: 600;
        color: #626262;
      }
    }
    &__list {
     .card {
       border-bottom: 2px solid transparentize($color-text--disabled, .3);
     }
    }
    &__no-messages {
      width: 215px;
      margin: 20px auto;
      font-size: 18px;
      color: #626262;
    }
  }
  .notifications {
    cursor: pointer;
    color: $color-text--disabled;
    &-active {
      color: $color-primary;
    }
  }
</style>
