import Vue from 'vue'

Vue.filter('currency', function (value, decimalPlaces) {
  decimalPlaces = decimalPlaces || 2
  let valueType = typeof value
  if (valueType === 'string') {
    let newValue = parseFloat(value)
    if (isNaN(newValue)) {
      return value
    }
    value = newValue
  } else if (valueType !== 'number') {
    return value
  }
  return value.toFixed(decimalPlaces)
})
