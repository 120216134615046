module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return (_vm.currentPages && _vm.currentPages.length > 0) ? _c('el-popover', {
    attrs: {
      "placement": _vm.placement,
      "width": "240",
      "trigger": "click",
      "popper-class": "popover-account"
    },
    model: {
      value: (_vm.visible),
      callback: function($$v) {
        _vm.visible = $$v
      },
      expression: "visible"
    }
  }, [_c('div', _vm._l((_vm.currentPages), function(page) {
    return _c('div', {
      key: page.id,
      staticClass: "account-link sidenav__popover-link"
    }, [_c('router-link', {
      staticClass: "u-inline-block u-text-uppercase",
      attrs: {
        "to": {
          path: ("/custom/" + (page.slug))
        },
        "active-class": "account-link__active"
      },
      nativeOn: {
        "click": function($event) {
          return _vm.managePopover.apply(null, arguments)
        }
      }
    }, [_vm._v("\n        " + _vm._s(page.title) + "\n      ")])], 1)
  }), 0), _vm._v(" "), _c('div', {
    staticClass: "sidenav__popover-button u-text--uppercase",
    class: _vm.visible ? 'el-button--side-nav_active' : '',
    attrs: {
      "slot": "reference"
    },
    slot: "reference"
  }, [_c('div', {
    staticClass: "sidenav__txt-line"
  }, [_c('fa-icon', {
    staticClass: "layer-icon image-icon u-text--light-gray text-center",
    attrs: {
      "icon": ['fal', 'layer-group']
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "u-ml2 u-mr3"
  }, [_vm._v(_vm._s(_vm.t('global.custom_pages_list_label')))]), _vm._v(" "), _c('fa-icon', {
    staticClass: "layer-icon",
    class: [_vm.visible ? 'u-rotate-180' : '', 'link-icon-small'],
    attrs: {
      "icon": ['far', 'chevron-down']
    }
  })], 1)])]) : _vm._e()
},staticRenderFns: []}