module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return (_vm.imageSource) ? _c('img', {
    attrs: {
      "src": _vm.imageSource
    }
  }) : (_vm.loading) ? _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: (_vm.loading),
      expression: "loading"
    }]
  }, [_vm._v("...")]) : _vm._e()
},staticRenderFns: []}