<template>
  <div class="add-payment-button-root">
    <el-button
        v-loading="!insuredIds.length"
        :disabled="!insuredIds.length"
        type="primary"
        class="u-mt3 u-mb3"
        @click="openDialog"
        size="small"
    >
      {{ t('payments.add_payment_method') }}
    </el-button>
    <ba-add-payment-method-modal :available-insureds="insuredIds"/>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import BaAddPaymentMethodModal from '@/pages/payments-redesign/add-payment-method.modal'

export default {
  name: 'ba-add-payment-method',
  components: {
    BaAddPaymentMethodModal
  },
  computed: {
    ...mapGetters('account', ['insuredIds']),
  },
  methods: {
    ...mapMutations('common', ['handlePayDialog']),
    openDialog () {
      return this.handlePayDialog({
        isDialogVisible: true,
        currentSubMenu: 'payment-methods'
      })
    }
  },
}
</script>

<style scoped>

</style>
