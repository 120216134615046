// @flow
/* eslint-disable */

import {Policy} from '../models/flow.typings'

export default function agentKeys (policies: Array<Policy>): Array<string> {
  let agents = {}
  for (let i = 0; i < policies.length; i++) {
    let policy = policies[i]
    if (policy.agent_contact_id) {
      agents[policy.agent_contact_id] = 1
    }
    if (policy.agency_contact_id) {
      agents[policy.agency_contact_id] = 1
    }
  }
  return Object.keys(agents)
}
