import axios from 'axios'
import config from '@/../config/britecore'
import { isFailed } from '@shared_src/utils/api.utils'
import _ from 'lodash'

const agentsModule = {
  namespaced: true,
  state: {
    primaryCarrier: {},
    agentToPolicies: {}
  },
  mutations: {
    setPrimaryCarrier (state, payload) {
      state.primaryCarrier = payload
    },
    setAgentToPolicies (state, payload) {
      state.agentToPolicies = payload
    }
  },
  actions: {
    getPrimaryCarrier (context, payload) {
      return new Promise((resolve, reject) => {
        axios.post(config.get_primary_carrier_url, {
          token: localStorage.token
        }).then((response) => {
          if (isFailed(response)) {
            reject(response)
            return
          }

          // For carriers, only show Mailing Address
          let addresses = response.data.data.addresses
          let pickedAddress = _.find(addresses, address => address.type === 'Mailing')

          if (pickedAddress) {
            response.data.data.addresses = [pickedAddress]
          }

          context.commit('setPrimaryCarrier', response.data.data)
          resolve()
        }).catch((e) => {
          reject(e)
        })
      })
    },
    loadAgentsContactsData: function (context, payload) {
      return new Promise((resolve, reject) => {
        axios.post(config.get_contacts_data_url, {
          token: localStorage.token,
          contactIdList: payload.contactIdList
        }).then((response) => {
          if (isFailed(response)) {
            reject(response)
            return
          }
          let contactData = response.data.data

          let agentToPolicies = JSON.parse(JSON.stringify(context.state.agentToPolicies))
          for (let key in contactData) {
            if (!agentToPolicies.hasOwnProperty(key)) {
              // agent belongs to agency but agency alone doesn't have policies so it isn't here
              agentToPolicies[key] = {}
            }
            agentToPolicies[key].contact = contactData[key]
          }

          context.commit('setAgentToPolicies', agentToPolicies)
          resolve()
        }).catch((e) => {
          reject(e)
        })
      })
    }
  },
  getters: {
    onlyAgentIdsList: state => {
      return _.filter(Object.keys(state.agentToPolicies), (agentId) => {
        return state.agentToPolicies[agentId].type === 'agent'
      })
    },
    onlyAgencyIdsList: state => {
      return _.filter(Object.keys(state.agentToPolicies), (agentId) => {
        return state.agentToPolicies[agentId].type === 'agency'
      })
    },
    agentForPolicy: (state) => (policyId) => {
      let globalFound = null
      for (let singleAgentKey in state.agentToPolicies) {
        let singleAgent = state.agentToPolicies[singleAgentKey]
        let found = singleAgent.policies.find(_ => _.policy_id === policyId)
        if (found) {
          globalFound = singleAgent
          break
        }
      }

      return globalFound
    },
    uiAgentKeys: (state, getters, rootState, rootGetters) => {
      let getSetting = rootGetters['common/getSetting']
      let onlyDisplayAgencyContactInformation = getSetting('policies.only_display_agency_contact_information')
      let hideAgencyContactInformation = getSetting('global.hide_agent_and_agency_contact_info')
      if (onlyDisplayAgencyContactInformation === null || hideAgencyContactInformation === null) {
        return []
      }

      if (hideAgencyContactInformation) return []
      return onlyDisplayAgencyContactInformation
        ? getters.onlyAgencyIdsList : getters.onlyAgencyIdsList.concat(getters.onlyAgentIdsList)
    },
    uiAgents: (state, getters) => {
      return getters.uiAgentKeys.map(id => state.agentToPolicies[id])
    },
  }
}

export default agentsModule
