import config from '../config/britecore'
import settings from '../config/settings'
import axios from 'axios'
import {setupCache} from 'axios-cache-adapter'
import CryptoJS from 'crypto-js'

const cache = setupCache({
  maxAge: 15 * 60 * 1000
})

const api = axios.create({
  adapter: cache.adapter
})

function decrypt (data, secretKey) {
  let key = secretKey
  let iv = secretKey
  iv = CryptoJS.enc.Hex.parse(iv)
  key = CryptoJS.enc.Hex.parse(key)
  let cipher = CryptoJS.lib.CipherParams.create({
    ciphertext: CryptoJS.enc.Base64.parse(data)
  })

  return CryptoJS.AES.decrypt(cipher, key, {iv: iv, mode: CryptoJS.mode.CFB}).toString(CryptoJS.enc.Utf8)
}

export function retrieveAttachment (fileId, base64Decode, resized, secretKey, uniqueValueToIsolateCache, doNotCache) {
  return new Promise((resolve, reject) => {
    let useCache = doNotCache !== true  // don't cache if doNotCache explicitly passed as "true"
    api({
      url: config.get_encrypted_attachment,
      method: 'get',
      headers: {Authorization: localStorage.token},
      params: {
        file_id: fileId,
        company_id: settings.company_id,
        base64_decode: base64Decode,
        resized: resized,
        unique_value_to_isolate_cache: uniqueValueToIsolateCache
          // several users can access to same file_id+company_id.
          // uniqueValueToIsolateCache will help to isolate cache
      },
      useCache: useCache})
    .then(response => {
      let dataToSave = {
        data: decrypt(response.data.ciphertext, secretKey),
        contentType: response.headers['content-type'],
        contentDisposition: response.headers['content-disposition']
      }
      resolve(dataToSave)
    })
    .catch(error => {
      console.error('get_encrypted_attachment failed', error)
      reject(error)
    })
  })
}
