<template>
  <div v-loading="loading">
    <div v-if="photoId" @click="photoViewerVisible = true" class="photo-thumbnail">
      <div v-cloak v-if="isVisibleCount && photosList.length > 0" class="photo-counter">
        <fa-icon :icon="[ 'fas', 'clone']" class="image-icon u-white" />
        <span class="image-amount">{{ photosList.length }}</span>
      </div>
      <bc-general-image :fileId="photoId" @imageLoaded="isVisibleCount = true"/>
    </div>
    <div v-if="!photoId" class="image-card__no-photo"> {{ t('policies.no_photos') }}</div>
    
    <el-dialog custom-class="dialog-slider"
               :title="dialogTitle"
               :visible.sync="photoViewerVisible && photosList.length > 0"
               :close-on-press-escape="true"
               :destroy-on-close="true"
               @open="attachHotkeys"
               @close="dialogClosed"
               >
      <el-carousel v-if="propertyData"
                   ref="carousel"
                   trigger="click"
                   :autoplay="false"
                   class="property-slider"
                   indicator-position="none"
                   @change="showActionView('info')"
                   arrow="always">
        <el-carousel-item v-for="item in photosList" :key="item.id">
          <div v-loading="loading">
            <div class="property-slider__frame">
              <fa-icon v-if="!isCarrierPhoto(item.id)" :icon="[ preferredPhoto.id  === item.id ? 'fas' : 'far', 'star']"
                       @click="setPreferredPhoto(item)"
                       class="image-icon"
              ></fa-icon>
              <div>
                <bc-general-image :fileId="item.id" class="bc-general-image"></bc-general-image>
              </div>
            </div>
          
            <div v-if="action.info" class="property-slider__info u-mt2">
              <div v-if="isCarrierPhoto(item.id)" class="property-slider__caption">
                {{ t('policies.added_by_your_agent') }}
              </div>
              <div v-if="!isCarrierPhoto(item.id)" class="u-flex-horizontal-space-between u-width100">
                <div class="property-slider__caption">{{ item.caption | capitalize }}</div>
                <div class="property-slider__modify">
                  <el-button @click.stop="showActionView('edit', item.caption)" :icon="['fas', 'edit']" type="info" :disabled="loading"></el-button>
                  <el-button @click.stop="showActionView('delete')" :icon="['fas', 'trash']" type="info" :disabled="loading"></el-button>
                </div>
              </div>
            </div>
            
            <div v-if="action.delete" class="property-slider__info u-mt2">
              <div>{{ t('policies.are_you_sure_you_want_to_delete_photo')  }}</div>
              <div class="property-slider__modify">
                <el-button @click.stop="showActionView('info')" type="info" size="small" class="u-text-uppercase" :disabled="loading">
                  {{ t('global.cancel') }}
                </el-button>
                <el-button @click.stop="removeItem(item)" type="danger" size="small" class="u-text-uppercase" :disabled="loading">
                  {{ t('global.delete') }}
                </el-button>
              </div>
            </div>
          
            <div v-if="action.edit" class="property-slider__info u-mt2">
             <el-input class="property-slider__input" type="text"
                       :placeholder="item.caption"
                       v-model="currentCaption"/>
              <div class="property-slider__modify">
                <el-button @click.stop="showActionView('info')" type="info" size="small" class="u-text-uppercase" :disabled="loading">
                  {{ t('global.cancel') }}
                </el-button>
                <el-button @click.stop="updateItem(item)" type="primary" size="small" class="u-text-uppercase" :disabled="loading">
                  {{ t('global.save_button') }}
                </el-button>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </el-dialog>
    
  </div>
</template>

<script>
  import axios from 'axios'
  import config from '../../config/britecore'
  import {mapActions} from 'vuex'
  import BcImagesTable from '../components/ImagesTable'
  
  export default {
    name: 'bc-property-images-block',
    components: {
      BcImagesTable
    },
    props: {
      propertyId: {
        type: String,
        required: true,
      }
    },
    mounted () {
      // Reload property photos after uploading of new photo
      this.$root.$on('reloadPropertyPhotos', (id) => {
        if (id === this.propertyId) {
          return this.load()
        }
      })
    },
    data () {
      (async () => {
        await this.load()
      })()
      return {
        loading: true,
        currentPhotoId: '',
        propertyData: null,
        photoViewerVisible: false,
        isChangedPhotoData: false,
        currentCaption: '',
        isVisibleCount: false,
        action: {
          info: true,
          delete: false,
          edit: false,
        }
      }
    },
    computed: {
      photoId () {
        // determines id of the photo shown as a preview
        if (!this.propertyData) return
        if (this.propertyData.photos.preferred) {
          return this.propertyData.photos.preferred.id
        }
        if (this.propertyData.photos.insured.length > 0) {
          return this.propertyData.photos.insured[0].id
        }
        if (this.propertyData.photos.carrier.length > 0) {
          return this.propertyData.photos.carrier[0].id
        }
      },
      photosList () {
        return this.propertyData ? this.insuredPhotos.concat(this.carrierPhotos) : []
      },
      carrierPhotos () {
        return this.propertyData ? this.propertyData.photos.carrier : []
      },
      insuredPhotos () {
        return this.propertyData ? this.propertyData.photos.insured : []
      },
      preferredPhoto () {
        return this.propertyData && this.propertyData.photos.preferred ? this.propertyData.photos.preferred : []
      },
      dialogTitle () {
        return this.propertyData ? this.propertyData.property.address_line1 + ' ' + this.t('claims.photos_section_title')
          : this.t('claims.photos_section_title')
      },
    },
    methods: {
      ...mapActions('policies', ['updatePropertyPhoto', 'deletePropertyPhoto']),
      isCarrierPhoto (id) {
        return this.carrierPhotos.some(el => el.id === id)
      },
      hotkeyHandler (evt) {
        if (evt.key === 'ArrowRight') {
          this.$refs.carousel.next()
        }
        if (evt.key === 'ArrowLeft') {
          this.$refs.carousel.prev()
        }
      },
      detachHotkeys () {
        document.removeEventListener('keydown', this.hotkeyHandler)
      },
      attachHotkeys () {
        document.addEventListener('keydown', this.hotkeyHandler)
      },
      dialogClosed () {
        this.detachHotkeys()
        this.photoViewerVisible = false
        if (this.isChangedPhotoData) {
          this.isChangedPhotoData = false
          this.propertyData = null
          this.load()
        }
      },
      async load () {
        try {
          this.loading = true
          let response = await axios.post(config.get_property_url, {
            token: localStorage.token,
            property_id: this.propertyId
          })
          if (response) {
            this.propertyData = response.data.data
          }
        } catch (e) {
          console.log('e: ', e)
          this.$elAlert(this.t('policies.error_retrieving_property_information'), this.t('global.notice'))
        } finally {
          this.loading = false
        }
      },
      showActionView (view, caption) {
        this.currentCaption = ''
        for (let el in this.action) {
          if (el === 'edit') this.currentCaption = caption
          el === view ? this.action[el] = true : this.action[el] = false
        }
      },
      setPreferredPhoto (item) {
        if (this.preferredPhoto.id === item.id) return
        const initialCaption = this.propertyData.photos.insured.filter(el => el.id === item.id)[0].caption
        this.currentCaption = initialCaption
        return this.updateItem(item, true)
      },
      updateItem (item, isPreferredPhoto) {
        let propertyId = this.propertyId
        let caption = this.currentCaption
        let setAsPreferred = isPreferredPhoto || false
        let fileId = item.id
        let payload = {propertyId, caption, setAsPreferred, fileId}
        this.loading = true
        this.updatePropertyPhoto(payload).then(() => {
          this.isChangedPhotoData = true
          this.load()
          this.showActionView('info')
        }).catch((e) => {
          this.loading = false
          console.warn('Error when trying to update a photo', e)
          this.$elAlert(this.t('policies.error_retrieving_property_information'), this.t('global.notice'))
        }).finally(() => {
          // loading = false will be set inside finally block in `load` method
        })
      },
      async removeItem (item) {
        this.logInsuredAction('Clicked "Delete Photo for property"', 'custom_property_photo_delete', {})
        this.loading = true
        try {
          await this.deletePropertyPhoto(item.id)
        } catch (e) {
          this.$elAlert(this.t('enrollment.some_error_happened_notification'), this.t('global.error'))
          this.loading = false
          return
        }
        await this.load()
        this.showActionView('info')
        this.isChangedPhotoData = true
      },
    }
  }
</script>

<style scoped lang="scss">
  
  .photo-thumbnail {
    cursor: pointer;
    position: relative;
    .photo-counter {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 24px;
    }
    .image-amount {
      position: absolute;
      top: 3px;
      right: 4px;
      font-size: 12px;
      font-weight: 600;
    }
  }
</style>

<style lang="scss">
  @import '../sass/_variables.scss';
  
  .dialog-slider {
    width: 550px;
    max-height: 65vh;
  
    @media only screen and (max-width: $m-screen) {
      margin-left: auto !important;
      margin-right: auto !important;
    }
    
    @media only screen and (max-width: $s-screen) {
      max-height: 65vh;
      width: 550px;
    }
    
    [aria-label="Close"] {
      svg.el-dialog__close {
        font-size: 25px;
        color: #626262 !important;
      }
    }
    
    .el-dialog__title {
      @media only screen and (max-width: $s-screen) {
        display: inline-block;
        margin-right: 25px;
      }
    }
    
    .el-dialog__body {
      padding-top: 0 !important;
      
      .property-slider {
        &__frame {
          width: 100%;
          position: relative;
          text-align: center;
          img.bc-general-image {
            max-width: 100%;
            max-height: 45vh;
            @media only screen and (max-width: $m-screen) {
              max-height: 35vh;
            }
          }
        }
        &__info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: $color-black;
          font-size: 16px;
  
          @media only screen and (max-width: $m-screen) {
            flex-direction: column;
            justify-content: center;
          }
        }
        
        &__caption{
          font-size: 16px;
          color: #626262;
        }
        
        &__modify {
          display: flex;
          
          @media only screen and (max-width: $m-screen) {
            margin-top: 10px;
          }
          
          & > div {
            width: 50px;
          }
        }
        &__input {
          display: inline-block;
          width: 100%;
          margin-right: 10px;
          
          @media only screen and (max-width: $m-screen) {
            margin-right: 0;
          }
        }
        .el-carousel__container {
          
          @media only screen and (max-width: $m-screen) {
            height: 45vh !important;
          }
        }
        button.el-carousel__arrow {
          border-radius: 18px !important;
          background-color: transparentize(#626262, 0.5);
          top: 30%;
          &:hover {
            color: transparentize($color-primary, .1) !important;
            background-color: transparentize(#626262, 0.3) !important;
          }
          svg[data-icon^="chevron"] {
            position: relative;
            top: 5px;
            font-size: 1.5em;
          }
        }
      }
    }
  }

  svg.image-icon {
    font-size: 16px !important;
    width: 25px !important;
  }
  
</style>
