<template>
  <el-form-item :label="t('enrollment.date_of_birth')" class="dob-form-field">
    <input autofocus @keyup="check" placeholder="mm/dd/yyyy" v-model="codeDOB" ref="picker"
           type="text" class="el-input__inner">
      <el-date-picker v-model="defineShowPageDOB" ref="nbv" @change="check" :picker-options="pickerOptions" type="date" format="MM/dd/yyyy"
                      value-format="MM/dd/yyyy" class="dob-form-field__date-picker">
    </el-date-picker>
  </el-form-item>
</template>
<script>
  /* eslint-disable */
  import moment from 'moment'

  export default {
    name: 'date-picker',
    props: {
      showPage: {},
    },
    mounted() {
      this.getInputValue();
      this.transformInputValue();
    },
    data () {
      let _this = this
      return {
        innerValue: '',
        previousValue: '',
        invalidateValue: '',
        addCaretStep: 0,
        insuredDOB: '',
        code: '',
        pickerOptions: {
          shortcuts: [
            {
              text: '10 years backwards',
              onClick (picker) {
                const start = new Date()
                let backwardDate = moment(picker.date.getTime()).subtract(10, 'years')
                start.setTime(backwardDate)
                picker.$emit('pick', start)
                _this.$nextTick(() => {
                  _this.$refs.nbv.focus()
                })
              }
            },
            {
              text: '10 years forward',
              onClick (picker) {
                const start = new Date()
                let forwardDate = moment(picker.date.getTime()).add(10, 'years')
                start.setTime(forwardDate)
                picker.$emit('pick', start)
                _this.$nextTick(() => {
                  _this.$refs.nbv.focus()
                })
              }
            }
          ],
          disabledDate (time) {
            return time.getTime() > new Date(new Date().setDate(new Date().getDate()))
          }
        }
      }
    },
    methods: {
      updateDate() {
        if(this.showPage ==="challenge") {
          this.$emit('updateDatePicker', this.code)
        }
        if(this.showPage === "search") {
          this.$emit('updateDatePicker', this.insuredDOB)
        }
      },
      check(event) {
        this.updateDate()
        if (event.key === 'Enter') {
          this.$emit('willSubmit')
        }

        if (["Delete", "Backspace"].indexOf(event.key) === -1) {
          return
        }
        event.stopPropagation()
        event.preventDefault()
        let pos = this.$refs.picker.selectionStart
        let cc = this.codeDOB
        let start = cc.slice(0, pos)
        let end = cc.slice(pos)
        if (event.key === 'Delete') {
          if (end.startsWith('/')) {
            end = end.slice(2)
          } else {
            // Nothing to do here, let the input handle event
          }
          this.addCaretStep = pos
        } else {
          if (start.endsWith('/')) {
            start = start.slice(0, -2)
            this.addCaretStep = pos -2
          } else {
            //  nothing to do here as well
          }
        }

        this.codeDOB = start + end
      },
      getInputValue() {
        let dob
        if(this.showPage ==="challenge") {
          dob = this.code
        }
        if(this.showPage === "search") {
          dob = this.insuredDOB
        }
        this.invalidateValue // dirty trick to force input have codeDOB value
        this.previousValue = dob || this.innerValue
        if (this.addCaretStep !== -1 && this.$refs.picker) {
          this.$nextTick(() => {
            this.$refs.picker.selectionEnd = this.addCaretStep
            this.addCaretStep = -1
          })
        }
        return dob || this.innerValue
      },
      transformInputValue(newValue) {
        this.invalidateValue = new Date()  // dirty trick to force input have codeDOB value
        if (this.showPage === "challenge") {
          if (!newValue) {
            this.innerValue = ''
            this.code = ''
            this.updateDate()
            return
          }
          let f = /^\d{2}\/\d{2}\/\d{4}$/g
          let x = newValue.match(f)
          if (x !== null) {
            this.code = newValue
            this.innerValue = newValue
            this.updateDate()
            return
          }
          this.code = ''
          let z = newValue.replace(/\D/g, '')
          if (z.length > 8) {
            z = z.slice(0, 8)
          }
          let dd = z.length
          let res = z
          if (dd > 2) {
            res = z.slice(0, 2) + '/' + z.slice(2)
          }
          if (dd > 4) {
            res = z.slice(0, 2) + '/' + z.slice(2, 4) + '/' + z.slice(4)
          }
          this.innerValue = res
          x = res.match(f)
          if (x !== null) {
            this.code = res
            this.updateDate()
            return
          }
        }
        if (this.showPage === "search") {
          if (!newValue) {
            this.innerValue = ''
            this.insuredDOB = ''
            this.updateDate()
            return
          }
          let f = /^\d{2}\/\d{2}\/\d{4}$/g
          let x = newValue.match(f)
          if (x !== null) {
            this.insuredDOB = newValue
            this.innerValue = newValue
            this.updateDate()
            return
          }
          this.insuredDOB = ''
          let z = newValue.replace(/\D/g, '')
          if (z.length > 8) {
            z = z.slice(0, 8)
          }
          let dd = z.length
          let res = z
          if (dd > 2) {
            res = z.slice(0, 2) + '/' + z.slice(2)
          }
          if (dd > 4) {
            res = z.slice(0, 2) + '/' + z.slice(2, 4) + '/' + z.slice(4)
          }
          this.innerValue = res
          x = res.match(f)
          if (x !== null) {
            this.insuredDOB = res
            this.updateDate()
            return
          }
        }
      },
    },
    computed: {
      defineShowPageDOB: {
        get () {
          return this.showPage === 'challenge' ? this.code : this.insuredDOB
        },
        set (val) {
          return this.showPage === 'challenge' ? this.code = val : this.insuredDOB = val
        }
      },
      codeDOB: {
        get () {
          return this.getInputValue()
        },
        set (newValue) {
          return this.transformInputValue(newValue)
        }
      },
    }
  }
</script>
<style lang="scss">
  .dob-form-field {
    display: flex;
    flex-direction: column;
    align-items: baseline;

    &__date-picker {
      width: 35px !important;
      cursor: pointer;
      margin-top: 1px;

      &:hover {
        opacity: .6;
      }
    }
  }

</style>
