module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    staticClass: "ba-alert u-p3",
    class: ("ba-alert--" + _vm.type)
  }, [_c('div', {
    staticClass: "ba-alert__icon u-pr2"
  }, [_c('fa-icon', {
    class: ("u-mr2 bc-icon bc-color--" + _vm.type + " " + _vm.getIconType),
    attrs: {
      "icon": "exclamation-circle",
      "fixed-width": ""
    }
  })], 1), _vm._v(" "), _vm._t("default", function() {
    return [_vm._v("Alert Message")]
  })], 2)
},staticRenderFns: []}