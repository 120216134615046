// THIS FILE IS GOING TO BE OVERWRITTEN ON BUILD SERVER
var port = 8081

// useful to test from real mobile device while running dev server
var apiRoot = window.location.protocol + '//'+window.location.hostname + ':' + port

if (process.env.API_ROOT) {
  apiRoot = process.env.API_ROOT
}

if (window.DOCKER_API_ROOT) {
  apiRoot = window.DOCKER_API_ROOT
}


var config = {
  url: apiRoot,
  initial_url: apiRoot,
}

config.regenerateConfig = function () {
  config.get_company_settings_url = config.url + '/get_company_settings/'
  config.get_attachment_all_url = config.url + '/get_attachment_all/'
  config.get_attachment_all_url_v2 = config.url + '/get_attachment_all_v2/'
  config.get_encrypted_attachment = config.url + '/get_encrypted_attachment/'
  config.get_contact_data_url = config.url + '/get_contact_data/'
  config.ajax_login_url = config.url + '/ajax_login/'
  config.check_token_url = config.url + '/check_token/'
  config.auth_by_pin_url = config.url + '/auth/by-pin/'
  config.get_account_history_url = config.url + '/get_account_history/'
  config.get_policies_url = config.url + '/get_policies/'
  config.get_owned_payment_methods_url = config.url + '/get-owned-payment-methods/'
  config.get_insured_details_from_policy_number_url = config.url + '/ajax-get-insured-details-from-policy-number/'
  config.add_missing_policy = config.url + '/add-missing-policy/'
  config.advance_enrollment_answering_challenge_url = config.url + '/ajax-advance-enrollment-answering-challenge/'
  config.send_contact_challenge_url = config.url + '/ajax-send-contact-challenge/'
  config.get_agents_url = config.url + '/get-agents/'
  config.get_primary_carrier_url = config.url + '/get-primary-carrier/'
  config.get_account_information = config.url + '/ajax-get-account-information/'
  config.update_pin = config.url + '/update_pin/'
  config.update_consent = config.url + '/update-consent-to-text/'
  config.change_password = config.url + '/ajax-change-password/'
  config.remove_contact_information = config.url + '/ajax-remove-contact-information/'
  config.validate_username = config.url + '/ajax-validate-username/'
  config.get_attachments_file_list_url = config.url + '/get_attachments_file_list/'
  config.change_billing_schedule = config.url + '/ajax-change-billing-schedule/'
  config.upload_attachment_to_user_folder_url = config.url + '/upload_attachment_to_user_folder/'
  config.remove_attachments_url = config.url + '/remove_attachments/'
  config.get_claim_url = config.url + '/get_claim/'
  config.file_claim_url = config.url + '/file-claim/'
  config.add_claim_photo = config.url + '/add_claim_photo/'
  config.get_property_url = config.url + '/get_property/'
  config.get_property_photo_metadata_url = config.url + '/get_property_photo_metadata/'
  config.get_policy_terms_url = config.url + '/get_policy_terms/'
  config.get_contact_url = config.url + '/get_contact/'
  config.get_company_content = config.url + '/get_company_content/'
  config.get_policy_billing_information_url = config.url + '/get_policy_billing_information/'
  config.retrieve_billing_schedule_options = config.url + '/retrieve_billing_schedule_options/'
  config.ajax_add_payment_method_url = config.url + '/ajax-add-payment-method/'
  config.remove_payment_method_url = config.url + '/remove-payment-method/'
  config.ajax_enrollment_sign_up_url = config.url + '/enrollment-sign-up/'
  config.add_property_photo_url = config.url + '/add_property_photo/'
  config.modify_property_photo_url = config.url + '/modify_property_photo/'
  config.delete_property_photo_url = config.url + '/delete_photo/'
  config.get_contacts_data_url = config.url + '/get-contacts-data/'
  config.get_unauthorized_contact_data = config.url + '/ajax-get-unauthorized-contact-data/'
  config.send_recovery_code = config.url + '/ajax-send-recovery-code/'
  config.create_folder_in_user_folder = config.url + '/create_folder_in_user_folder/'
  config.rename_user_file = config.url + '/rename_user_file/'
  config.move_user_file_to_folder = config.url + '/move_user_file_to_folder/'
  config.approve_tos_url = config.url + '/approve-tos/'

  config.update_account_information_new = config.url + '/update-account-information/'
  config.get_personal_secret_key = config.url + '/get-personal-secret-key/'
  config.get_session_expiration = config.url + '/get-session-expiration/'

  config.is_insured_enrolled_url = config.url + '/is-insured-enrolled/'

  config.set_user_device_token = config.url + '/set_user_device_token/'
  config.log_insured_action = config.url + '/log_insured_action/'

  config.find_user_for_enrollment = config.url + '/find-user-for-enrollment/'

  config.update_delivery_type = config.url + '/update-delivery-type/'

  config.change_username = config.url + '/change_username/'
  config.confirm_email_by_code = config.url + '/confirm_email_by_code/'
  config.change_email = config.url + '/change_email/'

  config.enrollment_email_check = config.url + '/enrollment_email_check/'
  config.enrollment_sign_up_with_code = config.url + '/enrollment_sign_up_with_code/'

  config.advance_answering_challenge_and_attach_contact_to_user = config.url + '/advance-answering-challenge-and-attach-contact-to-user/'
  config.get_riskdata_url = config.url + '/get_risk_data/'

  config.update_user_phones = config.url + '/update_user_phones/'
  config.update_temporary_password = config.url + '/update_temporary_password/'

  config.get_change_autopay_single_url = config.url + '/change_autopay_single/'

  config.claims_gen3_url = config.url + '/claims-gen3/'

  config.auth_by_token_url = config.url + '/v2/auth/token/'
  config.payment_reminders_url = config.url + '/payment-reminders/'

  config.get_revision_by_date_url = config.url + '/get-revision-by-date/'

  config.claims_gen3_timezones_with_states_url = config.url + '/claims-timezones-with-states/'
}

config.regenerateConfig()

config.overrideApiUrl = function (newUrl) {
  config.url = newUrl
  config.regenerateConfig()
}

window.___config = config

var tag = 'local'
if (window.DOCKER_IMAGE_TAG) {
  tag = window.DOCKER_IMAGE_TAG
}

config.DOCKER_IMAGE_TAG = tag

export default config
