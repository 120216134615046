module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    staticClass: "missing-policy__success"
  }, [_c('div', {
    staticClass: "u-text--bold missing-policy__success-text"
  }, [_c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: (_vm.state.loading),
      expression: "state.loading"
    }],
    staticClass: "u-mr4"
  }, [_c('fa-icon', {
    staticClass: "fa-2x missing-policy__success-icon u-ml1",
    class: _vm.state.loading ? 'missing-policy__success-icon--pending' : '',
    attrs: {
      "icon": ['fa', 'check-circle']
    }
  })], 1), _vm._v(" "), _c('div', [_vm._v("\n      " + _vm._s(_vm.t('enrollment.policies_were_attached_successfully')) + "\n    ")])]), _vm._v(" "), (_vm.state.loading) ? _c('div', {
    staticClass: "u-text--bold u-text-center u-mt4"
  }, [_vm._v(_vm._s(_vm.t('global.loading')))]) : _vm._e()])
},staticRenderFns: []}