<template>
    <div @click="showPreview" :class="bgClass">
        <el-footer :title="version"> {{t('global.footer', {current_year: currentYr})}}</el-footer>
    </div>
</template>

<script>
  import {mapState} from 'vuex'
  import moment from 'moment'

  export default {
    name: 'bc-footer',
    props: {
      bgClass: String,
    },
    data () {
      return {
        counter: 0,
        currentYr: moment().format('YYYY')
      }
    },
    computed: {
      ...mapState('common', ['version']),
      ...mapState('policies', ['policies']),
    },
    methods: {
      showPreview () {
        this.counter++
        if (this.counter === 10 && this.$route.path === '/dashboard') {
          this.counter = 0
          this.$router.push('/testing-all-controls')
        }
      }
    }
  }
</script>
