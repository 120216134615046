<template>
  <div>
    <div v-for="section in riskData" v-bind:key="section.id">
      <div class="one-section  u-mt4 u-mb0">
        <h2 v-if="showHeader(section)" class="secondary-header">{{ t(section.heading) }} ({{section.items.length}})</h2>
        <el-row :gutter="30">
          <el-col v-for="(riskComponent, index) in section.items" :key="index" :xs="24" :span="itemsPerRow(section) * 2">
            <el-card shadow="never">
              <div class="u-mb2">
                <ba-risk-icon class="u-mr2 u-color-secondary"
                              :risk-component="riskComponent"/>
                {{riskComponent.name}}
              </div>
              <ba-risk-repeated-fields
                  class="one-section__item"
                  v-if="riskComponent.fields"
                  :fields="riskComponent.fields"
                  :riskType="riskComponent.type"/>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'
  import BaRiskRepeatedFields from './dynamic/repeated-fields.component'
  import BaRiskIcon from '@shared_src/policies/risk-icon.component'

  export default {
    name: 'RiskDetails',
    components: { BaRiskIcon, BaRiskRepeatedFields },
    props: ['policyId'],
    computed: {
      ...mapGetters('policies', ['rawRiskDataForPolicy']),
      riskData () {
        let riskData = this.rawRiskDataForPolicy(this.policyId)
        if (riskData) {
          return riskData.sections
        }
      },
    },
    methods: {
      itemsPerRow (riskItem) {
        switch (riskItem.component) {
        case 'driver':
          return 12 / 3
        case 'vehicle':
          return 12
        }

        return 12
      },
      showHeader (section) {
        if ((section.items && section.items.length > 0) ||
          (section.fields && section.fields.length > 0)) {
          return true
        }
        return false
      },

    },
  }
</script>
<style>
  .one-section {

  }

  .subheading {
    margin-left: 10px;
    margin-top: -10px;
  }
</style>
