<template>

  <el-popover
    v-model="visible" v-if="currentPages && currentPages.length > 0"
    :placement="placement"
    width="240"
    trigger="click"
    popper-class="popover-account"
  >
    <div>
      <div v-for="page in currentPages" :key="page.id" class="account-link sidenav__popover-link">
        <router-link :to="{ path: `/custom/${page.slug}` }"
                     @click.native="managePopover"
                     active-class="account-link__active"
                     class="u-inline-block u-text-uppercase"
        >
          {{ page.title }}
        </router-link>
      </div>
    </div>
      <div slot="reference"
           class="sidenav__popover-button u-text--uppercase"
           :class="visible ? 'el-button--side-nav_active' : ''"
      >
        <div class="sidenav__txt-line">
          <fa-icon :icon="[ 'fal', 'layer-group']" class="layer-icon image-icon u-text--light-gray text-center" />
          <div class="u-ml2 u-mr3">{{ t('global.custom_pages_list_label') }}</div>
          <fa-icon :icon="['far', 'chevron-down']" :class="[visible ? 'u-rotate-180' : '', 'link-icon-small']" class="layer-icon" />
        </div>
      </div>
  </el-popover>
</template>

<script>
  import {mapGetters} from 'vuex'
  
  export default {
    props: ['onlyPublic', 'placement'],
    name: 'ba-custom-pages',
    data () {
      return {
        visible: false
      }
    },
    computed: {
      ...mapGetters('customPages', ['publishedPages', 'publicPages']),
      currentPages () {
        if (this.onlyPublic) {
          return this.publicPages
        }
        return this.publishedPages
      },
    },
    methods: {
      managePopover () {
        this.visible = !this.visible
        this.$emit('hideMobile')
      }
    }
  }
</script>

<style lang="scss">
  @import '../sass/_variables.scss';
  
  .sidenav__link {
    .sidenav__popover-button,
    svg.layer-icon path{
      box-shadow: none;
      fill: #626262;
      color: #626262;
      text-decoration: none;
      transition: opacity .3s ease;
      background: none !important;
      &:hover {
        box-shadow: none;
        fill: #626262;
        color: #626262 !important;
        opacity: .6;
        text-decoration: none;
        transition: opacity .3s ease;
        background: none !important;
      }
    }
  }

  .sidenav__current {
    .sidenav__popover-button {
      color: $color-primary !important;
      transition: opacity .3s ease;
      &:hover {
        transition: opacity .3s ease;
        color: $color-primary !important;
      }
    }
  }
  .sidenav__txt-line {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 16px;
  }
  
  .sidenav__popover-button {
    cursor: pointer;
    display: flex;
    &:hover {
      color: $color-primary;
    }
  }

  .sidenav__current {
    .sidenav__popover-button {
      color: $color-primary;
    }
  }
  .sidenav__popover-link {
    font-size: 16px;
    &:hover {
      text-decoration: none !important;
    }
  }
</style>
