import axios from 'axios'
import store from '../store'
import {setCookie} from '../../shared_src/utils/api.utils'

axios.interceptors.request.use(
  function (config) {
    let version = store.getters['common/version']
    if (config.url.indexOf('ident.me') >= 0) {
      return config
    }
    if (version) {
      config.headers['X-UI-Version'] = JSON.stringify(version.replace(/\n/g, ''))
    }
    return config
  },
  function (error) {
    console.warn('axios.interceptors.request.use error', error)
    // Do something with request error
    return Promise.reject(error)
  })

axios.interceptors.response.use(
  function (response) {
    let token = response.data.token
    if (token) {
      // if we have token in response - update token we have in storage
      store.commit('login/updateToken', token)
      setCookie('user_online', store.getters['common/contactName'], store.getters['common/sessionExpiration'])
    }
    return response
  },
  function (error) {
    if (!error.response || error.response.status !== 401) {
      // if it is regular error - rethrow error
      return Promise.reject(error)
    }
    // from here is only 401 cases

    let currentPath = window.__vue_router.currentRoute.path
    store.commit('login/updateToken', null)
    localStorage.setItem('login__token-expired', true)
    if (
      (currentPath === '/login') || // login page
      (currentPath !== null && currentPath.indexOf('/enrollment/') === 0) // enrollment - no need to raise error
    ) {
      return Promise.reject(error)
    }

    localStorage.setItem('last_location', window.__vue_router.currentRoute.path)
    if (!window.non_autorized_request_ocurred) {
      window.non_autorized_request_ocurred = true
      window.__vue_router.replace('/login?redir=1')

      window.non_autorized_request_ocurred = undefined
    }

    return new Promise((resolve, reject) => {})
  }
)

