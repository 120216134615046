<template>
  <div>
    <div class="u-mt1 u-mb4" v-html="t('enrollment.add_missing_policy_search_policy_step_text', {
      carrier_name: primaryCarrier.name})">
    </div>
    <el-input type="text" v-model="internalValue">{{ t('enrollment.policy_number_input_label') }}</el-input>
  </div>
</template>
<script>
  import {mapState} from 'vuex'

  export default {
    name: 'ba-search-by-policy-number',
    props: {
      value: String
    },
    computed: {
      ...mapState('agents', ['primaryCarrier']),
      internalValue: {
        get () {
          return this.value
        },
        set (val) {
          this.$emit('input', val)
        }
      },
    },
  }
</script>
