module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: (_vm.loading),
      expression: "loading"
    }],
    staticClass: "file-claim-component"
  }, [_c('h2', {
    staticClass: "secondary-header u-mr3 u-mt2 u-mb2"
  }, [_vm._v(_vm._s(_vm.t('claims.file_claim_headline')))]), _vm._v(" "), (_vm.fileClaimByForm) ? _c('div', [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.t('claims.custom_claims_form_html'))
    }
  })]) : _c('div', [_c('div', {
    staticClass: "u-mb4"
  }, [_c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.t('claims.file_claim_text'))
    }
  })]), _vm._v(" "), _c('div', {
    staticClass: "file-claim-forms__fields u-mnb6"
  }, [_c('el-form', {
    staticClass: "inputs-list u-mb3 u-max-width "
  }, [(!_vm.policySelectorReadOnly && _vm.policies) ? _c('div', {
    staticClass: "list-item--select"
  }, [_c('span', {
    staticClass: "u-block u-text-uppercase subheader"
  }, [_vm._v(_vm._s(_vm.t('claims.policy_number_title')) + " *")]), _vm._v(" "), _c('el-select', {
    staticClass: "item-input-field u-block select-input",
    attrs: {
      "placeholder": "",
      "disabled": _vm.policySelectorReadOnly
    },
    model: {
      value: (_vm.selectedPolicyId),
      callback: function($$v) {
        _vm.selectedPolicyId = $$v
      },
      expression: "selectedPolicyId"
    }
  }, [_c('template', {
    slot: "prefix"
  }, [(_vm.getCurrentIcon) ? _c('fa-icon', {
    staticClass: "link-icon-medium prefix-icon u-text--lg",
    attrs: {
      "icon": _vm.getCurrentIcon
    }
  }) : _vm._e()], 1), _vm._v(" "), _vm._l((_vm.activePoliciesList), function(policy) {
    return _c('el-option', {
      key: policy.id,
      attrs: {
        "label": _vm.t('claims.policy_select_prefix') + ' ' + policy.policy_number + ' (' + policy.policy_status + ')',
        "value": policy.id
      }
    }, [_c('fa-icon', {
      staticClass: "link-icon-medium",
      attrs: {
        "icon": _vm.determinePolicyIcon(policy)
      }
    }), _vm._v(" "), _c('span', {
      staticClass: "u-pl1"
    }, [_vm._v(_vm._s(_vm.t('claims.policy_select_prefix') + ' ' + policy.policy_number + ' (' + policy.policy_status + ')'))])], 1)
  })], 2)], 1) : _vm._e(), _vm._v(" "), (_vm.policySelectorReadOnly && _vm.selectedPolicy) ? _c('div', [_c('div', {
    staticClass: "item-content reduced-min-height u-mb4"
  }, [_c('div', {
    staticClass: "item-inner reduced-min-height u-text--lg"
  }, [_vm._v("\n              " + _vm._s(_vm.t('claims.policy_select_prefix')) + _vm._s(_vm.selectedPolicy.policy_number) + "\n            ")])])]) : _vm._e()])], 1), _vm._v(" "), (_vm.selectedPolicy) ? _c('div', [(_vm.isGen3Selected) ? _c('div', [_c('file-claim-gen3', {
    key: _vm.selectedPolicyId,
    attrs: {
      "policyId": _vm.selectedPolicyId
    },
    on: {
      "closeClaimDialog": _vm.closeClaimDialog
    }
  })], 1) : _c('div', [_c('file-claim-gen2', {
    key: _vm.selectedPolicyId,
    attrs: {
      "policyId": _vm.selectedPolicyId
    },
    on: {
      "closeClaimDialog": _vm.closeClaimDialog
    }
  })], 1)]) : _vm._e()])])
},staticRenderFns: []}