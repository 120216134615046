<template>
  <div>
    <bc-navbar :title="t('documents.documents_label')"></bc-navbar>
    <bc-policy-list v-if="policiesListByType" :policiesListByType="policiesListByType" screenType="documents" />
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'
  import PolicyList from '@/components/policy-list.vue'
  
  export default {
    name: 'documents',
    components: {
      'bc-policy-list': PolicyList
    },
    computed: {
      ...mapGetters('policies', ['policiesListByType'])
    }
  }
</script>
