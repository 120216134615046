module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: (_vm.loading),
      expression: "loading"
    }],
    staticClass: "init-screen"
  }, [_c('init-sidebar'), _vm._v(" "), _c('div', {
    staticClass: "init-screen__info centered-block"
  }, [_c('div', {
    staticClass: "init-block"
  }, [_c('div', {
    staticClass: "navigation"
  }, [_c('div', {
    staticClass: "init-block__back",
    on: {
      "click": function($event) {
        return _vm.$router.back()
      }
    }
  }, [_c('ba-icon-link', {
    attrs: {
      "icon_type": "far",
      "icon_name": "arrow-left",
      "icon_class": "u-pl2",
      "label": _vm.t('documents.back')
    }
  })], 1)]), _vm._v(" "), _c('h1', {
    staticClass: "init-block--heading"
  }, [_vm._v(_vm._s(_vm.t('reset_password.header')))]), _vm._v(" "), _c('div', {
    staticClass: "subheader u-mb3"
  }, [_vm._v(_vm._s(_vm.t('reset_password.enter_new_password')))]), _vm._v(" "), _c('div', [_c('el-form', [_c('el-form-item', {
    attrs: {
      "label": _vm.t('reset_password.mfa_code_field')
    }
  }, [_c('el-input', {
    staticClass: "item-input-field",
    class: {
      'u-input-error': _vm.mfaValidationMessages.length > 0
    },
    attrs: {
      "type": "tel",
      "maxlength": 6
    },
    model: {
      value: (_vm.mfaCode),
      callback: function($$v) {
        _vm.mfaCode = $$v
      },
      expression: "mfaCode"
    }
  }), _vm._v(" "), (_vm.mfaValidationMessages.length > 0) ? _c('div', {
    staticClass: "u-warning"
  }, _vm._l((_vm.mfaValidationMessages), function(validationMessage) {
    return _c('div', {
      staticClass: "u-input-validation u-pb1"
    }, [_vm._v(_vm._s(validationMessage))])
  }), 0) : _vm._e()], 1)], 1), _vm._v(" "), _c('ba-new-password', {
    on: {
      "setNewPassword": _vm.setNewPassword
    }
  })], 1)])]), _vm._v(" "), _c('div', {
    staticClass: "init-screen__logo-language"
  }, [_c('bc-footer', {
    attrs: {
      "bgClass": "bg-login"
    }
  }), _vm._v(" "), (_vm.displayLanguageLink) ? _c('div', {
    staticClass: "init-screen__bc-language"
  }, [_c('ba-language-selector')], 1) : _vm._e()], 1)], 1)
},staticRenderFns: []}