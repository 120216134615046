const getBrowserInfo = () => {
  'use strict'
  let browserInfo = {
    name: '',
    version: '',
  }
  const ua = navigator.userAgent
  let version
  let matches = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []
  if (/trident/i.test(matches[1])) {
    version = /\brv[ :]+(\d+)/g.exec(ua) || []
    browserInfo = {
      name: 'IE',
      version: (version[1] || '')
    }
    return browserInfo
  }
  if (matches[1] === 'Chrome') {
    version=ua.match(/\bOPR\/(\d+)/)
    if (version !== null) {
      browserInfo = {
        name: 'Opera',
        version: version[1]
      }
      return browserInfo
    }
  }
  matches = matches[2] ? [matches[1], matches[2]]: [navigator.appName, navigator.appVersion, '-?']
  if ((version = ua.match(/version\/(\d+)/i)) !== null) {
    matches.splice(1, 1, version[1])
  }
  browserInfo = {
    name: matches[0],
    version: matches[1]
  }
  return browserInfo
}

export default getBrowserInfo
