export default function invoiceToText (invoiceOrPayment) {
  let description = 'Description: ' + invoiceOrPayment.summary
  let paymentAmount = 'Payment Amount: ' + invoiceOrPayment.data.details.paymentAmount
  let policyStatus = 'Policy Status: ' + invoiceOrPayment.data.details.policyStatus
  let payor = 'Payor: ' + invoiceOrPayment.data.details.payor
  let paymentMethod = 'Payment Method: ' + invoiceOrPayment.data.details.paymentMethod
  let paymentStatus = 'Payment Status: ' + invoiceOrPayment.data.details.paymentStatus
  let confirmationNumber = 'Confirmation Number: ' + invoiceOrPayment.data.details.confirmationNumber
  return [description, policyStatus, paymentAmount,
    payor, paymentMethod, paymentStatus, confirmationNumber].join('<br>\n')
}
