module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', [_c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_c('div', {
    staticClass: "claim-photos u-mb4"
  }, [_c('h2', {
    staticClass: "secondary-title u-mb3"
  }, [_vm._v(_vm._s(_vm.t('claims.photos_section_title')))]), _vm._v(" "), _c('el-row', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: (_vm.loading),
      expression: "loading"
    }],
    staticClass: "claim-photos__content u-m0",
    attrs: {
      "gutter": 20
    }
  }, [_c('el-col', {
    staticClass: "claim-photos__data",
    attrs: {
      "sm": 24,
      "md": 8
    }
  }, [_c('h3', {
    staticClass: "subheader u-text-trim u-mt2"
  }, [_vm._v(_vm._s(_vm.t('claims.loss_photos')))]), _vm._v(" "), (_vm.insuredPhotos && _vm.insuredPhotos.length) ? _c('div', {
    staticClass: "claim-photos__block"
  }, [_c('div', {
    staticClass: "photo-counter"
  }, [_c('fa-icon', {
    staticClass: "image-icon u-white",
    attrs: {
      "icon": ['fas', 'clone']
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "image-amount"
  }, [_vm._v(_vm._s(_vm.insuredPhotos.length))])], 1), _vm._v(" "), _c('bc-images-table', {
    staticClass: "claims-gallery",
    attrs: {
      "rows": _vm.insuredPhotos,
      "carouselCaption": _vm.t('claims.loss_photos'),
      "isDeletableFunction": _vm.isDeletableFunction,
      "claimPreview": ""
    },
    on: {
      "removeImage": _vm.removeImage
    }
  })], 1) : _c('div', [_c('div', {
    staticClass: "u-pt4"
  }, [_vm._v(_vm._s(_vm.t('policies.no_photos')))])])]), _vm._v(" "), (_vm.carrierPhotos && _vm.carrierPhotos.length) ? _c('el-col', {
    staticClass: "claim-photos__data",
    attrs: {
      "sm": 24,
      "md": 8
    }
  }, [_c('h3', {
    staticClass: "subheader u-text-trim u-mt2"
  }, [_vm._v(_vm._s(_vm.t('claims.loss_photos')) + " " + _vm._s(_vm.t('policies.added_by_your_agent')))]), _vm._v(" "), _c('div', {
    staticClass: "claim-photos__block"
  }, [_c('div', {
    staticClass: "photo-counter"
  }, [_c('fa-icon', {
    staticClass: "image-icon u-white",
    attrs: {
      "icon": ['fas', 'clone']
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "image-amount"
  }, [_vm._v(_vm._s(_vm.carrierPhotos.length))])], 1), _vm._v(" "), _c('bc-images-table', {
    attrs: {
      "rows": _vm.carrierPhotos,
      "carouselCaption": _vm.t('claims.loss_photos') + ' (' + _vm.t('policies.added_by_your_agent') + ')',
      "claimPreview": ""
    }
  })], 1)]) : _vm._e(), _vm._v(" "), (!_vm.hideUploadPhotosAndUpdateClaim) ? _c('el-col', {
    staticClass: "claim-photos__data",
    attrs: {
      "sm": 24,
      "md": 8
    }
  }, [_c('drag-n-drop-photo', {
    on: {
      "onPhotoUpload": _vm.onPhotoUpload
    }
  })], 1) : _vm._e()], 1)], 1)])], 1)
},staticRenderFns: []}