<template>
  <div>
      <div>
        <el-switch
          v-model="consentToText"
          active-color="#5AB67F"
          inactive-color="#CB6B0B"
          :active-text="t('global.accept')"
          :inactive-text="t('global.decline')"
          @change="onChangeConsent()">
        </el-switch>
      </div>

      <dialog-info
        :visibleDialogInfo="visibleDialogInfo"
        @userApproved="userApprovedConsent"
        @handleCloseDialog="handleCloseDialog"
        :title="t('account.consent_text_info_title')"
        :description="t('account.consent_text_info', { company_name: companyName })"
      ></dialog-info>

  </div>
</template>

<script>
  import DialogInfo from '@/components/dialog-info.modal'

  export default {
    name: 'ba-consent-to_text',
    props: ['account', 'notifyBackend'],
    data () {
      return {
        visibleDialogInfo: false,
        consentToText: false,
        companyName: this.setting('global.carrier_name') || null,
      }
    },
    watch: {
      account: {
        handler (newValue) {
          if (!newValue) {
            return
          }

          if (newValue.consent_to_text) {
            this.consentToText = newValue.consent_to_text
          }
        },
        immediate: true
      },
    },
    components: {
      DialogInfo
    },
    methods: {
      handleCloseDialog (fromButton) {
        this.visibleDialogInfo = false
        if (!fromButton) {
          this.consentToText = false
        }
      },
      userApprovedConsent (value) {
        this.handleCloseDialog(true)
        if (value === 'accept') {
          this.consent_to_text = true
          if (this.notifyBackend) {
            this.saveConsentToText()
          }
        } else {
          this.consentToText = false
        }
      },
      onChangeConsent () {
        if (this.consentToText) {
          this.visibleDialogInfo = true
          return
        }
        if (this.notifyBackend) {
          this.saveConsentToText()
        }
      },
      async saveConsentToText () {
        this.$store.dispatch('account/updateConsentToText', this.consentToText).then(() => {
          this.$store.dispatch('account/loadAccount')
        }).catch((error) => {
          let cause = error.cause
          let message = cause ? this.causeToString(cause) : this.t('account.error_saving_auth_data')
          this.$elAlert(message, this.t('global.warning'))
        })
      },
    },
  }
</script>
