module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    staticClass: "ba-intl-tel-input__wrapper",
    class: _vm.disabled ? 'is-disabled' : ''
  }, [_c('div', [_c('input', {
    ref: "telephoneInput",
    staticClass: "native-phone-input",
    class: _vm.customClass,
    attrs: {
      "disabled": _vm.disabled,
      "type": "tel"
    },
    on: {
      "blur": _vm.beforeInputLosesFocus
    }
  })])])
},staticRenderFns: []}