<template>
  <div>
    <div v-if="isHuman">
      <div class="media u-mt4">
        <div class="media__image">
          <bc-general-image v-if="renderContactPhoto" :fileId="contactPhotoId" :source="contactPhotoUrl" class="agent__image"></bc-general-image>

          <h2 v-if="!renderContactPhoto" class="u-color-secondary u-m0">
            <span fa="user"></span>
          </h2>
        </div>
        <div class="media__body">
          <h2 class="u-color-text u-m0">
            {{ name }}
          </h2>
        </div>
      </div>
    </div>
    <div v-if="!isHuman" class="u-mt4 u-mb4">
      <div v-if="renderContactPhoto" class="media">
        <div class="media__image">
          <bc-general-image :fileId="contactPhotoId" :source="contactPhotoUrl" class="agency__image"></bc-general-image>
        </div>
        <div class="media__body">
          <h2 class="u-m3 color-white"> {{ name }}</h2>
        </div>
      </div>
      <div v-if="!renderContactPhoto" class="agency">
        <h2 class="secondary-header u-mt0 u-mb0">{{ name }}</h2>
      </div>
    </div>


    <div v-if="contact.phones && contact.phones.length">
      <div class="media media--top u-mb4">
        <div class="media__body">
          <h3 class="subheader media__title u-mt2 u-mb2">{{ t('global.phone_number') }}</h3>
          <div v-for="phone in contact.phones" :key="phone.id">
            <div>
              <div v-if="isShowPhoneLabel" class="phone-info">
                <div v-if="phone.type.length" class="phone-type">{{phone.type}}:</div>
                <div><a :href="'tel:' + phone.phone">{{phone.phone}}</a></div>
              </div>
              <div v-else class="phone-content">
                <a :href="'tel:' + phone.phone">{{phone.phone}}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!shouldHideEmails && contact.emails && contact.emails.length">
      <div class="media media--top">
        <div class="media__body">
          <h3 class="subheader media__title u-mt1 u-mb2">{{ t('global.email_address')}}</h3>

          <div class="u-mb4">
            <div :key="i" v-for="(email, i) in contact.emails">
              <span>
                <a :href="'mailto:' +  email.email">{{ email.email }}</a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-for="(address, i) in contact.addresses" :key="i">
      <div class="media media--top" v-if="contact.addresses.length">
        <div class="media__body">
          <h3 class="subheader media__title u-mt1 u-mb2">{{ getAddressLabel(address) }} {{ t('global.address') }}</h3>
          <div class="u-mb2">
            <div>{{ address.address_line1 }}</div>
            <div v-if="address.address_line2">{{ address.address_line2 }}</div>
            <div>{{ address.address_city}},  {{ address.address_state }}</div>
            <div>{{ address.address_zip }}</div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="contact.website">
      <div class="media media--top">
        <div class="media__body" v-if="contact && modifiedWebsite">
          <h3 class="subheader media__title u-mt1 u-mb2">{{ t('global.website') }}</h3>

          <div class="u-mb2">
            <a :href="contact.website">{{modifiedWebsite}}</a>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showAdditionalWording">
      <span v-html="t('global.additional_carrier_wording')"></span>
    </div>
  </div>

</template>

<script>

export default {
  name: 'bc-contact',
  props: {
    contact: {type: Object, required: true},
    hidePhoto: {type: Boolean, default: false},
    human: {type: Boolean, default: false},
    imageData: {type: Object, required: false},
    name: {type: String},
    isAgentOrAgency: {type: Boolean, default: false}
  },
  methods: {
    getAddressLabel (address) {
      if (address.type === 'Other' && address.type_label && this.shouldUseTypeLabel) {
        return address.type_label
      }
      return address.type
    }
  },
  computed: {
    renderContactPhoto () {
      return !!(this.contactPhotoId || this.contactPhotoUrl)
    },
    contactPhotoId () {
      return this.imageData ? this.imageData.photoId : null
    },
    contactPhotoUrl () {
      return this.imageData ? this.imageData.photo_url : null
    },
    isHuman () {
      let calculatedValue = false
      return this.human ? true : calculatedValue
    },
    shouldHideEmails () {
      return this.isAgentOrAgency && this.setting('global.hide_agent_emails')
    },
    shouldUseTypeLabel () {
      return this.setting('global.use_type_label_for_other_addresses')
    },
    modifiedWebsite () {
      let http = /^(https?:\/\/)/gm
      return this.contact.website ? (this.contact.website.replace(http, '') || this.contact.website.substr(7)) : null
    },
    isShowPhoneLabel () {
      return this.setting('account.show_phone_labels')
    },
    isShowPhoneType () {
      if (!this.contact.phones.length) {
        return
      }
      return this.contact.phones.some(el => el.type !== '')
    },
    showAdditionalWording () {
      return this.setting('global.carrier_additional_wording') && !this.isHuman && !this.isAgentOrAgency
    }
  },
}
</script>
<style scoped lang="scss">
  .agent-thumbnail {
    max-width: 1.5rem;
  }
  .phone-type {
    display: inline-block;
    width: 50px
  }
  .phone-content {
    display: inline-block;
  }
  .phone-info {
    display: flex;
  }
</style>
