
/* styles */
require("!!../../node_modules/extract-text-webpack-plugin/dist/loader.js?{\"omit\":1,\"remove\":true}!vue-style-loader!css-loader?{\"minimize\":true,\"sourceMap\":true}!../../node_modules/vue-loader/lib/style-compiler/index?{\"id\":\"data-v-029d2105\",\"scoped\":false,\"hasInlineConfig\":false}!sass-loader?{\"sourceMap\":true}!../../node_modules/vue-loader/lib/selector?type=styles&index=0!./new-phone-number.dialog.vue")

var Component = require("!../../node_modules/vue-loader/lib/component-normalizer")(
  /* script */
  require("!!babel-loader!../../node_modules/vue-loader/lib/selector?type=script&index=0!./new-phone-number.dialog.vue"),
  /* template */
  require("!!../../node_modules/vue-loader/lib/template-compiler/index?{\"id\":\"data-v-029d2105\"}!../../node_modules/vue-loader/lib/selector?type=template&index=0!./new-phone-number.dialog.vue"),
  /* scopeId */
  null,
  /* cssModules */
  null
)

module.exports = Component.exports
