<template>
  <div>
    <p v-loading="!account" class="secondary-title">{{ t('account.login_credentials') }}</p>
    <div v-if="account">
      <el-form @submit.native.prevent>
        
        <el-form-item class="u-mt2 u-mb2 subheader u-text-uppercase" :label="t('account.login_header')">
          <el-input @keyup.enter.native="save" :disabled="isAccountSaveInProgress" v-model="form.username"
                    class="item-input-field" type="email"/>
        </el-form-item>
        
        <el-form-item class="u-mb0" :label="t('enrollment.4_digit_pin_number_input_label')">
          <el-input @keyup.enter.native="save"
                    id="pinNumberInput"
                    @focus="handleInput"
                    @blur="handleInput"
                    :disabled="isAccountSaveInProgress"
                    class="item-input-field"
                    :placeholder="isExistPin ? '****' : ''"
                    :type="form.pin.masked ? 'tel' : 'password'"
                    v-model.trim="form.pin.newPin"
                    autocomplete="off"
                    :maxlength="4"
          >
          </el-input>
          <div v-show="pinHasValue"
             @click="displayPinCode"
             class="item-input-field__eye"
             :class="{ 'crossed-out': form.pin.masked && pinHasValue }">
            <img src="~@/assets/logos/eye_logo.svg" :alt="t('global.show')">
          </div>
          <div class="u-warning u-mb2">
            <i v-if="form.pin.validatePinMessage" class="material-icons md-text" aria-hidden="true">warning</i>
            <span>{{ form.pin.validatePinMessage }}</span>
          </div>
        </el-form-item>
    
      </el-form>

      <el-button v-if="account"
           v-loading="isAccountSaveInProgress"
           :disabled="loading || (!usernameHasChanges && !pinHasValue)"
           class="u-mt2 u-mb4 el-button--wide u-text-uppercase"
           type="primary"
           @click="save">
        {{ t('global.save_changes') }}
      </el-button>
    </div>

    <div v-if="displayLanguageLink">
      <!-- Contact language -->
      <p class="secondary-title u-mt3 u-mb3">{{ t('global.language') }}</p>
      <ba-language-selector class="u-width100" notify-backend="true"/>
    </div>

    <div>
      <p class="secondary-title u-mt4 u-mb3">{{ t('account.context_to_text_choice') }}
        <span class="u-ml2 portal-code-info">
          <el-popover
            placement="top-start"
            title=""
            width="325"
            trigger="hover">
          <span slot="reference"><fa-icon icon="exclamation-circle"></fa-icon></span>
          <span v-html="t('account.consent_to_text_help')"></span>
        </el-popover>
        </span>
      </p>
      <ba-consent-to-text :account="account" :notify-backend='true'/>
    </div>

  </div>

</template>

<script>

import {isEmailValid, isPinValid} from '@shared_src/utils/misc.util'
import {mapState, mapGetters, mapActions} from 'vuex'

import BaLanguageSelector from '@/components/language-selector.component'
import BaConsentToText from '@/components/consent_to_text.component'

export default {
  data () {
    return {
      form: {
        username: '',
        pin: {
          masked: false,
          newPin: '',
          validatePinMessage: '',
        }
      },
      loading: false,
      isAccountSaveInProgress: false,
    }
  },
  mounted () {
    this.handleInput(null)
  },
  watch: {
    account: {
      handler (newValue) {
        if (!newValue) {
          return
        }

        if (newValue.username) {
          this.form.username = newValue.username
        }
      },
      immediate: true
    },
  },
  components: {
    'ba-language-selector': BaLanguageSelector,
    'ba-consent-to-text': BaConsentToText
  },
  methods: {
    ...mapActions('account', ['validateUsername', 'updateUsername', 'updatePin']),
    handleInput (e) {
      let pinInput = document.getElementById('pinNumberInput')
      e && e.type === 'focus' ? pinInput.readOnly = false : pinInput.readOnly = true
    },
    displayPinCode () {
      if (this.pinHasValue) {
        this.form.pin.masked = !this.form.pin.masked
      }
    },
    async save () {
      let queries = []
      if (this.pinHasValue) {
        if (!this.isValidPin()) return
        const pinRequest = this.validateAndSavePin()
        queries.push(pinRequest)
      }
      if (this.usernameHasChanges) {
        if (!this.isValidaUserName()) return
        const usernameRequest = this.validateAndSaveUsername()
        queries.push(usernameRequest)
      }

      this.loading = true
      this.isAccountSaveInProgress = true
      try {
        await Promise.all(queries)
        await this.updateStorageInfo()
      } catch (e) {
        console.log('error: ', e)
      } finally {
        this.loading = false
        this.isAccountSaveInProgress = false
      }
    },
    isValidPin () {
      this.form.pin.validatePinMessage = this.pinValidationErrMessage
      if (!this.validateInputValues) {
        this.$elAlert(this.t('enrollment.some_error_happened_notification'), this.t('global.error'))
        return
      }
      return true
    },
    isValidaUserName () {
      if (this.usernameValidationErrMessage !== '') {
        this.$elAlert(this.usernameValidationErrMessage, this.t('global.error'))
        return
      }
      return true
    },
    async validateAndSavePin () {
      let newPin = this.form.pin.newPin
      try {
        let res = await this.updatePin(newPin)
        if (res.success) {
          this.form.pin.newPin = ''
          this.form.pin.validatePinMessage = ''
          this.form.pin.masked = false
        }
      } catch (error) {
        let cause = error.cause
        let message = cause ? this.causeToString(cause) : this.t('account.error_saving_auth_data')
        this.$elAlert(message, this.t('global.warning'))
      }
    },
    async validateAndSaveUsername () {
      let newUsername = this.form.username
      try {
        await this.validateAndSaveNewUsername(newUsername)
      } catch (e) {
        console.log('error: ', e)
      }
    },
    async updateStorageInfo () {
      this.loading = true
      await this.reloadPromise()
      this.loading = false

      if (this.usersAreIdentifiedByEmail && this.usernameHasChanges) {
        this.askUserToChangeEmail()
      }
    },
    reloadPromise () {
      return Promise.all([
        this.$store.dispatch('account/loadAccount'),
        this.$store.dispatch('common/loadContact')
      ])
    },
    askUserToChangeEmail () {
      this.$confirm(this.t('account.change_email_used_for_correspondence'), '', {
        confirmButtonText: this.t('global.yes'),
        cancelButtonText: this.t('global.no'),
        showClose: false,
      }).then(() => {
        this.$router.push('/account/emails')
      })
    },
    async validateAndSaveNewUsername (newUsername: string) {
      try {
        await this.validateUsername({ username: newUsername })
      } catch (e) {
        this.loading = false
        let message = e.response.cause
            ? this.t(e.response.cause)
            : this.t('enrollment.user_with_username_exists', { username: newUsername })
        this.$elAlert(
            message,
            this.t('global.error')
        )
        return
      }

      try {
        await this.updateUsername(newUsername)
      } catch (e) {
        this.loading = false
        this.$elAlert(this.t('account.error_saving_contact_information'), this.t('global.error'))
        return
      }
    },
  },
  computed: {
    ...mapState('common', ['settings']),
    ...mapGetters('account', ['account']),
    ...mapState('account', ['accountData']),
    validateInputValues () {
      return this.pinValidationErrMessage === ''
    },
    pinValidationErrMessage () {
      let errorMsg = ''
      if (this.pinHasValue && !isPinValid(this.form.pin.newPin)) {
        errorMsg = this.t('enrollment.pin_should_be_a_4_digit_number')
      }
      if (!this.form.pin.newPin.length) {
        errorMsg = this.t('enrollment.pin_empty_notification')
      }
      return errorMsg
    },
    isExistPin () {
      return this.accountData ? this.accountData.pin : false
    },
    usersAreIdentifiedByEmail () {
      return this.setting('enrollment.users_are_identified_by_email_address')
    },
    displayLanguageLink () {
      return this.setting('global.display_spanish_link') === true
    },
    pinHasValue () {
      return this.form.pin.newPin.length > 0
    },
    usernameHasChanges () {
      return this.account && this.account.username !== this.form.username
    },
    usernameValidationErrMessage () {
      let updatedUsername = this.form.username
      if (updatedUsername.trim() === '') {
        return this.t('account.login_should_not_be_empty')
      }
      if (this.usersAreIdentifiedByEmail && !isEmailValid(updatedUsername)) {
        return this.t('account.email_is_incorrect')
      }
      return ''
    },
  },
}
</script>

<style scoped>
  .el-form-item {
    max-width: none;
  }
</style>
