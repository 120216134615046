<template>
    <div v-loading="loading" :class="tosFromLogin ? 'tos-from-login' : ''">
        <div v-html="convertMarkdownToHtml(tos)"></div>
        <div v-if="!isEnrollmentTOS && tosFromLogin">
            <div class="u-mt4 u-mb4">
                <el-checkbox data-cy="accept-checkbox" v-model="checked">
                    {{ t('enrollment.i_have_read_and_accept_tos') }}
                </el-checkbox>
            </div>
            <el-button data-cy="accept-button" type="primary" class="el-button--wide" @click="advance" :disabled="!checked">
                {{ t('enrollment.agree_to_terms') }}
            </el-button>
        </div>
    </div>
</template>

<script>
  import marked from 'marked'
  import {mapState, mapActions, mapMutations} from 'vuex'
  import {deleteCookie} from '../../../shared_src/utils/api.utils'
  import detectIP from '../../../shared_src/ip-detection.utils'
  
  export default {
    name: 'terms-of-service',
    props: {
      isEnrollmentTOS: {
        type: Boolean
      }
    },
    created () {
      this.getIP()
    },
    methods: {
      ...mapActions('enrollment', ['approveTos']),
      ...mapMutations('enrollment', ['confirmTos']),
      async getIP () {
        this.ip = await detectIP()
      },
      convertMarkdownToHtml: (content) => {
        if (!content) return ''
        let converted = marked(content)

        return converted
      },
      advance () {
        if (this.isEnrollmentTOS) {
          this.confirmTos(true)
          this.$emit('handleEnrollmentDialogTOS', true)
        } else {
          this.loading = true
          this.approveTos().then(() => {
            // Logging for registered user
            this.logInsuredAction('Acknowledge Terms of Service', 'custom_acknowledge_tos', {
              username: this.contacts[0].name,
              ip_address: this.ip,
            })
            this.loading = false
            this.$router.replace('/dashboard')
          }, () => {
            delete localStorage.token
            localStorage.removeItem('last_location')
            deleteCookie('user_online')
            this.loading = false
            this.$router.replace('/login')
          })
        }
      }
    },
    data () {
      return {
        checked: false,
        ip: '',
        loading: false
      }
    },
    computed: {
      ...mapState('enrollment', ['tos']),
      ...mapState('common', ['contacts']),
      tosFromLogin () {
        return this.$route.params.backLink === '0'
      }
    }
  }
</script>
<style lang="scss">

.tos-from-login {
    width: 50vw;
    max-width: 600px;
    margin: 10vh auto 0;
    .el-checkbox__label {
        white-space: normal;
    }
}
</style>
