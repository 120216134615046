module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    staticClass: "payment-method-icon"
  }, [(_vm.paymentMethod.type != 'Credit Card') ? _c('div', [_c('i', {
    staticClass: "material-icons md-black md-48"
  }, [_vm._v("account_balance")])]) : _c('div', [_c('div', {
    attrs: {
      "data-card-type": _vm.paymentTypeName
    }
  })])])
},staticRenderFns: []}