<template>
  <div class="init-screen" v-loading="loading">
    <init-sidebar/>
    <div class="init-screen__info centered-block">
      <div class="init-block">
        <div class="navigation">
          <div class="init-block__back" @click="$router.back()">
            <ba-icon-link icon_type="far" icon_name="arrow-left" icon_class="u-pl2" :label="t('documents.back')"/>
          </div>
        </div>
        <h1 class="init-block--heading">{{ t('reset_password.header') }}</h1>
        <div class="subheader u-mb3">{{ t('reset_password.enter_new_password') }}</div>
        <div>
          <!-- MFA CODE -->
          <el-form>
            <el-form-item :label="t('reset_password.mfa_code_field')">
              <el-input v-model="mfaCode" type="tel" class="item-input-field" :maxlength="6"
                        :class="{'u-input-error': mfaValidationMessages.length > 0}"/>
              <div class="u-warning" v-if="mfaValidationMessages.length > 0">
                <div v-for="validationMessage in mfaValidationMessages" class="u-input-validation u-pb1">{{ validationMessage }}</div>
              </div>
            </el-form-item>
          </el-form>
          <!-- NEW PASSWORD -->
          <ba-new-password @setNewPassword="setNewPassword"></ba-new-password>
        </div>
      </div>
    </div>
    <div class="init-screen__logo-language">
      <bc-footer bgClass="bg-login"></bc-footer>
      <div class="init-screen__bc-language" v-if="displayLanguageLink">
        <ba-language-selector/>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import {mapState} from 'vuex'
import config from '@/../config/britecore'
import settings from '@/../config/settings'

import initSidebar from '@/components/init-sidebar'
import Footer from '@/components/footer'
import BaLanguageSelector from '@/components/language-selector.component'
import BaNewPassword from '@/components/new-password.component'
import iconLink from '@/ui/icon-link.component'
import {isMfaCodeValid} from '@shared_src/utils/misc.util'

export default {
  name: 'bc-new-password-step',
  components: {
    initSidebar,
    'bc-footer': Footer,
    'ba-icon-link': iconLink,
    BaLanguageSelector,
    'ba-new-password': BaNewPassword,
  },
  watch: {

  },
  computed: {
    ...mapState('passwordReset', ['unauthorizedUser']),
    displayLanguageLink () {
      return this.setting('global.display_spanish_link') === true
    },
    mfaValidationMessages () {
      if (!isMfaCodeValid(this.mfaCode)) {
        return [this.t('reset_password.mfa_should_be_a_6_digit_number')]
      }
      return []
    },
  },
  methods: {
    async setNewPassword (newPassword) {
      if (this.mfaValidationMessages.length > 0) {
        this.$elAlert(this.t('enrollment.some_error_happened_notification'), this.t('global.error'))
        return
      }

      this.loading = true
      try {
        let result = await axios.post(config.url + '/ajax-reset-password/', {
          username: this.unauthorizedUser.name,
          mfa: this.mfaCode,
          password: newPassword,
          company_id: settings.company_id,
        })
        this.loading = false
        if (result.data.success) {
          this.$elAlert(this.causeToString(result.data.cause), this.t('global.notice'), () => {
            this.login(newPassword)
          }, this.t('global.ok'))
        } else {
          this.$elAlert(this.causeToString(result.data.cause), this.t('global.error'), null, this.t('documents.close'))
        }
      } catch (e) {
        this.loading = false
        console.error(e)
        this.$elAlert(this.t('global.error'), this.t('global.error'))
      }
    },
    login (newPassword) {
      this.loading = true
      axios.post(config.ajax_login_url, {
        username: this.unauthorizedUser.name,
        password: newPassword,
        company_id: settings.company_id,
      }).then((result) => {
        this.loading = false
        this.$router.push('/dashboard', () => location.reload(true))
      }).catch((e) => {
        this.loading = false
        this.$elAlert(this.t('global.error'), this.t('global.error'))
      })
    },
  },
  data () {
    return {
      mfaCode: '',
      mfaOk: false,

      loading: false,
    }
  },
}
</script>
