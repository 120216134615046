import vue from 'vue'
import moment from 'moment-timezone'

vue.filter('dateFormat', function (date, format) {
  let dateObj = moment(date)
  return dateObj.format(format)
})

vue.filter('dateFormatWithTimeZone', function (date, format, timezone) {
  let dateObj = moment(date)
  return dateObj.tz(timezone).format(format)
})
