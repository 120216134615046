<template>
  <div class="init-screen" v-loading="loading">
    <init-sidebar/>
    <div class="init-screen__info centered-block">
      <div class="init-block">
        <div class="navigation">
          <div class="init-block__back" @click="$router.back()">
            <ba-icon-link icon_type="far" icon_name="arrow-left" icon_class="u-pl2" :label="t('documents.back')"/>
          </div>
        </div>
        <h1 class="init-block--heading">{{ t('reset_password.send_header') }}</h1>
        <div class="subheader u-mb3">{{ t('reset_password.choose_where_to_send_reset_code') }}</div>
        <!--  EMAILS LIST  -->
        <div v-if="unauthorizedUser.emails.length > 0" class="u-mb2">
          <div class="init-block--sub-heading u-pb2">{{ t('account.send_code_via_email') }}</div>
          <div class="unauthorized-data u-m0">
            <el-radio-group v-model="picked.id">
              <div v-for="email in unauthorizedUser.emails" class="u-pb1">
                <el-radio :label="email.id" @change="setData(email, 'emails')">{{ email.email }}</el-radio>
              </div>
            </el-radio-group>
          </div>
        </div>
        <!--  PHONES LIST  -->
        <div v-if="unauthorizedUser.phones.length > 0">
          <div class="init-block--sub-heading u-pb2">{{ t('account.send_code_via_sms') }}</div>
          <el-radio-group v-model="picked.id">
            <div v-for="phone in unauthorizedUser.phones" class="u-pb1">
              <el-radio :label="phone.id" @change="setData(phone, 'phones')">{{ phone.phone }}</el-radio>
            </div>
          </el-radio-group>
        </div>
        
        <div class="u-mt2">
          <el-button class="el-button--wide u-text-uppercase" type="primary" @click="sendCode">{{ t('global.submit') }}</el-button>
        </div>
      </div>
    </div>
    <div class="init-screen__logo-language">
      <bc-footer bgClass="bg-login"></bc-footer>
      <div class="init-screen__bc-language" v-if="displayLanguageLink">
        <ba-language-selector/>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import config from '../../../config/britecore'
import settings from '../../../config/settings'
import axios from 'axios'
import {mapState} from 'vuex'
import initSidebar from '@/components/init-sidebar'
import Footer from '@/components/footer'
import BaLanguageSelector from '@/components/language-selector.component'
import iconLink from '@/ui/icon-link.component'

export default {
  name: 'bc-code-delivery-step',
  components: {
    initSidebar,
    'bc-footer': Footer,
    'ba-icon-link': iconLink,
    BaLanguageSelector,
  },
  computed: {
    // TODO: Add validation phone number of this.unauthorizedUser.phones on BC/BA
    ...mapState('passwordReset', ['unauthorizedUser']),
    displayLanguageLink () {
      return this.setting('global.display_spanish_link') === true
    },
  },
  methods: {
    setData (data, type) {
      if (data && type === 'emails') {
        this.picked = {
          value: data.email,
          type,
          id: data.id
        }
      } else {
        this.picked = {
          value: data.phone,
          type,
          id: data.id
        }
      }
      return this.picked
    },
    sendRecoveryCode (username, choiceId, choiceType, successCallback, failureCallback) {
      this.loading = true
      axios.post(config.send_recovery_code, {
        username: username,
        data: choiceId,
        type: choiceType,
        company_id: settings.company_id,
      })
        .then((result) => {
          let data = result.data
          this.loading = false
          successCallback(data)
        })
        .catch(() => {
          console.log('Error occurred during send_recovery_code processing')
          this.loading = false
          failureCallback()
        })
    },
    sendCode () {
      let thisVar = this
      if (_.isEmpty(this.picked)) {
        thisVar.$elAlert(this.t('reset_password.choose_delivery_method'), 'Warning')
        return
      }

      this.sendRecoveryCode(
        thisVar.unauthorizedUser.name,
        thisVar.picked.id,
        thisVar.picked.type,
        (data) => {
          if (data.success) {
            thisVar.$elAlert(
              thisVar.t('reset_password.recovery_code_sent', {sent_to: thisVar.picked.value}),
              'Note',
              () => thisVar.$router.push('/password-reset/new-password'),
            )
          } else {
            thisVar.$elAlert(
            thisVar.t('reset_password.internal_error_occurred_during_code_recovery'),
            thisVar.t('global.error'))
          }
        },
        () => {
          thisVar.$elAlert(
            thisVar.t('reset_password.internal_error_occurred_during_code_recovery'),
            thisVar.t('global.error'))
        },
      )
    },
  },
  data () {
    return {
      loading: false,
      picked: {},
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  .unauthorized-data {
    & > li {
        list-style-type: none;
      & > label[class*="item-content"] {
        display: flex;
        cursor: pointer;
      }
    }
  }
</style>
