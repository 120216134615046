<template>
  <div v-loading="loading">
    <div class="file-claim-forms">
      <div class="file-claim-forms__fields">
        
        <!-- Location -->
        <el-form class="u-max-width u-mt6">
          <div class="inputs-list u-mb3">
            <div v-if="selectedPolicy" class="list-item--select">
              <span class="u-block u-text-uppercase subheader">{{ t('claims.location_title') }} *</span>
              <el-select v-model="selectedPropertyId" class="item-input-field u-block"
                         :disabled="selectedPolicy.properties.length === 1">
                <el-option value="" disabled hidden></el-option>
                <el-option v-for="property in selectedPolicy.properties" :value="property.id" :key="property.id"
                  :label="property.address_line1 + ' ' + property.address_line2 + ', ' + property.address_city + ', ' + property.address_state + ', ' +property.address_zip">
                </el-option>
              </el-select>
            </div>
          </div>
        </el-form>
      
        <!-- Loss Date -->
        <el-form class="u-max-width">
          <div class="inputs-list u-mb3">
              <div>
                <span class="u-block u-text-uppercase subheader">{{ t('claims.loss_date_title') }} *</span>
                <el-date-picker
                  format="MM/dd/yyyy"
                  class="u-width100"
                  :picker-options="{ disabledDate: isDateAfterToday }"
                  :disabled="isCanceledCurrentPolicy"
                  v-model="date"
                  :clearable="false"
                />
              </div>
              <div v-if="validateInputValuesDictCopy.lossDate" class="u-warning u-mb4">
                <i class="material-icons md-text" aria-hidden="true">warning</i> {{ validateInputValuesDictCopy.lossDate }}
              </div>
            </div>
        </el-form>
        
        <!-- Contact Phone Number -->
        <el-form class="u-max-width">
          <div class="inputs-list">
            <div class="ba-intl-tel-input__wrapper">
              <span v-if="phoneNumberRequired" class="u-block u-text-uppercase subheader">{{ t('global.phone_number') }} *</span>
              <span v-else class="u-block u-text-uppercase subheader">{{ t('global.phone_number') }}</span>
              <ba-intl-tel-input
                @keyup.enter.native="fileClaimOperation"
                v-model="contactPhoneNumber"
                :class="validateInputValuesDictCopy.phoneNumber ? 'phone-number-u-warning' : ''"
                customClass="el-input__inner"
                :disabled="isCanceledCurrentPolicy"
              >
              </ba-intl-tel-input>
            </div>
            <div v-if="validateInputValuesDictCopy.phoneNumber" class="u-warning">
              <i class="material-icons md-text" aria-hidden="true">warning</i> {{ validateInputValuesDictCopy.phoneNumber }}
            </div>
          </div>
        </el-form>

      </div>
      
       <!-- Upload Photos -->
      <drag-n-drop-photo v-if="!isCanceledCurrentPolicy" @onPhotoUpload="onPhotoUpload"></drag-n-drop-photo>
    </div>
    
    <!-- Description -->
    <el-form class="u-max-width">
      <div class="inputs-list u-mt4 u-mb3">
        <div>
          <span class="u-block u-text-uppercase subheader">{{ t('claims.loss_description_title') }} *</span>
          <el-input type="textarea" class="u-block" v-model="description" :disabled="isCanceledCurrentPolicy" resizable></el-input>
        </div>
        <div v-if="validateInputValuesDictCopy.description" class="u-warning">
          <i class="material-icons md-text" aria-hidden="true">warning</i> {{ validateInputValuesDictCopy.description }}
        </div>
      </div>
    </el-form>

    <!-- Photos preview -->
    <div v-if="chosenPhotos.length">
        <el-row :gutter="10">
          <el-col v-for="(photo, pos) in chosenPhotos" :key="pos" :md="8" :sm="24">
            <el-card class="el-card__claim-image-preview u-mb1 u-p0">
              <img :src="photo.image" class="claim-image-thumbnail"/>
              <div class="u-flex-justify-content-end u-m1">
                <el-button type="info" size="small" :icon="['fas', 'trash']" @click="deletePhoto(pos)"></el-button>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
    
    <div class="u-mt4 u-text-right">
        <el-button type="primary"
                   @click="fileClaimOperation"
                   :disabled="selectedPolicy === null || isCanceledCurrentPolicy"
                   class="u-text-uppercase">
          {{ t('claims.submit_claim_buttom_caption') }}
        </el-button>
      </div>
    <div v-if="invalidateHack"></div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import {deepClone, convertToUSFormatPhoneNumber} from '@shared_src/utils/misc.util'
import moment from 'moment'
import dragnDropPhotoComponent from '@/components/drag-n-drop-photo.component.vue'
import alert from '@/ui/alert.component'
import {validateUSFormatPhoneNumber} from '../../shared/data-validators'
import {isValidPhoneNumber} from 'libphonenumber-js'
import BaIntlTelInput from '@/../shared_src/phone-wrap/intl-tel-input.wrapper'

export default {
  name: 'ba-file-claim',
  components: {
    'ba-alert': alert,
    'drag-n-drop-photo': dragnDropPhotoComponent,
    BaIntlTelInput
  },
  props: {
    policyId: {type: String},
  },
  watch: {
    contactPhoneNumber: {
      handler (value) {
        this.contactPhoneNumber = convertToUSFormatPhoneNumber(value)
      },
      immediate: true
    },
  },
  methods: {
    ...mapActions('claims', ['fileClaim']),
    ...mapActions('policies', {reloadPolicies: 'reload'}),
    onPhotoUpload (photoDict) {
      this.chosenPhotos.push(photoDict)
    },
    isDateAfterToday (evaledDate) {
      let evaledMillis = +evaledDate
      return evaledMillis >= this.startOfTomorrowMillis
    },
    getNowAsString () {
      return new Date()
    },
    deletePhoto (pos) {
      this.$elConfirm(
        this.t('policies.are_you_sure_you_want_to_delete_photo'),
        this.t('policies.confirm_removal'), {
          okCallback: () => {
            this.chosenPhotos.splice(pos, 1)
          }
        }
      )
    },
    async fileClaimOperation () {
      this.validateInputValuesDictCopy = deepClone(this.validateInputValuesDict)
      if (!this.validateInputValues) {
        this.$elAlert(this.t('enrollment.some_error_happened_notification'), this.t('global.notice'))
        return
      }
      this.loading = true
      let newClaimId
      try {
        let fileClaimResult = await this.fileClaim({
          policyId: this.policyId,
          propertyId: this.selectedPropertyId,
          loss_date: moment(this.date).format('YYYY-MM-DD'),
          description: this.description,
          chosenPhotos: this.chosenPhotos,
          contactPhoneNumber: this.contactPhoneNumber,
        })
        newClaimId = fileClaimResult.data.claim_id
      } catch (e) {
        this.loading = false
        let errorCause = e.response.cause || 'claims.create_claim_failure'
        if (errorCause === 'claims.upload_claim_photo_failure') {
          this.$elAlert(this.t('claims.upload_claim_photo_failure'), this.t('global.notice'))
          newClaimId = e.response.data.claim_id
        } else {
          this.$elAlert(this.t(errorCause), this.t('global.notice'))
          return  // no further actions if this error is not photo-related
        }
      }
      try {
        await this.reloadPolicies()
      } catch (err) {
        this.loading = false
        this.$elAlert(this.t('claims.claims_reload_failure_alert_message'), this.t('global.notice'))
        return  // no navigation to claim-view if error
      }
      this.contactPhoneNumber = ''
      this.loading = false

      this.$emit('closeClaimDialog')
      this.$router.replace({path: '/claims/' + newClaimId})
    },
  },
  computed: {
    ...mapGetters('policies', ['policyById']),
    ...mapState('policies', ['policies']),
    isValidSelectedDate () {
      return moment(this.date, 'YYYY-MM-DD', true).isValid()
    },
    isCanceledCurrentPolicy () {
      return this.selectedPolicy && this.selectedPolicy.is_canceled
    },
    selectedPolicy () {
      return this.policyId? this.policyById(this.policyId): null
    },
    selectedProperty () {
      if (this.selectedPolicy() === null || !this.selectedPropertyId) {
        return null
      }
      return this.policyId? this.policyById(this.policyId): null
    },
    invalidateHack () {  // will be triggered only policy is modified - change selectedPropertyId on this event
      if (!this.selectedPolicy || this.selectedPolicy.properties.length !== 1) {
        this.selectedPropertyId = ''
      } else {
        this.selectedPropertyId = this.selectedPolicy.properties[0].id
      }
    },
    validateInputValues () {
      return Object.keys(this.validateInputValuesDict).every(key => !this.validateInputValuesDict[key])
    },
    validateInputValuesDict () {
      return {
        description: (this.description.trim() === '')
          ? this.t('claims.loss_description_is_not_defined') : null,
        lossDate: !this.isValidSelectedDate ? this.t('claims.loss_date_is_not_defined') : null,
        phoneNumber: this.validatePhoneNumber
      }
    },
    validatePhoneNumber () {
      if (this.contactPhoneNumber.length === 0 && this.phoneNumberRequired) {
        return this.t('enrollment.phone_not_defined_notification')
      } else if (this.contactPhoneNumber.length > 0) {
        let fallbackValidity = validateUSFormatPhoneNumber(this.contactPhoneNumber)
        let isValid = isValidPhoneNumber(this.contactPhoneNumber, 'US')
        if (isValid || fallbackValidity) {
          return null
        } else {
          return this.t('enrollment.please_check_the_correct_phone_number_format')
        }
      }
      return null
    },
    phoneNumberRequired () {
      return this.setting('claims.require_contact_phone_new_claim')
    },
  },
  data () {
    let startOfTomorrowMillis = +moment().add('1', 'day').startOf('day').toDate()
    setTimeout(() => {
      if (!this.policyId && this.policies.policies) {
        this.policyId = this.policies.policies[0].id
      }
    }, 16)
    return {
      selectedPropertyId: null,
      date: this.getNowAsString(),
      description: '',
      chosenPhotos: [],
      loading: false,
      contactPhoneNumber: '',
      validateInputValuesDictCopy: {
        lossDate: null,
        description: null,
        phoneNumber: null
      },
      startOfTomorrowMillis: startOfTomorrowMillis
    }
  }
}
</script>

<style lang="scss">
  .file-claim-forms {
    .iti {
      width: 100%;
    }
  }
</style>

