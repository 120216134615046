import axios from 'axios'
import config from '../../config/britecore'
import {retrieveAttachment} from '@/../shared_src/decrypt'
import * as qs from 'qs'

const documentsModule = {
  namespaced: true,
  state: {
    userDocumentsFileList: null,
    foldersNavigationFileList: null,
    isModalOpen: false,
    secretKey: null,
    storedImage: null,
  },
  mutations: {
    setUserDocumentsFileList (state, [dataToStore, folderNavigation]) {
      if (!folderNavigation) {
        state.userDocumentsFileList = dataToStore
      } else {
        state.foldersNavigationFileList = dataToStore
      }
    },
    modalToggle (state, desiredModalState) {
      // mobile-related
      state.isModalOpen = desiredModalState
    },
    setSecretKey (state, payload) {
      state.secretKey = payload
    },
    setStoredImage (state, payload) {
      state.storedImage = payload
    },
  },
  actions: {
    loadDocuments: function (context, [folderId, pageNumber, orderBy, ascending, folderNavigation]) {
      return new Promise((resolve, reject) => {
        axios.post(config.get_attachments_file_list_url, {
          token: localStorage.token,
          folder_id: folderId,
          order_by: orderBy,
          ascending: ascending,
          page: pageNumber
        })
          .then(response => {
            let dataToStore = response.data.data
            context.commit('setUserDocumentsFileList', [dataToStore, folderNavigation])
            resolve()
          }, response => {
            reject()
          })
      })
    },
    uploadUserAttachment: function (context, [fileName, fileDataBase64, fileType, folderIdName, policyNumber]) {
      return new Promise((resolve, reject) => {
        axios.post(config.upload_attachment_to_user_folder_url, {
          token: localStorage.token,
          file_data_base64: fileDataBase64,
          file_type: fileType,
          file_name: fileName,
          folder_id_name: folderIdName,
          policy_number: policyNumber
        })
        .then(response => {
          if (response.data.success) {
            resolve(response)
          } else {
            reject()
          }
        }).catch(() => reject())
      })
    },
    removeUserAttachments (context, attachmentIds) {
      return new Promise((resolve, reject) => {
        axios.post(config.remove_attachments_url, {
          token: localStorage.token,
          attachment_ids: attachmentIds
        })
        .then(response => {
          console.log('Attachments removed')
          resolve()
        }, response => {
          reject()
        })
      })
    },
    getAttachmentFile (context, [fileId, base64Decode]) {
      return new Promise((resolve, reject) => {
        let doNotCache = fileId.startsWith('policy-declaration_')  // No cache for this "file" because it is NOT unique
        retrieveAttachment(
            fileId, base64Decode, false, context.state.secretKey, context.rootState.common.contacts[0].id, doNotCache)
          .then(dataToSave => resolve(dataToSave))
          .catch((error) => reject(error))
      })
    },
    createFolder (context, [folderIdName, folderName]) {
      return new Promise((resolve, reject) => {
        axios.post(config.create_folder_in_user_folder, {
          folder_id_name: folderIdName,
          folder_name: folderName,
          token: localStorage.token,
        })
        .then(response => {
          console.log('Folder created')
          resolve()
        }, response => {
          reject()
        })
      })
    },
    renameFolder (context, [folderId, folderName]) {
      return new Promise((resolve, reject) => {
        axios.post(config.rename_user_file, {
          folder_id: folderId,
          folder_name: folderName,
          token: localStorage.token,
        })
        .then(response => {
          console.log('Folder renamed')
          resolve()
        }, response => {
          reject()
        })
      })
    },
    moveFileToFolder (context, [fileId, folderToId]) {
      return new Promise((resolve, reject) => {
        axios.post(config.move_user_file_to_folder, {
          file_id: fileId,
          to_folder_id: folderToId,
          token: localStorage.token,
        })
        .then(response => {
          console.log('File moved')
          resolve()
        }, response => {
          reject()
        })
      })
    },
    getSecretKey (context) {
      return new Promise((resolve, reject) => {
        if (localStorage.token) {
          axios.post(config.get_personal_secret_key, qs.stringify({token: localStorage.token}))
            .then((response) => {
              context.commit('setSecretKey', response.data.data)
              resolve()
            }).catch((e) => {
              context.commit('setSecretKey', null)
              reject(e)
            })
        } else {
          context.commit('setSecretKey', null)
          reject()
        }
      })
    }
  },
  getters: {
    documentsFileList: state => {
      return state.userDocumentsFileList
    },
    foldersNavigationFileList: state => {
      return state.foldersNavigationFileList
    },
  }
}

export default documentsModule
