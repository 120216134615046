<template>
  <div v-if="insureds.length">
    <p class="u-mt1">{{ t('enrollment.please_identify_yourself_on_missing_policy') }}</p>
    <el-form>
      <el-form-item class="umb-1 u-max-width" v-for="insured in registered_insureds" :key="insured.id" >
        <el-radio value="false" class="el-radio--wide el-radio--main-bg" disabled border>{{ insured.name }} - {{ t('enrollment.user_already_enrolled') }}</el-radio>
      </el-form-item>
      <el-form-item class="umb-1 u-max-width " v-for="insured in insureds" :key="insured.id">
        <el-radio v-model="internalValue" :label="insured.id" class="el-radio--wide el-radio--main-bg" border>{{ insured.name }}</el-radio>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
  export default {
    name: 'ba-insured-selection',
    props: {
      value: Object,
      insureds: Array,
      registered_insureds: Array,
    },
    computed: {
      internalValue: {
        get () {
          return this.value
        },
        set (val) {
          this.$emit('input', val)
        }
      }
    },
  }
</script>
