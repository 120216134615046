<template>
  <div class="tabs-view">
    <div class="tabs-view__heading">
      <h2 class="secondary-header u-mr3 u-mt2">
        <ba-policy-header :policy="policy" :show-address="!policy.isGen3"></ba-policy-header>
      </h2>
      <div>
        <el-dropdown class="policy-dropdown" trigger="click">
          <div class="box-menu">
            <fa-icon :icon="['far', 'ellipsis-v']"/>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-if="setting('claims.claims_enabled') && !policy.is_canceled"
                              @click="newClaimButtonClick"
                              @click.native="newClaimButtonClick"
                              data-test-id="new-claim"
            >
              {{ t('claims.file_claim_headline') }}
            </el-dropdown-item>
            <el-dropdown-item @click="loadPageDocuments"
                              @click.native="loadPageDocuments"
                              class="u-text-capitalize"
            >
              {{ t('policies.view_documents') }}
            </el-dropdown-item>
            <el-dropdown-item @click="linkToAgent"
                              @click.native="linkToAgent"
            >
                  {{ t('agent.agent_contact_us') }}
            </el-dropdown-item>
            <el-dropdown-item v-if="showDeclarationLink" @click="navigateToPolicyDeclaration()"
                              @click.native="navigateToPolicyDeclaration()"
            >
                  {{ t('policies.view_declaration') }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>

    <div class="u-mt2">
      <div class="u-mb1 tabs-view__policy-info">
        <div class="policy-data">
          <h2 class="u-mt0 u-mb1">{{ t('payments.policy_#', {number: policy.policy_number}) }}
            <div v-if="policy.is_canceled" class="subheader u-mt2">({{ policy.policy_status }})</div>
          </h2>
          <div class="policy-date">
            {{ dateLong(policy.current_term.effective_date) }} - {{ policy.is_canceled ? dateLong(policy.revision.cancel_date) : dateLong(policy.current_term.expiration_date) }}
          </div>
        </div>

        <p v-if="policy.current_due > 0 && canMakePayment(policy)">
          <router-link :to="{ path: '/payments' }" data-test-id="make-payment">
            {{ t('payments.make_payment_due', {amount: asCurrency(policy.current_due)}) }}
          </router-link>
        </p>
        <p v-if="policy.payoff_amount <= 0 && policy.next_due > 0 && canMakePayment(policy)">
          <router-link :to="{ path: '/payments' }" data-test-id="make-payment">
            {{ t('payments.make_payment_next_due', {amount: asCurrency(policy.next_due)}) }}
          </router-link>
        </p>
        
        <div class="u-mt4 u-mb0">
          <div class="subheader u-text-uppercase">{{ t('policies.named_insureds') }}</div>
          <el-row :gutter="40"  v-for="named_insured in policy.revision.named_insureds" :key="named_insured.contact_id" class="tabs-view__contacts u-mb2">
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8" class="tabs-view__contacts">
              <div>{{ named_insured.name}}</div>
            </el-col>
          </el-row>
        </div>
      </div>
      
      <div v-if="policy.isGen3">
        <risk-details :policyId='policy.id'></risk-details>
      </div>
      
      <div v-if="!policy.isGen3 && !hideLocationDetails">
        <div class="u-mt4 u-mb3">
          <p class="secondary-title">{{ t('policies.location_details') }}</p>
        </div>

        <el-row :gutter="40" v-for="property in policy.properties" :key="property.id" class="location-details u-ml0 u-mr0 u-mb3">
          <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
            <div class="location-details__sign u-pb4">
              <fa-icon :icon="['far', 'map-marker']" class="link-icon-medium" />
              <div class="u-ml2">{{ t('global.address') }}</div>
            </div>
            <div class="location-details__text u-mb1">{{ property.address_line1 }}</div>
            <div v-if="property.address_line2" class="location-details__text u-mb1">{{ property.address_line2 }}</div>
            <div class="location-details__text u-mb2">{{ property.address_city }}, {{ property.address_state }} {{ property.address_zip }}</div>
          </el-col>
          <el-col v-if="!property.deleted" :xs="24" :sm="24" :md="8" :lg="8" :xl="8" class="location-details__column col-border">
            <div class="u-mt2 u-mb2">
                <bc-property-images-block :propertyId="property.id"/>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
            <upload-image :propertyId="property.id"/>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import riskDetails from './riskdetails.vue'
import BaPolicyHeader from '@shared_src/policies/policy-header.component'
import BcPropertyImagesBlock from '@/components/BcPropertyImagesBlock'
import { isPaymentAllowed } from '@shared_src/utils/payments.utils'
import uploadImage from './upload-image'

export default {
  components: {
    BaPolicyHeader,
    'risk-details': riskDetails,
    'bc-property-images-block': BcPropertyImagesBlock,
    'upload-image': uploadImage,
  },
  computed: {
    ...mapGetters('policies', ['policyById']),
    ...mapGetters('agents', ['uiAgentKeys']),
    policy () {
      return this.policyById(this.policyId)
    },
    policyNumber () {
      return this.policy ? this.policy.policy_number : '...'
    },
    hideLocationDetails () {
      return this.setting('global.hide_location_details')
    },
    showDeclarationLink () {
      return !this.policy.isGen3 && this.setting('policies.show_declaration_link')
    }
  },
  methods: {
    newClaimButtonClick () {
      this.$router.push('/claims/new/' + this.policy.id)
    },
    linkToAgent () {
      let ids = [this.policy.agent_contact_id, this.policy.agency_contact_id]
      let availableIds = ids.filter(id => this.uiAgentKeys.includes(id))
      let hash = availableIds.length ? availableIds[0] : null
      this.$router.push({path: '/agents/list', hash: hash})
    },
    loadPageDocuments () {
      return this.$router.push({path: `/documents/${this.policy.id}/folders/root`})
    },
    navigateToPolicyDeclaration () {
      this.$router.push({
        path: `/document-view/policy-declaration_${this.policy.id}`,
        query: {fileName: `Declaration for ${this.policy.policy_number}`, folderName: ''}
      })
    },
    canMakePayment (policy) {
      return isPaymentAllowed(policy)
    }
  },
  data () {
    return {
      policyId: this.$route.params.policyId,
    }
  }
}
</script>

<style lang="scss">
  @import '../../sass/_variables.scss';

.policy-date {
  color: transparentize($color-black, .4);
}

.location-details {
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 20px 0;

  @media only screen and (max-width: $l-screen) {
    text-align: center;
    padding: 20px;
  }
  .col-border {
    border-left: 1px solid #E5E5E5;
    border-right: 1px solid #E5E5E5;
    @media only screen and (max-width: 991px) {
      border-top: 1px solid #E5E5E5;
      border-bottom: 1px solid #E5E5E5;
      border-left: none;
      border-right: none;
    }
  }
  &__column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 200px;

    @media only screen and (max-width: $l-screen) {
      min-height: 100px;
    }

    [data-icon="cloud-upload"] {
      font-size: 30px;
      color: #C0C0C0;
    }
  }
  &__sign {
    display: flex;
    align-items: center;
    font-size: 18px;
    color: $color-black;

    @media only screen and (max-width: $l-screen) {
      justify-content: center;
    }

    & > .svg-inline--fa {
      font-size: 30px;
      color: #C0C0C0;
      width: 1em;
    }
  }
  &__text {
    font-size: 16px;
    color: black;
  }
}

</style>
