module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: (_vm.loading),
      expression: "loading"
    }]
  }, [_c('h2', {
    staticClass: "secondary-header u-mt4 u-mb4"
  }, [_vm._v(_vm._s(_vm.t('global.emails_label')))]), _vm._v(" "), _vm._l((_vm.insureds), function(insured, index) {
    return _c('div', {
      key: insured.id,
      staticClass: "el-card box-card is-always-shadow"
    }, [_c('div', {
      staticClass: "u-p3"
    }, [_c('h3', {
      staticClass: "subheader"
    }, [_vm._v(_vm._s(_vm.t('global.policies_header')))]), _vm._v(" "), _vm._l((insured.policies), function(policy, index) {
      return _c('div', {
        key: index,
        staticClass: "u-mb3"
      }, [_c('div', {
        staticClass: "emails-info__headline u-text--bold"
      }, [_vm._v(" " + _vm._s(policy.policyHeadline) + " ")]), _vm._v(" "), _c('div', {
        staticClass: "subheader u-mt1 u-mb1"
      }, [_vm._v(_vm._s(_vm.t('policies.header_policy_number', {
        policy_number: policy.policyNumber
      })))])])
    }), _vm._v(" "), _c('h3', {
      staticClass: "subheader u-mt2"
    }, [_vm._v(_vm._s(_vm.t('account.associated_emails_label')) + " *")]), _vm._v(" "), _c('el-input', {
      staticClass: "item-input-field pin-item__input",
      attrs: {
        "autofocus": "",
        "type": "text",
        "required": ""
      },
      nativeOn: {
        "keyup": function($event) {
          if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
          return _vm.saveEmail(insured)
        }
      },
      model: {
        value: (insured.email),
        callback: function($$v) {
          _vm.$set(insured, "email", $$v)
        },
        expression: "insured.email"
      }
    }), _vm._v(" "), (insured.validationError) ? _c('div', {
      staticClass: "u-warning"
    }, [_c('i', {
      staticClass: "material-icons md-text",
      attrs: {
        "aria-hidden": "true"
      }
    }, [_vm._v("warning")]), _vm._v(" " + _vm._s(insured.validationError) + "\n        ")]) : _vm._e(), _vm._v(" "), (insured.unconfirmedEmail) ? _c('div', [_c('h3', {
      staticClass: "subheader u-mt2 u-mb0"
    }, [_vm._v(_vm._s(_vm.t('account.pending_confirmation_email_label', {
      new_email: insured.unconfirmedEmail
    })))])]) : _vm._e(), _vm._v(" "), _c('div', {
      staticClass: "u-flex-justify-content-end"
    }, [(_vm.insuredsWithEditedEmail[insured.id]) ? _c('el-button', {
      attrs: {
        "type": "primary"
      },
      on: {
        "click": function($event) {
          return _vm.saveEmail(insured)
        }
      }
    }, [_vm._v("\n            " + _vm._s(_vm.t('global.save_changes')) + "\n          ")]) : _vm._e()], 1)], 2)])
  }), _vm._v(" "), _c('el-dialog', {
    attrs: {
      "visible": _vm.visibleDialog,
      "show-close": true,
      "close-on-click-modal": false,
      "close-on-press-escape": false
    }
  }, [_c('div', {
    staticClass: "el-dialog__title_border",
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_vm._v("\n        " + _vm._s(_vm.t('account.user_action_required')) + "\n      ")]), _vm._v(" "), (_vm.dialogMode === 'input') ? _c('div', [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.t('account.email_update_confirmation_sent', {
        new_email: _vm.insured.email
      }))
    }
  }), _vm._v(" "), _c('el-input', {
    staticClass: "item-input-field pin-item__input u-mt3 u-mb3",
    attrs: {
      "autofocus": "",
      "type": "text",
      "required": ""
    },
    model: {
      value: (_vm.confirmationCode),
      callback: function($$v) {
        _vm.confirmationCode = $$v
      },
      expression: "confirmationCode"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "u-flex-justify-content-end"
  }, [_c('el-button', {
    staticClass: "el-button--small u-mr1",
    attrs: {
      "type": "secondary"
    },
    on: {
      "click": function($event) {
        return _vm.cancelCodeChallenge()
      }
    }
  }, [_vm._v("\n            " + _vm._s(_vm.t('global.cancel')) + "\n          ")]), _vm._v(" "), _c('el-button', {
    staticClass: "el-button--small",
    attrs: {
      "type": "primary",
      "disabled": !_vm.confirmationCode
    },
    on: {
      "click": function($event) {
        return _vm.checkConfirmationCode()
      }
    }
  }, [_vm._v("\n            " + _vm._s(_vm.t('global.confirm')) + "\n          ")])], 1)], 1) : _c('div', [_c('div', {
    staticClass: "u-mt2 u-mb2"
  }, [_vm._v(_vm._s(_vm.info))]), _vm._v(" "), _c('div', {
    staticClass: "u-flex-justify-content-end"
  }, [_c('el-button', {
    staticClass: "el-button--small",
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function($event) {
        return _vm.closeInfoDialog()
      }
    }
  }, [_vm._v("\n            " + _vm._s(_vm.t('global.ok')) + "\n          ")])], 1)])])], 2)
},staticRenderFns: []}