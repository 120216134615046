<template>
  <div class="root payment-method u-flex u-flex-horizontal-center u-flex-align-items  u-py2 u-pl2 u-mt2 u-pr3 ">
    <div class="payment-method_icon-box">
      <fa-icon size="2x"  :icon="currentIcon" />
    </div>
    <div class="u-text-collapse-line-height">{{ title }}</div>
    <div v-if="expires" class="subheader u-ml3 u-mt0 u-mb0">
      {{ expires }}
    </div>
    <div class="payment-method__remove">
      <el-button @click="$emit('willDelete', paymentMethod)" :icon="['fas', 'trash']" class="payment-method__remove-button" size="small"></el-button>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
// eslint-disable-next-line no-unused-vars
import {PaymentMethod} from '@shared_src/models/flow.typings'

export default {
  name: 'ba-single-payment-method',
  props: ['paymentMethod'],
  computed: {
    title () {
      if (this.paymentMethod.type === 'Credit Card') {
        return `${this.paymentMethod.name} ending in #${this.paymentMethod.masked_number}`
      } else {
        return `${this.paymentMethod.name} Account ending in ${this.paymentMethod.masked_number}`
      }
    },
    expires () {
      if (this.paymentMethod.type !== 'Credit Card') {
        return null
      }
      let dateObj = moment(this.paymentMethod.expire_date)
      return `Exp ${dateObj.format('MM/YYYY')}`
    },
    currentIcon () {
      let paymentMethod : PaymentMethod = this.paymentMethod
      if (paymentMethod.type === 'Credit Card') {
        let cardProvider = paymentMethod.name.toLowerCase()
        if (cardProvider === 'mastercard') {
          return ['fab', 'cc-mastercard']
        }
        if (cardProvider === 'visa') {
          return ['fab', 'cc-visa']
        }
        if (cardProvider === 'american express') {
          return ['fab', 'cc-amex']
        }

        return ['far', 'credit-card']
      } else {
        return ['fas', 'university']
      }
    },
  },
}
</script>

<style scoped>
  .payment-method{
    border: 2px solid #E5E5E5;
    border-radius: 4px;
    justify-content: flex-start;

  }
  .payment-method_icon-box {
    width: 4em;
    color: white;
    text-align: center;
    border-radius: 4px;
  }
  .payment-method__remove {
    margin-left: auto;
  }
  .payment-method__remove-button {
    border: none;
  }
</style>

<style>
.payment-method_icon-box svg path {
  fill: #626262 !important;
}
</style>
