module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: (_vm.loading),
      expression: "loading"
    }],
    attrs: {
      "data-f7": "page"
    }
  }, [_c('bc-navbar', {
    attrs: {
      "title": _vm.t('global.policies_header'),
      "backLink": ""
    }
  }), _vm._v(" "), (_vm.insured) ? _c('div', {
    attrs: {
      "data-f7": "block"
    }
  }, [_c('bc-contact', {
    attrs: {
      "name": _vm.insured.name,
      "imageData": {
        photoId: _vm.insured.photo_id
      },
      "contact": _vm.insured,
      "human": ""
    }
  })], 1) : _vm._e()], 1)
},staticRenderFns: []}