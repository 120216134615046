<template>
  <div v-loading="loading">
    <div v-if="policies">
      <div class="bulk-pay-once__radio">
        <el-radio-group v-model="activeName">
          <el-radio v-for="(value, name) in payOptions" v-if="value.length" :label="name" :key="name">
            {{ getLabel(name) }}
          </el-radio>
        </el-radio-group>
      </div>
      <div class="bulk-pay-once__info">

        <div v-for="el in checkedPolicies" :key="el.policyId" class="bulk-pay-once__list">
          <div class="u-flex-horizontal-center">
            <div class="payments__custom-checkbox u-mr3">
              <el-checkbox disabled checked></el-checkbox>
              <div class="item-media"><i class="icon icon-form-checkbox"></i></div>
            </div>
            <div class="bulk-pay-once__property">
              <div>{{ el.address }}</div>
              <div class="bulk-pay-once__policy u-mt1">
               {{ t('payments.policy_#', {number: el.policyNumber}) }}
              </div>
            </div>
          </div>
          <div :class="el.cssClass" class="bulk-pay-once__property">
             {{ el.dollarAmount }}
          </div>
        </div>
      </div>
    </div>

    <div v-if="!ownedPaymentMethods.length" class="u-mt2 u-mb2">
      <div class="u-warning">
        <i class="material-icons md-text" aria-hidden="true">warning</i>
        {{ t('payments.you_currently_have_no_payment_methods')}}
      </div>
    </div>

    <div v-if="ownedPaymentMethods.length" class="u-mt3 u-mb4">
      <div>
        <span class="subheader u-text-uppercase u-block">{{ t('payments.payment_method') }}*</span>
        <el-select v-model="selectedPaymentMethod" class="selected-payment-method">
          <el-option v-for="m in ownedPaymentMethods" :value="m.id" :key="m.id" :label="m.label"></el-option>
        </el-select>
      </div>
    </div>

    <el-card>
      <a @click="addPaymentMethod">
        <i class="material-icons u-mr1 md-text">add</i>{{ t('payments.add_payment_method') }}
      </a>
    </el-card>

    <div class="u-text-right">
      <el-button  class="el-button--primary u-text-uppercase"
                  @click="makePayment"
                  :disabled="!isCorrectPayment || this.selectedPaymentMethodId === null">
        {{ payButtonText }}
      </el-button>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import _ from 'lodash'

export default {
  name: 'general-bulk-pay',
  props: {
    policies: {
      type: Array,
      required: true,
    },
    payOptions: {
      type: Object,
      required: true,
    },
    activePayType: {
      type: String,
      required: false,
    }
  },
  watch: {
    activePayType: {
      handler (val) {
        this.activeName = val
      },
      immediate: true
    }
  },
  data () {
    return {
      loading: false,
      selectedPaymentMethodId: null,
      activeName: ''
    }
  },
  computed: {
    ...mapState('payments', ['ownedPaymentMethods']),
    calculateAmount () {
      const getSum = (type) => this.policies.reduce((acc, policy) => acc + policy[type], 0)
      return {
        currentDue: getSum('current_due'),
        entireTerm: getSum('payoff_amount'),
        nextTerm: getSum('next_due')
      }
    },
    getCurrentAmount () {
      return this.calculateAmount[this.activeName]
    },
    isCorrectPayment () {
      return this.getCurrentAmount > 0
    },
    payButtonText () {
      if (!this.isCorrectPayment) {
        return this.t('payments.nothing_to_pay_at_the_time')
      }
      return this.t('payments.pay_amount_now', { amount: this.dollarAmount(this.getCurrentAmount) })
    },
    selectedPaymentMethod: {
      get () {
        if (this.selectedPaymentMethodId) {
          return this.selectedPaymentMethodId
        }
        if (this.ownedPaymentMethods && this.ownedPaymentMethods.length) {
          this.selectedPaymentMethodId = this.ownedPaymentMethods[0].id
        }
        return this.selectedPaymentMethodId
      },
      set (newValue) {
        this.selectedPaymentMethodId = newValue
      }
    },
    checkedPolicies () {
      return this.payOptions[this.activeName]
    },
  },
  methods: {
    makeLogAction (paymentType, dollarAmt) {
      if (this.checkedPolicies.length > 1) {
        this.logInsuredAction('Clicked "Bulk payment"', 'custom_bulk_payment', {
          payment_type: paymentType,
          dollar_amount: dollarAmt,
        })
      }
    },
    addPaymentMethod () {
      this.$emit('handleDialogs', false)
    },
    toggleMakePaymentsDialog (val) {
      this.$emit('handleMakePaymentsDialog', val)
    },
    getLabel (name) {
      const getAmount = (name) => this.calculateAmount[name].toFixed(2)
      if (name === 'currentDue') {
        return this.t('payments.pay_current_due', {amount: getAmount(name)})
      }
      if (name === 'entireTerm') {
        return this.t('payments.pay_entire_term', {amount: getAmount(name)})
      }
      if (name === 'nextTerm') {
        return this.t('payments.pay_next_term', {amount: getAmount(name)})
      }
    },
    dollarAmount (amount) {
      return '$' + Number(amount).toFixed(2)
    },
    getAllPayment () {
      return this.calculateAmount[this.activeName]
    },
    makePayment () {
      let current = this.ownedPaymentMethods.find(x => x.id === this.selectedPaymentMethodId)
      let paymentType = this.checkedPolicies[0].paymentType
      let message = this.t('payments.one_time_bulk_payment_confirmation_message', {
        amount: this.dollarAmount(this.getAllPayment()),
        payment_method: current.label,
        policies_num: this.checkedPolicies.length,
        transactions_num: this.checkedPolicies.length
      })
      let title = this.t('payments.one_time_confirm_payment_title')
      this.$confirm(message, title,
        {
          confirmButtonText: this.t('payments.accept'),
          cancelButtonText: this.t('payments.decline')
        }).then(() => {
          this.$emit('resetCheckedPoliciesList')  // reset make payment page's checkboxes on any payment attempt
          let dollarAmt = this.dollarAmount(this.getCurrentAmount)
          this.makeLogAction(paymentType, dollarAmt)
          this.loading = true
          let promisesArray = _.map(this.checkedPolicies, policy => {
            return this.$store.dispatch('payments/makePayment', {
              policy_id: policy.policyId,
              payment_amount: policy.paymentType,
              payment_method_id: this.selectedPaymentMethodId,
              number_of_policies: this.checkedPolicies.length,
              dollar_amount: policy.dollarAmount,
              policy_number: policy.policyNumber,
            })
          })
          Promise.all(promisesArray)
            .then(() => {
              this.loading = false
              this.toggleMakePaymentsDialog(false)
              this.paymentDidSucceed()
            })
            .catch((e) => {
              this.loading = false
              this.toggleMakePaymentsDialog(false)
              this.$elAlert(this.t('payments.one_time_error_payment_error_message'), this.t('global.error'))
            })
        })
    },
    paymentDidSucceed () {
      this.$emit('reloadPolicies')
      let title = this.t('payments.one_time_success_payment_title')
      let message = this.t('payments.one_time_success_payment_success_receipt', {
        amount: this.dollarAmount(this.getAllPayment())
      })
      this.$elAlert(title, message)
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../../sass/_variables.scss';

.bulk-pay-once {
  margin-bottom: 20px;
  border-bottom: 2px solid #EFEFEF;

  &__radio {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 2px solid #EFEFEF;
    width: 100%;

    .el-radio__label {
      font-size: 16px;
      color: $color-black;
    }
  }
  &__info {
    margin-bottom: 20px;
    border-bottom: 2px solid #EFEFEF;
  }
  &__list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  &__checkbox {
    width: 20px;
  }
  &__property {
    font-size: 16px;
    font-weight: 500;
    color: $color-black;
  }
  &__policy {
    font-size: 15px;
    color: #626262;
    font-weight: 300;
  }
}

.selected-payment-method {
  z-index: 0 !important;
}
</style>
