<template>
  <span>{{headerText}}<span v-if="showAddress">{{addressText}}</span></span>
</template>

<script>
  import {mapGetters} from 'vuex'

  export default {
    props: {
      policy: Object,
      // for web ui we will show address on gen2 policies
      showAddress: Boolean,
    },
    computed: {
      ...mapGetters('policies', ['riskLabelByPolicy', 'riskDataForPolicyCard']),
      riskData () {
        return this.riskDataForPolicyCard(this.policy.id)
      },
      headerText () {
        if (!this.policy) {
          return
        }
        if (!this.policy.isGen3) {
          return this.policy.policy_type + ' - ' + this.policy.policy_number
        } else {
          let riskData = this.riskData
          if (riskData) {
            let totalRisks = riskData.length
            let label = this.riskLabelByPolicy(this.policy, true)
            if (totalRisks > 1) {
              return `${totalRisks} ${label}`
            } else {
              return this.policy.policy_type
            }
          }
        }
      },
      addressText () {
        let result = ''
        if (this.policy.properties.length <= 0) {
          return ''
        }
        let property = this.policy.properties[0]
        if (property.address_line1) {
          result += `, ${property.address_line1}`
        }
        if (property.address_city) {
          result += `, ${property.address_city}`
        }
        if (property.address_state) {
          result += `, ${property.address_state}`
        }
        return result
      }
    },
    name: 'ba-policy-header',
  }
</script>
