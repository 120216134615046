module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: (_vm.loading),
      expression: "loading"
    }],
    class: _vm.tosFromLogin ? 'tos-from-login' : ''
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.convertMarkdownToHtml(_vm.tos))
    }
  }), _vm._v(" "), (!_vm.isEnrollmentTOS && _vm.tosFromLogin) ? _c('div', [_c('div', {
    staticClass: "u-mt4 u-mb4"
  }, [_c('el-checkbox', {
    attrs: {
      "data-cy": "accept-checkbox"
    },
    model: {
      value: (_vm.checked),
      callback: function($$v) {
        _vm.checked = $$v
      },
      expression: "checked"
    }
  }, [_vm._v("\n                " + _vm._s(_vm.t('enrollment.i_have_read_and_accept_tos')) + "\n            ")])], 1), _vm._v(" "), _c('el-button', {
    staticClass: "el-button--wide",
    attrs: {
      "data-cy": "accept-button",
      "type": "primary",
      "disabled": !_vm.checked
    },
    on: {
      "click": _vm.advance
    }
  }, [_vm._v("\n            " + _vm._s(_vm.t('enrollment.agree_to_terms')) + "\n        ")])], 1) : _vm._e()])
},staticRenderFns: []}