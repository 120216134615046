module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', [_c('el-button', {
    attrs: {
      "type": "info"
    },
    on: {
      "click": _vm.openDialog
    }
  }, [_vm._v(_vm._s(_vm.t('enrollment.add_missing_policy_label')))]), _vm._v(" "), _c('el-dialog', {
    attrs: {
      "visible": _vm.state.dialogVisible,
      "close-on-press-escape": false,
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function($event) {
        return _vm.$set(_vm.state, "dialogVisible", $event)
      },
      "handleCloseDialog": _vm.dialogWillClose
    }
  }, [_c('div', {
    staticClass: "el-dialog__title_border add-missing-policy__dialog-title",
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [(_vm.canGoBack && !_vm.state.loading) ? _c('el-button', {
    staticClass: "u-mr3",
    attrs: {
      "icon": ['far', 'chevron-left'],
      "type": 'text'
    },
    on: {
      "click": _vm.back
    }
  }) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "el-dialog__title"
  }, [_vm._v(_vm._s(_vm.dialogTitle))])], 1), _vm._v(" "), _c('div', [(_vm.state.debug) ? _c('div', [_c('div', [_vm._v("page `" + _vm._s(_vm.workflow.page) + "`")]), _vm._v(" "), _c('div', [_vm._v("policy_number `" + _vm._s(_vm.state.policy_number) + "`")]), _vm._v(" "), _c('div', [_vm._v("insured_id `" + _vm._s(_vm.searchState.selectedInsuredId) + "`")]), _vm._v(" "), _c('div', [_vm._v("insured `" + _vm._s(_vm.searchState.selectedInsured) + "`")]), _vm._v(" "), _c('div', [_vm._v("selectedContactMethod `" + _vm._s(_vm.challenge.selectedContactMethod) + "`")]), _vm._v(" "), _c('div', [_vm._v("send_via `" + _vm._s(_vm.challenge.sendVia) + "`")]), _vm._v(" "), _c('div', [_vm._v("code `" + _vm._s(_vm.challenge.code) + "`")]), _vm._v(" "), _c('div', [_vm._v("emails: " + _vm._s(_vm.emails))]), _vm._v(" "), _c('hr')]) : _vm._e(), _vm._v(" "), (_vm.workflow.page === 'search') ? _c('ba-search-by-policy-number', {
    model: {
      value: (_vm.state.policy_number),
      callback: function($$v) {
        _vm.$set(_vm.state, "policy_number", $$v)
      },
      expression: "state.policy_number"
    }
  }) : _vm._e(), _vm._v(" "), (_vm.workflow.page === 'insuredSelection') ? _c('ba-insured-selection', {
    attrs: {
      "insureds": _vm.searchState.insureds,
      "registered_insureds": _vm.searchState.registered_insureds
    },
    model: {
      value: (_vm.searchState.selectedInsuredId),
      callback: function($$v) {
        _vm.$set(_vm.searchState, "selectedInsuredId", $$v)
      },
      expression: "searchState.selectedInsuredId"
    }
  }) : _vm._e(), _vm._v(" "), (_vm.workflow.page === 'challenge') ? _c('ba-policy-challenge', {
    attrs: {
      "challenge": _vm.challenge,
      "selectedInsured": _vm.searchState.selectedInsured,
      "errorMessage": _vm.state.errorMessageDetail
    },
    on: {
      "requestCode": _vm.requestCode
    }
  }) : _vm._e(), _vm._v(" "), (_vm.workflow.page === 'sendCode') ? _c('ba-code-delivery', {
    attrs: {
      "selectedInsured": _vm.searchState.selectedInsured,
      "sendVia": _vm.challenge.sendVia
    },
    model: {
      value: (_vm.challenge.selectedContactMethod),
      callback: function($$v) {
        _vm.$set(_vm.challenge, "selectedContactMethod", $$v)
      },
      expression: "challenge.selectedContactMethod"
    }
  }) : _vm._e(), _vm._v(" "), (_vm.workflow.page === 'useAnotherEmail') ? _c('ba-use-different-email', {
    attrs: {
      "available-emails": _vm.emails.availableEmails,
      "loading": _vm.state.loading
    },
    model: {
      value: (_vm.emails.toUse),
      callback: function($$v) {
        _vm.$set(_vm.emails, "toUse", $$v)
      },
      expression: "emails.toUse"
    }
  }) : _vm._e(), _vm._v(" "), (_vm.workflow.page === 'emailVerification') ? _c('ba-new-email-verification', {
    attrs: {
      "email": _vm.emails.toUse,
      "verificationFailure": _vm.emails.verificationFailure
    },
    model: {
      value: (_vm.emails.verificationCode),
      callback: function($$v) {
        _vm.$set(_vm.emails, "verificationCode", $$v)
      },
      expression: "emails.verificationCode"
    }
  }) : _vm._e(), _vm._v(" "), (_vm.workflow.page === 'success') ? _c('ba-missing-policy-added', {
    attrs: {
      "state": _vm.state
    }
  }) : _vm._e()], 1), _vm._v(" "), (_vm.workflow.page !== 'success' || (_vm.workflow.page === 'success' && !_vm.state.loading)) ? _c('div', {
    staticClass: "u-flex-justify-content-end",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: (_vm.state.loading),
      expression: "state.loading"
    }],
    staticClass: "u-text-capitalize",
    attrs: {
      "disabled": !_vm.canGoNext || _vm.state.loading,
      "type": "primary"
    },
    on: {
      "click": function($event) {
        return _vm.next()
      }
    }
  }, [_vm._v("\n        " + _vm._s(_vm.nextButtonText) + "\n      ")])], 1) : _vm._e()])], 1)
},staticRenderFns: []}