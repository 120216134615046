<template>
  <div class="agency-info">
    <div class="agency-info__content">
      <router-link :to="{ path: '/agents/list', hash: agentOrAgency.id }" class="contact-name__link u-text-trim" data-test-id="contact-agent">
        <div class="contact-name contact-name__agent u-text-trim u-mb1">{{ agentOrAgency.contact.name }}</div>
        <div v-for="policy in agentOrAgency.policies" :key="policy.policy_number">
          <div class="subheader u-mt2">{{ t('payments.policy_#', { number: policy.policy_number }) }}</div>
        </div>
      </router-link>
      <div v-if="agentsAmount === 1" class="agent-picture">
        <div v-if="agentOrAgency.contact.photo_id">
          <bc-general-image :fileId="agentOrAgency.contact.photo_id" class="u-img-responsive"/>
        </div>
        <fa-icon v-else :icon="['far', 'user']" />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'agency-card-content',
  props: ['agentOrAgency', 'agentsAmount']
}

</script>

<style scoped lang="scss">
@import '../sass/_variables.scss';

  .agency-info {
    height: 100%;
  }
  .agency-info__content {
    display: flex;
    justify-content: space-between;
  }
  .agent-picture {
    width: 25%;
    margin-left: 0.5em;
    text-align: center;
    &__icon {
      font-size: 35px;
    }
    svg[data-icon="user"] {
      font-size: 32px;
    }
  }
  .contact-name__link {
    color: $color-text !important;
  }
  .contact-name__agent {
    font-size: 18px;
    font-weight: 500;
  }
</style>
