<template>
  <div class="init-screen">
    <init-sidebar/>
      <div class="init-screen__info">
      <div class="init-block">
        <div class="init-block__tabs">
          <div @click="activateTab('logIn')" class="tab-switch">
            <ba-tab :sign="t('account.login_header')" :isActive="activeTypeTab === 'logIn'" type="logIn"></ba-tab>
          </div>
          <div @click="activateTab('signUp')" data-cy="link--sign-up" class="tab-switch">
            <ba-tab :sign="t('enrollment.sign_up')" :isActive="activeTypeTab === 'signUp'" type="signUp"></ba-tab>
          </div>
        </div>
        <div>
          <h1 class="init-block--heading">{{ t('global.welcome') }}</h1>
          <div v-if="!error" class="subheader u-mb3">{{ t('login.enter_the_information_below_to_login') }}</div>
        </div>
        <el-form class="mx-auto">
          <ba-alert v-if="error" type="warning">
            {{ error }}
          </ba-alert>
          <el-form-item v-show="!pin_nonce" :label="usernameFieldLabel">
            <el-input data-cy="username" v-if="!username" @keyup.enter.native="login" autofocus v-model="username" :class="{'u-input-error': validateInputValuesCopy.username}"></el-input>
            <el-input data-cy="username" v-else @keyup.enter.native="login" v-model="username" :class="{'u-input-error': validateInputValuesCopy.username}"></el-input>
            <div v-if="validateInputValuesCopy.username" class="u-input-validation u-danger u-pt2 u-pb1">
              {{ validateInputValuesCopy.username }}
            </div>
          </el-form-item>
      
          <el-form-item v-show="!pin_nonce" :label="t('enrollment.password_field')">
            <el-input data-cy="password" v-if="!username" @keyup.enter.native="login" type="password" v-model="password" class="u-mb2" :class="{'u-input-error': validateInputValuesCopy.password}"></el-input>
            <el-input data-cy="password" v-else autofocus @keyup.enter.native="login" type="password" v-model="password" class="u-mb2" :class="{'u-input-error': validateInputValuesCopy.password}"></el-input>
            <div v-if="validateInputValuesCopy.password" class="u-input-validation u-danger u-pb2">
              {{ validateInputValuesCopy.password }}
            </div>
            <el-checkbox v-model="usePinNextTime">{{ t('login.fast_login') }}</el-checkbox>
      
          </el-form-item>
      
          <el-form-item v-show="pin_nonce" :label="t('login.pin_placeholder')">
            <el-input v-model="pin" @keyup.enter.native="login"></el-input>
            <a @click="signInByEmailInstead" class="u-inline-block u-mt2">
              {{ usersAreIdentifiedByEmailAddress ? t('login.sign_in_using_email') : t('login.sign_in_by_username') }}
            </a>
          </el-form-item>
      
          <el-button data-cy="button__sign-in" type="primary" class="el-button--wide u-text-uppercase" @click="login" v-loading="loading">
            {{ t('account.login_header') }}
          </el-button>
          
          <div class="u-text-center u-pt3">
            <router-link :to="{ path: '/password-reset/search-account' }">
              {{ usersAreIdentifiedByEmailAddress ? t('login.forgot_your_password_or_email') : t('login.forgot_your_password_or_username') }}
            </router-link>
          </div>
  
        </el-form>
      </div>
    </div>
    <div class="init-screen__logo-language">
      <bc-footer bgClass="bg-login"></bc-footer>
      <div class="c-header__nav-link u-text--uppercase u-mt3">
        <div class="sidenav__link u-p0">
          <ba-custom-pages :only-public="true" class="u-mb2"/>
        </div>
      </div>
      <div class="init-screen__bc-language" v-if="displayLanguageLink">
        <ba-language-selector/>
      </div>
    </div>
  </div>
</template>

<script>
  /* eslint-disable */
  import  {mapActions, mapGetters, mapMutations, mapState} from 'vuex'

  import {localize as t} from '../utils/i18n'
  import {storeLoginTime} from '@shared_src/utils/timezone.utils'
  import initSidebar from '../components/init-sidebar'
  import Footer from "../components/footer"
  import baTab from "../ui/tab.component"
  import alert from '../ui/alert.component'
  import BaLanguageSelector from '@/components/language-selector.component'
  import BaCustomPages from '@/components/custom-pages-menu'
  
  const { isAllowedPage } = require('@/router-allowed')
  
  export default {
  components: {
    initSidebar,
    'bc-footer': Footer,
    'ba-tab': baTab,
    'ba-alert': alert,
    BaLanguageSelector,
    BaCustomPages
  },
    name: 'ba-login',
    data () {
    let error = null
    let username = ''
    let routerQuery = this.$router.currentRoute.query
    if (localStorage.getItem('enrollment-error')) {
      error = localStorage.getItem('enrollment-error')
    }
    if (localStorage.getItem('login__token-expired') && routerQuery && routerQuery.redir) {
      // this means we were redirected to home screen due to expired token
      error = t('global.error_relogin_msg')
      username = localStorage.getItem('last_user')
    }
    return {
      username: username,
      password:'',
      usePinNextTime: false,
      pin: '',
      error: error,
      loading: false,
      activeTypeTab: 'logIn',
      validateInputValuesCopy: {}
    }
  },
  beforeRouteLeave (to, from, next) {
    localStorage.removeItem('enrollment-error')
    if (localStorage.token) {
      next()
      return
    }
    if (isAllowedPage(to.path)) {
      next()
      return
    } else {
      console.log('Page navigation not allowed beforeRouteLeave')
      next('/login')

    }
  },
  methods: {
    ...mapActions('common', ['savePreferredLanguage']),
    ...mapActions('login', ['auth', 'clearPinNonce']),
    ...mapActions(['loadUserData']),
    ...mapMutations('common', ['setLanguage']),
    ...mapMutations('login', ['setUsernameToForceUpdatePassword']),
    activateTab (type) {
      this.activeTypeTab = type
      if (type === 'signUp') return this.$router.push('/enrollment/search-policies-step')
    },
    signInByEmailInstead () {
      this.error = null
      this.clearPinNonce()
    },
    ...mapActions('documents', [
      'getSecretKey'
    ]),
    clearErrors () {
      this.error = null
      this.validateInputValuesCopy = {}
    },
    setBusy() {
      this.loading = true
    },
    setIdle() {
      this.loading = false
    },
    login() {
      this.clearErrors()
      let payload
      if (this.pin_nonce) {
        if (!this.pin) return
        payload = {
          by: 'pin',
          pin: this.pin,
          pin_nonce: this.pin_nonce
        }
      } else {
        this.validateInputValuesCopy = {...this.validateInputValues}  // deep copy
        if (!this.username || !this.password) return
        payload = {
          by: 'email',
          username: this.username,
          password: this.password,
          usePinNextTime: this.usePinNextTime,
        }
      }
      this.setBusy()
      this.auth(payload).then(() => {
        let last_location = ''
        let routerQuery = this.$router.currentRoute.query
        if (routerQuery) {
          if (routerQuery.url) {
            last_location = routerQuery.url
          } else if (routerQuery.redir) {
            last_location = localStorage.getItem('last_location')
          }
        }
        localStorage.removeItem('login__token-expired')
        localStorage.removeItem('last_location')
        localStorage.removeItem('last_user')
        storeLoginTime()
        // getSecretKey first, otherwise we face issues with pictures/attachments
        this.getSecretKey().then(this.loadUserData).then(() => {
          this.setIdle()
          this.savePreferredLanguage(null)
          if (this.tos) {
            /* If ToS is loaded, needs to be agreed */
            this.$router.push('/enrollment/terms-of-service/0')
            return
          }
          let destination_link = '/dashboard'
          if (last_location) {
            destination_link = last_location
          }
          this.$router.push(destination_link)

        })
      }).catch((response) => {
        this.setIdle()
        if (response.data && response.data.cause) {
          if (response.data.cause === 'account.requires_password_reset') {
            this.setUsernameToForceUpdatePassword(this.username)
            this.$router.push('/password-update-required')
            return
          }
          this.error = this.causeToString(response.data.cause)
        } else {
          this.error = this.t('login.login_failed')
        }
      })
    },
  },
  computed: {
    ...mapState('common', ['settings','language']),
    ...mapState('login', ['pin_nonce']),
    ...mapGetters('account', ['tos']),
    usersAreIdentifiedByEmailAddress () {
      return this.setting('enrollment.users_are_identified_by_email_address')
    },
    usernameFieldLabel () {
      return this.usersAreIdentifiedByEmailAddress === true ? this.t('login.email') : this.t('enrollment.username')
    },
    displayLanguageLink () {
      return this.setting('global.display_spanish_link') === true
    },
    validateInputValues () {
      const errorMsg =  this.t('global.information_entered_is_not_valid')
      return {
        username: !this.username.trim() ? errorMsg : null,
        password: !this.password.trim() ? errorMsg : null,
      }
    },
  }
}
</script>

<style lang="scss">
  @import '../sass/_variables.scss';
  
  .init-screen {
    display: flex;
    min-height: 100vh;
  
    @media only screen and (max-width: $m-screen) {
      position: relative;
      display: flex;
      flex-direction: column;
      min-height: 100vh;
    }
    
    &__logo {
      position: absolute;
      height: auto;
      width: 330px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      
      @media only screen and (max-width: $m-screen) {
        position: relative;
        width: 260px;
        height: 200px;
        top: 0;
        left: 0;
        transform: translate(0, 0);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
  
        &-image {
          width: 100%;
        }
      }
      
      @media only screen and (max-width: $s-screen) {
        width: 255px;
        height: 160px;

        &-image {
          width: auto;
          max-height: 100px;
        }
      }
      
    }
    .el-form-item__label {
      text-transform: uppercase;
    }
    .el-form-item {
      margin-bottom: 12px;
    }
    &__info {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: #F8F8F8;
      width: 100%;
      
      @media only screen and (max-width: $m-screen) {
        padding: 60px 0 90px;
      }
  
      @media only screen and (max-width: $s-screen) {
        flex-grow: 1;
        padding: 40px 0 90px;
      }

      @media only screen and (max-width: $xs-screen) {
        padding: 25px 0 40px;
      }
    }
    &__logo-language {
      display: none;
      background: $color-white;
      
      @media only screen and (max-width: $m-screen) {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 15px 40px;
        flex-grow: 1;
      }
      
      @media only screen and (max-width: $s-screen) {
        padding: 0 40px 15px;
        background: #F8F8F8;
        flex-grow: 0;
        footer.el-footer {
          background: #F8F8F8;
        }
      }
      
      @media only screen and (max-width: $xs-screen) {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
      }
      
    }
    .init-block {
      width: 360px;
      margin-top: 250px;
      
      @media only screen and (max-width: $m-screen) {
        width: 360px;
        margin-top: 0;
        padding: 0 20px;
      }
      @media only screen and (max-width: $xs-screen) {
        width: 100%;
        margin: 0 15px;
      }
      &__tabs {
        display: flex;
        margin-bottom: 40px;
        
        @media only screen and (max-width: $m-screen) {
          margin-bottom: 25px;
        }
        
        & > .tab-switch {
          width: 50%;
        }
      }
      &__back {
        .block-link {
          align-items: flex-start;
          width: auto;
          padding-bottom: 60px;
  
          @media only screen and (max-width: $xs-screen) {
            padding-bottom: 25px;
          }
        }
      }
      
      &--heading {
        font-size: 32px;
      }
      &--sub-heading {
        color: $color-text;
        font-size: 18px;
        font-weight: 500;
      }
      &--sign {
        padding-bottom: 10px;
      }
    }
    &__bc-language {
      @media only screen and (max-width: $xs-screen) {
        text-align: center;
      }
    }
  }
</style>
