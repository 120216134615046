module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: (_vm.loading),
      expression: "loading"
    }],
    staticClass: "init-screen"
  }, [_c('init-sidebar'), _vm._v(" "), _c('div', {
    staticClass: "init-screen__info"
  }, [_c('div', {
    staticClass: "init-block"
  }, [_c('div', {
    staticClass: "navigation"
  }, [_c('div', {
    staticClass: "init-block__back",
    on: {
      "click": function($event) {
        return _vm.$router.back()
      }
    }
  }, [_c('ba-icon-link', {
    attrs: {
      "icon_type": "far",
      "icon_name": "arrow-left",
      "icon_class": "u-pl2",
      "label": _vm.t('documents.back')
    }
  })], 1)]), _vm._v(" "), _c('h1', {
    staticClass: "init-block--heading"
  }, [_vm._v(_vm._s(_vm.t('reset_password.forgot_password')))]), _vm._v(" "), _c('div', {
    staticClass: "subheader u-mb3",
    domProps: {
      "innerHTML": _vm._s(_vm.usersAreIdentifiedByEmailAddress ? _vm.t('reset_password.enter_email_address_when_created_your_account') : _vm.t('reset_password.enter_username_when_created_your_account'))
    }
  }), _vm._v(" "), _c('el-form', [_c('el-form-item', {
    attrs: {
      "label": _vm.usersAreIdentifiedByEmailAddress ? _vm.t('login.email') : _vm.t('enrollment.username')
    }
  }, [_c('el-input', {
    staticClass: "item-input-field",
    attrs: {
      "type": _vm.usersAreIdentifiedByEmailAddress ? 'email' : 'text'
    },
    nativeOn: {
      "keyup": function($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.searchAccount.apply(null, arguments)
      }
    },
    model: {
      value: (_vm.search_input),
      callback: function($$v) {
        _vm.search_input = $$v
      },
      expression: "search_input"
    }
  })], 1)], 1), _vm._v(" "), _c('div', {
    staticClass: "u-mt2"
  }, [_c('el-button', {
    staticClass: "el-button--wide u-text-uppercase",
    attrs: {
      "disabled": !_vm.search_input,
      "type": "primary"
    },
    on: {
      "click": _vm.searchAccount
    }
  }, [_vm._v("\n          " + _vm._s(_vm.t('global.submit')) + "\n        ")])], 1), _vm._v(" "), _c('div', {
    staticClass: "u-mt3"
  }, [_c('a', {
    on: {
      "click": _vm.showNotification
    }
  }, [_vm._v("\n          " + _vm._s(_vm.usersAreIdentifiedByEmailAddress ? _vm.t('login.forgot_your_email') : _vm.t('login.forgot_your_username')) + "\n        ")])])], 1)]), _vm._v(" "), _c('div', {
    staticClass: "init-screen__logo-language"
  }, [_c('bc-footer', {
    attrs: {
      "bgClass": "bg-login"
    }
  }), _vm._v(" "), (_vm.displayLanguageLink) ? _c('div', {
    staticClass: "init-screen__bc-language"
  }, [_c('ba-language-selector')], 1) : _vm._e()], 1)], 1)
},staticRenderFns: []}