module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', [_c('div', {
    staticClass: "tabs-view__heading"
  }, [_c('bc-navbar', {
    staticClass: "u-mb4",
    attrs: {
      "title": _vm.t('payments.payment_plans')
    }
  }), _vm._v(" "), _c('div', [_c('el-dropdown', {
    staticClass: "payments-dropdown",
    attrs: {
      "trigger": "click"
    }
  }, [_c('div', {
    staticClass: "box-menu"
  }, [_c('fa-icon', {
    attrs: {
      "icon": ['far', 'ellipsis-v']
    }
  })], 1), _vm._v(" "), _c('el-dropdown-menu', {
    attrs: {
      "slot": "dropdown"
    },
    slot: "dropdown"
  }, [_c('el-dropdown-item', {
    on: {
      "click": _vm.openAddPayMethodDialog
    },
    nativeOn: {
      "click": function($event) {
        return _vm.openAddPayMethodDialog.apply(null, arguments)
      }
    }
  }, [_vm._v("\n            " + _vm._s(_vm.t('payments.add_payment_method')) + "\n          ")])], 1)], 1)], 1)], 1), _vm._v(" "), (_vm.policiesListByType) ? _c('div', _vm._l((_vm.policiesListByType.active), function(policy) {
    return _c('div', [_c('bc-pay-terms', {
      staticClass: "bc-pay-plan",
      attrs: {
        "policy": policy
      }
    })], 1)
  }), 0) : _vm._e(), _vm._v(" "), _c('ba-add-payment-method-modal', {
    attrs: {
      "available-insureds": _vm.insuredIds
    }
  })], 1)
},staticRenderFns: []}