<template>
  <div v-loading="loading" class="init-screen">
    <init-sidebar/>
    <div class="init-screen__info" :class="showPage !== 'search' ? 'centered-block' : ''">
      <div class="init-block">
        <div class="navigation">
          <div v-if="showPage === 'search' && initialSearchAttempt" class="init-block__tabs">
            <div @click="activateTab('logIn')" class="tab-switch">
              <ba-tab :sign="t('account.login_header')" :isActive="activeTypeTab === 'logIn'" type="logIn"></ba-tab>
            </div>
            <div @click="activateTab('signUp')" data-cy="link--sign-up" class="tab-switch">
              <ba-tab :sign="t('enrollment.sign_up')" :isActive="activeTypeTab === 'signUp'" type="signUp"></ba-tab>
            </div>
          </div>
          <div v-else class="init-block__back" @click="goBack()">
            <ba-icon-link icon_type="far" icon_name="arrow-left" icon_class="u-pl2" :label="t('documents.back')"/>
          </div>
        </div>
        
        <!-- 1st Step 'Search Account' by DOB / Policy number-->
        <div v-if="showPage=='search'">
          <div v-if="userBasedEnrollment">
            <!--  Init Attempt with dob search -->
            <div v-if="initialSearchAttempt">
              <h1 class="init-block--heading">{{ t('global.sign_up') }}</h1>
              <div class="subheader u-mb3">{{ subheaderTexting }}</div>
              <el-form @submit.native.prevent>
                <el-form-item :label="t('enrollment.last_name')">
                  <el-input @keyup.enter.native="findUserForEnrollment" v-model="insuredLastName" type="text" />
                </el-form-item>
                <div v-if="validateInputValuesDictCopy.lastName" class="u-warning u-mb3">
                  <i class="material-icons md-text" aria-hidden="true">warning</i> {{ validateInputValuesDictCopy.lastName }}
                </div>
                <date-picker @updateDatePicker="updateDatePicker" :showPage="showPage" />
                <div v-if="validateInputValuesDictCopy.dob" class="u-warning u-mb3">
                  <i class="material-icons md-text" aria-hidden="true">warning</i> {{ validateInputValuesDictCopy.dob }}
                </div>
                <el-form-item>
                  <el-button type="primary" @click="findUserForEnrollment" :disabled='!validDate' class="el-button--wide u-text-uppercase">
                    {{ t('enrollment.search_insured_button_caption') }}
                  </el-button>
                </el-form-item>
              </el-form>
            </div>
            
            <!--  Second Attempt if dob not found -->
            <div v-if="!initialSearchAttempt">
              <h1>{{ t('enrollment.account_not_found') }}</h1>
              <p class="u-mt1" v-html="t('enrollment.account_not_found_message')"></p>
              <el-form @submit.native.prevent>
                <el-form-item :label="t('enrollment.policy_number_input_label')">
                  <el-input @keyup.enter.native="linkPolicy" v-model="policy_number" type="text" data-test-id="policy-number"
                            :class="{'u-input-error': validateInputValuesDictCopy.policyNumber}"/>
                  <div v-if="validateInputValuesDictCopy.policyNumber" class="u-input-validation u-danger u-pt2 u-pb1">
                    {{ validateInputValuesDictCopy.policyNumber }}
                  </div>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="linkPolicy" data-test-id="search-policy" class="el-button--wide u-text-uppercase">
                    {{ t('enrollment.search_insured_button_caption') }}
                  </el-button>
                </el-form-item>
                <div class="u-text-center">
                  <el-popover
                    placement="top-start"
                    title=""
                    width="300"
                    trigger="click"
                    :content="t('login.contact_your_carrier_for_assistance')">
                    <div slot="reference"><a v-html="t('enrollment.can_not_find_your_policy_number')"></a></div>
                  </el-popover>
                </div>
              </el-form>
            </div>
          </div>
    
          <div v-else>
            <h1 class="init-block--heading">{{ t('enrollment.sign_up') }}</h1>
            <div class="subheader u-mb3">{{ subheaderTexting }}</div>
            <el-form @submit.native.prevent>
              <el-form-item :label="t('enrollment.policy_number_input_label')">
                <el-input data-cy="policy-number" @keyup.enter.native="linkPolicy" v-model="policy_number" type="text" data-test-id="policy-number"
                          :class="{'u-input-error': validateInputValuesDictCopy.policyNumber}" />
                <div v-if="validateInputValuesDictCopy.policyNumber" class="u-input-validation u-danger u-pt2 u-pb1">
                  {{ validateInputValuesDictCopy.policyNumber }}
                </div>
              </el-form-item>
              <el-form-item>
                <el-button data-cy="search-policy" type="primary" @click="linkPolicy" class="el-button--wide u-text-uppercase" data-test-id="search-policy">
                  {{ t('enrollment.search_insured_button_caption') }}
                </el-button>
              </el-form-item>
            </el-form>
          </div>
    
        </div>
  
        <!-- 2nd Step if accounts amount more than 1 -->
        <div v-if="showPage=='insuredSelection'">
          <h1>{{ t('enrollment.accounts_found') }}</h1>
          <div v-if="insureds.length">
            <p class="u-mt1">{{ t('enrollment.please_identify_yourself') }}</p>
            <el-form>
              <el-form-item class="umb-1" v-for="(insured, idx) in registered_insureds" :key="insured.id" >
                <el-radio value="false" class="el-radio--wide el-radio--main-bg" disabled border>{{ insured.name }} - {{ t('enrollment.user_already_enrolled') }}</el-radio>
              </el-form-item>
              <el-form-item class="umb-1" v-for="(insured, idx) in insureds" :key="insured.id">
                <el-radio :data-cy="'insured__' + insured.id" v-model="selectedInsuredId" :label="insured.id" class="el-radio--wide el-radio--main-bg" border>{{ insured.name }}</el-radio>
              </el-form-item>
              <el-form-item class="u-mt2">
                <el-button data-cy="pick-insured" type="primary" @click="pickInsured" class="el-button--wide u-text-uppercase" data-test-id="search-policy">
                  {{ t('enrollment.verify_account') }}
                </el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
        
        <!-- 3rd (Last) Step  -->
        <div v-if="showPage=='challenge'">
          <h1>{{ t('enrollment.account_found') }}</h1>
          <div v-if="challengeType == 'portal_code'">
            <p class="u-mt3 u-mb3">
              {{ t('enrollment.please_enter_your_security_code') }}
              <span class="portal-code-links">
                <a v-if="getContactMethods && getContactMethods.userEmail" @click="showDialog('email')">{{ t('enrollment.send_it_to_my_email') }}</a>
                <span v-if="getContactMethods && getContactMethods.userEmail && getContactMethods.userPhone"> {{ t('enrollment.or') }} </span>
                <a v-if="getContactMethods && getContactMethods.userPhone" @click="showDialog('phone')">{{ t('enrollment.send_it_to_my_phone') }}</a>
              </span>
            </p>
          </div>
          <div v-if="challengeType == 'dob'" class="u-mb2">{{ t('enrollment.please_enter_your_birthdate') }}</div>
          <div v-if="challengeType == 'ssn'" class="u-mb2">{{ t('enrollment.please_enter_the_last_4_digits_of_your_ssn') }}</div>
          <el-form @submit.native.prevent >
            <el-form-item v-if="challengeType == 'portal_code' || challengeType == 'ssn'" :label="challengeType === 'ssn' ? t('enrollment.last_4_digits_of_ssn') : t('enrollment.portal_code')">
              <span v-if="challengeType === 'portal_code'" class="portal-code-info">
                <el-popover
                  placement="top-start"
                  title=""
                  width="325"
                  trigger="hover">
                <span slot="reference"><fa-icon icon="exclamation-circle"></fa-icon></span>
                <span v-html="t('enrollment.title_where_do_i_find_enrollment_code_message')"></span>
              </el-popover>
              </span>
              <el-input data-cy="code-input" @keyup.native="tryToConfirmCodeChallengeAndAdvance" autofocus v-model="code" type="text" :maxlength="challengeType == 'ssn' ? 4 : 6" />
            </el-form-item>
            <el-form-item v-if="challengeType == 'dob'" class="dob-form-field">
              <date-picker @updateDatePicker="updateDatePicker" @willSubmit="tryToConfirmChallengeAndAdvance" :showPage="showPage" />
            </el-form-item>
            <div v-if="validateInputValuesDictCopy.dob" class="u-warning u-mb3">
              <i class="material-icons md-text" aria-hidden="true">warning</i> {{ validateInputValuesDictCopy.dob }}
            </div>
            <el-form-item>
              <el-button v-if="challengeType === 'dob'" @click="confirmChallengeAndAdvance" :disabled='!validDate' type="primary" class="el-button--wide u-text-uppercase">
                {{ t('enrollment.verify_account') }}
              </el-button>
              <el-button v-else @click="confirmChallengeAndAdvance" :disabled='!code' type="primary" class="el-button--wide u-text-uppercase" data-cy="code-confirm-button">
                {{ t('enrollment.verify_account') }}
              </el-button>
            </el-form-item>
          </el-form>
        </div>
        
        <div v-if="showPage === 'notfound'">
          <div class="u-mb3">
            <h1>{{ t('enrollment.unable_to_find_your_account') }}</h1>
            <div class="subheader u-mt1">
              <span v-if="userBasedEnrollment && errorMessage && errorMessageDetail">
                <span v-html="errorMessage"></span> - <span v-html="errorMessageDetail"></span>
              </span>
              <span v-else v-html="t('enrollment.no_account_found_information') "></span>
            </div>
          </div>
        </div>
        
        <div v-if="showPage === 'notVerified'">
          <div class="u-mb3">
            <h1 v-html="errorMessage"></h1>
            <div class="subheader u-mt1">
              <span v-html="errorMessageDetail"></span>
            </div>
          </div>
        </div>
        
      </div>
    </div>
    <div class="init-screen__logo-language">
      <bc-footer bgClass="bg-login"></bc-footer>
      <div class="init-screen__bc-language" v-if="displayLanguageLink">
        <ba-language-selector/>
      </div>
    </div>
  
    <el-dialog
      :title="t('enrollment.please_select_how_you_wish')"
      :visible.sync="showDialogContacts"
      width="40%"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :show-close="false"
      custom-class="dialog-info"
      >
      <div>
        <el-form>
          <el-form-item v-for="contactMethod in actualMethods" :key="contactMethod.id">
            <el-radio v-model="selectedContactMethod" :label="contactMethod">{{ labelForContactMethod(contactMethod) }}</el-radio>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="u-mt3 dialog-footer__portal-code">
          <el-button type="primary" @click="chooseContactMethodId" data-test-id="search-policy" :disabled="!Object.keys(selectedContactMethod).length">
            {{ t('enrollment.send_security_code') }}
          </el-button>
          <el-button type="primary" @click="closeDialogContacts">
             {{ t('documents.documents_popover_menu_cancel') }}
          </el-button>
        </div>
      </span>
    </el-dialog>
    
  </div>
</template>

<script>
  /* eslint-disable */
  import settings from '../../../config/settings'
  import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'
  import _ from 'lodash'
  import moment from 'moment'
  import DatePicker from "./date-picker";
  import initSidebar from '../../components/init-sidebar'
  import baTab from "../../ui/tab.component";
  import iconLink from '../../ui/icon-link.component'
  import BaLanguageSelector from '@/components/language-selector.component'
  
  export default {
  components: {
    DatePicker,
    initSidebar,
    baTab,
    'ba-icon-link': iconLink,
    BaLanguageSelector,
  },
  beforeRouteLeave (to, from , next) {
    if (to.path.startsWith('/login') && from.path === '/enrollment/search-policies-step') {
      if (this.showPage === 'search') {
        this.saveFilledData({view: '', type: '', policyNumber: '', contactId: '', insureds: []})
        next()
        return
      }

      if (this.showPage === 'insuredSelection') {
        this.showPage = 'search'
        next(false)
      }

      if (this.showPage === 'notfound') {
        this.showPage = 'search'
        next(false)
      }

      if (this.showPage === 'challenge') {
        this.code = ''
        if (this.enrollmentError) {
          next()
          return
        } if (this.insureds.length === 1) {
          this.showPage = 'search'
          next(false)
        } else {
          this.showPage = 'insuredSelection'
          next(false)
        }
      }
    }

    if (from.path === '/enrollment/search-policies-step' && to.path === '/enrollment/setup-account-step' ) {
      if (this.showPage === 'challenge' && this.foundPolicy) {
        this.saveCurrentData()
        next()
      } else {
        next(false)
      }
    }
  },
  methods: {
    ...mapActions('enrollment', ['getInsuredDetailsFromPolicyNumber',
                                 'sendContactChallenge',
                                 'answerChallengeAndFinishEnrollment',
                                 'findInsuredByProvidedData',
                                 'isInsuredEnrolled'
    ]),
    ...mapMutations('enrollment', ['saveFilledData']),
    showDialog (type) {
      this.showDialogContacts = true
      this.actualMethods = this.selectedInsuredData.contactMethods.filter(el => el[type])
    },
    closeDialogContacts () {
      this.showDialogContacts = false
      this.selectedContactMethod = {}
    },
    saveCurrentData () {
      this.saveFilledData({
        view: this.showPage,
        type: this.challengeType,
        policyNumber: this.policy_number,
        contactId: this.selectedInsuredId,
        // companyId: settings.company_id,
        insureds: this.insureds,
      })
    },
    goBack () {
      let { insureds } = this.$store.state.enrollment.savedData
      this.insureds = insureds
      if (this.showPage === 'challenge' && this.insureds.length > 1) {
        this.showPage = 'insuredSelection'
      } if (this.showPage === 'notVerified') {
        this.showPage = 'challenge'
      } else {
        if (!this.initialSearchAttempt) this.initialSearchAttempt = true
        this.showPage = 'search'
      }
    },
    activateTab (type) {
      this.activeTypeTab = type
      if (type === 'logIn') this.$router.push('/login')
    },
    updateDatePicker (newValue) {
      if(this.showPage ==="challenge") {
        this.code = newValue
      }
      if(this.showPage === "search") {
        this.insuredDOB = newValue
      }
    },
    showMessageFindSecurityCode() {
      this.$elAlert(
        this.t('enrollment.title_where_do_i_find_enrollment_code_message'),
        this.t('enrollment.enrollment_code_help_link_caption')
      )
    },
    findUserForEnrollment () {
      this.validateInputValuesDictCopy.lastName = !this.insuredLastName ? this.t('enrollment.last_name_not_defined_notification'): ''
      this.validateInputValuesDictCopy.dob = !this.insuredDOB ? this.t('enrollment.dob_not_defined_notification'): ''
      if (!this.insuredLastName || !this.insuredDOB ) {
        this.$elAlert(this.t('enrollment.some_error_happened_notification'), this.t('global.notice'))
        return
      }
      this.loading = true
      let postData = {
        insured_last_name: this.insuredLastName,
        insured_dob: this.insuredDOB,
        company_id: settings.company_id,
      }
      this.findInsuredByProvidedData(postData).then((response) => {
        this.loading = false
        let resp = response.data
        if (resp.success) {
          this.insureds = resp.confirmation_required
          this.selectedInsuredId = resp.confirmation_required[0].id
          if (this.insureds.length === 1) {
            this.pickInsured()
          } else {
            this.showPage = 'insuredSelection'
          }
        } else {
          if (resp.cause === 'enrollment.user_was_not_found') {
            this.initialSearchAttempt = false
            this.showPage = 'search'
          } else {
            localStorage.setItem('enrollment-error', this.causeToString(resp.cause))
            this.$router.push('/login')
          }
        }
      },
        () => {
          this.loading = false
          this.showErrorNotification()
        }).catch(() => {
          this.loading = false
          this.showErrorNotification()
        })
    },

    linkPolicy () {
      if (!this.policy_number ) {
        this.validateInputValuesDictCopy.policyNumber = this.t('claims.policy_number_is_not_defined')
        return
      }
      let postData = {
        policy_number: this.policy_number,
        company_id: settings.company_id,
      }
      this.loading = true
      this.getInsuredDetailsFromPolicyNumber(postData).then((response) => {
        this.loading = false
        let resp = response.data
        if (resp.success) {
          this.insureds = resp.data
          this.registered_insureds = resp.registered_insureds
          this.selectedInsuredId = resp.data[0].id
          // FIXME not being inside `data` is ugly, but cannot force everyone to upgrade if the shape of the retval
          // is broken (backwards-compat)
          if (resp.policy_number) {
            this.policy_number = resp.policy_number
          }
          if (this.insureds.length === 1) {
            this.pickInsured()
          } else {
            this.showPage = 'insuredSelection'
          }
        } else {
          this.enrollmentError = true
          localStorage.setItem('enrollment-error', this.causeToString(resp.cause))
          if (resp.cause.key === 'enrollment.user_already_exist_please_login') {
            this.$router.push('/login')
          } else {
            this.showPage = 'notfound'
          }
        }
      }).catch(() => {
        this.loading = false
      })
    },
    pickInsured () {
      let insured = _.find(this.insureds, {id: this.selectedInsuredId})
      this.selectedInsuredData = insured
      if (this.insureds.length > 1) this.saveCurrentData()
      if (this.selectedInsuredData.ssn) return this.advanceChallenge('ssn')
      else if (this.selectedInsuredData.dob) return this.advanceChallenge('dob')
      else this.advanceChallenge('portal_code', false)
    },
    advanceChallenge(type, data) {
      this.challengeType = type
      this.showPage = 'challenge'
      this.insuredKnowsPortalCode = data
    },
    chooseContactMethodId() {
      let payload = {
        company_id: settings.company_id,
        policy_number: this.policy_number,
        contact_id: this.selectedInsuredId,
        contact_method_id: this.selectedContactMethod['id'],
        contact_method_type: this.actualMethods[0]['email'] ? 'emails' : 'phones',
        preferred_language: localStorage.getItem('language'),
      }
      this.showDialogContacts = false
      this.loading = true
      this.sendContactChallenge(payload).then((result) => {
          let ret = result.data
          this.loading = false
          if (ret.success) {
            this.advanceChallenge('portal_code')
          } else {
            let message = ret.message !== 'General Error' ? ret.message : null
            message = message || this.t('enrollment.it_wasnt_possible_to_send_a_security_code')
            this.$elAlert(message, this.t('global.error'))
          }
      }).catch(() => {
        this.loading = false
      })
    },
    tryToConfirmCodeChallengeAndAdvance(event) {
      if (!this.code) {
        return
      }

      if (event.key === 'Enter') {
        this.confirmChallengeAndAdvance()
      }
    },
    tryToConfirmChallengeAndAdvance() {
      if (!this.validDate) {
        return
      }

      this.confirmChallengeAndAdvance()
    },
    confirmChallengeAndAdvance() {
      if(this.showPage === 'challenge' && this.challengeType === 'dob') {
        this.validateInputValuesDictCopy.dob = !this.code ? this.t('enrollment.dob_not_defined_notification'): ''
        if (!this.code ) {
          this.$elAlert(this.t('enrollment.some_error_happened_notification'), this.t('global.notice'))
          return
        }
      }
      let payload = {
        company_id: settings.company_id,
        policy_number: this.policy_number,
        contact_id: this.selectedInsuredId,
        code: this.code,
        challengeType: this.challengeType
      }

      this.loading = true

      this.answerChallengeAndFinishEnrollment(payload).then(
        (response) => {
          this.loading = false
          if (response.data.success) {
            if (this.linkedPolicies && this.linkedPolicies.length) {
              this.foundPolicy = true
              this.isInsuredEnrolled({
                contact_id: this.selectedInsuredId,
                company_id: settings.company_id
              }).then(
                (response) => {
                  if (response.data.exists === false) {
                    this.$router.push('/enrollment/setup-account-step')
                  } else {
                    this.enrollmentError = true
                    localStorage.setItem('enrollment-error', this.t('enrollment.user_already_exist_please_login'))
                    this.$router.push('/login')
                  }
                }
              )
            } else {
              this.showPage = 'notfound'
              this.errorMessage = this.t('enrollment.policy_lookup_fail_reason')
              this.errorMessageDetail = this.t('enrollment.user_has_no_active_policies')

              this.$elAlert(
                this.t('enrollment.you_have_no_active_policies'),
                this.t('enrollment.error_msg_label')
              )
            }
          } else {
            this.showPage = 'notVerified'
            this.errorMessage = (this.userBasedEnrollment && this.initialSearchAttempt) ? this.t('enrollment.account_for_lastname_dob_could_not_be_verified') : this.t('enrollment.no_policies_found', { policy_number: this.policy_number })
            this.errorMessageDetail = (this.userBasedEnrollment && this.initialSearchAttempt) ? this.t('enrollment.go_back_and_verify_code') : this.t('enrollment.go_back_and_verify_dob_and_code')
          }
        },
        () => {
          this.loading = false
          this.showErrorNotification()
        }
      )
    },
    showErrorNotification () {
      let text = this.userBasedEnrollment === true
        ? this.t('enrollment.failed_to_perform_account_search') : this.t('enrollment.error_msg_linked_policy_failure')
      this.$elAlert(
        text,
        this.t('enrollment.error_msg_label')
      )
    },
    labelForContactMethod(contactMethod) {
      if (contactMethod.email) {
        return this.t('enrollment.send_an_email_to_email', {email: contactMethod.email})
      } else {
        return this.t('enrollment.send_an_sms_to_number', {number: contactMethod.phone})
      }
    }
  },
  computed: {
    ...mapGetters('enrollment', ['linkedPolicies']),
    subheaderTexting () {
      return this.userBasedEnrollment ? this.t('enrollment.enter_the_details_below_to_look_up_your_account') : this.t('enrollment.search_policy_step_text')
    },
    validDate () {
      if(this.showPage ==="challenge") {
        return moment(this.code, "MM/DD/YYYY", true).isValid()
      }
      if(this.showPage === "search") {
        return moment(this.insuredDOB, "MM/DD/YYYY", true).isValid()
      }
    },
    challengeConfirmationFailedButtonCaption () {
      if (this.userBasedEnrollment === true) {
        return this.t('enrollment.reenter_user_data_button_caption')
      }
      if (this.userBasedEnrollment === false) {
        return this.t('enrollment.reenter_policy_number_button_caption')
      }
      return ''
    },
    codeSentViaContactMethod () {
      if (!this.selectedContactMethod.id) {
        return ''
      }

      if (this.selectedContactMethod.email) {
        return this.t('enrollment.your_security_code_was_sent_via_email', {email: this.selectedContactMethod.email})
      } else {
        return this.t('enrollment.your_security_code_was_sent_via_sms', {number: this.selectedContactMethod.phone})
      }
    },
    userBasedEnrollment () {
      return this.setting('enrollment.use_user_based_search_during_enrollment')
    },
    displayLanguageLink () {
      return this.setting('global.display_spanish_link') === true
    },
    getContactMethods () {
      if (this.insureds.length) {
        let checkExistingType = (list, type) => list.some(el => el[type] && el[type].length)
        return {
          userEmail: checkExistingType(this.selectedInsuredData.contactMethods, 'email'),
          userPhone: checkExistingType(this.selectedInsuredData.contactMethods, 'phone'),
        }
      }
    }
  },
  data () {
    let policyNumber = this.$route.query && this.$route.query.policy_number
        ? this.$route.query.policy_number : ''
    return {
      ssn: '',
      code: '',
      policy_number: policyNumber,
      insuredDOB: '',
      insuredLastName: '',
      verify_via_ssn: settings.enrollment.verify_via_ssn,
      showPage: '',
      errorMessage: '',
      errorMessageDetail: '',
      loading: false,
      insureds: [],
      registered_insureds: [],
      selectedInsuredId: '',
      selectedInsuredData: {},
      selectedContactMethod: {},
      challengeType: '',
      insuredKnowsPortalCode: false,
      foundPolicy: false,
      initialSearchAttempt: true,
      validateInputValuesDictCopy: {
        dob: '',
        lastName: '',
        policyNumber: ''
      },
      activeTypeTab: 'signUp',
      enrollmentError: false,
      showDialogContacts: false,
      actualMethods: {}
    }
  },
  mounted () {
    let savedData = this.$store.state.enrollment.savedData
    if (!this.insureds.length) this.insureds = savedData
    let { view, type, policyNumber, contactId } = savedData
    let previousView = this.$store.state.enrollment.previousView

    if (previousView === 'setup' && view === 'challenge') {
      this.showPage = view
      this.challengeType = type
      this.policy_number =  policyNumber
      this.selectedInsuredId = contactId
    } else {
      this.showPage = 'search'
    }
  }
}
</script>
<style lang="scss" scoped>
  .el-row {
    margin-bottom:10px;
  }
  .width100proc {
    width: 100%
  }
</style>

<style lang="scss">
  @import '../../sass/_variables.scss';
  
  .enrollment-message-box {
    & > .el-message-box__content {
      padding-top: 30px;
      padding-bottom: 30px;
      & > .el-message-box__message > p {
        font-size: 15px;
      }
    }
    & > .el-message-box__btns {
      display: flex;
      justify-content: space-between;
    }
    .el-message-box__headerbtn {
      top: 0;
    }
  }
 .dob-form-field {
   display: flex;
   flex-direction: column;
   align-items: baseline;
   width: 100%;
   .el-form-item__content {
     display: flex;
     align-items: center;
     width: 100%;
   }
   &__date-picker {
     width: 35px !important;
     cursor: pointer;
     margin-top: 1px;
     &:hover {
       opacity: .6;
     }
     & > .el-input__prefix {
        z-index: 10;
      }
     .el-input__inner {
       padding: 0 !important;
       width: 35px;
       color: transparent;
     }
   }
 }

.el-picker-panel {
  .el-picker-panel__sidebar {
    .el-picker-panel__shortcut {
      font-weight: 300;
      text-shadow: none;
      font-size: 1em !important;
      border-bottom: 1px solid #e4e7ed;
    }
  }
}
  .portal-code-info {
    position: relative;
    right: 7px;
    svg {
      color: $color-text--disabled;
    }
  }

.centered-block {
  justify-content: center;
  .init-block {
    margin-top: 40px;
    margin-bottom: 40px;
  
    @media only screen and (max-width: $xs-screen) {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}
.portal-code-links {
  display: inline-block;
  &::first-letter {
    text-transform: capitalize;
  }
}
.dialog-footer {
  display: flex;
  align-items: start;
  &__cancel {
    align-self: flex-end;
  }
  &__portal-code {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .el-button+.el-button {
    margin-left: 0;
  }
}
.emails-list-footer {
  flex-direction: column;
}
</style>
