<template>
  <div class="block-link">
    <div class="block-link__icon u-flex-vertical-center">
      <fa-icon v-if="icon_type" :icon="[icon_type, icon_name]" :class="icon_class"/>
      <i v-else :class="icon_name" class="fa-lg"></i>
    </div>
    <div v-if="label" class="block-link__sign">
      {{ label }}
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ba-icon-link',
    props: {
      icon_name: {
        type: String,
        required: true,
      },
      label: {
        type: String,
        required: false,
      },
      icon_type: {
        type: String,
        required: false,
      },
      icon_class: {
        type: String,
        required: false,
      }
    }
  }
</script>

<style scoped lang="scss">
  @import '../sass/_variables.scss';
  
  .block-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100px;
    color: #626262;
    transition: color .3s linear;
    &:hover {
      cursor: pointer;
      color: $color-primary;
    }
    &__icon {
      max-width: 35px;
      height: 30px;
      font-size: 30px;
      & > i {
        font-size: 30px;
      }
    }
    &__sign {
      font-size: 16px;
      text-transform: uppercase;
    }
  }
</style>
