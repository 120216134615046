module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    staticClass: "init-screen"
  }, [_c('init-sidebar'), _vm._v(" "), _c('div', {
    staticClass: "init-screen__info"
  }, [_c('div', {
    staticClass: "init-block"
  }, [_c('div', {
    staticClass: "init-block__tabs"
  }, [_c('div', {
    staticClass: "tab-switch",
    on: {
      "click": function($event) {
        return _vm.activateTab('logIn')
      }
    }
  }, [_c('ba-tab', {
    attrs: {
      "sign": _vm.t('account.login_header'),
      "isActive": _vm.activeTypeTab === 'logIn',
      "type": "logIn"
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "tab-switch",
    attrs: {
      "data-cy": "link--sign-up"
    },
    on: {
      "click": function($event) {
        return _vm.activateTab('signUp')
      }
    }
  }, [_c('ba-tab', {
    attrs: {
      "sign": _vm.t('enrollment.sign_up'),
      "isActive": _vm.activeTypeTab === 'signUp',
      "type": "signUp"
    }
  })], 1)]), _vm._v(" "), _c('div', [_c('h1', {
    staticClass: "init-block--heading"
  }, [_vm._v(_vm._s(_vm.t('global.welcome')))]), _vm._v(" "), (!_vm.error) ? _c('div', {
    staticClass: "subheader u-mb3"
  }, [_vm._v(_vm._s(_vm.t('login.enter_the_information_below_to_login')))]) : _vm._e()]), _vm._v(" "), _c('el-form', {
    staticClass: "mx-auto"
  }, [(_vm.error) ? _c('ba-alert', {
    attrs: {
      "type": "warning"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.error) + "\n        ")]) : _vm._e(), _vm._v(" "), _c('el-form-item', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: (!_vm.pin_nonce),
      expression: "!pin_nonce"
    }],
    attrs: {
      "label": _vm.usernameFieldLabel
    }
  }, [(!_vm.username) ? _c('el-input', {
    class: {
      'u-input-error': _vm.validateInputValuesCopy.username
    },
    attrs: {
      "data-cy": "username",
      "autofocus": ""
    },
    nativeOn: {
      "keyup": function($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.login.apply(null, arguments)
      }
    },
    model: {
      value: (_vm.username),
      callback: function($$v) {
        _vm.username = $$v
      },
      expression: "username"
    }
  }) : _c('el-input', {
    class: {
      'u-input-error': _vm.validateInputValuesCopy.username
    },
    attrs: {
      "data-cy": "username"
    },
    nativeOn: {
      "keyup": function($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.login.apply(null, arguments)
      }
    },
    model: {
      value: (_vm.username),
      callback: function($$v) {
        _vm.username = $$v
      },
      expression: "username"
    }
  }), _vm._v(" "), (_vm.validateInputValuesCopy.username) ? _c('div', {
    staticClass: "u-input-validation u-danger u-pt2 u-pb1"
  }, [_vm._v("\n            " + _vm._s(_vm.validateInputValuesCopy.username) + "\n          ")]) : _vm._e()], 1), _vm._v(" "), _c('el-form-item', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: (!_vm.pin_nonce),
      expression: "!pin_nonce"
    }],
    attrs: {
      "label": _vm.t('enrollment.password_field')
    }
  }, [(!_vm.username) ? _c('el-input', {
    staticClass: "u-mb2",
    class: {
      'u-input-error': _vm.validateInputValuesCopy.password
    },
    attrs: {
      "data-cy": "password",
      "type": "password"
    },
    nativeOn: {
      "keyup": function($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.login.apply(null, arguments)
      }
    },
    model: {
      value: (_vm.password),
      callback: function($$v) {
        _vm.password = $$v
      },
      expression: "password"
    }
  }) : _c('el-input', {
    staticClass: "u-mb2",
    class: {
      'u-input-error': _vm.validateInputValuesCopy.password
    },
    attrs: {
      "data-cy": "password",
      "autofocus": "",
      "type": "password"
    },
    nativeOn: {
      "keyup": function($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.login.apply(null, arguments)
      }
    },
    model: {
      value: (_vm.password),
      callback: function($$v) {
        _vm.password = $$v
      },
      expression: "password"
    }
  }), _vm._v(" "), (_vm.validateInputValuesCopy.password) ? _c('div', {
    staticClass: "u-input-validation u-danger u-pb2"
  }, [_vm._v("\n            " + _vm._s(_vm.validateInputValuesCopy.password) + "\n          ")]) : _vm._e(), _vm._v(" "), _c('el-checkbox', {
    model: {
      value: (_vm.usePinNextTime),
      callback: function($$v) {
        _vm.usePinNextTime = $$v
      },
      expression: "usePinNextTime"
    }
  }, [_vm._v(_vm._s(_vm.t('login.fast_login')))])], 1), _vm._v(" "), _c('el-form-item', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: (_vm.pin_nonce),
      expression: "pin_nonce"
    }],
    attrs: {
      "label": _vm.t('login.pin_placeholder')
    }
  }, [_c('el-input', {
    nativeOn: {
      "keyup": function($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.login.apply(null, arguments)
      }
    },
    model: {
      value: (_vm.pin),
      callback: function($$v) {
        _vm.pin = $$v
      },
      expression: "pin"
    }
  }), _vm._v(" "), _c('a', {
    staticClass: "u-inline-block u-mt2",
    on: {
      "click": _vm.signInByEmailInstead
    }
  }, [_vm._v("\n            " + _vm._s(_vm.usersAreIdentifiedByEmailAddress ? _vm.t('login.sign_in_using_email') : _vm.t('login.sign_in_by_username')) + "\n          ")])], 1), _vm._v(" "), _c('el-button', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: (_vm.loading),
      expression: "loading"
    }],
    staticClass: "el-button--wide u-text-uppercase",
    attrs: {
      "data-cy": "button__sign-in",
      "type": "primary"
    },
    on: {
      "click": _vm.login
    }
  }, [_vm._v("\n          " + _vm._s(_vm.t('account.login_header')) + "\n        ")]), _vm._v(" "), _c('div', {
    staticClass: "u-text-center u-pt3"
  }, [_c('router-link', {
    attrs: {
      "to": {
        path: '/password-reset/search-account'
      }
    }
  }, [_vm._v("\n            " + _vm._s(_vm.usersAreIdentifiedByEmailAddress ? _vm.t('login.forgot_your_password_or_email') : _vm.t('login.forgot_your_password_or_username')) + "\n          ")])], 1)], 1)], 1)]), _vm._v(" "), _c('div', {
    staticClass: "init-screen__logo-language"
  }, [_c('bc-footer', {
    attrs: {
      "bgClass": "bg-login"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "c-header__nav-link u-text--uppercase u-mt3"
  }, [_c('div', {
    staticClass: "sidenav__link u-p0"
  }, [_c('ba-custom-pages', {
    staticClass: "u-mb2",
    attrs: {
      "only-public": true
    }
  })], 1)]), _vm._v(" "), (_vm.displayLanguageLink) ? _c('div', {
    staticClass: "init-screen__bc-language"
  }, [_c('ba-language-selector')], 1) : _vm._e()], 1)], 1)
},staticRenderFns: []}