<template>
  <div class="pay-history">
    <div class="pay-history__heading u-flex-horizontal-space-between u-pt3 u-pb4">
      <bc-navbar class="u-m0" :title="t('payments.payment_history')"></bc-navbar>
      <div>
        <el-select v-model="value" @change="changePolicy(value)" placeholder="Select">
          <el-option
            v-for="item in policySelectList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    
    <pay-schedule v-loading="loading" />
    
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import PaySchedule from './pay-schedule'
  
  export default {
    name: 'payment-history',
    components: {
      PaySchedule
    },
    mounted () {
      this.loadPolicies()
    },
    data () {
      return {
        loading: false,
        value: ''
      }
    },
    computed: {
      ...mapState('policies', ['policies']),
      policySelectList () {
        if (!this.policies) return
        return this.policies.policies.map(el => {
          return {
            label: this.t('payments.policy_#', {number: el.policy_number}),
            value: el.id
          }
        })
      },
    },
    methods: {
      changePolicy (id) {
        this.$router.push(`/payments/payment-history/${id}`)
      },
      setInitialPolicyId () {
        this.value = this.$route.params.policyId
      },
      loadPolicies () {
        this.loading = true
        this.$store.dispatch('policies/load', 1)
          .then(() => {
            this.setInitialPolicyId()
          })
          .catch((e) => {
            console.log('e', e)
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
</script>

<style scoped lang="scss">
  .pay-history {
    &__heading {
      border-bottom: 1px solid transparentize(#c8c7cc, .5);
    }
  }
  
</style>
