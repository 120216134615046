module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('drag-n-drop-photo', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: (_vm.loading),
      expression: "loading"
    }],
    on: {
      "onPhotoUpload": _vm.onPhotoUpload
    }
  })
},staticRenderFns: []}