<template>
  <div>
    <div @click="onBackButtonClick()" class="u-mb4">
      <ba-icon-link icon_type="far" icon_name="arrow-left" icon_class="u-pl2" :label="t('documents.back')"/>
    </div>
    <bc-navbar :title="pageBySlug(slug) && pageBySlug(slug).title"/>
    <div class="content-root" v-loading="loading">
      <div class="guard scoped_todo" v-if="page">
        <div v-html="page.html_content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import config from '@/../config/britecore'
import settings from '@/../config/settings'
import iconLink from '@/ui/icon-link.component'

const { mapGetters, mapState } = require('vuex')
export default {
  name: 'custom-page',
  components: {
    'ba-icon-link': iconLink,
  },
  data () {
    return {
      loading: true,
      page: null,
    }
  },
  computed: {
    ...mapState('account', ['accountData']),
    slug () {
      return this.$route.params.slug
    },
    ...mapGetters('customPages', ['pageBySlug']),
  },
  methods: {
    onBackButtonClick () {
      this.$router.push(this.accountData? '/dashboard' : '/login')
    },
    loadData: async function () {
      try {
        let url = `${config.url}/v2/company/${settings.company_id}/custom-pages/${this.slug}/`
        let axiosResponse = await axios.get(url)
        this.page = axiosResponse.data
      } catch (e) {
        console.error('unexpected error while trying to load custom page', e)
        this.content = 'Page failed to load.'
      }

      this.loading = false
    },
  },
  async mounted () {
    this.loadData()
  },
  watch: {
    $route (to, from) {
      this.loading = true
      this.loadData()
    }
  },
  updated () {
  }
}
</script>
