<template>
  <div>
    <el-row :gutter="40">
      <el-col :md="24" :lg="8">
        <div class="u-text-trim">
          <fa-icon :icon="determinePolicyIcon(policy)" class="pay-terms__icon" />
          <span class="pay-terms__address u-pl1">
            {{ policy.properties && policy.properties.length > 0 ? policy.properties[0].address_line1 : '' }}
          </span>
        </div>
        <div class="u-mt1 u-mb2">{{ t('payments.policy_#', {number: policy.policy_number}) }}</div>
      </el-col>
      <el-col class="col-borders" :md="24" :lg="8">
        <bc-pay-plan v-if="policy.current_term.id" :policy="policy" @updateCustomPayPlan="updateCustomPayPlan" termsType="current"/>
      </el-col>
      <el-col :md="24" :lg="8">
        <bc-pay-plan v-if="policy.next_policy_term_id" ref="next" :policy="policy" @updateCustomPayPlan="updateCustomPayPlan" termsType="next"/>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import payPlan from './r_pay-plan.component'
import { mapGetters } from 'vuex'

export default {
  name: 'pay-term-component',
  props: {
    policy: {
      type: Object,
      required: true
    }
  },
  components: {
    'bc-pay-plan': payPlan
  },
  computed: {
    ...mapGetters('policies', ['determinePolicyIcon']),
  },
  methods: {
    updateCustomPayPlan (term) {
      if (term === 'current' && this.policy.next_policy_term_id) {
        this.$refs.next.loadDataOnInit()
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../../sass/_variables.scss';

.pay-terms {
  &__icon {
    font-size: 22px;
    color: #626262;
  }
  &__address {
    font-size: 22px;
  }
}
.col-borders {
  border-left: 2px solid #E5E5E5;
  border-right: 2px solid #E5E5E5;

  @media only screen and (max-width: $lg-screen) {
    border: none;
  }
}
</style>
