<template>
  <div class="submenu">
    <div v-if="claims" class="submenu__menu-outer">
      <el-button type="primary" @click="createNewClaim">
        {{ t('claims.file_new_claim') }}
      </el-button>
      <div class="submenu__menu-outer_scrolling">
        <div v-for="(type, i) in claimTypes" :index="i">
          <bc-claims-submenu v-if="sortClaimsByDesc[`${type}`].length > 0" :claimsList="sortClaimsByDesc[`${type}`]" :type="type"/>
        </div>
      </div>
    </div>
    <!-- Dialog File a Claim  -->
    <el-dialog :visible.sync="dialogClaimVisible"
               custom-class="file-claim-dialog"
               :show-close="true"
               :close-on-click-modal="false"
               :close-on-press-escape="false"
               :before-close="closeClaimDialog"
    >
      <bc-file-claims-view
        v-if="dialogClaimVisible"
        ref="fileClaimView"
        @closeClaimDialog="closeClaimDialog"
        openClaimDialog/>
    </el-dialog>
    
    <!-- Dialog Add New Phone Number -->
    <phone-number-dialog />
    
    <div class="submenu__view">
      <router-view :key="$route.fullPath"></router-view>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import BcClaimsSubmenu from '@/pages/claims/claims-submenu-links.component'
import BcFileClaimsView from '@/pages/claims/file-claim.component'
import PhoneNumberDialog from '@/components/new-phone-number.dialog.vue'

export default {
  name: 'bc-claims-list',
  components: {
    'bc-claims-submenu': BcClaimsSubmenu,
    'bc-file-claims-view': BcFileClaimsView,
    PhoneNumberDialog
  },
  props: ['claims'],
  data () {
    return {
      dialogClaimVisible: false,
      claimTypes: ['open', 'closed']
    }
  },
  computed: {
    sortClaimsByDesc () {
      let sortClaims = (claims) => _.orderBy(claims, ['loss_date'], ['desc'])
      return {
        open: sortClaims(this.claims.open) || [],
        closed: sortClaims(this.claims.closed) || []
      }
    },
  },
  methods: {
    closeClaimDialog () {
      this.dialogClaimVisible = false
    },
    createNewClaim () {
      this.dialogClaimVisible = true
    }
  }
}
</script>

<style lang="scss">
  @import '../../sass/variables';
  
  .file-claim-dialog {
    min-width: 650px !important;
  
    @media only screen and (max-width: $m-screen) {
      min-width: 95% !important;
    }
    .el-dialog__header {
      padding: 0;
      top: 10px;
      margin-right: 5px;
      .el-dialog__headerbtn .el-dialog__close {
        font-size: 24px;
        color: #626262;
      }
    }
  }
</style>
