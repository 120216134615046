module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return (_vm.uiAgents.length) ? _c('div', {
    attrs: {
      "data-cy": "agent-card"
    }
  }, [_c('div', {
    staticClass: "dashboard__outer-card_info u-height100"
  }, [_c('div', {
    staticClass: "el-card__header"
  }, [_vm._v(_vm._s(_vm.t('agent.your_agent')))]), _vm._v(" "), (_vm.uiAgents.length < 5) ? _c('div', _vm._l((_vm.uiAgents), function(agent) {
    return _c('div', {
      key: agent.id,
      staticClass: "u-mb2"
    }, [_c('agency-card-content', {
      attrs: {
        "agentOrAgency": agent,
        "agentsAmount": _vm.uiAgents.length
      }
    })], 1)
  }), 0) : _c('div', {
    staticClass: "u-height100 u-flex-vertical-space-between"
  }, [_c('h2', [_vm._v(_vm._s(_vm.t('agent.agents_number', {
    number: _vm.uiAgents.length
  })))]), _vm._v(" "), _c('el-button', {
    staticClass: "u-text-capitalize u-width100",
    attrs: {
      "type": "info",
      "data-test-id": "contact-agent"
    },
    on: {
      "click": function($event) {
        return _vm.$router.push('/agents/list')
      }
    }
  }, [_vm._v("\n        " + _vm._s(_vm.t('agent.view_agents')) + "\n      ")])], 1)])]) : _vm._e()
},staticRenderFns: []}