module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: (_vm.loading),
      expression: "loading"
    }]
  }, [_c('div', {
    staticClass: "file-claim-forms"
  }, [_c('div', {
    staticClass: "file-claim-forms__fields"
  }, [_c('el-form', {
    staticClass: "u-max-width u-mt6"
  }, [_c('div', {
    staticClass: "inputs-list u-mb3"
  }, [(_vm.selectedPolicy) ? _c('div', {
    staticClass: "list-item--select"
  }, [_c('span', {
    staticClass: "u-block u-text-uppercase subheader"
  }, [_vm._v(_vm._s(_vm.t('claims.location_title')) + " *")]), _vm._v(" "), _c('el-select', {
    staticClass: "item-input-field u-block",
    attrs: {
      "disabled": _vm.selectedPolicy.properties.length === 1
    },
    model: {
      value: (_vm.selectedPropertyId),
      callback: function($$v) {
        _vm.selectedPropertyId = $$v
      },
      expression: "selectedPropertyId"
    }
  }, [_c('el-option', {
    attrs: {
      "value": "",
      "disabled": "",
      "hidden": ""
    }
  }), _vm._v(" "), _vm._l((_vm.selectedPolicy.properties), function(property) {
    return _c('el-option', {
      key: property.id,
      attrs: {
        "value": property.id,
        "label": property.address_line1 + ' ' + property.address_line2 + ', ' + property.address_city + ', ' + property.address_state + ', ' + property.address_zip
      }
    })
  })], 2)], 1) : _vm._e()])]), _vm._v(" "), _c('el-form', {
    staticClass: "u-max-width"
  }, [_c('div', {
    staticClass: "inputs-list u-mb3"
  }, [_c('div', [_c('span', {
    staticClass: "u-block u-text-uppercase subheader"
  }, [_vm._v(_vm._s(_vm.t('claims.loss_date_title')) + " *")]), _vm._v(" "), _c('el-date-picker', {
    staticClass: "u-width100",
    attrs: {
      "format": "MM/dd/yyyy",
      "picker-options": {
        disabledDate: _vm.isDateAfterToday
      },
      "disabled": _vm.isCanceledCurrentPolicy,
      "clearable": false
    },
    model: {
      value: (_vm.date),
      callback: function($$v) {
        _vm.date = $$v
      },
      expression: "date"
    }
  })], 1), _vm._v(" "), (_vm.validateInputValuesDictCopy.lossDate) ? _c('div', {
    staticClass: "u-warning u-mb4"
  }, [_c('i', {
    staticClass: "material-icons md-text",
    attrs: {
      "aria-hidden": "true"
    }
  }, [_vm._v("warning")]), _vm._v(" " + _vm._s(_vm.validateInputValuesDictCopy.lossDate) + "\n            ")]) : _vm._e()])]), _vm._v(" "), _c('el-form', {
    staticClass: "u-max-width"
  }, [_c('div', {
    staticClass: "inputs-list"
  }, [_c('div', {
    staticClass: "ba-intl-tel-input__wrapper"
  }, [(_vm.phoneNumberRequired) ? _c('span', {
    staticClass: "u-block u-text-uppercase subheader"
  }, [_vm._v(_vm._s(_vm.t('global.phone_number')) + " *")]) : _c('span', {
    staticClass: "u-block u-text-uppercase subheader"
  }, [_vm._v(_vm._s(_vm.t('global.phone_number')))]), _vm._v(" "), _c('ba-intl-tel-input', {
    class: _vm.validateInputValuesDictCopy.phoneNumber ? 'phone-number-u-warning' : '',
    attrs: {
      "customClass": "el-input__inner",
      "disabled": _vm.isCanceledCurrentPolicy
    },
    nativeOn: {
      "keyup": function($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.fileClaimOperation.apply(null, arguments)
      }
    },
    model: {
      value: (_vm.contactPhoneNumber),
      callback: function($$v) {
        _vm.contactPhoneNumber = $$v
      },
      expression: "contactPhoneNumber"
    }
  })], 1), _vm._v(" "), (_vm.validateInputValuesDictCopy.phoneNumber) ? _c('div', {
    staticClass: "u-warning"
  }, [_c('i', {
    staticClass: "material-icons md-text",
    attrs: {
      "aria-hidden": "true"
    }
  }, [_vm._v("warning")]), _vm._v(" " + _vm._s(_vm.validateInputValuesDictCopy.phoneNumber) + "\n          ")]) : _vm._e()])])], 1), _vm._v(" "), (!_vm.isCanceledCurrentPolicy) ? _c('drag-n-drop-photo', {
    on: {
      "onPhotoUpload": _vm.onPhotoUpload
    }
  }) : _vm._e()], 1), _vm._v(" "), _c('el-form', {
    staticClass: "u-max-width"
  }, [_c('div', {
    staticClass: "inputs-list u-mt4 u-mb3"
  }, [_c('div', [_c('span', {
    staticClass: "u-block u-text-uppercase subheader"
  }, [_vm._v(_vm._s(_vm.t('claims.loss_description_title')) + " *")]), _vm._v(" "), _c('el-input', {
    staticClass: "u-block",
    attrs: {
      "type": "textarea",
      "disabled": _vm.isCanceledCurrentPolicy,
      "resizable": ""
    },
    model: {
      value: (_vm.description),
      callback: function($$v) {
        _vm.description = $$v
      },
      expression: "description"
    }
  })], 1), _vm._v(" "), (_vm.validateInputValuesDictCopy.description) ? _c('div', {
    staticClass: "u-warning"
  }, [_c('i', {
    staticClass: "material-icons md-text",
    attrs: {
      "aria-hidden": "true"
    }
  }, [_vm._v("warning")]), _vm._v(" " + _vm._s(_vm.validateInputValuesDictCopy.description) + "\n      ")]) : _vm._e()])]), _vm._v(" "), (_vm.chosenPhotos.length) ? _c('div', [_c('el-row', {
    attrs: {
      "gutter": 10
    }
  }, _vm._l((_vm.chosenPhotos), function(photo, pos) {
    return _c('el-col', {
      key: pos,
      attrs: {
        "md": 8,
        "sm": 24
      }
    }, [_c('el-card', {
      staticClass: "el-card__claim-image-preview u-mb1 u-p0"
    }, [_c('img', {
      staticClass: "claim-image-thumbnail",
      attrs: {
        "src": photo.image
      }
    }), _vm._v(" "), _c('div', {
      staticClass: "u-flex-justify-content-end u-m1"
    }, [_c('el-button', {
      attrs: {
        "type": "info",
        "size": "small",
        "icon": ['fas', 'trash']
      },
      on: {
        "click": function($event) {
          return _vm.deletePhoto(pos)
        }
      }
    })], 1)])], 1)
  }), 1)], 1) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "u-mt4 u-text-right"
  }, [_c('el-button', {
    staticClass: "u-text-uppercase",
    attrs: {
      "type": "primary",
      "disabled": _vm.selectedPolicy === null || _vm.isCanceledCurrentPolicy
    },
    on: {
      "click": _vm.fileClaimOperation
    }
  }, [_vm._v("\n        " + _vm._s(_vm.t('claims.submit_claim_buttom_caption')) + "\n      ")])], 1), _vm._v(" "), (_vm.invalidateHack) ? _c('div') : _vm._e()], 1)
},staticRenderFns: []}