module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    staticClass: "init-screen__presentation"
  }, [_vm._m(0), _vm._v(" "), _c('div', {
    staticClass: "init-screen__bc-footer"
  }, [_c('div', [_c('div', {
    staticClass: "c-header__nav-link u-text--uppercase"
  }, [_c('div', {
    staticClass: "sidenav__link u-p0 u-mb3"
  }, [_c('ba-custom-pages', {
    attrs: {
      "only-public": true,
      "placement": "top"
    }
  })], 1)]), _vm._v(" "), _c('bc-footer', {
    attrs: {
      "bgClass": "bg-login"
    }
  })], 1), _vm._v(" "), (_vm.displayLanguageLink) ? _c('div', {
    staticClass: "init-screen__bc-language"
  }, [_c('ba-language-menu')], 1) : _vm._e()])])
},staticRenderFns: [function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    staticClass: "init-screen__logo"
  }, [_c('img', {
    staticClass: "init-screen__logo-image",
    attrs: {
      "src": require("@/assets/desktop_logo.png")
    }
  })])
}]}