<template>
  <div class="ba-alert u-p3" :class="`ba-alert--${type}`">
    <div class="ba-alert__icon u-pr2">
      <fa-icon icon="exclamation-circle" :class="`u-mr2 bc-icon bc-color--${type} ${getIconType}`" fixed-width></fa-icon>
    </div>
    <slot>Alert Message</slot>
  </div>
</template>

<script>
  export default {
    name: 'ba-alert',
    props: {
      type: {
        type: String,
        required: true,
      },
    },
    computed: {
      getIconType () {
        return this.type === 'success' ? 'el-icon-success' : 'el-icon-warning'
      }
    }
  }
</script>

<style scoped lang="scss">
  @import '../sass/_variables.scss';
  
  .bc-color {
    &--success {
      color: $color-success;
    }
    &--warning {
      color: $color-warning;
    }
    &--error {
      color: $color-danger;
    }
  }
  .ba-alert {
    border: 2px solid;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: transparentize($color-text, .4);
    background: $color-white;
    
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
    
    &--success {
      color: $color-success;
    }
    &--warning {
      color: $color-warning;
    }
    &--error {
      color: $color-danger;
    }
  }
  .bc-icon {
    font-size: 22px;
    line-height: 22px;
  }
</style>
