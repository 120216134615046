module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    staticClass: "tabs-view"
  }, [_c('div', {
    staticClass: "tabs-view__heading"
  }, [_c('h2', {
    staticClass: "secondary-header u-mr3 u-mt2"
  }, [_c('ba-policy-header', {
    attrs: {
      "policy": _vm.policy,
      "show-address": !_vm.policy.isGen3
    }
  })], 1), _vm._v(" "), _c('div', [_c('el-dropdown', {
    staticClass: "policy-dropdown",
    attrs: {
      "trigger": "click"
    }
  }, [_c('div', {
    staticClass: "box-menu"
  }, [_c('fa-icon', {
    attrs: {
      "icon": ['far', 'ellipsis-v']
    }
  })], 1), _vm._v(" "), _c('el-dropdown-menu', {
    attrs: {
      "slot": "dropdown"
    },
    slot: "dropdown"
  }, [(_vm.setting('claims.claims_enabled') && !_vm.policy.is_canceled) ? _c('el-dropdown-item', {
    attrs: {
      "data-test-id": "new-claim"
    },
    on: {
      "click": _vm.newClaimButtonClick
    },
    nativeOn: {
      "click": function($event) {
        return _vm.newClaimButtonClick.apply(null, arguments)
      }
    }
  }, [_vm._v("\n            " + _vm._s(_vm.t('claims.file_claim_headline')) + "\n          ")]) : _vm._e(), _vm._v(" "), _c('el-dropdown-item', {
    staticClass: "u-text-capitalize",
    on: {
      "click": _vm.loadPageDocuments
    },
    nativeOn: {
      "click": function($event) {
        return _vm.loadPageDocuments.apply(null, arguments)
      }
    }
  }, [_vm._v("\n            " + _vm._s(_vm.t('policies.view_documents')) + "\n          ")]), _vm._v(" "), _c('el-dropdown-item', {
    on: {
      "click": _vm.linkToAgent
    },
    nativeOn: {
      "click": function($event) {
        return _vm.linkToAgent.apply(null, arguments)
      }
    }
  }, [_vm._v("\n                " + _vm._s(_vm.t('agent.agent_contact_us')) + "\n          ")]), _vm._v(" "), (_vm.showDeclarationLink) ? _c('el-dropdown-item', {
    on: {
      "click": function($event) {
        return _vm.navigateToPolicyDeclaration()
      }
    },
    nativeOn: {
      "click": function($event) {
        return _vm.navigateToPolicyDeclaration()
      }
    }
  }, [_vm._v("\n                " + _vm._s(_vm.t('policies.view_declaration')) + "\n          ")]) : _vm._e()], 1)], 1)], 1)]), _vm._v(" "), _c('div', {
    staticClass: "u-mt2"
  }, [_c('div', {
    staticClass: "u-mb1 tabs-view__policy-info"
  }, [_c('div', {
    staticClass: "policy-data"
  }, [_c('h2', {
    staticClass: "u-mt0 u-mb1"
  }, [_vm._v(_vm._s(_vm.t('payments.policy_#', {
    number: _vm.policy.policy_number
  })) + "\n          "), (_vm.policy.is_canceled) ? _c('div', {
    staticClass: "subheader u-mt2"
  }, [_vm._v("(" + _vm._s(_vm.policy.policy_status) + ")")]) : _vm._e()]), _vm._v(" "), _c('div', {
    staticClass: "policy-date"
  }, [_vm._v("\n          " + _vm._s(_vm.dateLong(_vm.policy.current_term.effective_date)) + " - " + _vm._s(_vm.policy.is_canceled ? _vm.dateLong(_vm.policy.revision.cancel_date) : _vm.dateLong(_vm.policy.current_term.expiration_date)) + "\n        ")])]), _vm._v(" "), (_vm.policy.current_due > 0 && _vm.canMakePayment(_vm.policy)) ? _c('p', [_c('router-link', {
    attrs: {
      "to": {
        path: '/payments'
      },
      "data-test-id": "make-payment"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.t('payments.make_payment_due', {
    amount: _vm.asCurrency(_vm.policy.current_due)
  })) + "\n        ")])], 1) : _vm._e(), _vm._v(" "), (_vm.policy.payoff_amount <= 0 && _vm.policy.next_due > 0 && _vm.canMakePayment(_vm.policy)) ? _c('p', [_c('router-link', {
    attrs: {
      "to": {
        path: '/payments'
      },
      "data-test-id": "make-payment"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.t('payments.make_payment_next_due', {
    amount: _vm.asCurrency(_vm.policy.next_due)
  })) + "\n        ")])], 1) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "u-mt4 u-mb0"
  }, [_c('div', {
    staticClass: "subheader u-text-uppercase"
  }, [_vm._v(_vm._s(_vm.t('policies.named_insureds')))]), _vm._v(" "), _vm._l((_vm.policy.revision.named_insureds), function(named_insured) {
    return _c('el-row', {
      key: named_insured.contact_id,
      staticClass: "tabs-view__contacts u-mb2",
      attrs: {
        "gutter": 40
      }
    }, [_c('el-col', {
      staticClass: "tabs-view__contacts",
      attrs: {
        "xs": 24,
        "sm": 24,
        "md": 8,
        "lg": 8,
        "xl": 8
      }
    }, [_c('div', [_vm._v(_vm._s(named_insured.name))])])], 1)
  })], 2)]), _vm._v(" "), (_vm.policy.isGen3) ? _c('div', [_c('risk-details', {
    attrs: {
      "policyId": _vm.policy.id
    }
  })], 1) : _vm._e(), _vm._v(" "), (!_vm.policy.isGen3 && !_vm.hideLocationDetails) ? _c('div', [_c('div', {
    staticClass: "u-mt4 u-mb3"
  }, [_c('p', {
    staticClass: "secondary-title"
  }, [_vm._v(_vm._s(_vm.t('policies.location_details')))])]), _vm._v(" "), _vm._l((_vm.policy.properties), function(property) {
    return _c('el-row', {
      key: property.id,
      staticClass: "location-details u-ml0 u-mr0 u-mb3",
      attrs: {
        "gutter": 40
      }
    }, [_c('el-col', {
      attrs: {
        "xs": 24,
        "sm": 24,
        "md": 8,
        "lg": 8,
        "xl": 8
      }
    }, [_c('div', {
      staticClass: "location-details__sign u-pb4"
    }, [_c('fa-icon', {
      staticClass: "link-icon-medium",
      attrs: {
        "icon": ['far', 'map-marker']
      }
    }), _vm._v(" "), _c('div', {
      staticClass: "u-ml2"
    }, [_vm._v(_vm._s(_vm.t('global.address')))])], 1), _vm._v(" "), _c('div', {
      staticClass: "location-details__text u-mb1"
    }, [_vm._v(_vm._s(property.address_line1))]), _vm._v(" "), (property.address_line2) ? _c('div', {
      staticClass: "location-details__text u-mb1"
    }, [_vm._v(_vm._s(property.address_line2))]) : _vm._e(), _vm._v(" "), _c('div', {
      staticClass: "location-details__text u-mb2"
    }, [_vm._v(_vm._s(property.address_city) + ", " + _vm._s(property.address_state) + " " + _vm._s(property.address_zip))])]), _vm._v(" "), (!property.deleted) ? _c('el-col', {
      staticClass: "location-details__column col-border",
      attrs: {
        "xs": 24,
        "sm": 24,
        "md": 8,
        "lg": 8,
        "xl": 8
      }
    }, [_c('div', {
      staticClass: "u-mt2 u-mb2"
    }, [_c('bc-property-images-block', {
      attrs: {
        "propertyId": property.id
      }
    })], 1)]) : _vm._e(), _vm._v(" "), _c('el-col', {
      attrs: {
        "xs": 24,
        "sm": 24,
        "md": 8,
        "lg": 8,
        "xl": 8
      }
    }, [_c('upload-image', {
      attrs: {
        "propertyId": property.id
      }
    })], 1)], 1)
  })], 2) : _vm._e()])])
},staticRenderFns: []}