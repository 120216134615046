module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', [_c('bc-navbar', {
    attrs: {
      "title": _vm.t('dashboard.claims')
    }
  }), _vm._v(" "), (_vm.claimsToShow && _vm.claimsToShow.open.length > 0 || _vm.claimsToShow.closed.length > 0) ? _c('bc-claims-list', {
    attrs: {
      "claims": _vm.claimsToShow
    }
  }) : _vm._e()], 1)
},staticRenderFns: []}