<template>
  <div v-if="claimsList" class="submenu__nested-menu u-mt4">
    <h2 class="u-mb3 submenu__link-type-title">
      {{ type === 'open' ? t('claims.open_claims') : t('claims.closed_claims') }}
    </h2>
    <div v-for="claim in claimsList" :key="claim.id">
      <router-link :to="'/claims/' + claim.id" class="submenu__link">
        <div class="u-p1">
          <div class="submenu__link-line-1">
            <span class="submenu__link_bold">{{ claim.claim_number }}</span>
          </div>
          <div>
            {{ claim.loss_date | dateFormat('MM/DD/YYYY') }}
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'claims-submenu-link',
    props: ['claimsList', 'type']
  }
</script>

<style scoped>

</style>
