module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: (_vm.loading),
      expression: "loading"
    }]
  }, [_c('bc-navbar', {
    staticClass: "u-mt2 u-mb4",
    attrs: {
      "title": _vm.t('policies.header_policy_number', {
        policy_number: _vm.policyNumber
      }),
      "backLink": ""
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "u-flex-horizontal-space-between u-mb3"
  }, [_c('div', [_c('el-button', {
    staticClass: "u-mr3",
    attrs: {
      "type": "primary",
      "disabled": _vm.policy.is_canceled
    },
    on: {
      "click": function($event) {
        return _vm.chooseFile()
      }
    }
  }, [_vm._v("\n        " + _vm._s(_vm.t('documents.documents_popover_menu_upload_file')) + "\n      ")]), _vm._v(" "), (_vm.backLink !== null && _vm.backLink.type === 'insured_upload_folder') ? _c('el-button', {
    staticClass: "u-mr3",
    attrs: {
      "type": "info"
    },
    on: {
      "click": function($event) {
        return _vm.createFolder()
      }
    }
  }, [_vm._v("\n        " + _vm._s(_vm.t('documents.create_folder')) + "\n      ")]) : _vm._e()], 1), _vm._v(" "), _c('el-dropdown', {
    on: {
      "command": _vm.changeSorting
    }
  }, [_c('span', {
    attrs: {
      "title": _vm.t('documents.documents_sorting_menu_title')
    }
  }, [_vm._v("\n        " + _vm._s(_vm.sortData.sortTitle) + "\n        "), _c('fa-icon', {
    class: _vm.sortData.ascending ? "fa-rotate-90" : "fa-rotate-270",
    attrs: {
      "icon": ['far', 'arrow-left']
    }
  })], 1), _vm._v(" "), _c('el-dropdown-menu', {
    attrs: {
      "slot": "dropdown"
    },
    slot: "dropdown"
  }, _vm._l((_vm.sortFieldsData), function(item) {
    return _c('el-dropdown-item', {
      key: item.sortTitle,
      attrs: {
        "command": {
          sortByField: item.sortByField,
          ascending: item.ascending,
          sortTitle: item.sortTitle
        }
      }
    }, [_vm._v("\n            " + _vm._s(item.sortTitle) + "\n        ")])
  }), 1)], 1)], 1), _vm._v(" "), (_vm.folderName && _vm.folderName.length > 1) ? _c('div', {
    staticClass: "folders-links u-mb3",
    on: {
      "click": function($event) {
        return _vm.$router.back()
      }
    }
  }, [_c('div', {
    staticClass: "media"
  }, [_c('i', {
    staticClass: "material-icons u-mr2"
  }, [_vm._v("arrow_back")]), _vm._v(" "), _c('el-breadcrumb', {
    attrs: {
      "separator": "/"
    }
  }, _vm._l((_vm.folderName), function(el) {
    return _c('el-breadcrumb-item', {
      key: el.title
    }, [_vm._v("\n         " + _vm._s(el.title) + "\n        ")])
  }), 1)], 1)]) : _vm._e(), _vm._v(" "), (_vm.documentsFileList) ? _c('div', _vm._l((_vm.sorted_records), function(document) {
    return _c('div', {
      key: document.id,
      staticClass: "item-input-field"
    }, [_c('div', {
      staticClass: "media folders-list"
    }, [_c('div', {
      staticClass: "media__image",
      on: {
        "click": function($event) {
          return _vm.clickOnFile(document)
        }
      }
    }, [(document.isdir) ? _c('fa-icon', {
      staticClass: "folders-list__icon",
      attrs: {
        "icon": ['far', 'folder']
      }
    }) : _c('fa-icon', {
      staticClass: "folders-list__icon",
      attrs: {
        "icon": ['far', 'file']
      }
    })], 1), _vm._v(" "), (document.isdir) ? _c('div', {
      staticClass: "media__body media__body--trim-text",
      on: {
        "click": function($event) {
          return _vm.clickOnFile(document)
        }
      }
    }, [_vm._v("\n              " + _vm._s(document.title) + "\n          ")]) : _c('div', {
      staticClass: "media__body media__body--trim-text"
    }, [_c('el-dropdown', {
      staticClass: "u-width100",
      attrs: {
        "trigger": "click"
      },
      on: {
        "command": _vm.onFileCommand
      }
    }, [_c('span', {
      staticClass: "el-dropdown-link u-flex-horizontal-space-between"
    }, [_c('div', {
      staticClass: "u-pr2"
    }, [_vm._v(_vm._s(document.title))]), _vm._v(" "), _c('div', [_c('el-button', {
      staticClass: "u-pt1 u-pb1 u-pl3 u-pr3 el-button--transparent",
      attrs: {
        "type": "info"
      }
    }, [_c('fa-icon', {
      staticClass: "fa-rotate-90",
      attrs: {
        "icon": ['far', 'ellipsis-v']
      }
    })], 1)], 1)]), _vm._v(" "), _c('el-dropdown-menu', {
      attrs: {
        "slot": "dropdown"
      },
      slot: "dropdown"
    }, [_c('el-dropdown-item', {
      attrs: {
        "command": {
          command: 'download',
          file: document
        }
      }
    }, [_vm._v("\n                  " + _vm._s(_vm.t('documents.documents_popover_menu_download')) + "\n                ")]), _vm._v(" "), (document.mimeType === 'application/pdf') ? _c('el-dropdown-item', {
      attrs: {
        "command": {
          command: 'open',
          file: document
        }
      }
    }, [_vm._v("\n                  " + _vm._s(_vm.t('documents.documents_popover_menu_open')) + "\n                ")]) : _vm._e(), _vm._v(" "), (_vm.documentsFileList.insured_upload_folder) ? _c('el-dropdown-item', {
      attrs: {
        "command": {
          command: 'move',
          file: document
        }
      }
    }, [_vm._v("\n                    " + _vm._s(_vm.t('documents.documents_popover_menu_move')) + "\n                ")]) : _vm._e(), _vm._v(" "), (_vm.documentsFileList.insured_upload_folder) ? _c('el-dropdown-item', {
      attrs: {
        "command": {
          command: 'delete',
          file: document
        }
      }
    }, [_vm._v("\n                  " + _vm._s(_vm.t('documents.documents_popover_menu_delete')) + "\n                ")]) : _vm._e()], 1)], 1)], 1), _vm._v(" "), (document.isdir && _vm.backLink !== null && _vm.documentsFileList.insured_upload_folder) ? _c('div', {
      staticClass: "u-mr2"
    }, [_c('el-button', {
      staticClass: "u-pt1 u-pb1 u-pl2 u-pr2 el-button--transparent",
      attrs: {
        "icon": ['fas', 'pen'],
        "type": "info"
      },
      on: {
        "click": function($event) {
          $event.stopPropagation();
          return _vm.renameFolder(document)
        }
      }
    })], 1) : _vm._e(), _vm._v(" "), (document.isdir && _vm.backLink !== null && _vm.documentsFileList.insured_upload_folder) ? _c('div', [_c('el-button', {
      staticClass: "u-pt1 u-pb1 u-pl2 u-pr2 el-button--transparent",
      attrs: {
        "icon": ['fas', 'trash'],
        "type": "info"
      },
      on: {
        "click": function($event) {
          $event.stopPropagation();
          return _vm.removeFolder(document)
        }
      }
    })], 1) : _vm._e()])])
  }), 0) : _vm._e(), _vm._v(" "), (_vm.foldersNavigationFileList) ? _c('el-dialog', {
    staticClass: "modal-move",
    attrs: {
      "title": _vm.t('documents.select_destination_folder'),
      "visible": _vm.dialogTableVisible
    },
    on: {
      "update:visible": function($event) {
        _vm.dialogTableVisible = $event
      }
    }
  }, [(_vm.foldersNavigationFileList && _vm.foldersNavigationFileList.folders.length) ? _c('div', {
    staticClass: "modal-move__header"
  }, [_c('el-button', {
    on: {
      "click": _vm.moveFile
    }
  }, [_vm._v("\n             " + _vm._s(_vm.t('documents.move_to_folder', {
    folder: _vm.foldersNavigationFileList.folderName
  })) + "\n          ")])], 1) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "modal-move__body"
  }, [(_vm.foldersNavigationFileList) ? _c('div', [(_vm.foldersNavigationFileList.folders.length) ? _c('div', {
    on: {
      "click": function($event) {
        return _vm.loadPickerData(_vm.folderNavigationBackLink.id)
      }
    }
  }, [_c('div', {
    staticClass: "media"
  }, [_c('div', {
    staticClass: "media__image"
  }, [_c('el-popover', {
    attrs: {
      "placement": "top-start",
      "title": "",
      "width": "150",
      "trigger": "hover",
      "content": _vm.t('documents.back')
    }
  }, [_c('el-button', {
    staticClass: "popover-btn u-no-border el-button--transparent u-p0",
    attrs: {
      "slot": "reference",
      "size": "mini"
    },
    slot: "reference"
  }, [_c('i', {
    staticClass: "material-icons"
  }, [_vm._v("reply")])])], 1)], 1), _vm._v(" "), _c('div', {
    staticClass: "media__body media__body--trim-text"
  }, [_vm._v("\n                        " + _vm._s(_vm.foldersNavigationFileList.folderName) + "\n                      ")])])]) : _vm._e(), _vm._v(" "), _vm._l((_vm.foldersNavigationFileList.records), function(document) {
    return (document.isdir && !document.readOnly && _vm.foldersNavigationFileList) ? _c('div', {
      key: document.id,
      on: {
        "click": function($event) {
          return _vm.loadPickerData(document.id)
        }
      }
    }, [(_vm.foldersNavigationFileList.folders.length) ? _c('div', {
      staticClass: "media"
    }, [_c('i', {
      staticClass: "material-icons u-pr3"
    }, [_vm._v("folder")]), _vm._v(" "), _c('div', {
      staticClass: "media__body media__body--trim-text"
    }, [_vm._v("\n                       " + _vm._s(document.title) + "\n                    ")])]) : _c('div', {
      staticClass: "media"
    }, [_c('i', {
      staticClass: "material-icons u-pr3"
    }, [_vm._v("reply")]), _vm._v(" "), _c('div', {
      staticClass: "media__body media__body--trim-text"
    }, [_vm._v("\n                      " + _vm._s(_vm.t('documents.you_can_not_move_file_here')) + "\n                    ")])])]) : _vm._e()
  })], 2) : _vm._e()])]) : _vm._e(), _vm._v(" "), _c('input', {
    staticStyle: {
      "visibility": "hidden"
    },
    attrs: {
      "type": "file",
      "id": "uploadFileInput",
      "accept": _vm.availableFileTypes
    },
    on: {
      "change": _vm.onFileSelected
    }
  })], 1)
},staticRenderFns: []}