<template>
  <div v-loading="loading">
  <h2 class="secondary-header u-mt4 u-mb4">{{ t('global.emails_label') }}</h2>
      <div class="el-card box-card is-always-shadow" v-for="(insured, index) in insureds" :key="insured.id">
        <div class="u-p3">
          <h3 class="subheader">{{ t('global.policies_header') }}</h3>
          <div v-for="(policy, index) in insured.policies" :key="index" class="u-mb3">
            <div class="emails-info__headline u-text--bold"> {{ policy.policyHeadline }} </div>
            <div class="subheader u-mt1 u-mb1">{{ t('policies.header_policy_number', {policy_number: policy.policyNumber}) }}</div>
          </div>

          <h3 class="subheader u-mt2">{{ t('account.associated_emails_label') }} *</h3>
          <el-input @keyup.enter.native="saveEmail(insured)" autofocus type="text"
            class="item-input-field pin-item__input"
             required
            v-model="insured.email">

          </el-input>
          <div v-if="insured.validationError" class="u-warning">
            <i class="material-icons md-text" aria-hidden="true">warning</i> {{ insured.validationError }}
          </div>
          <div v-if="insured.unconfirmedEmail">
            <h3 class="subheader u-mt2 u-mb0">{{ t('account.pending_confirmation_email_label', {new_email: insured.unconfirmedEmail}) }}</h3>
          </div>
          <div class="u-flex-justify-content-end">
            <el-button type="primary" @click="saveEmail(insured)" v-if="insuredsWithEditedEmail[insured.id]">
              {{ t('global.save_changes') }}
            </el-button>
          </div>
        </div>
      </div>
      <el-dialog :visible="visibleDialog"
                 :show-close="true"
                 :close-on-click-modal="false"
                 :close-on-press-escape="false"
         >
        <div slot="title" class="el-dialog__title_border">
          {{ t('account.user_action_required') }}
        </div>
        <div v-if="dialogMode === 'input'">
          <span v-html="t('account.email_update_confirmation_sent', {new_email: insured.email})"></span>
          <el-input autofocus type="text"
            class="item-input-field pin-item__input u-mt3 u-mb3"
             required
            v-model="confirmationCode">
          </el-input>
          <div class="u-flex-justify-content-end">
            <el-button class="el-button--small u-mr1" type="secondary" @click="cancelCodeChallenge()">
              {{ t('global.cancel') }}
            </el-button>
            <el-button class="el-button--small" type="primary" @click="checkConfirmationCode()" :disabled="!confirmationCode">
              {{ t('global.confirm') }}
            </el-button>
          </div>
        </div>
        <div v-else>
          <div class="u-mt2 u-mb2">{{ info }}</div>
          <div class="u-flex-justify-content-end">
            <el-button class="el-button--small" type="primary" @click="closeInfoDialog()">
              {{ t('global.ok') }}
            </el-button>
          </div>
        </div>
      </el-dialog>
    </div>
</template>

<script>
  import axios from 'axios'
  import config from '../../../config/britecore'
  import {mapState, mapGetters} from 'vuex'
  import {deepClone, isEmailValid} from '../../../shared_src/utils/misc.util'
  import _ from 'lodash'
  import {getCauseData} from '../../../shared_src/utils/api.utils'

  export default {
    computed: {
      ...mapState('account', ['accountData']),
      ...mapGetters('account', ['account']),
      ...mapState('policies', ['policies']),
      ...mapState('common', ['contact', 'settings']),
      insuredsWithEditedEmail () {
        let changed = {}
        this.insureds.forEach(insured => {
          let initialInsured = _.find(this.initialInsureds, _initialInsured => {
            return insured.id === _initialInsured.id
          })
          changed[insured.id] = insured.email !== initialInsured.email
        })
        return changed
      },
      title () {
        return this.t('account.your_account').toUpperCase()
      },
    },
    methods: {
      cancelCodeChallenge () {
        this.visibleDialog = false
      },
      checkEmail (email) {
        if (email.trim() === '') {
          return this.t('account.email_should_not_be_empty')
        }
        if (!isEmailValid(email)) {
          return this.t('account.email_is_incorrect')
        }
        return ''
      },
      reloadData () {
        this.loading = true
        this.$store.dispatch('account/loadAccount').then(() => {
          this.insureds = deepClone(this.getInsuredsWithData())
          this.initialInsureds = deepClone(this.getInsuredsWithData())
          this.loading = false
        })
      },
      policyHeadline (policy) {
        if (policy.properties.length === 0) {
          return policy.policy_type
        }

        if (policy.properties.length === 1) {
          return policy.policy_type + ' - ' + policy.properties[0].address_line1
        }
        if (policy.properties.length > 1) {
          return policy.policy_type + ' - ' + this.t('payments.properties_number', {number: policy.properties.length})
        }
      },
      getInsuredsWithData () {
        let insureds = this.$store.getters['account/account'].insureds
        let allPolicies = this.$store.state.policies.policies.policies
        let result = []
        for (let key in insureds) {
          let insured = insureds[key]

          let policies = _.filter(allPolicies, policy => {
            return _.some(policy.revision.named_insureds, policyInsured => {
              return insured.contact_id === policyInsured.contact_id
            })
          })
          let policyNumbers = []
          policies.forEach(policy => {
            policyNumbers.push({
              policyNumber: policy.policy_number,
              policyHeadline: this.policyHeadline(policy)
            })
          })
          let res = {
            contactId: insured.contact_id,
            id: key,
            policies: policyNumbers,
            email: insured.email ? insured.email.email : '',
            unconfirmedEmail: insured.unconfirmed_email,
            validationError: '',
          }
          result.push(res)
        }
        result = _.orderBy(result, 'id')
        return result
      },
      showInfo (info, closeOnOk) {
        this.dialogMode = 'info'
        this.info = info
        this.closeOnOk = !!closeOnOk
      },
      closeInfoDialog () {
        if (this.closeOnOk) {
          this.visibleDialog = false
        } else {
          this.dialogMode = 'input'
        }
      },
      checkConfirmationCode () {
        this.loading = true
        return axios.post(config.confirm_email_by_code, {
          token: localStorage.token,
          confirmation_code: this.confirmationCode,
          insured_id: this.insured.id,
          email: this.insured.email
        }).then(
          (response) => {
            this.loading = false
            let cause = getCauseData(response)
            if (!cause.key) {  // error case - confirm_email_by_code EP should always send cause as result code
              this.showInfo(this.t('global.error'), true)
              return
            }
            if (response.data.success) {
              this.showInfo(this.t(cause.key, cause.params), true)
              this.reloadData()
            } else {
              this.showInfo(this.t(cause.key, cause.params),
                  cause.key !== 'account.email_confirmation_code_does_not_match')
            }
          },
          () => {
            this.loading = false
            this.showInfo(this.t('account.error_saving_contact_information'), true)
          }
        )
      },
      openConfirmationDialog (insured) {
        this.insured = insured
        this.dialogMode = 'input'
        this.visibleDialog = true
      },
      saveEmail (insured) {
        insured.validationError = this.checkEmail(insured.email)
        if (insured.validationError) {
          this.$elAlert(this.t('enrollment.some_error_happened_notification'))
          return
        }
        this.loading = true
        return axios.post(config.change_email, {
          token: localStorage.token,
          new_email: insured.email,
          insured_id: insured.id,
        }).then(
          (response) => {
            this.loading = false
            if (response.data.success) {
              let cause = getCauseData(response)
              if (cause.key === 'account.email_update_confirmation_sent') {
                // confirmation required
                this.openConfirmationDialog(insured)
              } else
              if (cause.key === 'account.email_updated_immediately') {
                this.$elAlert(
                  this.t(cause.key, cause.params),
                  this.t('global.notice'))
                this.reloadData()
              }
            } else {
              this.$elAlert(
                this.t('account.error_saving_contact_information'), this.t('global.warning'))
            }
          }
        )
      }
    },
    data () {
      return {
        insureds: deepClone(this.getInsuredsWithData()),
        initialInsureds: deepClone(this.getInsuredsWithData()),
        loading: false,
        visibleDialog: false,
        insured: '',
        confirmationCode: '',
        dialogMode: '',
        info: '',
        closeOnOk: false
      }
    },
  }
</script>

<style lang="scss">
  @import '../../sass/_variables.scss';
  
.emails-info__headline {
  font-size: 18px;
}
</style>
