module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', [_c('p', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: (!_vm.account),
      expression: "!account"
    }],
    staticClass: "secondary-title"
  }, [_vm._v(_vm._s(_vm.t('account.login_credentials')))]), _vm._v(" "), (_vm.account) ? _c('div', [_c('el-form', {
    nativeOn: {
      "submit": function($event) {
        $event.preventDefault();
      }
    }
  }, [_c('el-form-item', {
    staticClass: "u-mt2 u-mb2 subheader u-text-uppercase",
    attrs: {
      "label": _vm.t('account.login_header')
    }
  }, [_c('el-input', {
    staticClass: "item-input-field",
    attrs: {
      "disabled": _vm.isAccountSaveInProgress,
      "type": "email"
    },
    nativeOn: {
      "keyup": function($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.save.apply(null, arguments)
      }
    },
    model: {
      value: (_vm.form.username),
      callback: function($$v) {
        _vm.$set(_vm.form, "username", $$v)
      },
      expression: "form.username"
    }
  })], 1), _vm._v(" "), _c('el-form-item', {
    staticClass: "u-mb0",
    attrs: {
      "label": _vm.t('enrollment.4_digit_pin_number_input_label')
    }
  }, [_c('el-input', {
    staticClass: "item-input-field",
    attrs: {
      "id": "pinNumberInput",
      "disabled": _vm.isAccountSaveInProgress,
      "placeholder": _vm.isExistPin ? '****' : '',
      "type": _vm.form.pin.masked ? 'tel' : 'password',
      "autocomplete": "off",
      "maxlength": 4
    },
    on: {
      "focus": _vm.handleInput,
      "blur": _vm.handleInput
    },
    nativeOn: {
      "keyup": function($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.save.apply(null, arguments)
      }
    },
    model: {
      value: (_vm.form.pin.newPin),
      callback: function($$v) {
        _vm.$set(_vm.form.pin, "newPin", (typeof $$v === 'string' ? $$v.trim() : $$v))
      },
      expression: "form.pin.newPin"
    }
  }), _vm._v(" "), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: (_vm.pinHasValue),
      expression: "pinHasValue"
    }],
    staticClass: "item-input-field__eye",
    class: {
      'crossed-out': _vm.form.pin.masked && _vm.pinHasValue
    },
    on: {
      "click": _vm.displayPinCode
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/logos/eye_logo.svg"),
      "alt": _vm.t('global.show')
    }
  })]), _vm._v(" "), _c('div', {
    staticClass: "u-warning u-mb2"
  }, [(_vm.form.pin.validatePinMessage) ? _c('i', {
    staticClass: "material-icons md-text",
    attrs: {
      "aria-hidden": "true"
    }
  }, [_vm._v("warning")]) : _vm._e(), _vm._v(" "), _c('span', [_vm._v(_vm._s(_vm.form.pin.validatePinMessage))])])], 1)], 1), _vm._v(" "), (_vm.account) ? _c('el-button', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: (_vm.isAccountSaveInProgress),
      expression: "isAccountSaveInProgress"
    }],
    staticClass: "u-mt2 u-mb4 el-button--wide u-text-uppercase",
    attrs: {
      "disabled": _vm.loading || (!_vm.usernameHasChanges && !_vm.pinHasValue),
      "type": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("\n      " + _vm._s(_vm.t('global.save_changes')) + "\n    ")]) : _vm._e()], 1) : _vm._e(), _vm._v(" "), (_vm.displayLanguageLink) ? _c('div', [_c('p', {
    staticClass: "secondary-title u-mt3 u-mb3"
  }, [_vm._v(_vm._s(_vm.t('global.language')))]), _vm._v(" "), _c('ba-language-selector', {
    staticClass: "u-width100",
    attrs: {
      "notify-backend": "true"
    }
  })], 1) : _vm._e(), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "secondary-title u-mt4 u-mb3"
  }, [_vm._v(_vm._s(_vm.t('account.context_to_text_choice')) + "\n      "), _c('span', {
    staticClass: "u-ml2 portal-code-info"
  }, [_c('el-popover', {
    attrs: {
      "placement": "top-start",
      "title": "",
      "width": "325",
      "trigger": "hover"
    }
  }, [_c('span', {
    attrs: {
      "slot": "reference"
    },
    slot: "reference"
  }, [_c('fa-icon', {
    attrs: {
      "icon": "exclamation-circle"
    }
  })], 1), _vm._v(" "), _c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.t('account.consent_to_text_help'))
    }
  })])], 1)]), _vm._v(" "), _c('ba-consent-to-text', {
    attrs: {
      "account": _vm.account,
      "notify-backend": true
    }
  })], 1)])
},staticRenderFns: []}