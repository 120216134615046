module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', [_c('bc-navbar', {
    attrs: {
      "title": _vm.t('dashboard.claims')
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "submenu"
  }, [(_vm.hasNoClaims) ? _c('div', {
    staticClass: "file-claim-info no-claims u-flex-vertical-center u-text-center u-mr4"
  }, [_c('ba-icon-link', {
    attrs: {
      "icon_type": "far",
      "icon_name": "file-alt",
      "icon_class": "file-claim-info__icon"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "no-claim__notice u-mt4"
  }, [_vm._v(_vm._s(_vm.t('claims.your_account_does_not_have_claims_yet')))]), _vm._v(" "), _c('div', {
    staticClass: "no-claim__form-info u-mt2"
  }, [_vm._v(_vm._s(_vm.t('claims.use_form_to_file_your_first_claim')))])], 1) : _vm._e(), _vm._v(" "), _c('ba-file-claim-component')], 1)], 1)
},staticRenderFns: []}