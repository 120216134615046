module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('el-form-item', {
    staticClass: "dob-form-field",
    attrs: {
      "label": _vm.t('enrollment.date_of_birth')
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: (_vm.codeDOB),
      expression: "codeDOB"
    }],
    ref: "picker",
    staticClass: "el-input__inner",
    attrs: {
      "autofocus": "",
      "placeholder": "mm/dd/yyyy",
      "type": "text"
    },
    domProps: {
      "value": (_vm.codeDOB)
    },
    on: {
      "keyup": _vm.check,
      "input": function($event) {
        if ($event.target.composing) { return; }
        _vm.codeDOB = $event.target.value
      }
    }
  }), _vm._v(" "), _c('el-date-picker', {
    ref: "nbv",
    staticClass: "dob-form-field__date-picker",
    attrs: {
      "picker-options": _vm.pickerOptions,
      "type": "date",
      "format": "MM/dd/yyyy",
      "value-format": "MM/dd/yyyy"
    },
    on: {
      "change": _vm.check
    },
    model: {
      value: (_vm.defineShowPageDOB),
      callback: function($$v) {
        _vm.defineShowPageDOB = $$v
      },
      expression: "defineShowPageDOB"
    }
  })], 1)
},staticRenderFns: []}