module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', [_c('el-dialog', {
    attrs: {
      "custom-class": "payments-dialog font-montseratt",
      "visible": _vm.dialogVisible,
      "close-on-press-escape": false
    },
    on: {
      "update:visible": function($event) {
        _vm.dialogVisible = $event
      },
      "close": function($event) {
        return _vm.handleDialog(false)
      }
    }
  }, [_c('div', {
    staticClass: "payments-dialog__title",
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_vm._v("\n      " + _vm._s(_vm.t('payments.make_payment')) + "\n    ")]), _vm._v(" "), (_vm.dialogVisible && _vm.checkedPolicies.length) ? _c('div', [_c('bc-general-bulk-pay', {
    attrs: {
      "policies": _vm.checkedPolicies,
      "payOptions": _vm.payOptions,
      "activePayType": _vm.activePayType
    },
    on: {
      "handleDialogs": _vm.appPaymentMethodDialog,
      "handleMakePaymentsDialog": _vm.handleMakePaymentsDialog,
      "reloadPolicies": _vm.reloadPolicies,
      "resetCheckedPoliciesList": _vm.resetCheckedPoliciesList
    }
  })], 1) : _vm._e()]), _vm._v(" "), _c('ba-add-payment-method-modal', {
    attrs: {
      "available-insureds": _vm.insuredIds
    }
  })], 1)
},staticRenderFns: []}