module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('el-dialog', {
    attrs: {
      "title": _vm.title,
      "visible": _vm.visibleDialogInfo,
      "show-close": _vm.isVisibleCloseIcon,
      "close-on-click-modal": false,
      "close-on-press-escape": false,
      "custom-class": "dialog-info",
      "before-close": _vm.handleClose
    }
  }, [_c('span', {
    staticClass: "dialog-info__description",
    domProps: {
      "innerHTML": _vm._s(_vm.description)
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    staticClass: "dialog-info__btn u-text-uppercase",
    attrs: {
      "type": "info"
    },
    on: {
      "click": function($event) {
        return _vm.getResult('decline')
      }
    }
  }, [_vm._v(_vm._s(_vm.t('global.decline')))]), _vm._v(" "), _c('el-button', {
    staticClass: "dialog-info__btn u-text-uppercase",
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function($event) {
        return _vm.getResult('accept')
      }
    }
  }, [_vm._v(_vm._s(_vm.t('global.accept')))])], 1)])
},staticRenderFns: []}