<template>
  <fa-icon :icon="iconFromRiskComponent" />
</template>
<script>
  export default {
    name: 'ba-risk-icon',
    props: {
      riskComponent: Object
    },
    computed: {
      iconFromRiskComponent () {
        switch (this.riskComponent.type) {
        case 'driver':
          return ['far', 'user']
        case 'vehicle':
          return ['fal', 'car']
        case 'golfCarts':
          return ['fac', 'golf-cart']
        case 'antiqueAutos':
          return ['fac', 'retro-car']
        case 'motorHomes':
          return ['fal', 'rv']
        case 'trailersOrCampers':
          return ['fal', 'caravan-alt']
        case 'allOtherTrailers':
          return ['fal', 'trailer']
        case 'watercraft':
          return ['fal', 'ship']
        case 'location':
          return ['far', 'map-marker']
        }
      },
    },
  }
</script>
