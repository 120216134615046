import axios from 'axios'
import config from '../../config/britecore'
import _ from 'lodash'
import {axiosWrapper} from '@shared_src/utils/misc.util'

const claimsModule = {
  namespaced: true,
  state: {
    claims: null,
    currentClaim: null,
    timezonesWithStatesByDate: {}
  },
  mutations: {
    setCurrentClaim (state, payload) {
      state.currentClaim = payload
    },
    setClaims (state, payload) {
      state.claims = payload
    },
    addClaims (state, payload) {
      state.claims.push(...payload)
    },
    setTimezonesWithStateByDate (state, payload) {
      state.timezonesWithStatesByDate[payload.lossDate] = payload.data
    }
  },
  actions: {
    getClaim: function getClaimPromise (context, claimId) {
      return new Promise((resolve, reject) => {
        axios.post(config.get_claim_url, {
          token: localStorage.token,
          claim_id: claimId
        })
        .then(response => {
          let dataToSave = response.data.data
          context.commit('setCurrentClaim', dataToSave)
          console.log('Fetched claim')
          resolve()
        })
        .catch(error => {
          console.error('get_claim_url failed', error)
          reject()
        })
      })
    },
    async getGen2Claim (context, claimId) {
      return await axiosWrapper(axios.post(config.get_claim_url, {
        token: localStorage.token,
        claim_id: claimId
      }))
    },
    async getGen3Claim (context, claimId) {
      return await axiosWrapper(axios.get(config.claims_gen3_url + claimId + '/', {
        headers: {Authorization: localStorage.token},
      }))
    },
    async getGen3ClaimTimezonesWithStates (context, lossDate) {
      return await axiosWrapper(
        axios.get(config.claims_gen3_timezones_with_states_url, {
          params: {loss_date: lossDate},
          headers: {Authorization: localStorage.token}
        })
      )
    },
    async getGen3ClaimTimezonesWithStatesCached (context, lossDate) {
      if (!context.state.timezonesWithStatesByDate[lossDate]) {
        let response = await context.dispatch('getGen3ClaimTimezonesWithStates', lossDate)
        let data = response.data
        context.commit('setTimezonesWithStateByDate', {lossDate, data})
      }

      return context.state.timezonesWithStatesByDate[lossDate]
    },
    async fileClaim (context, payload) {
      return await axiosWrapper(axios.post(config.file_claim_url, {
        token: localStorage.token,
        policy_id: payload.policyId,
        property_id: payload.propertyId,
        loss_date: payload.loss_date,
        claim_description: payload.description,
        chosen_photos: payload.chosenPhotos,
        contact_phone_number: payload.contactPhoneNumber
      }))
    },
    async getGen3Claims (context, payload) {
      let claimsRequests = []

      let policies = context.rootState.policies.policies.policies
      policies.forEach(policy => {
        if (policy.isGen3) {
          let getRequest = axios.get(config.claims_gen3_url, {
            params: {policy_number: policy.policy_number},
            headers: {Authorization: localStorage.token},
          })
          claimsRequests.push(getRequest)
        }
      })
      let claimGen3Responses = await Promise.all(claimsRequests)
      let results = []
      claimGen3Responses.forEach(claimGen3Response => {
        let claimsGen3 = claimGen3Response.data.data
        if (claimsGen3 && claimsGen3.length) {
          let claimPolicyNumber = claimsGen3[0].policy_number
          let policy = _.find(policies, policy => {
            return policy.policy_number === claimPolicyNumber
          })
          claimsGen3.forEach(claimGen3 => {
            claimGen3.policy_id = policy.id
            claimGen3.isGen3 = true
            results.push(claimGen3)
          })
        }
      })
      context.commit('addClaims', results)
    },
    async fileClaimGen3 (context, payload) {
      return await axiosWrapper(axios.post(config.claims_gen3_url, {
        token: localStorage.token,
        revision_id: payload.revisionId,
        risk_id: payload.riskId,
        loss_date: payload.loss_date,
        claim_description: payload.description,
        loss_timezone: payload.lossTimezone,
        chosen_photos: payload.chosenPhotos,
        preferred_phone: payload.selectedPhone
      }))
    },
    saveRecentClaimPhoto: function saveRecentClaimPhoto (context, payload) {
      return new Promise((resolve, reject) => {
        let request = {
          token: localStorage.token,
          claim_id: payload.claimId,
          photo: payload.image,
          filename: payload.fileName,
          mimetype: payload.mimeType,
          caption: payload.caption,
        }

        axios.post(config.add_claim_photo, request).then(response => {
          if (response.data.success) {
            resolve(response)
          } else {
            reject(response)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },
    async removeRecentClaimPhoto (context, fileId) {
      return await axiosWrapper(
        axios.post(config.delete_property_photo_url, {
          token: localStorage.token,
          file_id: fileId
        })
      )
    },
    removeRecentPhoto: function removeRecentPhoto (context, attachmentIds) {
      return new Promise((resolve, reject) => {
        axios.post(config.remove_attachments_url, {
          token: localStorage.token,
          attachment_ids: attachmentIds
        })
          .then(response => {
            console.log('Attachments removed')
            resolve()
          }, response => {
            reject()
          })
      })
    },
  },
  getters: {
    openClaims: state => {
      if (!state.claims) {
        return null
      }
      return state.claims.filter(x => x.status !== null && !x.status.match(/(Closed|Archive)/ig))
    },
    closedClaims: state => {
      if (!state.claims) {
        return null
      }
      return state.claims.filter(x => x.status !== null && x.status.indexOf('Closed') !== -1)
    },
    claimById: (state, getters) => (id) => {
      if (!state.claims) return null
      return state.claims.find(claim => claim.id === id)
    },
  }
}

export default claimsModule
