module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    staticClass: "agency-info"
  }, [_c('div', {
    staticClass: "agency-info__content"
  }, [_c('router-link', {
    staticClass: "contact-name__link u-text-trim",
    attrs: {
      "to": {
        path: '/agents/list',
        hash: _vm.agentOrAgency.id
      },
      "data-test-id": "contact-agent"
    }
  }, [_c('div', {
    staticClass: "contact-name contact-name__agent u-text-trim u-mb1"
  }, [_vm._v(_vm._s(_vm.agentOrAgency.contact.name))]), _vm._v(" "), _vm._l((_vm.agentOrAgency.policies), function(policy) {
    return _c('div', {
      key: policy.policy_number
    }, [_c('div', {
      staticClass: "subheader u-mt2"
    }, [_vm._v(_vm._s(_vm.t('payments.policy_#', {
      number: policy.policy_number
    })))])])
  })], 2), _vm._v(" "), (_vm.agentsAmount === 1) ? _c('div', {
    staticClass: "agent-picture"
  }, [(_vm.agentOrAgency.contact.photo_id) ? _c('div', [_c('bc-general-image', {
    staticClass: "u-img-responsive",
    attrs: {
      "fileId": _vm.agentOrAgency.contact.photo_id
    }
  })], 1) : _c('fa-icon', {
    attrs: {
      "icon": ['far', 'user']
    }
  })], 1) : _vm._e()], 1)])
},staticRenderFns: []}