module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    class: _vm.bgClass,
    on: {
      "click": _vm.showPreview
    }
  }, [_c('el-footer', {
    attrs: {
      "title": _vm.version
    }
  }, [_vm._v(" " + _vm._s(_vm.t('global.footer', {
    current_year: _vm.currentYr
  })))])], 1)
},staticRenderFns: []}