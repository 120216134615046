module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    staticClass: "init-screen"
  }, [_c('init-sidebar'), _vm._v(" "), _c('div', {
    staticClass: "init-screen__info"
  }, [_c('div', {
    staticClass: "init-block"
  }, [_c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: (_vm.loading),
      expression: "loading"
    }]
  }), _vm._v(" "), (_vm.message.length > 0) ? _c('div', {
    staticClass: "u-mt4 u-text-center mx-auto"
  }, [_vm._v(_vm._s(_vm.message))]) : _vm._e()])]), _vm._v(" "), _c('div', {
    staticClass: "init-screen__logo-language"
  }, [_c('bc-footer', {
    attrs: {
      "bgClass": "bg-login"
    }
  }), _vm._v(" "), (_vm.displayLanguageLink) ? _c('div', {
    staticClass: "init-screen__bc-language"
  }, [_c('ba-language-selector')], 1) : _vm._e()], 1)], 1)
},staticRenderFns: []}