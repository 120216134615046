<template>
  <div class="contact-agency">
    <bc-navbar :title="t('global.contact')" backLink></bc-navbar>
    <div class="contact-agency__block">
      <div class="contact-agency__carrier u-mr4" v-if="primaryCarrier">
        <bc-contact :name="primaryCarrier.name" :contact="primaryCarrier"/>
      </div>
      <div v-if="uiAgents.length" class="contact-agency__info">
        <div class="u-flex-horizontal-space-between u-width100 u-mt4">
          <h2 class="contact-agency__info_no-wrap secondary-header u-mt0 u-mb0 u-mr2">{{ t('agent.agent_info') }}</h2>
          <div v-if="policies && policies.policies && displayedContactInformation.amount > 1">
            <el-select v-model="selectedPolicyId" :placeholder="t('global.filter_by_policy')" clearable>
              <el-option v-for="policy in policies.policies"
                         :key="policy.id"
                         :label="policy.policy_number"
                         :value="policy.id"
              />
            </el-select>
          </div>
        </div>
        <div v-for="agent in displayedContactInformation.data" :key="agent.id">
          <!--  Flag for scrolling  -->
          <div :ref="agent.id">
            <agent-card :agent="agent" :class="{ 'agent-block-active': agencyIdFromHash === agent.id }" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapState, mapGetters} from 'vuex'
  import BcContact from '@/components/contact.vue'
  import AgentCard from '@/components/agent-card.vue'

  export default {
    components: {
      'bc-contact': BcContact,
      'agent-card': AgentCard,
    },
    mounted () {
      if (this.agencyIdFromHash) this.scrollTo(this.agencyIdFromHash)
      this.logInsuredAction('Opened Agent/Carrier Page', 'custom_agent_carrier_page_open', {name: 'Carrier'})
    },
    data () {
      return {
        selectedPolicyId: ''
      }
    },
    computed: {
      ...mapState('agents', ['primaryCarrier']),
      ...mapGetters('agents', ['uiAgents']),
      ...mapState('policies', ['policies']),
      agencyIdFromHash () {
        return this.$route.hash && this.$route.hash.slice(1)
      },
      displayedContactInformation () {
        const amount = this.uiAgents.length
        let data = this.uiAgents
        if (this.selectedPolicyId) {
          data = data.filter(el => {
            return el.policies.find(policy => policy.policy_id === this.selectedPolicyId)
          })
        }
        return {
          data,
          amount
        }
      },
    },
    methods: {
      scrollTo (id) {
        let element = this.$refs[id]
        let top = element[0].offsetTop
        window.scrollTo({
          top,
          behavior: 'smooth'
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  @import '../../sass/_variables.scss';

  .agent-block-active {
    border: 2px solid transparentize($color-primary, 0.5) !important;
  }
  .contact-agency {
    &__block {
      display: flex;
      width: 100%;

      @media only screen and (max-width: $s-screen) {
        flex-direction: column;
      }
    }
    &__carrier {
      border: none;
      width: 350px;

      @media only screen and (max-width: $m-screen) {
        width: auto;
      }

      @media only screen and (max-width: $s-screen) {
        border-top: 2px solid #E5E5E5;
        border-bottom: 2px solid #E5E5E5;
      }
    }
    &__info {
      width: 100%;
      &_no-wrap {
        white-space: nowrap;
      }
    }
  }
</style>
