module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return (_vm.notifications) ? _c('div', {
    staticClass: "message-center--page"
  }, [_c('div', {
    staticClass: "message-center__header"
  }, [_c('div', {
    staticClass: "message-center__header_accented"
  }, [_vm._v("\n        " + _vm._s(_vm.t('notifications.notifications', {
    amount: _vm.getNotificationsAmount
  })) + "\n      ")]), _vm._v(" "), _c('div', {
    class: ['notifications', _vm.getNotificationsAmount ? 'notifications-active' : ''],
    on: {
      "click": _vm.dismiss_all
    }
  }, [_vm._v("\n       " + _vm._s(_vm.t('notifications.delete_all')) + "\n      ")])]), _vm._v(" "), _c('div', {
    staticClass: "message-center__list"
  }, [(_vm.notifications.new.length > 0) ? _c('div', {
    staticClass: "new-notifications"
  }, _vm._l((_vm.notifications.new), function(notification) {
    return _c('ba-notification-card', {
      key: notification.id,
      attrs: {
        "message": notification,
        "type": "new"
      }
    })
  }), 1) : _vm._e(), _vm._v(" "), (_vm.notifications.seen.length > 0) ? _c('div', {
    staticClass: "seen-notifications"
  }, _vm._l((_vm.notifications.seen), function(notification) {
    return _c('ba-notification-card', {
      key: notification.id,
      attrs: {
        "message": notification,
        "type": "seen"
      }
    })
  }), 1) : _vm._e()]), _vm._v(" "), (!_vm.has_at_least_one_message) ? _c('div', {
    staticClass: "message-center__no-messages u-text-center"
  }, [_vm._v("\n      " + _vm._s(_vm.t('messaging.no_messages_to_show')) + "\n    ")]) : _vm._e()]) : _vm._e()
},staticRenderFns: []}