module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    staticClass: "contact-agency"
  }, [_c('bc-navbar', {
    attrs: {
      "title": _vm.t('global.contact'),
      "backLink": ""
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "contact-agency__block"
  }, [(_vm.primaryCarrier) ? _c('div', {
    staticClass: "contact-agency__carrier u-mr4"
  }, [_c('bc-contact', {
    attrs: {
      "name": _vm.primaryCarrier.name,
      "contact": _vm.primaryCarrier
    }
  })], 1) : _vm._e(), _vm._v(" "), (_vm.uiAgents.length) ? _c('div', {
    staticClass: "contact-agency__info"
  }, [_c('div', {
    staticClass: "u-flex-horizontal-space-between u-width100 u-mt4"
  }, [_c('h2', {
    staticClass: "contact-agency__info_no-wrap secondary-header u-mt0 u-mb0 u-mr2"
  }, [_vm._v(_vm._s(_vm.t('agent.agent_info')))]), _vm._v(" "), (_vm.policies && _vm.policies.policies && _vm.displayedContactInformation.amount > 1) ? _c('div', [_c('el-select', {
    attrs: {
      "placeholder": _vm.t('global.filter_by_policy'),
      "clearable": ""
    },
    model: {
      value: (_vm.selectedPolicyId),
      callback: function($$v) {
        _vm.selectedPolicyId = $$v
      },
      expression: "selectedPolicyId"
    }
  }, _vm._l((_vm.policies.policies), function(policy) {
    return _c('el-option', {
      key: policy.id,
      attrs: {
        "label": policy.policy_number,
        "value": policy.id
      }
    })
  }), 1)], 1) : _vm._e()]), _vm._v(" "), _vm._l((_vm.displayedContactInformation.data), function(agent) {
    return _c('div', {
      key: agent.id
    }, [_c('div', {
      ref: agent.id,
      refInFor: true
    }, [_c('agent-card', {
      class: {
        'agent-block-active': _vm.agencyIdFromHash === agent.id
      },
      attrs: {
        "agent": agent
      }
    })], 1)])
  })], 2) : _vm._e()])], 1)
},staticRenderFns: []}