import Vue from 'vue'

import { library, dom } from '@fortawesome/fontawesome-svg-core'

import {faUser} from '@fortawesome/pro-regular-svg-icons/faUser'
import {faTimes} from '@fortawesome/pro-solid-svg-icons/faTimes'
import {faTimesCircle} from '@fortawesome/pro-regular-svg-icons/faTimesCircle'
import {faAlignJustify} from '@fortawesome/pro-solid-svg-icons/faAlignJustify'
import {faCamera} from '@fortawesome/pro-solid-svg-icons/faCamera'
import {faChevronDown} from '@fortawesome/pro-regular-svg-icons/faChevronDown'
import {faStar} from '@fortawesome/pro-solid-svg-icons/faStar'
import {faTrash} from '@fortawesome/pro-solid-svg-icons/faTrash'
import {faEdit} from '@fortawesome/pro-solid-svg-icons/faEdit'
import {faArrowLeft} from '@fortawesome/pro-regular-svg-icons/faArrowLeft'
import {faBell} from '@fortawesome/pro-regular-svg-icons/faBell'
import {faFolderOpen} from '@fortawesome/pro-regular-svg-icons/faFolderOpen'
import {faFolder} from '@fortawesome/pro-regular-svg-icons/faFolder'
import {faShieldCheck} from '@fortawesome/pro-regular-svg-icons/faShieldCheck'
import {faTachometerAlt} from '@fortawesome/pro-solid-svg-icons/faTachometerAlt'
import {faCreditCard} from '@fortawesome/pro-regular-svg-icons/faCreditCard'
import {faAddressCard} from '@fortawesome/pro-regular-svg-icons/faAddressCard'
import {faFile} from '@fortawesome/pro-regular-svg-icons/faFile'
import {faSignOut} from '@fortawesome/pro-regular-svg-icons/faSignOut'
import {faCheckCircle} from '@fortawesome/pro-solid-svg-icons/faCheckCircle'
import {faCaretDown} from '@fortawesome/pro-solid-svg-icons/faCaretDown'
import {faBars} from '@fortawesome/pro-regular-svg-icons/faBars'
import {faChevronLeft} from '@fortawesome/pro-regular-svg-icons/faChevronLeft'
import {faHome} from '@fortawesome/pro-regular-svg-icons/faHome'
import {faHome as faHomeSolid} from '@fortawesome/pro-solid-svg-icons/faHome'
import {faMapMarker} from '@fortawesome/pro-regular-svg-icons/faMapMarker'
import {faCloudUpload} from '@fortawesome/pro-regular-svg-icons/faCloudUpload'
import {faEllipsisV} from '@fortawesome/pro-regular-svg-icons/faEllipsisV'
import {faClone} from '@fortawesome/pro-solid-svg-icons/faClone'
import {faCopy} from '@fortawesome/pro-regular-svg-icons/faCopy'
import {faUserCircle} from '@fortawesome/pro-light-svg-icons/faUserCircle'
import {faExclamationTriangle} from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle'
import {faExclamationCircle} from '@fortawesome/pro-light-svg-icons/faExclamationCircle'
import {faPen} from '@fortawesome/pro-solid-svg-icons/faPen'
import {faPlusCircle} from '@fortawesome/pro-light-svg-icons/faPlusCircle'
import {faLayerGroup} from '@fortawesome/pro-light-svg-icons/faLayerGroup'
import {faCcVisa} from '@fortawesome/free-brands-svg-icons/faCcVisa'
import {faCcMastercard} from '@fortawesome/free-brands-svg-icons/faCcMastercard'
import {faCcAmex} from '@fortawesome/free-brands-svg-icons/faCcAmex'

import {faUniversity} from '@fortawesome/pro-solid-svg-icons/faUniversity'

import {faShip} from '@fortawesome/pro-solid-svg-icons/faShip'
import {faShip as faShipLight} from '@fortawesome/pro-light-svg-icons/faShip'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faUser)
library.add(faTimes)
library.add(faTimesCircle)
library.add(faAlignJustify)
library.add(faCamera)
library.add(faChevronDown)
library.add(faArrowLeft)
library.add(faStar)
library.add(faTrash)
library.add(faEdit)
library.add(faBell)
library.add(faTachometerAlt)
library.add(faFolderOpen)
library.add(faFolder)
library.add(faShieldCheck)
library.add(faCreditCard)
library.add(faAddressCard)
library.add(faFile)
library.add(faSignOut)
library.add(faCheckCircle)
library.add(faCaretDown)
library.add(faBars)
library.add(faChevronLeft)
library.add(faUserCircle)
library.add(faHome)
library.add(faHomeSolid)
library.add(faMapMarker)
library.add(faCloudUpload)
library.add(faEllipsisV)
library.add(faClone)
library.add(faCopy)
library.add(faExclamationTriangle)
library.add(faExclamationCircle)
library.add(faPen)
library.add(faPlusCircle)
library.add(faLayerGroup)
library.add(faCcVisa)
library.add(faCcAmex)
library.add(faCcMastercard)
library.add(faUniversity)
library.add(faShip)
library.add(faShipLight)

import '@shared_src/icons/auto-icons.includes'

Vue.component('fa-icon', FontAwesomeIcon)

// This will kick of the initial replacement of i to svg tags and configure a MutationObserver
dom.watch()
