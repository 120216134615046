module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    staticClass: "schedule-preview"
  }, [_c('transition', {
    attrs: {
      "name": "el-fade-in"
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: (_vm.visiblePopover),
      expression: "visiblePopover"
    }],
    staticClass: "schedule-preview__popover"
  }, [_c('div', {
    staticClass: "u-flex-horizontal-space-between schedule-info u-width100 u-mt2 u-mb2"
  }, [_c('span', [_vm._v(_vm._s(_vm.t('payments.payment_schedule')))]), _vm._v(" "), _c('span', {
    staticClass: "schedule-preview__close-icon",
    on: {
      "click": function($event) {
        return _vm.switchPopover(false, $event)
      }
    }
  }, [_c('fa-icon', {
    attrs: {
      "icon": "times",
      "aria-hidden": "true"
    }
  })], 1)]), _vm._v(" "), _vm._l((_vm.selectedBillingScheduleOptions), function(installment) {
    return _c('div', {
      staticClass: "u-mb1"
    }, [_c('div', {
      staticClass: "u-flex-horizontal-space-between u-width100",
      class: {
        'u-danger': installment.isPastDue
      }
    }, [(installment.isPastDue) ? _c('i', {
      staticClass: "material-icons md-text u-warning u-pb1"
    }, [_vm._v("warning")]) : _vm._e(), _vm._v(" "), _c('div', {
      staticClass: "u-pl1"
    }, [_vm._v(_vm._s(installment.dueDate))]), _vm._v(" "), _c('div', {
      staticClass: "u-ml-auto"
    }, [_vm._v("$" + _vm._s(_vm._f("currency")(installment.amountDue, 2)))])])])
  })], 2)]), _vm._v(" "), _c('div', {
    staticClass: "schedule-preview__sign schedule-info",
    on: {
      "click": function($event) {
        return _vm.switchPopover(!_vm.visiblePopover, $event)
      },
      "mouseover": function($event) {
        return _vm.switchPopover(true, $event)
      },
      "mouseleave": function($event) {
        return _vm.switchPopover(false, $event)
      }
    }
  }, [_c('fa-icon', {
    staticClass: "u-primary",
    attrs: {
      "icon": "exclamation-circle"
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "u-pl1"
  }, [_vm._v(_vm._s(_vm.t('payments.payment_schedule')))])], 1)], 1)
},staticRenderFns: []}