<template>
  <el-dialog :title="title"
             :visible="visibleDialogInfo"
             :show-close="isVisibleCloseIcon"
             :close-on-click-modal="false"
             :close-on-press-escape="false"
             custom-class="dialog-info"
             :before-close="handleClose"
  >
    <span class="dialog-info__description" v-html="description"></span>
    <span slot="footer" class="dialog-footer">
      <el-button type="info" @click="getResult('decline')" class="dialog-info__btn u-text-uppercase">{{ t('global.decline') }}</el-button>
      <el-button type="primary" @click="getResult('accept')" class="dialog-info__btn u-text-uppercase">{{ t('global.accept') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
  export default {
    name: 'dialog-info',
    props: {
      visibleDialogInfo: {
        type: Boolean,
        required: true,
      },
      title: {
        type: String,
        required: true
      },
      description: {
        type: String,
        required: true
      },
      isVisibleCloseIcon: {
        type: Boolean,
        default: true
      }
    },
    methods: {
      handleClose (done) {
        this.$emit('handleCloseDialog')
        done()
      },
      getResult (res) {
        this.$emit('userApproved', res)
      }
    }
  }
</script>

<style lang="scss">
  .el-dialog.dialog-info {
    background: #ffffff;
  }
  .dialog-footer {
    display: flex;
    justify-content: space-between;
  }
  .dialog-info {
    width: 400px !important;
    
    .el-dialog__header {
      padding-bottom: 20px;
      border-bottom: none;
    }
    .el-dialog__body {
      padding-top: 0;
    }
    
    &__description {
      word-break: keep-all;
      display: block;
      padding-top: 20px;
      border-top: 2px solid #E5E5E5;
    }
    
    &__btn > span {
      font-size: 16px;
    }
  }
  .el-dialog__headerbtn {
    background: transparent !important;
    &:hover {
      background: transparent !important;
    }
  }
</style>
