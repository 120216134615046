<template>
  <div>
    <el-dialog
        custom-class="payments-dialog font-montseratt"
        :visible.sync="dialogVisible"
        :close-on-press-escape="false"
        @close="handleDialog(false)"
    >
      <div slot="title" class="payments-dialog__title">
        {{ t('payments.make_payment') }}
      </div>
      <div v-if="dialogVisible && checkedPolicies.length">
        <bc-general-bulk-pay
            @handleDialogs="appPaymentMethodDialog"
            @handleMakePaymentsDialog="handleMakePaymentsDialog"
            @reloadPolicies="reloadPolicies"
            @resetCheckedPoliciesList="resetCheckedPoliciesList"
            :policies="checkedPolicies"
            :payOptions="payOptions"
            :activePayType="activePayType"
        />
      </div>
    </el-dialog>
    <ba-add-payment-method-modal :available-insureds="insuredIds"/>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import BcGeneralBulkPay from './general-bulk-pay.vue'
import BaAddPaymentMethodModal from '@/pages/payments-redesign/add-payment-method.modal'

export default {
  name: 'general-payment',
  props: {
    checkedPolicies: {
      type: Array,
      default: () => []
    },
    openPaymentDialog: {
      type: Boolean
    },
    activePayType: {
      type: String,
      required: true
    }
  },
  components: {
    BcGeneralBulkPay,
    BaAddPaymentMethodModal
  },
  computed: {
    ...mapState('common', ['dialogPayData']),
    ...mapGetters('account', ['insuredIds']),
    ...mapGetters('payments', ['payOptions']),
    dialogVisible: {
      get () {
        return this.openPaymentDialog
      },
      set (val) {
        return val
      }
    }
  },
  methods: {
    ...mapMutations('common', ['handlePayDialog']),
    handleDialog (val) {
      this.$emit('handleMakePaymentsDialog', val)
    },
    resetCheckedPoliciesList () {
      this.$emit('resetCheckedPoliciesList')
    },
    handleMakePaymentsDialog (val) {
      this.handleDialog(val)
      this.handlePayDialog({
        isDialogVisible: val,
      })
    },
    appPaymentMethodDialog () {
      this.handleDialog(false)
      this.handleMakePaymentsDialog(true)
    },
    reloadPolicies () {
      this.loading = true
      this.$emit('reloadPoliciesInProgress', true)
      this.$store.dispatch('policies/reload')
        .finally(() => {
          this.loading = false
          this.$emit('reloadPoliciesInProgress', false)
        })
    }
  }
}
</script>
