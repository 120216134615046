module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', [_c('div', {
    staticClass: "u-mt1 u-mb4",
    domProps: {
      "innerHTML": _vm._s(_vm.t('enrollment.add_missing_policy_search_policy_step_text', {
        carrier_name: _vm.primaryCarrier.name
      }))
    }
  }), _vm._v(" "), _c('el-input', {
    attrs: {
      "type": "text"
    },
    model: {
      value: (_vm.internalValue),
      callback: function($$v) {
        _vm.internalValue = $$v
      },
      expression: "internalValue"
    }
  }, [_vm._v(_vm._s(_vm.t('enrollment.policy_number_input_label')))])], 1)
},staticRenderFns: []}