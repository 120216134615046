<template>
  <div class="init-screen">
    <init-sidebar/>
    <div class="init-screen__info">
      <div class="init-block">
        <div v-loading="loading"></div>
        <div v-if="message.length > 0" class="u-mt4 u-text-center mx-auto">{{message}}</div>
      </div>
    </div>
    <div class="init-screen__logo-language">
      <bc-footer bgClass="bg-login"></bc-footer>
      <div class="init-screen__bc-language" v-if="displayLanguageLink">
        <ba-language-selector/>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapActions, mapMutations} from 'vuex'
  import initSidebar from '../components/init-sidebar'
  import Footer from '../components/footer'
  import BaLanguageSelector from '@/components/language-selector.component'
  import {storeLoginTime} from '@shared_src/utils/timezone.utils'

  export default {
    components: {
      initSidebar,
      'bc-footer': Footer,
      BaLanguageSelector,
    },
    data () {
      let token = this.$route.query && this.$route.query.token
        ? this.$route.query.token : null
      return {
        loading: true,
        token: token,
        message: 'One moment please',
      }
    },
    mounted () {
      this.login()
    },
    methods: {
      ...mapActions('login', ['authByToken']),
      ...mapActions(['loadUserData']),
      ...mapActions('common', ['savePreferredLanguage']),
      ...mapActions('documents', ['getSecretKey']),
      ...mapMutations('login', ['setUsernameToForceUpdatePassword']),
      setIdle () {
        this.loading = false
      },
      login () {
        if (!this.token) {
          this.$router.push('/login')
        }

        let payload = {
          token: this.token,
        }
        this.authByToken(payload).then(() => {
          localStorage.removeItem('login__token-expired')
          localStorage.removeItem('last_location')
          localStorage.removeItem('last_user')
          storeLoginTime()
          this.getSecretKey().then(this.loadUserData).then(() => {
            this.setIdle()
            this.savePreferredLanguage(null)
            if (this.tos) {
              this.$router.push('/enrollment/terms-of-service/0')
              return
            }
            let destinationLink = '/dashboard'
            this.$router.push(destinationLink)
          })
        }).catch((response) => {
          this.setIdle()
          if (response.data && response.data.cause) {
            if (response.data.cause === 'account.requires_password_reset') {
              this.setUsernameToForceUpdatePassword(response.data.username)
              this.$router.push('/password-update-required?show_password=0')
              return
            }
            this.message = this.causeToString(response.data.cause)
          } else {
            this.message = this.t('login.login_failed')
          }
        })
      }
    },
    computed: {
      displayLanguageLink () {
        return this.setting('global.display_spanish_link') === true
      },
    }
  }
</script>
