module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: (_vm.loading),
      expression: "loading"
    }],
    class: {
      'u-mt4': _vm.loading
    }
  }, [(_vm.claim) ? _c('div', {
    staticClass: "claim-block"
  }, [_c('div', {
    staticClass: "claim-description u-mb4"
  }, [_c('div', {
    staticClass: "claim-description__main"
  }, [_c('div', {
    staticClass: "claim-description__title"
  }, [_c('h1', [_vm._v(_vm._s(_vm.t('claims.claim_#', {
    number: _vm.claimDataFromList.claim_number
  })))]), _vm._v(" "), _c('div', [_c('div', [_c('span', {
    staticClass: "u-pr1"
  }, [_vm._v(_vm._s(_vm.t('claims.loss_date_title')) + ":")]), _vm._v(" "), _c('span', [_vm._v(_vm._s(_vm._f("dateFormatWithTimeZone")(_vm.claim.loss_date, 'MM-DD-YYYY hh:mm A z', _vm.claim.loss_timezone)))])])])]), _vm._v(" "), _c('div', {
    staticClass: "claim-description__title_subtitle"
  }, [_vm._v("\n          " + _vm._s(_vm.t('claims.policy_select_prefix')) + _vm._s(_vm.claim.policy_number) + "\n        ")])]), _vm._v(" "), _c('el-row', {
    attrs: {
      "gutter": 20
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "md": 24
    }
  }, [(_vm.claim.preferred_phone) ? _c('div', [_c('h3', {
    staticClass: "subheader"
  }, [_vm._v(_vm._s(_vm.t('global.phone_number')))]), _vm._v(" "), _c('div', {
    staticClass: "claim-description__content"
  }, [_c('bc-phone-national-format', {
    attrs: {
      "phone": _vm.claim.preferred_phone
    }
  })], 1)]) : _vm._e()]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "md": 12
    }
  }, [_c('h3', {
    staticClass: "subheader"
  }, [_vm._v(_vm._s(_vm.riskLabel))]), _vm._v(" "), _vm._l((_vm.claim.risks), function(risk) {
    return _c('div', {
      key: risk.id,
      staticClass: "claim-description__content"
    }, [_vm._v("\n            " + _vm._s(risk.name) + "\n          ")])
  })], 2), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "md": 12
    }
  }, [_c('h3', {
    staticClass: "subheader"
  }, [_vm._v(_vm._s(_vm.t('claims.loss_description_title')))]), _vm._v(" "), _c('div', {
    staticClass: "claim-description__content claim-description__content_scroll"
  }, [_vm._v(_vm._s(_vm.claim.loss_description))])])], 1)], 1), _vm._v(" "), _c('div', {
    staticClass: "u-mb4"
  }, [(!_vm.hideClaimsLossPhotos) ? _c('bc-claim-photo-preview', {
    attrs: {
      "photos": _vm.claim.photos
    },
    on: {
      "reloadClaimImage": _vm.loadClaim
    }
  }) : _vm._e()], 1), _vm._v(" "), (!_vm.hideClaimsAdjusters) ? _c('div', [_c('h2', {
    staticClass: "secondary-title u-mb3"
  }, [_vm._v("\n        " + _vm._s(_vm.t('claims.adjusters_section_title')) + "\n        "), (_vm.claim.adjusters && _vm.claim.adjusters.length > 0) ? _c('span', [_vm._v("(" + _vm._s(_vm.claim.adjusters.length) + ")")]) : _vm._e()]), _vm._v(" "), (_vm.claim.adjusters.length) ? _c('div', _vm._l((_vm.claim.adjusters), function(adjuster) {
    return _c('div', {
      key: adjuster.id,
      staticClass: "claim-adjusters u-mb4"
    }, [_c('bc-adjusters-list', {
      attrs: {
        "name": adjuster.name,
        "imageData": {
          photoId: adjuster.photo_id
        },
        "contact": adjuster
      }
    })], 1)
  }), 0) : _c('div', {
    staticClass: "claim-adjusters u-mb4"
  }, [(!_vm.claim.adjusters.length) ? _c('div', {
    staticClass: "u-mt2 u-mb4"
  }, [_vm._v("\n          " + _vm._s(_vm.t('claims.claim_no_adjusters_yet_text')) + "\n        ")]) : _vm._e()])]) : _vm._e()]) : _vm._e()])
},staticRenderFns: []}