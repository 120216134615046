<template>
  <div class="payment-method-icon">
    <div v-if="paymentMethod.type != 'Credit Card'">
      <i class="material-icons md-black md-48">account_balance</i>
    </div>
    <div v-else>
      <div v-bind:data-card-type="paymentTypeName"></div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'bc-payment-method-icon',
  props: ['paymentMethod'],
  computed: {
    paymentTypeName () {
      if (this.paymentMethod.type === 'Credit Card') {
        let name = this.paymentMethod.name
        return name
      }
      return 'attach_money'
    },
  },
}
</script>
