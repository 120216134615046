module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    staticClass: "submenu"
  }, [(_vm.claims) ? _c('div', {
    staticClass: "submenu__menu-outer"
  }, [_c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.createNewClaim
    }
  }, [_vm._v("\n      " + _vm._s(_vm.t('claims.file_new_claim')) + "\n    ")]), _vm._v(" "), _c('div', {
    staticClass: "submenu__menu-outer_scrolling"
  }, _vm._l((_vm.claimTypes), function(type, i) {
    return _c('div', {
      attrs: {
        "index": i
      }
    }, [(_vm.sortClaimsByDesc[("" + type)].length > 0) ? _c('bc-claims-submenu', {
      attrs: {
        "claimsList": _vm.sortClaimsByDesc[("" + type)],
        "type": type
      }
    }) : _vm._e()], 1)
  }), 0)], 1) : _vm._e(), _vm._v(" "), _c('el-dialog', {
    attrs: {
      "visible": _vm.dialogClaimVisible,
      "custom-class": "file-claim-dialog",
      "show-close": true,
      "close-on-click-modal": false,
      "close-on-press-escape": false,
      "before-close": _vm.closeClaimDialog
    },
    on: {
      "update:visible": function($event) {
        _vm.dialogClaimVisible = $event
      }
    }
  }, [(_vm.dialogClaimVisible) ? _c('bc-file-claims-view', {
    ref: "fileClaimView",
    attrs: {
      "openClaimDialog": ""
    },
    on: {
      "closeClaimDialog": _vm.closeClaimDialog
    }
  }) : _vm._e()], 1), _vm._v(" "), _c('phone-number-dialog'), _vm._v(" "), _c('div', {
    staticClass: "submenu__view"
  }, [_c('router-view', {
    key: _vm.$route.fullPath
  })], 1)], 1)
},staticRenderFns: []}