module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    staticClass: "pages"
  }, [_c('bc-navbar', {
    attrs: {
      "title": _vm.navbarTitle,
      "backLink": "",
      "showLogo": ""
    }
  }), _vm._v(" "), _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: (_vm.loading),
      expression: "loading"
    }],
    staticClass: "dashboard__info"
  }, [(_vm.hasAtLeastOnePolicy) ? _c('bc-payment-card', {
    staticClass: "dashboard__outer-card card-group",
    class: _vm.customBlockStretch
  }) : _vm._e(), _vm._v(" "), (_vm.claimsFeatureEnabled && _vm.hasAtLeastOnePolicy) ? _c('bc-claim-card', {
    staticClass: "dashboard__outer-card card-group",
    class: _vm.customBlockStretch
  }) : _vm._e(), _vm._v(" "), _c('bc-agency-card', {
    staticClass: "dashboard__outer-card card-group u-flex-vertical-space-between",
    class: _vm.customBlockStretch
  })], 1), _vm._v(" "), _c('bc-policy-card', {
    staticClass: "dashboard__outer-card"
  })], 1)
},staticRenderFns: []}