module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return (_vm.policies) ? _c('div', {
    staticClass: "dashboard__outer-card u-mt3",
    class: {
      'card--has-photo': _vm.singlePolicyPhotoId
    }
  }, [_c('div', {
    staticClass: "el-card__header"
  }, [_vm._v(_vm._s(_vm.t('global.policies_header')))]), _vm._v(" "), (!_vm.hasAtLeastOnePolicy) ? _c('div', [_c('h2', {
    staticClass: "subheader u-mb1",
    domProps: {
      "innerHTML": _vm._s(_vm.t('enrollment.you_have_no_active_policies'))
    }
  })]) : (_vm.policies.policies.length === 1) ? _c('div', [_c('router-link', {
    attrs: {
      "to": {
        path: '/policies/' + _vm.policies.policies[0].id
      },
      "data-test-id": "policy-link"
    }
  }, [_c('h2', [_vm._v(_vm._s(_vm.policies.policies[0].policy_type))]), _vm._v(" "), _c('h3', {
    staticClass: "subheader u-mb2"
  }, [_c('span', [_vm._v(_vm._s(_vm.t('payments.policy_#', {
    number: _vm.policies.policies[0].policy_number
  })))]), _vm._v(" "), _c('span', {
    class: _vm.setPolicyClassByStatus(_vm.policies.policies[0])
  }, [_vm._v("(" + _vm._s(_vm.policies.policies[0].policy_status) + ")")])])]), _vm._v(" "), (_vm.singlePolicyPhotoId) ? _c('div', [_c('bc-general-image', {
    staticClass: "image-card__picture",
    attrs: {
      "fileId": _vm.singlePolicyPhotoId
    }
  })], 1) : _vm._e()], 1) : (_vm.policies.policies.length < 5) ? _c('div', _vm._l((_vm.policies.policies), function(policy, index) {
    return _c('div', {
      key: policy.id,
      class: {
        'u-mt2': index !== 0
      }
    }, [_c('router-link', {
      attrs: {
        "to": {
          path: '/policies/' + policy.id
        },
        "data-test-id": 'policy-link-' + index
      }
    }, [_c('h2', [_vm._v(_vm._s(_vm.policyHeadline(policy)))]), _vm._v(" "), _c('h3', {
      staticClass: "subheader"
    }, [_c('span', [_vm._v(_vm._s(_vm.t('payments.policy_#', {
      number: policy.policy_number
    })))]), _vm._v(" "), _c('span', {
      class: _vm.setPolicyClassByStatus(policy)
    }, [_vm._v("(" + _vm._s(policy.policy_status) + ")")])])])], 1)
  }), 0) : (_vm.policies.policies.length >= 5) ? _c('div', [_c('div', {
    staticClass: "list-block no-hairlines-between"
  }, [_c('router-link', {
    staticClass: "item-link item-content",
    attrs: {
      "to": {
        path: _vm.linkForPolicies
      },
      "data-test-id": "policy-link"
    }
  }, [_c('div', {
    staticClass: "item-inner"
  }, [_c('h2', {
    staticClass: "u-mb0 u-mt0"
  }, [_vm._v("\n            " + _vm._s(_vm.t('payments.active_policies', {
    number: _vm.policiesListByType.active.length
  })) + "\n          ")])]), _vm._v(" "), (_vm.policiesListByType.canceled.length) ? _c('div', {
    staticClass: "policy-canceled-type subheader u-mt1 u-mb1"
  }, [_vm._v("\n          " + _vm._s(_vm.t('policies.canceled_policies', {
    number: _vm.policiesListByType.canceled.length
  })) + "\n        ")]) : _vm._e()])], 1)]) : _vm._e(), _vm._v(" "), _c('ba-add-missing-policy', {
    staticClass: "u-mt4 u-mb2"
  })], 1) : _vm._e()
},staticRenderFns: []}