// @flow
/* eslint-disable */

export class PolicyTerm {
  auto_payment_method_id: ?string
}

export class Policy {
  id: string
  agency_contact_id: string
  agent_contact_id: string
  current_term: PolicyTerm
  isGen3: boolean
  policy_number: string
}
export class PaymentMethod {
  id: string
  insured_id: string
  label: string
  // Credit Card | Personal checking
  type: string
  // MasterCard | Visa
  name: string
}

export class RiskField {
  label: string
  // array, boolean, primitive
  show_as: string
  value: any
}

export class SingleRisk {
  /*
    For example: 2020 Ford F150
  */
  name: string
  /*
    For example: vehicle, trailer
  */
  type: string
  fields: Array<RiskField>
}

export class RiskSection {
  component: string
  heading: string
  items: Array<SingleRisk>

}