<template>
  <nav>
    <div class="c-header__upper-nav">
      <div class="c-header__nav">
        <div v-if="isFeatureEnabled" @click="hideMobile" class="c-notification">
          <el-popover
            placement="bottom"
            v-model="visibleMessageDialog"
            popper-class="popover-message-center"
          >
            <div class="account-link">
              <bc-message-center v-if="visibleMessageDialog"/>
            </div>
            <el-button slot="reference" :type="getButtonType" class="c-header__nav-link el-button--transparent u-no-border">
              <fa-icon :icon="['far', 'bell']" />
              <div v-if="badge || badge > 0" class="c-notification__badge u-flex-vertical-center">
                <span>{{ badge }}</span>
              </div>
            </el-button>
          </el-popover>
        </div>
        <div class="c-account">
          <el-popover
            placement="top"
            width="200"
            v-model="visibleAccountDialog"
            popper-class="popover-account"
          >
            <div>
              <div @click="hideMobile" class="account-link">
                <router-link class="u-text--uppercase" active-class="account-link__active" :to="'/account/settings'">
                  {{ t('dashboard.view_account') }}
                </router-link>
              </div>
              <div @click="logOutClicked" class="account-link">
                <a class="u-text--uppercase">
                  {{ t('global.logout') }}
                </a>
              </div>
            </div>
            <el-button slot="reference" :type="getButtonType" class="c-header__nav-link el-button--transparent u-no-border" :class="isActiveAccountView">
              <fa-icon :icon="['far', 'user']" />
              <fa-icon :icon="['far', 'chevron-down']" :class="[visibleAccountDialog ? 'u-rotate-180' : '', 'link-icon-small'] " />
            </el-button>
          </el-popover>
        </div>
      </div>
    </div>
    <div class="toggle-icon">
      <a class="toggle-icon__link" @click="toggleMobMenu">
        <fa-icon :icon="isToggled ? ['fal', 'times'] : ['far', 'bars']" aria-hidden="true"></fa-icon>
      </a>
    </div>
  </nav>
</template>

<script>
  import MessageCenter from '../pages/message-center/message-center.page'
  
  export default {
    name: 'top-navigation',
    props: {
      isFeatureEnabled: {
        type: Boolean
      },
      isToggled: {
        type: Boolean
      },
      badge: {
        type: [Number, Object, String],
        required: false,
      }
    },
    components: {
      'bc-message-center': MessageCenter,
    },
    watch: {
      $route (to, from) {
        this.visibleMessageDialog = false
      }
    },
    data () {
      return {
        visibleAccountDialog: false,
        visibleMessageDialog: false,
      }
    },
    computed: {
      getButtonType () {
        return window.innerWidth > 1024 ? '' : 'navigation'
      },
      isActiveAccountView () {
        return this.$route.fullPath.includes('/account') ? 'sidenav__current' : ''
      }
    },
    methods: {
      toggleMobMenu () {
        this.$emit('toggleMobMenu')
      },
      hideMobile () {
        this.visibleAccountDialog = false
        this.$emit('hideMobile')
      },
      logOutClicked () {
        this.$emit('logOut')
      }
    }
  }
</script>

<style scoped lang="scss">
  @import '../sass/_variables.scss';
  
  .c-notification {
    position: relative;
    border-right: 2px solid transparentize($color-text--disabled, .3);
    
    @media only screen and (max-width: $s-screen) {
      border-right: none;
    }
    
    &__badge {
      position: absolute;
      bottom: 18px;
      left: 30px;
      height: 22px;
      width: 22px;
      padding: 1px;
      border-radius: 10px;
      background: $color-danger;
      color: $color-white;
      font-weight: 400;
      line-height: 18px;
      font-size: 15px;
    }
  }
  .c-account {
    display: block;
    
    @media only screen and (max-width: $s-screen) {
      display: none;
    }
  }
</style>

<style lang="scss">
  @import '../sass/_variables.scss';
  
  .popover-account {
    border: 2px solid $color-text--disabled;
    border-radius: 0;
    color: #626262;
    padding: 0 !important;
    .account-link {
      &:not(last-of-type) {
        border-bottom: 2px solid transparentize($color-text--disabled, .3);
      }
      & > a {
        display: inline-block;
        color: $color-text--secondary;
        font-size: 14px;
        line-height: 20px;
        padding: 10px 15px;
        &:hover {
          text-decoration: none;
        }
      }
      & > a.account-link__active {
        color: $color-primary;
      }
    }
  }
  .c-header__nav-link {
    svg.link-icon-small {
      width: 14px;
      position: relative;
      top: 2px;
      right: 3px;
    }
    svg.link-icon-medium {
      width: 20px;
      font-size: 20px;
    }
  }
  
  .popover-message-center {
    width: 510px;
    max-height: 400px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    padding: 0 !important;
    border-radius: 0 !important;
    border: 2px solid transparentize($color-text--disabled, .3) !important;
    
    @media only screen and (max-width: $s-screen) {
      width: 97%;
    }
  }

  body.body #app {
    .toggle-icon {
      display: none;

      .toggle-icon__link {
        display: inline-block;
        width: 32px;
        height: 32px;
        line-height: 32px;
        text-align: center;
      }

      @media only screen and (max-width: $l-screen) {
        display: block;
        z-index: 11;
        position: absolute;
        top: 30px;
        left: 45px;
      }
    
      @media only screen and (max-width: $s-screen) {
        top: 15px;
        left: 1em;
      }
      
      svg {
        font-size: 23px;
        path {
          fill: $color-text--secondary;
        }
      }
    }
  }
  
</style>
