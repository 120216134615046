<template>
  <div class="pages">
    <bc-navbar :title="navbarTitle" backLink showLogo></bc-navbar>

    <div class="dashboard__info" v-loading="loading">
      <!--Payments block-->
      <bc-payment-card
          v-if="hasAtLeastOnePolicy"
          class="dashboard__outer-card card-group"
          :class="customBlockStretch"
      />
      <!-- Claims block -->
      <bc-claim-card
        v-if="claimsFeatureEnabled && hasAtLeastOnePolicy"
        class="dashboard__outer-card card-group"
        :class="customBlockStretch"
      />
      <!-- Agency block -->
      <bc-agency-card
        class="dashboard__outer-card card-group u-flex-vertical-space-between"
        :class="customBlockStretch"
      />
    </div>

    <!-- Policy block-->
    <bc-policy-card
      class="dashboard__outer-card"
    />
  </div>
</template>

<script>

import {mapActions, mapState, mapGetters} from 'vuex'

import BcFileClaimsView from '@/pages/claims/file-claim.component'
import BaAddMissingPolicy from '@/pages/add-missing-policy/add-missing-policy.modal'
import getBrowserInfo from '@shared_src/browser-info'

import ClaimCard from '@/pages/dashboard/claim-card'
import AgencyCard from '@/pages/dashboard/agency-card'
import PolicyCard from '@/pages/dashboard/policy-card'
import PaymentCard from '@/pages/dashboard/payment-card'

export default {
  name: 'Dashboard',
  components: {
    BaAddMissingPolicy,
    'bc-agency-card': AgencyCard,
    'bc-file-claims-view': BcFileClaimsView,
    'bc-claim-card': ClaimCard,
    'bc-policy-card': PolicyCard,
    'bc-payment-card': PaymentCard,
  },
  watch: {
    loading () {
      this.getBlockCount()
    }
  },
  mounted () {
    this.getBlockCount()
    this.logInsuredAction('Get User Browser Info', 'custom_user_browser_info', {
      browser_name: getBrowserInfo().name,
      browser_version: getBrowserInfo().version,
      browser_language: navigator.language,
      device_type: 'Desktop',
    })
  },
  data () {
    return {
      visibleBlocks: 0,
    }
  },
  methods: {
    ...mapActions(['loadUserData']),
    getBlockCount () {
      this.visibleBlocks = document.getElementsByClassName('card-group').length
    }
  },
  computed: {
    ...mapState(['loading']),
    ...mapGetters('policies', ['hasAtLeastOnePolicy']),
    customBlockStretch () {
      return `u-width__${this.visibleBlocks}-col`
    },
    navbarTitle () {
      return this.loading ? this.t('global.loading') : this.t('global.welcome')
    },
    claimsFeatureEnabled () {
      return this.setting('claims.claims_enabled')
    },
  },
}
</script>
