/* eslint-disable */
import axios from 'axios'

const passwordResetModule = {
  namespaced: true,
  state: {
    unauthorizedUser: {},
  },
  mutations: {
    setUnauthorizedUser: function (state, payload) {
      state.unauthorizedUser = payload
    },
  },
  actions: {
  },
  getters: {
  }
}

export default passwordResetModule
