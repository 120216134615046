<template>
  <div>
    <el-dialog
        :visible.sync="state.isDialogVisible"
        :before-close="closeDialog"
        :close-on-press-escape="false"
    >
      <div slot="title" class="el-dialog__title_border">
        <el-button v-if="canGoBack"
                   @click="back" class="u-mr3"
                   :icon="['far', 'chevron-left']"
                   type="text">
        </el-button>
        <div class="el-dialog__title">
          {{ getPaymentTabs() }}
        </div>
      </div>
      <div>
        <div class="js-select-insured" v-if="workflow.page === 'select-insured'">
          <div class="u-mt2 u-mb4">{{t('payments.select_insured_for_payment_method')}}</div>
          <div class="js-single-insured u-mt2" v-for="ins in newPaymentMethodOwners" >
            <el-radio :label="ins" v-model="state.selectedInsured">
              {{ accountName(ins) }}
            </el-radio>
          </div>
        </div>
        <r-ba-add-payment-method @paymentMethodAdded="closeDialog" v-if="workflow.page === 'add-card'" :insured-id="state.selectedInsured"
                                 ref="addPayMethod"></r-ba-add-payment-method>


      </div>
      <div v-if="workflow.page !== 'success' || (workflow.page === 'success' && !state.loading)"
           slot="footer" class="u-flex-justify-content-end">
        <el-button v-loading="state.loading"  @click="next" :disabled="disabledNextStep" v-if="!canGoNext" type="primary" class="u-text-capitalize">
          {{ t('global.next') }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {computed, getCurrentInstance, reactive, onMounted} from '@vue/composition-api'
import NewAddPaymentMethod from './payment-methods/r-add-payment-method.component'
import {mapGetters, mapState, mapMutations} from 'vuex'

export default {
  name: 'ba-add-payment-method',
  props: ['availableInsureds'],
  components: {
    'r-ba-add-payment-method': NewAddPaymentMethod,
  },
  computed: {
    ...mapGetters('account', ['account']),
    ...mapState('common', ['contacts', 'dialogPayData']),
    newPaymentMethodOwners () {
      return this.dialogPayData.customInsureds || this.availableInsureds
    }
  },
  watch: {
    newPaymentMethodOwners: {
      handler (val, oldVal) {
        this.initDialogPageState()
      },
      immediate: true
    },
    isDialogVisible: {
      handler (val, oldVal) {
        this.initDialogPageState()
      },
      immediate: true
    },
  },
  methods: {
    ...mapMutations('common', ['handlePayDialog']),
    /*
      this is helper function to translate insured_id to account name
    */
    accountName (insuredId: string) {
      let ins = this.account.insureds[insuredId]
      if (!ins) {
        return insuredId
      }
      let contactId = ins.contact_id
      let result
      this.contacts.forEach(_ => {
        if (_.id === contactId) {
          result = _
        }
      })

      if (result) {
        return result.name
      }
      return insuredId
    },
    closeDialog () {
      return this.handlePayDialog({
        isDialogVisible: false,
        currentSubMenu: ''
      })
    },
    getPaymentTabs () {
      if (!this.$refs.addPayMethod) return this.t('payments.add_payment_method_title')
      let availableTabsAmount = this.$refs.addPayMethod.getAvailableTabsLength()
      return availableTabsAmount > 0 ? this.t('payments.add_payment_method_title') : this.t('global.error')
    }
  },
  setup (props, { root }) {
    const Step1SelectInsured = 'select-insured'
    const Step2AddCard = 'add-card'

    let self = getCurrentInstance().proxy

    onMounted(() => {
      initDialogPageState()
    })

    const workflow = reactive({
      // select-insured -> add-card
      page: Step1SelectInsured,
    })

    const isDialogVisible = computed(() => {
      return root.$store.state.common.dialogPayData.isDialogVisible
    })

    const state = reactive({
      selectedInsured: null,
      isDialogVisible,
      loading: false,
    })

    function initDialogPageState () {
      if (self.newPaymentMethodOwners.length > 1) {
        workflow.page = Step1SelectInsured
      } else {
        state.selectedInsured = self.newPaymentMethodOwners[0]
        workflow.page = Step2AddCard
      }
    }
    const canGoBack = computed(_canGoBack)
    const canGoNext = computed(_canGoNext)
    const disabledNextStep = computed(_disabledNextStep)

    function _canGoBack () {
      if (workflow.page === Step1SelectInsured || self.newPaymentMethodOwners.length === 1) {
        return false
      }

      return true
    }

    function _disabledNextStep () {
      if (workflow.page === Step1SelectInsured && !state.selectedInsured) {
        return true
      }
      return false
    }

    function _canGoNext () {
      if (workflow.page === Step1SelectInsured) {
        return false
      }

      return true
    }
    function back () {
      workflow.page = Step1SelectInsured
    }
    function next () {
      workflow.page = Step2AddCard
    }

    return {
      initDialogPageState,
      state,
      workflow,
      back,
      next,
      canGoBack,
      canGoNext,
      disabledNextStep
    }
  }
}
</script>
