<template>
  <div>
    <div v-if="field.label != null" class="u-color-secondary u-no-wrap u-mt1">{{ t(field.label) }}</div>
    <div class="u-mt2">{{field.value}}</div>
  </div>
</template>
<script>
  export default {
    name: 'ba-primitive-field',
    props: ['field'],
  }
</script>
