<template>
  <div>
    <div v-if="openClaims" class="u-height100">
      <div class="claims-card dashboard__outer-card_info">
        <div class="el-card__header">{{ t('dashboard.claims') }}</div>
        <div class="u-flex-vertical-space-between u-height100">
          <router-link v-if="openClaims.length === 1" :to="{ path: linkForClaims(openClaims) }" data-test-id="claims-link">
            <h2 class="u-mt0 u-mb5">{{ openClaims[0].date_added | dateFormat('M/DD/YYYY') }} {{ t('claims.claim') }}</h2>
            <h3 class="claims-card__open-claim subheader u-mt2">
              {{ t('claims.claim_#', { number: openClaims[0].claim_number }) }}</h3>
          </router-link>
          <router-link v-else-if="openClaims.length > 1" :to="{ path: linkForClaims(openClaims) }" data-test-id="claims-link">
            <h2 class="u-mt0 u-mb5">{{ t('claims.multiple_open_claims') }}</h2>
          </router-link>
          <div v-else>
            <h2 class="u-mt0 u-mb5">{{ t('claims.no_claims')}}</h2>
          </div>
          <div v-if="hasOneActivePolicy" class="claims-card__links">
            <el-button @click="fileNewClaim" type="info" class="u-text-capitalize u-width100">
              {{ t('claims.file_new_claim') }}
            </el-button>
          </div>
        </div>
      </div>
    </div>

    <el-dialog :visible.sync="dialogClaimVisible"
               custom-class="file-claim-dialog"
               :show-close="true"
               :close-on-click-modal="false"
               :close-on-press-escape="false"
    >
      <bc-file-claims-view
        v-if="dialogClaimVisible"
        ref="fileClaimView"
        @closeClaimDialog="dialogClaimVisible = false"
        openClaimDialog/>
    </el-dialog>
  
    <!-- Dialog Add New Phone Number -->
    <phone-number-dialog />
    
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import BcFileClaimsView from '@/pages/claims/file-claim.component'
  import _ from 'lodash'
  import PhoneNumberDialog from '@/components/new-phone-number.dialog.vue'
  
  export default {
    name: 'claim-card',
    components: {
      'bc-file-claims-view': BcFileClaimsView,
      PhoneNumberDialog
    },
    data () {
      return {
        dialogClaimVisible: false
      }
    },
    computed: {
      ...mapGetters('claims', ['openClaims', 'closedClaims']),
      ...mapGetters('policies', ['hasOneActivePolicy']),
    },
    methods: {
      linkForClaims (claimCollection) {
        if (claimCollection && claimCollection.length > 0) {
          let newestClaim = _.orderBy(claimCollection, ['date_added'], ['desc'])
          return `/claims/${newestClaim[0].id}`
        }
        return 'claims/new/undefined'
      },
      fileNewClaim () {
        if (this.openClaims && this.openClaims.length > 0) {
          this.dialogClaimVisible = true
        } else {
          this.$router.push('/claims/new/undefined')
        }
      }
    }
  }
</script>

