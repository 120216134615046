var Component = require("!../../node_modules/vue-loader/lib/component-normalizer")(
  /* script */
  require("!!babel-loader!../../node_modules/vue-loader/lib/selector?type=script&index=0!./login_token.vue"),
  /* template */
  require("!!../../node_modules/vue-loader/lib/template-compiler/index?{\"id\":\"data-v-6c4113eb\"}!../../node_modules/vue-loader/lib/selector?type=template&index=0!./login_token.vue"),
  /* scopeId */
  null,
  /* cssModules */
  null
)

module.exports = Component.exports
