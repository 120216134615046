<template>
  <div v-loading="loading">
    <!-- Document Preferences -->
    <div class="u-mt4 u-mb4">
      <h2 class="secondary-header">{{ t('enrollment.document_preferences') }}</h2>
    </div>
    <div>
      <el-radio-group v-model="eDocumentsEnabled">
        <div>
          <label role="radio" class="el-radio u-mt3">
                <span class="el-radio__input" :class="eDocumentsEnabled ?'is-checked':''">
                  <span class="el-radio__inner"></span>
                  <input type="radio" class="el-radio__original" :checked='eDocumentsEnabled' @click="onDocDeliveryUpdate(true)">
                </span>
            <span class="el-radio__label u-text-capitalize">{{ t('enrollment.go_paperless') }}*</span>
          </label>
        </div>
        <div>
          <el-radio class="u-mt3" :label="false" :checked='!eDocumentsEnabled' @change="onDocDeliveryUpdate(false)">
            {{ t('enrollment.receive_paper_documents')  }}
          </el-radio>
        </div>
      </el-radio-group>
      <div class="subheader u-mt4 u-mb0">
        {{ t('enrollment.documents_will_always_be_available_from_the_app') }}
      </div>
      <div class="subheader u-mt2 u-mb0">
        *{{ t('enrollment.you_will_receive_an_email_when_documents_are_ready_to_be_viewed') }}
      </div>
    </div>
  
    <dialog-info
      :visibleDialogInfo="visibleDialogInfo"
      @userApproved="userApprovedDeliveryType"
      @handleCloseDialog="handleCloseDialog"
      :title="t('e_delivery.acknowledge_info_title')"
      :description="t('e_delivery.acknowledge_info')"
    ></dialog-info>
    
  </div>
</template>

<script>
  import {mapState, mapGetters} from 'vuex'
  import DialogInfo from '@/components/dialog-info.modal'

  export default {
    name: 'document-delivery',
    components: {
      DialogInfo,
    },
    data () {
      return {
        loading: false,
        eDocumentsFixedState: null,
        visibleDialogInfo: false,
      }
    },
    computed: {
      ...mapState('account', ['accountData']),
      ...mapGetters('account', ['account']),
      eDocumentsEnabled () {
        // check if e-delivery enabled using BA(user) data
        return this.eDocumentsFixedState == null ? !!(this.account.edelivery) : this.eDocumentsFixedState
      },
      isEnabledElectronicDeliveryOption () {
        return this.setting('global.electronic_delivery_option')
      },
    },
    methods: {
      handleCloseDialog () {
        this.visibleDialogInfo = false
      },
      userApprovedDeliveryType (value) {
        this.handleCloseDialog()
        value === 'accept' ? this.docDeliveryByEmail(true) : false
      },
      onDocDeliveryUpdate (value) {
        if (!this.isEnabledElectronicDeliveryOption) return
        if (value && this.setting('e_delivery.e_delivery_acknowledgement')) {
          this.visibleDialogInfo = true
          return
        }
        return this.docDeliveryByEmail(value)
      },
      docDeliveryByEmail (value) {
        this.loading = true
        // store old state. New state will be applied once data reloaded and eDocumentsFixedState set to null
        this.eDocumentsFixedState = this.eDocumentsEnabled
        this.$store.dispatch('account/updateDeliveryType', value).then(() => {
          this.$store.dispatch('account/loadAccount').then(() => {
            this.eDocumentsFixedState = null
            this.loading = false
          })
        }).catch((e) => {
          this.eDocumentsFixedState = null
          this.loading = false
          this.$elAlert(this.t('account.error_saving_notification_settings'))
        })
      }
    },
  }
</script>

<style scoped>

</style>
