module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_c('div', {
    staticClass: "card u-p3"
  }, [_c('div', {
    staticClass: "card-content"
  }, [_c('div', {
    staticClass: "notifications-list"
  }, [_c('div', {
    staticClass: "u-pr2 u-mt1"
  }, [_c('fa-icon', {
    staticClass: "check-circle",
    class: _vm.type === 'new' ? 'check-circle--active' : '',
    attrs: {
      "icon": ['fa', 'check-circle']
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "u-width100"
  }, [_c('div', {
    staticClass: "notifications-list__info u-mb1"
  }, [_c('div', {
    staticClass: "notifications-list__title u-pr2"
  }, [_vm._v(_vm._s(_vm.message.title))]), _vm._v(" "), _c('div', {
    staticClass: "notifications-list__time"
  }, [_vm._v(_vm._s(_vm.showTime(_vm.message.created_at)))]), _vm._v(" "), _c('div', {
    staticClass: "notifications-list__dismiss",
    on: {
      "click": function($event) {
        return _vm.dismiss_message(_vm.message)
      }
    }
  }, [_c('fa-icon', {
    attrs: {
      "icon": ['fas', 'trash']
    }
  })], 1)]), _vm._v(" "), _c('div', [_c('div', {
    staticClass: "notifications-list__details"
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.message.details)
    }
  }), _vm._v(" "), (_vm.message.action_data) ? _c('a', {
    staticClass: "u-ml2",
    on: {
      "click": function($event) {
        return _vm.clickOnLink(_vm.message.action_data)
      }
    }
  }, [_vm._v(_vm._s(_vm.message.action_data.label))]) : _vm._e()])])])])])])])
},staticRenderFns: []}