module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: (_vm.loading),
      expression: "loading"
    }]
  }, [(_vm.photoId) ? _c('div', {
    staticClass: "photo-thumbnail",
    on: {
      "click": function($event) {
        _vm.photoViewerVisible = true
      }
    }
  }, [(_vm.isVisibleCount && _vm.photosList.length > 0) ? _c('div', {
    staticClass: "photo-counter"
  }, [_c('fa-icon', {
    staticClass: "image-icon u-white",
    attrs: {
      "icon": ['fas', 'clone']
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "image-amount"
  }, [_vm._v(_vm._s(_vm.photosList.length))])], 1) : _vm._e(), _vm._v(" "), _c('bc-general-image', {
    attrs: {
      "fileId": _vm.photoId
    },
    on: {
      "imageLoaded": function($event) {
        _vm.isVisibleCount = true
      }
    }
  })], 1) : _vm._e(), _vm._v(" "), (!_vm.photoId) ? _c('div', {
    staticClass: "image-card__no-photo"
  }, [_vm._v(" " + _vm._s(_vm.t('policies.no_photos')))]) : _vm._e(), _vm._v(" "), _c('el-dialog', {
    attrs: {
      "custom-class": "dialog-slider",
      "title": _vm.dialogTitle,
      "visible": _vm.photoViewerVisible && _vm.photosList.length > 0,
      "close-on-press-escape": true,
      "destroy-on-close": true
    },
    on: {
      "update:visible": function($event) {
        return _vm.$set(_vm.photoViewerVisible && _vm.photosList, "length > 0", $event)
      },
      "open": _vm.attachHotkeys,
      "close": _vm.dialogClosed
    }
  }, [(_vm.propertyData) ? _c('el-carousel', {
    ref: "carousel",
    staticClass: "property-slider",
    attrs: {
      "trigger": "click",
      "autoplay": false,
      "indicator-position": "none",
      "arrow": "always"
    },
    on: {
      "change": function($event) {
        return _vm.showActionView('info')
      }
    }
  }, _vm._l((_vm.photosList), function(item) {
    return _c('el-carousel-item', {
      key: item.id
    }, [_c('div', {
      directives: [{
        name: "loading",
        rawName: "v-loading",
        value: (_vm.loading),
        expression: "loading"
      }]
    }, [_c('div', {
      staticClass: "property-slider__frame"
    }, [(!_vm.isCarrierPhoto(item.id)) ? _c('fa-icon', {
      staticClass: "image-icon",
      attrs: {
        "icon": [_vm.preferredPhoto.id === item.id ? 'fas' : 'far', 'star']
      },
      on: {
        "click": function($event) {
          return _vm.setPreferredPhoto(item)
        }
      }
    }) : _vm._e(), _vm._v(" "), _c('div', [_c('bc-general-image', {
      staticClass: "bc-general-image",
      attrs: {
        "fileId": item.id
      }
    })], 1)], 1), _vm._v(" "), (_vm.action.info) ? _c('div', {
      staticClass: "property-slider__info u-mt2"
    }, [(_vm.isCarrierPhoto(item.id)) ? _c('div', {
      staticClass: "property-slider__caption"
    }, [_vm._v("\n              " + _vm._s(_vm.t('policies.added_by_your_agent')) + "\n            ")]) : _vm._e(), _vm._v(" "), (!_vm.isCarrierPhoto(item.id)) ? _c('div', {
      staticClass: "u-flex-horizontal-space-between u-width100"
    }, [_c('div', {
      staticClass: "property-slider__caption"
    }, [_vm._v(_vm._s(_vm._f("capitalize")(item.caption)))]), _vm._v(" "), _c('div', {
      staticClass: "property-slider__modify"
    }, [_c('el-button', {
      attrs: {
        "icon": ['fas', 'edit'],
        "type": "info",
        "disabled": _vm.loading
      },
      on: {
        "click": function($event) {
          $event.stopPropagation();
          return _vm.showActionView('edit', item.caption)
        }
      }
    }), _vm._v(" "), _c('el-button', {
      attrs: {
        "icon": ['fas', 'trash'],
        "type": "info",
        "disabled": _vm.loading
      },
      on: {
        "click": function($event) {
          $event.stopPropagation();
          return _vm.showActionView('delete')
        }
      }
    })], 1)]) : _vm._e()]) : _vm._e(), _vm._v(" "), (_vm.action.delete) ? _c('div', {
      staticClass: "property-slider__info u-mt2"
    }, [_c('div', [_vm._v(_vm._s(_vm.t('policies.are_you_sure_you_want_to_delete_photo')))]), _vm._v(" "), _c('div', {
      staticClass: "property-slider__modify"
    }, [_c('el-button', {
      staticClass: "u-text-uppercase",
      attrs: {
        "type": "info",
        "size": "small",
        "disabled": _vm.loading
      },
      on: {
        "click": function($event) {
          $event.stopPropagation();
          return _vm.showActionView('info')
        }
      }
    }, [_vm._v("\n                " + _vm._s(_vm.t('global.cancel')) + "\n              ")]), _vm._v(" "), _c('el-button', {
      staticClass: "u-text-uppercase",
      attrs: {
        "type": "danger",
        "size": "small",
        "disabled": _vm.loading
      },
      on: {
        "click": function($event) {
          $event.stopPropagation();
          return _vm.removeItem(item)
        }
      }
    }, [_vm._v("\n                " + _vm._s(_vm.t('global.delete')) + "\n              ")])], 1)]) : _vm._e(), _vm._v(" "), (_vm.action.edit) ? _c('div', {
      staticClass: "property-slider__info u-mt2"
    }, [_c('el-input', {
      staticClass: "property-slider__input",
      attrs: {
        "type": "text",
        "placeholder": item.caption
      },
      model: {
        value: (_vm.currentCaption),
        callback: function($$v) {
          _vm.currentCaption = $$v
        },
        expression: "currentCaption"
      }
    }), _vm._v(" "), _c('div', {
      staticClass: "property-slider__modify"
    }, [_c('el-button', {
      staticClass: "u-text-uppercase",
      attrs: {
        "type": "info",
        "size": "small",
        "disabled": _vm.loading
      },
      on: {
        "click": function($event) {
          $event.stopPropagation();
          return _vm.showActionView('info')
        }
      }
    }, [_vm._v("\n                " + _vm._s(_vm.t('global.cancel')) + "\n              ")]), _vm._v(" "), _c('el-button', {
      staticClass: "u-text-uppercase",
      attrs: {
        "type": "primary",
        "size": "small",
        "disabled": _vm.loading
      },
      on: {
        "click": function($event) {
          $event.stopPropagation();
          return _vm.updateItem(item)
        }
      }
    }, [_vm._v("\n                " + _vm._s(_vm.t('global.save_button')) + "\n              ")])], 1)], 1) : _vm._e()])])
  }), 1) : _vm._e()], 1)], 1)
},staticRenderFns: []}