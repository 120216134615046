<template>
  <div>
    <p class="u-mt1">{{ t('enrollment.please_select_how_you_wish') }}:</p>
    <el-form>
      <el-form-item v-for="contactMethod in actualMethods" :key="contactMethod.id">
        <el-radio v-model="internalValue" :label="contactMethod">{{ labelForContactMethod(contactMethod) }}</el-radio>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
  export default {
    props: {
      value: Object,
      sendVia: String,
      selectedInsured: Object
    },
    name: 'ba-code-delivery',
    computed: {
      actualMethods () {
        return this.selectedInsured.contactMethods.filter(el => el[this.sendVia])
      },
      internalValue: {
        get () {
          return this.value
        },
        set (val) {
          this.$emit('input', val)
        }
      }
    },
    methods: {
      labelForContactMethod (contactMethod) {
        if (contactMethod.email) {
          return this.t('enrollment.send_an_email_to_email', {email: contactMethod.email})
        } else {
          return this.t('enrollment.send_an_sms_to_number', {number: contactMethod.phone})
        }
      }
    },
  }
</script>
