module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: (_vm.loading),
      expression: "loading"
    }]
  }, [(_vm.policy) ? _c('div', {
    staticClass: "pay-plan-component"
  }, [(_vm.displayedTerm === 'current' || (_vm.nextTermId && (_vm.currentTermId !== _vm.nextTermId))) ? _c('div', [_c('div', {
    staticClass: "pay-plan-component__title u-text-uppercase u-mt3 u-mb2"
  }, [_vm._v(_vm._s(_vm.termTitle))]), _vm._v(" "), (_vm.isShowPaymentPlan) ? _c('div', [(_vm.effectiveTermDates) ? _c('div', {
    staticClass: "pay-plan-component__date u-mb3"
  }, [_c('div', [_vm._v(_vm._s(_vm.effectiveTermDates.begin) + " - " + _vm._s(_vm.effectiveTermDates.end))])]) : _vm._e(), _vm._v(" "), _c('div', [_c('el-select', {
    staticClass: "item-input-field",
    attrs: {
      "disabled": !_vm.isBillingScheduleChangeAllowed || _vm.isEscrowBillingSchedule(_vm.selectedBillingSchedule)
    },
    on: {
      "change": _vm.onInputBillingSheduleChange
    },
    model: {
      value: (_vm.selectedBillingSchedule),
      callback: function($$v) {
        _vm.selectedBillingSchedule = $$v
      },
      expression: "selectedBillingSchedule"
    }
  }, _vm._l((_vm.billingSchedulesList), function(schedule) {
    return _c('el-option', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: (_vm.shouldShowEscrowPlans(schedule.id)),
        expression: "shouldShowEscrowPlans(schedule.id)"
      }],
      key: schedule.id,
      attrs: {
        "disabled": _vm.isEscrowBillingSchedule(schedule.id),
        "selected": schedule.id === _vm.selectedBillingSchedule,
        "placeholder": _vm.placeholder,
        "value": schedule.id,
        "label": _vm.t('payments.selected_payment_schedule', {
          payment_schedule: schedule.billingSchedule
        })
      }
    }, [_vm._v("\n              " + _vm._s(_vm.t('payments.selected_payment_schedule', {
      payment_schedule: schedule.billingSchedule
    })) + "\n            ")])
  }), 1), _vm._v(" "), (!_vm.isBillingScheduleChangeAllowed) ? _c('div', {
    staticClass: "pay-plan-component__msg u-warning u-mt1 u-mb2"
  }, [_c('i', {
    staticClass: "material-icons md-text u-mr1"
  }, [_vm._v("warning")]), _vm._v(_vm._s(_vm.t('payments.payment_plan_change_not_allowed')) + "\n          ")]) : _vm._e()], 1), _vm._v(" "), (_vm.paymentValidationError) ? _c('div', {
    staticClass: "u-mb2"
  }, [_c('div', {
    staticClass: "u-warning"
  }, [_c('i', {
    staticClass: "material-icons md-text",
    attrs: {
      "aria-hidden": "true"
    }
  }, [_vm._v("warning")]), _vm._v(" " + _vm._s(_vm.paymentValidationError) + "\n          ")])]) : _vm._e()]) : _vm._e(), _vm._v(" "), (_vm.isVisibleBillingSchedule) ? _c('div', {
    staticClass: "schedule-preview u-mt2 u-mb2"
  }, [_c('ba-schedule-preview', {
    attrs: {
      "selectedBillingScheduleOptions": _vm.selectedBillingScheduleOptions
    }
  })], 1) : _vm._e(), _vm._v(" "), (_vm.isAutopayAllowed) ? _c('div', [(_vm.form.current.autopayEnabled) ? _c('div', [_c('el-select', {
    model: {
      value: (_vm.form.current.selectedPaymentMethod),
      callback: function($$v) {
        _vm.$set(_vm.form.current, "selectedPaymentMethod", $$v)
      },
      expression: "form.current.selectedPaymentMethod"
    }
  }, _vm._l((_vm.policyPaymentMethods), function(m) {
    return _c('el-option', {
      key: m.id,
      attrs: {
        "disabled": !_vm.checkIfPaymentMethodAllowed(m.id),
        "selected": m.id === _vm.form.current.selectedPaymentMethod,
        "value": m.id,
        "label": m.label
      }
    })
  }), 1)], 1) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "pay-plan-component__info u-text-center"
  }, [_c('div', {
    staticClass: "u-text-uppercase u-mb1"
  }, [_vm._v(_vm._s(_vm.t('payments.automatic_bill_pay')))]), _vm._v(" "), _c('div', [_c('el-switch', {
    attrs: {
      "active-text": _vm.t('global.on'),
      "inactive-text": _vm.t('global.off'),
      "active-color": "#5AB67F",
      "inactive-color": "#CB6B0B",
      "disabled": _vm.policy.is_canceled
    },
    on: {
      "change": _vm.validatePaymentMethod
    },
    model: {
      value: (_vm.form.current.autopayEnabled),
      callback: function($$v) {
        _vm.$set(_vm.form.current, "autopayEnabled", $$v)
      },
      expression: "form.current.autopayEnabled"
    }
  })], 1)])]) : _vm._e(), _vm._v(" "), (_vm.invalidInitialBillingSetup) ? _c('div', {
    staticClass: "u-warning u-mb2 u-mt2",
    domProps: {
      "innerHTML": _vm._s(_vm.t('payments.invalid_billing_setup'))
    }
  }) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "u-mt2"
  }, [(_vm.hasChangesForSave && !_vm.policy.is_canceled && !_vm.loading) ? _c('el-button', {
    staticClass: "u-text-uppercase u-width100",
    attrs: {
      "disabled": _vm.invalidInitialBillingSetup,
      "size": "small",
      "type": "primary"
    },
    on: {
      "click": _vm.saveWithConfirmation
    }
  }, [_vm._v("\n          " + _vm._s(_vm.t('global.save_changes')) + "\n        ")]) : _vm._e()], 1)]) : _vm._e()]) : _vm._e()])
},staticRenderFns: []}