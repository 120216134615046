module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: (_vm.loading),
      expression: "loading"
    }]
  }, [_c('div', {
    staticClass: "file-claim-forms"
  }, [_c('div', {
    staticClass: "file-claim-forms__fields"
  }, [_c('el-form', {
    staticClass: "u-max-width u-mt6"
  }, [_c('div', {
    staticClass: "inputs-list u-mb3"
  }, [(_vm.policy) ? _c('div', {
    staticClass: "list-item--select"
  }, [_c('span', {
    staticClass: "u-block u-text-uppercase subheader"
  }, [_vm._v(_vm._s(_vm.riskLabel) + " *")]), _vm._v(" "), _c('el-select', {
    staticClass: "item-input-field u-block",
    attrs: {
      "disabled": _vm.risks.length === 1
    },
    model: {
      value: (_vm.selectedRiskId),
      callback: function($$v) {
        _vm.selectedRiskId = $$v
      },
      expression: "selectedRiskId"
    }
  }, [_c('el-option', {
    attrs: {
      "value": "",
      "disabled": "",
      "hidden": ""
    }
  }), _vm._v(" "), _vm._l((_vm.risks), function(risk) {
    return _c('el-option', {
      key: risk.static_id,
      attrs: {
        "value": risk.static_id,
        "label": risk.name
      }
    })
  })], 2)], 1) : _vm._e(), _vm._v(" "), (_vm.validateInputValuesDictCopy.risk) ? _c('div', {
    staticClass: "u-warning u-mb4"
  }, [_c('i', {
    staticClass: "material-icons md-text",
    attrs: {
      "aria-hidden": "true"
    }
  }, [_vm._v("warning")]), _vm._v(" " + _vm._s(_vm.validateInputValuesDictCopy.risk) + "\n          ")]) : _vm._e()])]), _vm._v(" "), _c('el-form', {
    staticClass: "u-max-width"
  }, [_c('div', {
    staticClass: "inputs-list u-mb3"
  }, [_c('div', [_c('span', {
    staticClass: "u-block u-text-uppercase subheader"
  }, [_vm._v(_vm._s(_vm.t('claims.loss_date_title')) + " *")]), _vm._v(" "), _c('el-date-picker', {
    staticClass: "u-width100 item-input-field",
    attrs: {
      "type": "datetime",
      "format": "MM/dd/yyyy hh:mm A",
      "picker-options": {
        disabledDate: _vm.isDateAfterToday
      },
      "disabled": _vm.isCanceledCurrentPolicy,
      "clearable": false
    },
    model: {
      value: (_vm.lossDateTime),
      callback: function($$v) {
        _vm.lossDateTime = $$v
      },
      expression: "lossDateTime"
    }
  })], 1), _vm._v(" "), (_vm.validateInputValuesDictCopy.lossDate) ? _c('div', {
    staticClass: "u-warning u-mb4"
  }, [_c('i', {
    staticClass: "material-icons md-text",
    attrs: {
      "aria-hidden": "true"
    }
  }, [_vm._v("warning")]), _vm._v(" " + _vm._s(_vm.validateInputValuesDictCopy.lossDate) + "\n            ")]) : _vm._e()])]), _vm._v(" "), _c('el-form', {
    staticClass: "u-max-width"
  }, [_c('div', {
    staticClass: "inputs-list u-mb3"
  }, [(_vm.statesList) ? _c('div', {
    staticClass: "list-item--select"
  }, [_c('span', {
    staticClass: "u-block u-text-uppercase subheader"
  }, [_vm._v(_vm._s(_vm.t('global.state')) + " *")]), _vm._v(" "), _c('el-select', {
    staticClass: "item-input-field u-block",
    model: {
      value: (_vm.selectedState),
      callback: function($$v) {
        _vm.selectedState = $$v
      },
      expression: "selectedState"
    }
  }, _vm._l((_vm.statesList), function(state) {
    return _c('el-option', {
      key: state,
      attrs: {
        "value": state,
        "label": state
      }
    })
  }), 1)], 1) : _vm._e()]), _vm._v(" "), (_vm.validateInputValuesDictCopy.state) ? _c('div', {
    staticClass: "u-warning u-mb4"
  }, [_c('i', {
    staticClass: "material-icons md-text",
    attrs: {
      "aria-hidden": "true"
    }
  }, [_vm._v("warning")]), _vm._v(" " + _vm._s(_vm.validateInputValuesDictCopy.state) + "\n        ")]) : _vm._e()]), _vm._v(" "), _c('el-form', {
    staticClass: "u-max-width"
  }, [_c('div', {
    staticClass: "inputs-list u-mb3"
  }, [_c('div', {
    staticClass: "list-item--select"
  }, [_c('span', {
    staticClass: "u-block u-text-uppercase subheader"
  }, [_vm._v(_vm._s(_vm.t('claims.loss_timezone_title')) + " *")]), _vm._v(" "), _c('el-select', {
    staticClass: "item-input-field u-block",
    model: {
      value: (_vm.selectedTimezone),
      callback: function($$v) {
        _vm.selectedTimezone = $$v
      },
      expression: "selectedTimezone"
    }
  }, _vm._l((_vm.timezonesList), function(timezone) {
    return _c('el-option', {
      key: timezone,
      attrs: {
        "value": timezone,
        "label": timezone
      }
    })
  }), 1)], 1)]), _vm._v(" "), (_vm.validateInputValuesDictCopy.timezone) ? _c('div', {
    staticClass: "u-warning u-mb4"
  }, [_c('i', {
    staticClass: "material-icons md-text",
    attrs: {
      "aria-hidden": "true"
    }
  }, [_vm._v("warning")]), _vm._v(" " + _vm._s(_vm.validateInputValuesDictCopy.timezone) + "\n        ")]) : _vm._e()]), _vm._v(" "), _c('el-form', {
    staticClass: "u-max-width"
  }, [_c('div', {
    staticClass: "inputs-list"
  }, [_c('div', {
    staticClass: "list-item--select"
  }, [_c('span', {
    staticClass: "u-block u-text-uppercase subheader"
  }, [_vm._v(_vm._s(_vm.t('account.phone_numbers')) + " *")]), _vm._v(" "), _c('div', {
    staticClass: "u-flex-horizontal-space-between"
  }, [_c('div', {
    staticClass: "u-width100"
  }, [_c('el-select', {
    staticClass: "item-input-field u-width100 u-block",
    model: {
      value: (_vm.selectedPhone),
      callback: function($$v) {
        _vm.selectedPhone = $$v
      },
      expression: "selectedPhone"
    }
  }, _vm._l((_vm.userPhones), function(phone) {
    return _c('el-option', {
      key: phone.id,
      attrs: {
        "value": phone.phone,
        "label": _vm.getPhoneNationalFormat(phone.phone)
      }
    })
  }), 1)], 1), _vm._v(" "), _c('div', {
    staticClass: "u-ml3",
    on: {
      "click": _vm.handlePhoneNumberDialog
    }
  }, [_c('fa-icon', {
    staticClass: "list-item--select__slot-icon u-primary",
    attrs: {
      "icon": ['fal', 'plus-circle']
    }
  })], 1)])])]), _vm._v(" "), (_vm.validateInputValuesDictCopy.userPhone) ? _c('div', {
    staticClass: "u-warning u-mb4"
  }, [_c('i', {
    staticClass: "material-icons md-text",
    attrs: {
      "aria-hidden": "true"
    }
  }, [_vm._v("warning")]), _vm._v(" " + _vm._s(_vm.validateInputValuesDictCopy.userPhone) + "\n        ")]) : _vm._e()])], 1), _vm._v(" "), (!_vm.isCanceledCurrentPolicy) ? _c('drag-n-drop-photo', {
    on: {
      "onPhotoUpload": _vm.onPhotoUpload
    }
  }) : _vm._e()], 1), _vm._v(" "), _c('el-form', {
    staticClass: "u-max-width"
  }, [_c('div', {
    staticClass: "inputs-list u-mt4 u-mb3"
  }, [_c('div', [_c('span', {
    staticClass: "u-block u-text-uppercase subheader"
  }, [_vm._v(_vm._s(_vm.t('claims.loss_description_title')) + " *")]), _vm._v(" "), _c('el-input', {
    staticClass: "u-block",
    attrs: {
      "type": "textarea",
      "disabled": _vm.isCanceledCurrentPolicy,
      "resizable": ""
    },
    model: {
      value: (_vm.description),
      callback: function($$v) {
        _vm.description = $$v
      },
      expression: "description"
    }
  })], 1), _vm._v(" "), (_vm.validateInputValuesDictCopy.description) ? _c('div', {
    staticClass: "u-warning"
  }, [_c('i', {
    staticClass: "material-icons md-text",
    attrs: {
      "aria-hidden": "true"
    }
  }, [_vm._v("warning")]), _vm._v(" " + _vm._s(_vm.validateInputValuesDictCopy.description) + "\n      ")]) : _vm._e()])]), _vm._v(" "), (_vm.chosenPhotos.length) ? _c('div', [_c('el-row', {
    attrs: {
      "gutter": 10
    }
  }, _vm._l((_vm.chosenPhotos), function(photo, pos) {
    return _c('el-col', {
      key: pos,
      attrs: {
        "md": 8,
        "sm": 24
      }
    }, [_c('el-card', {
      staticClass: "el-card__claim-image-preview u-mb1 u-p0"
    }, [_c('img', {
      staticClass: "claim-image-thumbnail",
      attrs: {
        "src": photo.image
      }
    }), _vm._v(" "), _c('div', {
      staticClass: "el-card__description u-m1"
    }, [_c('el-button', {
      attrs: {
        "type": "info",
        "size": "small",
        "icon": ['fas', 'trash']
      },
      on: {
        "click": function($event) {
          return _vm.deletePhoto(pos)
        }
      }
    })], 1)])], 1)
  }), 1)], 1) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "u-mt4 u-text-right"
  }, [_c('el-button', {
    staticClass: "u-text-uppercase",
    attrs: {
      "type": "primary",
      "disabled": _vm.policy === null || _vm.isCanceledCurrentPolicy
    },
    on: {
      "click": _vm.fileClaimOperation
    }
  }, [_vm._v("\n        " + _vm._s(_vm.t('claims.submit_claim_buttom_caption')) + "\n      ")])], 1), _vm._v(" "), (!_vm.claims.length) ? _c('phone-number-dialog') : _vm._e()], 1)
},staticRenderFns: []}