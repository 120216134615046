module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('nav', {
    staticClass: "sidenav"
  }, [_c('div', {
    staticClass: "sidenav__logo",
    on: {
      "click": function($event) {
        return _vm.navigateTo('/dashboard')
      }
    }
  }, [_c('img', {
    staticClass: "sidenav-image",
    attrs: {
      "src": require("@/assets/desktop_logo.png")
    }
  })]), _vm._v(" "), _c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [(_vm.isToggled || _vm.isOpen) ? _c('div', {
    staticClass: "sidenav__content"
  }, [_c('router-link', {
    staticClass: "c-header__nav-link u-text--uppercase",
    attrs: {
      "to": '/dashboard'
    }
  }, [_c('div', {
    staticClass: "sidenav__link",
    class: _vm.isCurrent(['/dashboard']),
    on: {
      "click": _vm.hideMobile
    }
  }, [_c('fa-icon', {
    staticClass: "nav-icon",
    attrs: {
      "icon": ['fas', 'tachometer-alt']
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "u-pl1"
  }, [_vm._v(_vm._s(_vm.t('dashboard.dashboard_label')))])], 1)]), _vm._v(" "), (_vm.hasAtLeastOnePolicy) ? _c('router-link', {
    staticClass: "c-header__nav-link u-text--uppercase",
    attrs: {
      "to": _vm.linkForPayments
    }
  }, [_c('div', {
    staticClass: "sidenav__link",
    class: _vm.isCurrent(['/payments']),
    on: {
      "click": _vm.hideMobile
    }
  }, [_c('fa-icon', {
    staticClass: "nav-icon",
    attrs: {
      "icon": ['far', 'credit-card']
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "u-pl1"
  }, [_vm._v(_vm._s(_vm.t('dashboard.payments')))])], 1)]) : _vm._e(), _vm._v(" "), (_vm.hasAtLeastOnePolicy) ? _c('router-link', {
    staticClass: "c-header__nav-link u-text--uppercase",
    attrs: {
      "to": _vm.linkForPolicies
    }
  }, [_c('div', {
    staticClass: "sidenav__link",
    class: _vm.isCurrent(['/policies/']),
    on: {
      "click": _vm.hideMobile
    }
  }, [_c('fa-icon', {
    staticClass: "nav-icon",
    attrs: {
      "icon": ['far', 'shield-check']
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "u-pl1"
  }, [_vm._v(_vm._s(_vm.t('global.policies_header')))])], 1)]) : _vm._e(), _vm._v(" "), ((_vm.hasAtLeastOnePolicy && _vm.claimsFeatureEnabled && _vm.alwaysShowClaimsMenuLink) || (_vm.openClaims && _vm.openClaims.length > 0 && _vm.claimsFeatureEnabled && _vm.hasAtLeastOnePolicy)) ? _c('router-link', {
    staticClass: "c-header__nav-link u-text--uppercase",
    attrs: {
      "to": _vm.linkForClaims
    }
  }, [_c('div', {
    staticClass: "sidenav__link",
    class: _vm.isCurrent(['/claims/']),
    on: {
      "click": _vm.hideMobile
    }
  }, [_c('fa-icon', {
    staticClass: "nav-icon",
    attrs: {
      "icon": ['far', 'file-alt']
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "u-pl1"
  }, [_vm._v(_vm._s(_vm.t('dashboard.claims')))])], 1)]) : _vm._e(), _vm._v(" "), _c('router-link', {
    staticClass: "c-header__nav-link u-text--uppercase",
    attrs: {
      "to": _vm.linkForDocuments
    }
  }, [_c('div', {
    staticClass: "sidenav__link",
    class: _vm.isCurrent(['/documents/', '/document-view/']),
    on: {
      "click": _vm.hideMobile
    }
  }, [_c('fa-icon', {
    staticClass: "nav-icon",
    attrs: {
      "icon": ['far', 'folder-open']
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "u-pl1"
  }, [_vm._v(_vm._s(_vm.t('documents.documents_label')))])], 1)]), _vm._v(" "), (_vm.hasAtLeastOnePolicy) ? _c('router-link', {
    staticClass: "c-header__nav-link u-text--uppercase",
    attrs: {
      "to": _vm.linkForAgent
    }
  }, [_c('div', {
    staticClass: "sidenav__link",
    class: _vm.isCurrent(['/agents/']),
    on: {
      "click": _vm.hideMobile
    }
  }, [_c('fa-icon', {
    staticClass: "nav-icon",
    attrs: {
      "icon": ['far', 'address-card']
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "u-pl1"
  }, [_vm._v(_vm._s(_vm.t('global.contact')))])], 1)]) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "c-header__nav-link u-text--uppercase"
  }, [_c('div', {
    staticClass: "sidenav__link",
    class: _vm.isCurrent(['/custom/'])
  }, [_c('ba-custom-pages', {
    attrs: {
      "placement": "bottom"
    },
    on: {
      "hideMobile": _vm.hideMobile
    }
  })], 1)]), _vm._v(" "), _c('div', {
    staticClass: "sidenav__content-mobile"
  }, [_c('router-link', {
    staticClass: "c-header__nav-link u-text--uppercase",
    attrs: {
      "to": '/account/settings'
    }
  }, [_c('div', {
    staticClass: "sidenav__link u-pl4",
    class: _vm.isCurrent(['/account/']),
    on: {
      "click": _vm.hideMobile
    }
  }, [_c('fa-icon', {
    staticClass: "nav-icon",
    attrs: {
      "icon": ['far', 'user']
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "u-pl1"
  }, [_vm._v(_vm._s(_vm.t('dashboard.view_account')))])], 1)]), _vm._v(" "), _c('a', {
    staticClass: "c-header__nav-link u-text--uppercase",
    on: {
      "click": function($event) {
        return _vm.logOutClicked()
      }
    }
  }, [_c('div', {
    staticClass: "sidenav__link u-pl4"
  }, [_c('fa-icon', {
    staticClass: "nav-icon",
    attrs: {
      "icon": ['far', 'sign-out']
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "u-pl1"
  }, [_vm._v(" " + _vm._s(_vm.t('global.logout')))])], 1)])], 1)], 1) : _vm._e()])], 1)
},staticRenderFns: []}