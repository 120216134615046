<template>
  <el-row :gutter="20" class="adjuster">
    
    <!-- 1st block -->
    <el-col :md="24" :lg="8">
      <!-- Photo Section -->
      <div class="u-flex-align-items u-mb3">
        <div class="adjuster__image u-mr3">
          <bc-general-image v-if="renderContactPhoto" :fileId="contactPhotoId" :source="contactPhotoUrl" class="adjuster__image_preview" />
          <div v-if="!renderContactPhoto">
            <fa-icon :icon="['far', 'user']" />
          </div>
        </div>
        <div class="adjuster__name">
          {{ name }}
        </div>
      </div>
      
      <!--Phones Section-->
      <div v-if="contact.phones && contact.phones.length">
        <div v-for="phone in contact.phones" :key="phone.id">
          <div class="adjuster__phones u-mb2">
            <div v-if="isShowPhoneLabel" class="u-flex-align-items">
              <div v-if="phone.type.length" class="adjuster__phone-type u-text-uppercase u-mr2">
                {{phone.type}}:
              </div>
              <div><a :href="'tel:' + phone.phone" class="adjuster__phone-type_link">{{phone.phone}}</a></div>
            </div>
            <div v-else class="phone-content">
              <a :href="'tel:' + phone.phone">{{phone.phone}}</a>
            </div>
          </div>
        </div>
      </div>
    </el-col>
  
    <!-- 2nd block -->
    <el-col :md="24" :lg="8">
      <!-- Emails Section -->
      <div v-if="contact.emails && contact.emails.length">
        <div class="adjuster__contact">
          <div class="adjuster__contact_title u-text-uppercase u-mb2">{{ t('global.email_address')}}</div>
          <div>
            <div :key="i" v-for="(email, i) in contact.emails" class="u-mb1">
              <span>
                <a :href="'mailto:' +  email.email">{{ email.email }}</a>
              </span>
            </div>
          </div>
        </div>
      </div>
      
      <!-- Website Section -->
      <div v-if="contact && contact.website && modifiedWebsite" class="u-mt3">
        <div>
          <div class="adjuster__contact_title u-text-uppercase u-mb2">{{ t('global.website') }}</div>
          <div>
            <a :href="contact.website">{{ modifiedWebsite }}</a>
          </div>
        </div>
      </div>
      
      <div v-else>
        <div class="adjuster__contact_empty">No emails</div>
      </div>
  
    </el-col>
  
    <!-- 3rd block -->
    <el-col :md="24" :lg="8">
      <!-- Address Section -->
      <div class="adjuster__contact" v-if="contact.addresses.length">
        <div v-for="(address, i) in contact.addresses" :key="i" class="adjuster__contact_info">
          <div>
            <div class="adjuster__contact_title u-text-uppercase u-mb2">
              {{ getAddressLabel(address) }} {{ t('global.address') }}
            </div>
            <div>
              <div>{{ address.address_line1 }}</div>
              <div v-if="address.address_line2">{{ address.address_line2 }}</div>
              <div>{{ address.address_city}},  {{ address.address_state }}</div>
              <div>{{ address.address_zip }}</div>
            </div>
          </div>
        </div>
      </div>
    </el-col>
    
  </el-row>
</template>

<script>
  export default {
    name: 'adjusters-list',
    props: {
      contact: {type: Object, required: true},
      imageData: {type: Object, required: false},
      name: {type: String, required: true},
    },
    computed: {
      renderContactPhoto () {
        return !!(this.contactPhotoId || this.contactPhotoUrl)
      },
      contactPhotoId () {
        return this.imageData ? this.imageData.photoId : null
      },
      contactPhotoUrl () {
        return this.imageData ? this.imageData.photo_url : null
      },
      shouldUseTypeLabel () {
        return this.setting('global.use_type_label_for_other_addresses')
      },
      modifiedWebsite () {
        let http = /^(https?:\/\/)/gm
        return this.contact.website ? (this.contact.website.replace(http, '') || this.contact.website.substr(7)) : null
      },
      isShowPhoneLabel () {
        return this.setting('account.show_phone_labels')
      },
    },
    methods: {
      getAddressLabel (address) {
        if (address.type === 'Other' && address.type_label && this.shouldUseTypeLabel) {
          return address.type_label
        }
        return address.type
      }
    },
  }
</script>

<style scoped lang="scss">
  @import '../sass/_variables.scss';
  
  .adjuster {
    &__image {
      width: 50px;
      &_preview {
        max-height: 35px;
      }
      svg[data-icon="user"] {
        color: #C0C0C0;
        font-size: 34px;
      }
    }
    &__name {
      font-size: 18px;
      font-weight: 500;
      color: $color-black;
    }
    &__phones {
      font-size: 16px;
    }
    &__phone-type {
      width: 60px;
      font-size: 14px;
      &_link {
        color: $color-primary;
      }
    }
    &__contact {
      margin-top: 28px;
      position: relative;
      &_title {
        font-size: 14px;
        color: #626262;
      }
      &_info:not(:last-child) {
        margin-bottom: 25px;
      }
      &_empty {
        visibility: hidden;
        @media only screen and (max-width: $l-screen) {
          display: none;
        }
      }
    }
  }
</style>
