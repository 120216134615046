module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', [(_vm.openClaims) ? _c('div', {
    staticClass: "u-height100"
  }, [_c('div', {
    staticClass: "claims-card dashboard__outer-card_info"
  }, [_c('div', {
    staticClass: "el-card__header"
  }, [_vm._v(_vm._s(_vm.t('dashboard.claims')))]), _vm._v(" "), _c('div', {
    staticClass: "u-flex-vertical-space-between u-height100"
  }, [(_vm.openClaims.length === 1) ? _c('router-link', {
    attrs: {
      "to": {
        path: _vm.linkForClaims(_vm.openClaims)
      },
      "data-test-id": "claims-link"
    }
  }, [_c('h2', {
    staticClass: "u-mt0 u-mb5"
  }, [_vm._v(_vm._s(_vm._f("dateFormat")(_vm.openClaims[0].date_added, 'M/DD/YYYY')) + " " + _vm._s(_vm.t('claims.claim')))]), _vm._v(" "), _c('h3', {
    staticClass: "claims-card__open-claim subheader u-mt2"
  }, [_vm._v("\n            " + _vm._s(_vm.t('claims.claim_#', {
    number: _vm.openClaims[0].claim_number
  })))])]) : (_vm.openClaims.length > 1) ? _c('router-link', {
    attrs: {
      "to": {
        path: _vm.linkForClaims(_vm.openClaims)
      },
      "data-test-id": "claims-link"
    }
  }, [_c('h2', {
    staticClass: "u-mt0 u-mb5"
  }, [_vm._v(_vm._s(_vm.t('claims.multiple_open_claims')))])]) : _c('div', [_c('h2', {
    staticClass: "u-mt0 u-mb5"
  }, [_vm._v(_vm._s(_vm.t('claims.no_claims')))])]), _vm._v(" "), (_vm.hasOneActivePolicy) ? _c('div', {
    staticClass: "claims-card__links"
  }, [_c('el-button', {
    staticClass: "u-text-capitalize u-width100",
    attrs: {
      "type": "info"
    },
    on: {
      "click": _vm.fileNewClaim
    }
  }, [_vm._v("\n            " + _vm._s(_vm.t('claims.file_new_claim')) + "\n          ")])], 1) : _vm._e()], 1)])]) : _vm._e(), _vm._v(" "), _c('el-dialog', {
    attrs: {
      "visible": _vm.dialogClaimVisible,
      "custom-class": "file-claim-dialog",
      "show-close": true,
      "close-on-click-modal": false,
      "close-on-press-escape": false
    },
    on: {
      "update:visible": function($event) {
        _vm.dialogClaimVisible = $event
      }
    }
  }, [(_vm.dialogClaimVisible) ? _c('bc-file-claims-view', {
    ref: "fileClaimView",
    attrs: {
      "openClaimDialog": ""
    },
    on: {
      "closeClaimDialog": function($event) {
        _vm.dialogClaimVisible = false
      }
    }
  }) : _vm._e()], 1), _vm._v(" "), _c('phone-number-dialog')], 1)
},staticRenderFns: []}