module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', [_c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "md": 24,
      "lg": 8
    }
  }, [_c('div', {
    staticClass: "u-text-trim"
  }, [_c('fa-icon', {
    staticClass: "pay-terms__icon",
    attrs: {
      "icon": _vm.determinePolicyIcon(_vm.policy)
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "pay-terms__address u-pl1"
  }, [_vm._v("\n          " + _vm._s(_vm.policy.properties && _vm.policy.properties.length > 0 ? _vm.policy.properties[0].address_line1 : '') + "\n        ")])], 1), _vm._v(" "), _c('div', {
    staticClass: "u-mt1 u-mb2"
  }, [_vm._v(_vm._s(_vm.t('payments.policy_#', {
    number: _vm.policy.policy_number
  })))])]), _vm._v(" "), _c('el-col', {
    staticClass: "col-borders",
    attrs: {
      "md": 24,
      "lg": 8
    }
  }, [(_vm.policy.current_term.id) ? _c('bc-pay-plan', {
    attrs: {
      "policy": _vm.policy,
      "termsType": "current"
    },
    on: {
      "updateCustomPayPlan": _vm.updateCustomPayPlan
    }
  }) : _vm._e()], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "md": 24,
      "lg": 8
    }
  }, [(_vm.policy.next_policy_term_id) ? _c('bc-pay-plan', {
    ref: "next",
    attrs: {
      "policy": _vm.policy,
      "termsType": "next"
    },
    on: {
      "updateCustomPayPlan": _vm.updateCustomPayPlan
    }
  }) : _vm._e()], 1)], 1)], 1)
},staticRenderFns: []}