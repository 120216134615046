/* eslint-disable */
import axios from 'axios'
import config from '../../config/britecore'
import {isFailed} from '../../shared_src/utils/api.utils'
import settings from '../../config/settings'

const localstoragePinKey = 'auth.pin.nonce'

const loginModule = {
  namespaced: true,
  state: {
    usernameToForceUpdatePassword: null, // i would rather have it here instead of sessionStorage/localStorage etc to avoid caching  and password update by mistake (for cases when passwords equal for different accounts)
    isAuthorized: false,
    pin_nonce: null,
    currentLoginScreenState: false,

  },
  mutations: {
    setUsernameToForceUpdatePassword (state, usernameToForceUpdatePassword) {
      state.usernameToForceUpdatePassword = usernameToForceUpdatePassword
    },
    updateCurrentLoginScreenState (state, isOpened) {
      let previousState = state.currentLoginScreenState
      if (previousState === isOpened) {
        console.warn(' - update login screen visibility will have no affect, currentLoginScreenState is already, ', isOpened)
        return
      }
      state.currentLoginScreenState = isOpened
    },
    updateToken (state, payload) {
      state.isAuthorized = Boolean(payload)
      if (payload) {
        localStorage.token = payload
      } else {
        localStorage.removeItem('token')
      }
    },
    updatePinNonce (state, payload) {
      state.pin_nonce = payload

      if (typeof payload !== 'undefined') {
        localStorage.setItem(localstoragePinKey, payload)
      } else {
        localStorage.removeItem(localstoragePinKey)

      }
    },
  },
  actions: {
    auth: function (context, payload) {
      return new Promise((resolve, reject) => {
        let data
        let url

        if (payload.by === 'email') {
          url = config.ajax_login_url
          data = {
            username: payload.username,
            password: payload.password,
            use_pin: payload.usePinNextTime,
            company_id: settings.company_id,
          }
        } else if (payload.by === 'pin') {
          url = config.auth_by_pin_url
          data = {
            pin: payload.pin,
            pin_nonce: payload.pin_nonce,
            company_id: settings.company_id,
          }
        } else {
          throw "Unsupported login type " + payload.by
        }

        axios.post(url, data)
          .then((response) => {
            if (isFailed(response)) {
              reject(response)
              return
            }

            context.commit('updatePinNonce', response.data.pin_nonce)
            resolve()
          })
          .catch((e) => {
          reject(e)
        })
      })
    },
    authByToken: function (context, payload) {
      return new Promise((resolve, reject) => {
        let data = {
          token: payload.token,
          company_id: settings.company_id,
        }
        let url = config.auth_by_token_url
        axios.post(url, data)
          .then((response) => {
            if (isFailed(response)) {
              reject(response)
              return
            }
            resolve()
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    init: function (context, payload) {
      return new Promise((resolve) => { //after login init done resolve promise in ANY case
        context.commit('updateToken', localStorage.token)
        let nonce = localStorage.getItem('auth.pin.nonce')
        if (nonce) {
          context.commit('updatePinNonce', nonce)
        }
        if (context.state.isAuthorized) {
          axios.post(config.check_token_url, { //recheck token
            token: localStorage.token,
          }).then(
            () => {
              resolve()
            },
            () => {
              context.commit('updateToken', null)
              resolve()
            },
          )
        } else {
          resolve()
        }
      })
    },
    loginScreenWillShow (context, payload) {
      context.commit('updateCurrentLoginScreenState', true)
    },
    loginScreenDidHide (context, payload) {
      context.commit('updateCurrentLoginScreenState', false)
    },
    clearPinNonce (context, payload) {
      context.commit('updatePinNonce')

    },
  },
  getters: {},
}

export default loginModule
