<template>
  <div v-if="getPhoneNationalFormat">{{ getPhoneNationalFormat }}</div>
</template>

<script>
  import {parsePhoneNationalFormat} from '@shared_src/utils/misc.util'
  
  export default {
    name: 'phone-national-format',
    props: ['phone'],
    computed: {
      getPhoneNationalFormat () {
        return parsePhoneNationalFormat(this.phone)
      }
    }
  }
</script>
