<template>
  <svg width="25" height="23" id="icon-sign" viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path  d="M17.3984 15.5742C17.3125 15.6602 17.3125 15.7461 17.3125 15.832V20.6875H2.1875V5.5625H12.543C12.6289 5.5625 12.7148 5.5625 12.8008 5.47656L14.1758 4.10156C14.3906 3.88672 14.2188 3.5 13.918 3.5H2.1875C1.02734 3.5 0.125 4.44531 0.125 5.5625V20.6875C0.125 21.8477 1.02734 22.75 2.1875 22.75H17.3125C18.4297 22.75 19.375 21.8477 19.375 20.6875V14.457C19.375 14.1562 18.9883 13.9844 18.7734 14.1992L17.3984 15.5742ZM24.1016 6.9375C25.0898 5.94922 25.0898 4.35938 24.1016 3.37109L22.2539 1.52344C21.2656 0.535156 19.6758 0.535156 18.6875 1.52344L7.42969 12.7812L7 16.6914C6.87109 17.8086 7.81641 18.7539 8.93359 18.625L12.8438 18.1953L24.1016 6.9375ZM19.8906 8.22656L11.8984 16.2188L9.0625 16.5625L9.40625 13.7266L17.3984 5.73438L19.8906 8.22656ZM22.6406 4.83203C22.8555 5.00391 22.8555 5.26172 22.6836 5.47656L21.3516 6.80859L18.8594 4.27344L20.1484 2.98438C20.3203 2.76953 20.6211 2.76953 20.793 2.98438L22.6406 4.83203Z"
           :fill="iconColor"/>
  </svg>
</template>

<script>
  export default {
    name: 'sign-icon',
    props: {
      iconColor: {
        type: String,
        default: '#626262'
      }
    }
  }
</script>

<style scoped>

</style>
