<template>
  <div v-loading="loading">
    <div class="u-mt4 u-mb4">
      <h2 class="secondary-header">{{ t('account.change_password') }}</h2>
    </div>
    <ba-alert v-if="successPasswordChanged" class="u-width100" type="success">
      {{ t('reset_password.password_changed_successfully') }}
    </ba-alert>

    <ba-password-update ref="passwordUpdateRef" @updatePassword="updatePassword" :setNewPasswordButtonCaption="t('account.change_password')"></ba-password-update>
  </div>
</template>

<script>
import axios from 'axios'
import config from '@/../config/britecore'
import alert from '@/ui/alert.component'
import BaPasswordUpdate from '@/components/password-update.component'

export default {
  components: {
    'ba-alert': alert,
    'ba-password-update': BaPasswordUpdate,
  },
  methods: {
    async updatePassword ({currentPassword, newPassword}) {
      this.loading = true
      try {
        let response = await axios.post(config.change_password, {
          new_password: newPassword,
          current_password: currentPassword,
          token: localStorage.token
        })
        if (response.data.success) {
          this.$elAlert(this.t('reset_password.password_changed_successfully'), this.t('global.notice'),
            () => {
              this.loading = false
              this.$refs.passwordUpdateRef.resetPasswordFields()
              this.successPasswordChanged = true
            })
        } else {
          this.loading = false
          this.$elAlert(
            response.data.cause ? this.causeToString(response.data.cause) : this.t('global.error'),
            this.t('global.error')
          )
        }
      } catch (e) {
        this.loading = false
        console.error(e)
        this.$elAlert(this.t('global.error'), this.t('global.error'))
      }
    },
  },
  data () {
    return {
      loading: false,
      successPasswordChanged: false
    }
  },
}
</script>
