<template>
  <div>

        <div class="password-field-component u-mt2">
          <span v-if="inputLabel" class="subheader u-text-uppercase form-edit-contact-info__label u-pb2">{{ inputLabel }} *</span>
          <el-input @keyup.enter.native="$emit('enterKeyPressed')"
                    :type="innerShowPassword ? 'text' : 'password'"
                    autocomplete="off"
                    required
                    v-model.trim="innerValue"/>

          <a @click="innerShowPassword = !innerShowPassword" class="password-field-component__show-item" :class="innerShowPassword ? 'password-field-component__visible-password' : ''">
            <img src="~@/assets/logos/eye_logo.svg" :alt="t('global.show')">
          </a>

        </div>
  </div>

</template>

<script>

export default {
  name: 'ba-password-field',
  props: {
    inputLabel: {type: String, default: null},
    value: {type: String},
    showPassword: {type: Boolean, default: false},
    displayShowPasswordCheckbox: {type: Boolean, default: true},
  },
  watch: {
    value: {
      handler (value) {
        this.innerValue = value
      },
      immediate: true
    },
    innerValue: {
      handler (innerValue) {
        this.$emit('input', innerValue)
        this.$emit('change', innerValue)
      },
      immediate: true
    },
    showPassword: {
      handler (showPassword) {
        this.innerShowPassword = showPassword
      },
      immediate: true
    },
    innerShowPassword: {
      handler (innerShowPassword) {
        this.$emit('onShowPasswordCheckboxChange', innerShowPassword)
      },
      immediate: true
    },
  },
  data () {
    return {
      innerValue: '',
      innerShowPassword: false
    }
  }
}
</script>
<style lang="scss">
  .password-field-component {
    position: relative;
    &__show-item {
      position: absolute;
      right: 15px;
      top: 35px;
      z-index: 100;
    }
  }
  .password-field-component__visible-password {
    &:before {
      content: '';
      border: 1px solid #898a89;
      width: 33px;
      display: inline-block;
      transform: rotate(135deg);
      position: relative;
      left: 30px;
      bottom: 7px;
    }
  }
</style>

