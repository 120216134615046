<template>
  <div v-loading="loading">
    <div class="file-claim-forms">
      <div class="file-claim-forms__fields">

        <!-- Risk -->
        <el-form class="u-max-width u-mt6">
          <div class="inputs-list u-mb3">
            <div v-if="policy" class="list-item--select">
              <span class="u-block u-text-uppercase subheader">{{ riskLabel }} *</span>
              <el-select v-model="selectedRiskId" class="item-input-field u-block"
                         :disabled="risks.length === 1">
                <el-option value="" disabled hidden></el-option>
                <el-option v-for="risk in risks" :value="risk.static_id" :key="risk.static_id"
                  :label="risk.name">
                </el-option>
              </el-select>
            </div>
            <div v-if="validateInputValuesDictCopy.risk" class="u-warning u-mb4">
              <i class="material-icons md-text" aria-hidden="true">warning</i> {{ validateInputValuesDictCopy.risk }}
            </div>
          </div>
        </el-form>

        <!-- Loss DateTime -->
        <el-form class="u-max-width">
          <div class="inputs-list u-mb3">
              <div>
                <span class="u-block u-text-uppercase subheader">{{ t('claims.loss_date_title') }} *</span>
                <el-date-picker
                  type="datetime"
                  format="MM/dd/yyyy hh:mm A"
                  class="u-width100 item-input-field"
                  :picker-options="{ disabledDate: isDateAfterToday }"
                  :disabled="isCanceledCurrentPolicy"
                  v-model="lossDateTime"
                  :clearable="false"
                />
              </div>
              <div v-if="validateInputValuesDictCopy.lossDate" class="u-warning u-mb4">
                <i class="material-icons md-text" aria-hidden="true">warning</i> {{ validateInputValuesDictCopy.lossDate }}
              </div>
            </div>
        </el-form>

        <!-- State -->
        <el-form class="u-max-width">
          <div class="inputs-list u-mb3">
            <div v-if="statesList" class="list-item--select">
              <span class="u-block u-text-uppercase subheader">{{ t('global.state') }} *</span>
              <el-select v-model="selectedState" class="item-input-field u-block">
                <el-option v-for="state in statesList" :value="state" :key="state" :label="state">
                </el-option>
              </el-select>
            </div>
          </div>
          <div v-if="validateInputValuesDictCopy.state" class="u-warning u-mb4">
            <i class="material-icons md-text" aria-hidden="true">warning</i> {{ validateInputValuesDictCopy.state }}
          </div>
        </el-form>

        <!-- Loss Timezone -->
        <el-form class="u-max-width">
          <div class="inputs-list u-mb3">
            <div class="list-item--select">
              <span class="u-block u-text-uppercase subheader">{{ t('claims.loss_timezone_title') }} *</span>
              <el-select v-model="selectedTimezone" class="item-input-field u-block">
                <el-option v-for="timezone in timezonesList" :key="timezone" :value="timezone" :label="timezone">
                </el-option>
              </el-select>
            </div>
          </div>
          <div v-if="validateInputValuesDictCopy.timezone" class="u-warning u-mb4">
            <i class="material-icons md-text" aria-hidden="true">warning</i> {{ validateInputValuesDictCopy.timezone }}
          </div>
        </el-form>
  
        <!-- Phone Numbers -->
        <el-form class="u-max-width">
          <div class="inputs-list">
            <div class="list-item--select">
              <span class="u-block u-text-uppercase subheader">{{ t('account.phone_numbers') }} *</span>
              <div class="u-flex-horizontal-space-between">
                <div class="u-width100">
                  <el-select v-model="selectedPhone" class="item-input-field u-width100 u-block">
                    <el-option v-for="phone in userPhones" :value="phone.phone" :key="phone.id" :label="getPhoneNationalFormat(phone.phone)">
                    </el-option>
                  </el-select>
                </div>
                <div @click="handlePhoneNumberDialog" class="u-ml3">
                  <fa-icon :icon="[ 'fal', 'plus-circle']" class="list-item--select__slot-icon u-primary" />
                </div>
              </div>
            </div>
          </div>
          <div v-if="validateInputValuesDictCopy.userPhone" class="u-warning u-mb4">
            <i class="material-icons md-text" aria-hidden="true">warning</i> {{ validateInputValuesDictCopy.userPhone }}
          </div>
        </el-form>

      </div>

       <!-- Upload Photos -->
      <drag-n-drop-photo v-if="!isCanceledCurrentPolicy" @onPhotoUpload="onPhotoUpload"></drag-n-drop-photo>
    </div>

    <!-- Description -->
    <el-form class="u-max-width">
      <div class="inputs-list u-mt4 u-mb3">
        <div>
          <span class="u-block u-text-uppercase subheader">{{ t('claims.loss_description_title') }} *</span>
          <el-input type="textarea" class="u-block" v-model="description" :disabled="isCanceledCurrentPolicy" resizable></el-input>
        </div>
        <div v-if="validateInputValuesDictCopy.description" class="u-warning">
          <i class="material-icons md-text" aria-hidden="true">warning</i> {{ validateInputValuesDictCopy.description }}
        </div>
      </div>
    </el-form>

    <!-- Photos preview -->
    <div v-if="chosenPhotos.length">
        <el-row :gutter="10">
          <el-col v-for="(photo, pos) in chosenPhotos" :key="pos" :md="8" :sm="24">
            <el-card class="el-card__claim-image-preview u-mb1 u-p0">
              <img :src="photo.image" class="claim-image-thumbnail"/>
              <div class="el-card__description u-m1">
                <el-button type="info" size="small" :icon="['fas', 'trash']" @click="deletePhoto(pos)"></el-button>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>

    <div class="u-mt4 u-text-right">
        <el-button type="primary"
                   @click="fileClaimOperation"
                   :disabled="policy === null || isCanceledCurrentPolicy"
                   class="u-text-uppercase">
          {{ t('claims.submit_claim_buttom_caption') }}
        </el-button>
      </div>
  
    <!-- Dialog Add New Phone Number (If no Claims) -->
    <phone-number-dialog v-if="!claims.length" />
    
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState, mapMutations} from 'vuex'
import {deepClone, parsePhoneNationalFormat} from '@shared_src/utils/misc.util'
import moment from 'moment'
import dragnDropPhotoComponent from '@/components/drag-n-drop-photo.component.vue'
import PhoneNumberDialog from '@/components/new-phone-number.dialog.vue'
import eventBus from '@shared_src/eventHub'

export default {
  name: 'claims-create-gen3',
  components: {
    'drag-n-drop-photo': dragnDropPhotoComponent,
    PhoneNumberDialog
  },
  props: {
    policyId: {
      type: String,
      required: true,
    }
  },
  watch: {
    lossDateTime: {
      handler (lossDateTime) {
        this.onLossDateTimeUpdate()
      },
      immediate: true
    },
    selectedState: {
      handler (selectedState) {
        this.invalidateTimeZonesListAndSelectedTimezone()
      },
      immediate: true
    },
  },
  mounted () {
    eventBus.$on('phone-added', (val) => {
      this.selectedPhone = val
    })
  },
  destroyed () {
    eventBus.$off('phone-added')
  },
  data () {
    let startOfTomorrowMillis = +moment().add('1', 'day').startOf('day').toDate()
    return {
      risks: [],
      selectedRiskId: '',
      startOfTomorrowMillis,
      lossDateTime: new Date(),
      timezonesWithStates: null,
      selectedState: null,
      statesList: null,
      selectedTimezone: null,
      timezonesList: null,
      description: '',
      chosenPhotos: [],
      selectedPhone: '',
      validateInputValuesDictCopy: {
        lossDate: null,
        description: null,
        risk: null,
        timezone: null,
        userPhone: null
      },
      loading: false,
      revisionId: null,
    }
  },
  methods: {
    ...mapActions('claims', ['fileClaimGen3', 'getGen3ClaimTimezonesWithStatesCached']),
    ...mapActions('policies', {reloadPolicies: 'reload'}),
    ...mapActions('policies', ['getRisksByRevIdWithCache', 'getRevisionByRevisionDateCached']),
    ...mapMutations('common', ['handleDialogNewPhoneNumber', 'handleDialogCreateClaim']),
    async onLossDateTimeUpdate () {
      this.loading = true
      try {
        await Promise.all([this.updateRisksOnDateTimeUpdate(), this.updateTimezoneWithStatesOnDateTimeUpdate()])
      } catch (error) {
        this.$elAlert(this.t('enrollment.some_error_happened_notification'), this.t('global.error'))
      }
      this.loading = false
    },
    async updateTimezoneWithStatesOnDateTimeUpdate () {
      this.timezonesWithStates = await this.getGen3ClaimTimezonesWithStatesCached(
        moment(this.lossDateTime).format('YYYY-MM-DD'))
      this.statesList = this.timezonesWithStates.map(item => item.state)
      if (this.statesList && this.statesList.length) {
        if (!this.statesList.includes(this.selectedState)) {
          this.selectedState = null
          // state updated, invalidateTimeZonesListAndSelectedTimezone will be triggered by watcher
        } else {
          // state NOT updated, trigger invalidateTimeZonesListAndSelectedTimezone manually
          this.invalidateTimeZonesListAndSelectedTimezone()
        }
      }
    },
    async updateRisksOnDateTimeUpdate () {
      let revisionId = await this.getRevisionByRevisionDateCached(
        {policyId: this.policyId, revisionDate: moment(this.lossDateTime).format('YYYY-MM-DD')})

      if (!revisionId) throw new Error('revision Id not found for this date, failing')

      let revisionRiskData = await this.getRisksByRevIdWithCache({revisionId, policyId: this.policyId})
      this.risks = this.riskDataForClaimsConverter(revisionRiskData)
      this.revisionId = revisionId
      this.selectedRiskId = (this.risks && this.risks.length ===1) ? this.risks[0].static_id : null
    },
    invalidateTimeZonesListAndSelectedTimezone () {
      // refresh list if required. Called on state update/loss date update
      if (!this.selectedStateObj) {
        this.timezonesList = null
        this.selectedTimezone = null
        return
      }
      let timezonesObjs = this.selectedStateObj.zones
      this.timezonesList = [...new Set(timezonesObjs.map(item => item.id))]
      if (!this.selectedTimezone) { // init value
        return
      }
      // TZ not found for this date/state. Take any available TZ
      if (!this.timezonesList.includes(this.selectedTimezone)) this.selectedTimezone = this.timezonesList[0]
    },
    getPhoneNationalFormat (phone) {
      return parsePhoneNationalFormat(phone)
    },
    handlePhoneNumberDialog () {
      this.handleDialogNewPhoneNumber({ isDialogVisible: true })
    },
    getNowAsString () {
      return moment().format('YYYY-MM-DDTHH:mm')
    },
    isDateAfterToday (evaledDate) {
      let evaledMillis = +evaledDate
      return evaledMillis >= this.startOfTomorrowMillis
    },
    isValidSelectedDate () {
      return moment(this.lossDateTime, 'YYYY-MM-DDTHH:mm', true).isValid()
    },
    onPhotoUpload (photoDict) {
      this.chosenPhotos.push(photoDict)
    },
    deletePhoto (pos) {
      this.chosenPhotos.splice(pos, 1)
    },
    getZoneNameByTZlabel (tzLabel) {
      // choose ANY zone which uses required TZ
      return this.selectedStateObj.zones.find(item => item.id === tzLabel).zone
    },
    async fileClaimOperation () {
      this.validateInputValuesDictCopy = deepClone(this.validateInputValuesDict)
      if (!this.validateInputValues) {
        this.$elAlert(this.t('enrollment.some_error_happened_notification'), this.t('global.notice'))
        return
      }
      this.loading = true
      let newClaimId
      try {
        let fileClaimResult = await this.fileClaimGen3({
          revisionId: this.revisionId,
          riskId: this.selectedRiskId,
          loss_date: moment(this.lossDateTime).format('YYYY-MM-DDTHH:mm'),
          description: this.description,
          chosenPhotos: this.chosenPhotos,
          lossTimezone: this.getZoneNameByTZlabel(this.selectedTimezone),
          selectedPhone: this.selectedPhone
        })
        newClaimId = fileClaimResult.data.claim_id
      } catch (e) {
        this.loading = false
        let errorCause = e.response.cause || 'claims.create_claim_failure'
        if (errorCause === 'claims.upload_claim_photo_failure') {
          this.$elAlert(this.t('claims.upload_claim_photo_failure'), this.t('global.notice'))
          newClaimId = e.response.data.claim_id
        } else {
          this.$elAlert(this.t(errorCause), this.t('global.notice'))
          return  // no further actions if this error is not photo-related
        }
      }

      try {
        await this.reloadPolicies()
      } catch (err) {
        this.loading = false
        this.$elAlert(this.t('claims.claims_reload_failure_alert_message'), this.t('global.notice'))
        return  // no navigation to claim-view if error
      }
      this.loading = false
      this.$emit('closeClaimDialog')
      this.$router.replace({path: '/claims/' + newClaimId})
    }
  },
  computed: {
    ...mapGetters('policies', ['riskLabelByPolicy', 'riskDataForClaimsConverter', 'policyById']),
    ...mapState('common', ['userPhones']),
    ...mapState('claims', ['claims']),
    policy () {
      return this.policyById(this.policyId)
    },
    isCanceledCurrentPolicy () {
      return this.policy && this.policy.is_canceled
    },
    validateInputValues () {
      return Object.keys(this.validateInputValuesDict).every(key => !this.validateInputValuesDict[key])
    },
    validateInputValuesDict () {
      return {
        description: (this.description.trim() === '')
          ? this.t('claims.loss_description_is_not_defined') : null,
        lossDate: !this.isValidSelectedDate() ? this.t('claims.loss_date_is_not_defined') : null,
        risk: !this.selectedRiskId ? this.t('claims.risk_is_not_defined') : null,
        state: !this.selectedState ? this.t('enrollment.state_not_defined_notification') : null,
        timezone: !this.selectedTimezone ? this.t('claims.timezone_is_not_defined') : null,
        userPhone: !this.selectedPhone ? this.t('account.no_phone_number_added') : null,
      }
    },
    riskLabel () {
      return this.riskLabelByPolicy(this.policy, false)
    },
    selectedStateObj () {
      if (!this.timezonesWithStates || !this.selectedState) return
      return this.timezonesWithStates.find(item => item.state === this.selectedState)
    }
  }
}
</script>

<style>
  .list-item--select__slot-icon {
    font-size: 25px;
    margin-bottom: 6px;
    cursor: pointer;
  }
</style>
