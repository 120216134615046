module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    staticClass: "preview"
  }, [_c('el-row', {
    attrs: {
      "gutter": 20
    }
  }, [_c('el-col', {
    attrs: {
      "span": 16
    }
  }, [_c('h1', [_vm._v("Preview")]), _vm._v(" "), _c('div', {
    staticClass: "preview__block"
  }, [_c('h4', {
    staticClass: "preview__title"
  }, [_vm._v("Alert")]), _vm._v(" "), _c('div', {
    staticClass: "preview__show-case"
  }, [_c('div', {
    staticClass: "preview__type"
  }, [_vm._v("Success")]), _vm._v(" "), _c('ba-alert', {
    attrs: {
      "type": "success"
    }
  }, [_vm._v("\n            An account already exists with the information you provided, please login.\n          ")])], 1), _vm._v(" "), _c('div', {
    staticClass: "preview__show-case"
  }, [_c('div', {
    staticClass: "preview__type"
  }, [_vm._v("Warning")]), _vm._v(" "), _c('ba-alert', {
    attrs: {
      "type": "warning"
    }
  }, [_vm._v("\n            An account already exists with the information you provided, please login.\n          ")])], 1), _vm._v(" "), _c('div', {
    staticClass: "preview__show-case"
  }, [_c('div', {
    staticClass: "preview__type"
  }, [_vm._v("Error")]), _vm._v(" "), _c('ba-alert', {
    attrs: {
      "type": "error"
    }
  }, [_vm._v("\n            An account already exists with the information you provided, please login.\n          ")])], 1)]), _vm._v(" "), _c('div', {
    staticClass: "preview__block"
  }, [_c('h4', {
    staticClass: "preview__title"
  }, [_vm._v("Input")]), _vm._v(" "), _c('div', {
    staticClass: "preview__show-case"
  }, [_c('div', {
    staticClass: "preview__type"
  }, [_vm._v("Default")]), _vm._v(" "), _c('div', {
    staticClass: "preview__info"
  }, [_c('span', {
    staticClass: "u-input-label subheader"
  }, [_vm._v("Email Address")]), _vm._v(" "), _c('el-input', {
    staticClass: "u-input--default",
    attrs: {
      "type": "tel",
      "placeholder": "Default placeholder"
    }
  })], 1)]), _vm._v(" "), _c('div', {
    staticClass: "preview__show-case"
  }, [_c('div', {
    staticClass: "preview__type"
  }, [_vm._v("Hover")]), _vm._v(" "), _c('div', {
    staticClass: "preview__info"
  }, [_c('span', {
    staticClass: "u-input-label subheader"
  }, [_vm._v("Email Address")]), _vm._v(" "), _c('el-input', {
    staticClass: "u-input-preview--hover",
    attrs: {
      "type": "tel",
      "placeholder": "Default placeholder"
    }
  })], 1)]), _vm._v(" "), _c('div', {
    staticClass: "preview__show-case"
  }, [_c('div', {
    staticClass: "preview__type"
  }, [_vm._v("Focused")]), _vm._v(" "), _c('div', {
    staticClass: "preview__info"
  }, [_c('span', {
    staticClass: "u-input-label subheader"
  }, [_vm._v("Email Address")]), _vm._v(" "), _c('el-input', {
    staticClass: "u-input-preview--focused",
    attrs: {
      "type": "tel",
      "placeholder": "Default placeholder"
    }
  })], 1)]), _vm._v(" "), _c('div', {
    staticClass: "preview__show-case"
  }, [_c('div', {
    staticClass: "preview__type"
  }, [_vm._v("Disabled")]), _vm._v(" "), _c('div', {
    staticClass: "preview__info"
  }, [_c('span', {
    staticClass: "u-input-label subheader"
  }, [_vm._v("Email Address")]), _vm._v(" "), _c('el-input', {
    attrs: {
      "type": "tel",
      "placeholder": "Default placeholder",
      "disabled": ""
    }
  })], 1)])]), _vm._v(" "), _c('div', {
    staticClass: "preview__block"
  }, [_c('h4', {
    staticClass: "preview__title"
  }, [_vm._v("Radio Card")]), _vm._v(" "), _c('div', {
    staticClass: "preview__show-case"
  }, [_c('div', {
    staticClass: "preview__type"
  }, [_vm._v("Deselected")]), _vm._v(" "), _c('div', {
    staticClass: "preview__info"
  }, [_c('el-radio', {
    attrs: {
      "border": ""
    },
    model: {
      value: (_vm.radioButton.radio1),
      callback: function($$v) {
        _vm.$set(_vm.radioButton, "radio1", $$v)
      },
      expression: "radioButton.radio1"
    }
  }, [_vm._v("John Smith")])], 1)]), _vm._v(" "), _c('div', {
    staticClass: "preview__show-case"
  }, [_c('div', {
    staticClass: "preview__type"
  }, [_vm._v("Hover")]), _vm._v(" "), _c('div', {
    staticClass: "preview__info"
  }, [_c('el-radio', {
    staticClass: "u-radio-preview--hover",
    attrs: {
      "label": "1",
      "border": ""
    },
    model: {
      value: (_vm.radioButton.radio2),
      callback: function($$v) {
        _vm.$set(_vm.radioButton, "radio2", $$v)
      },
      expression: "radioButton.radio2"
    }
  }, [_vm._v("John Smith")])], 1)]), _vm._v(" "), _c('div', {
    staticClass: "preview__show-case"
  }, [_c('div', {
    staticClass: "preview__type"
  }, [_vm._v("Selected")]), _vm._v(" "), _c('div', {
    staticClass: "preview__info"
  }, [_c('el-radio', {
    attrs: {
      "label": "1",
      "border": ""
    },
    model: {
      value: (_vm.radioButton.radio3),
      callback: function($$v) {
        _vm.$set(_vm.radioButton, "radio3", $$v)
      },
      expression: "radioButton.radio3"
    }
  }, [_vm._v("John Smith")])], 1)]), _vm._v(" "), _c('div', {
    staticClass: "preview__show-case"
  }, [_c('div', {
    staticClass: "preview__type"
  }, [_vm._v("Disabled")]), _vm._v(" "), _c('div', {
    staticClass: "preview__info"
  }, [_c('el-radio', {
    attrs: {
      "label": "1",
      "border": "",
      "disabled": ""
    },
    model: {
      value: (_vm.radioButton.radio4),
      callback: function($$v) {
        _vm.$set(_vm.radioButton, "radio4", $$v)
      },
      expression: "radioButton.radio4"
    }
  }, [_vm._v("John Smith")])], 1)])]), _vm._v(" "), _c('div', {
    staticClass: "preview__block"
  }, [_c('h4', {
    staticClass: "preview__title"
  }, [_vm._v("Checkbox")]), _vm._v(" "), _c('div', {
    staticClass: "preview__show-case"
  }, [_c('div', {
    staticClass: "preview__type"
  }, [_vm._v("Unchecked")]), _vm._v(" "), _c('div', {
    staticClass: "preview__info"
  }, [_c('el-checkbox', {
    model: {
      value: (_vm.checkBox.value1),
      callback: function($$v) {
        _vm.$set(_vm.checkBox, "value1", $$v)
      },
      expression: "checkBox.value1"
    }
  }, [_vm._v(_vm._s(_vm.t('login.fast_login')))])], 1)]), _vm._v(" "), _c('div', {
    staticClass: "preview__show-case"
  }, [_c('div', {
    staticClass: "preview__type"
  }, [_vm._v("Checked")]), _vm._v(" "), _c('div', {
    staticClass: "preview__info"
  }, [_c('el-checkbox', {
    model: {
      value: (_vm.checkBox.value2),
      callback: function($$v) {
        _vm.$set(_vm.checkBox, "value2", $$v)
      },
      expression: "checkBox.value2"
    }
  }, [_vm._v(_vm._s(_vm.t('login.fast_login')))])], 1)]), _vm._v(" "), _c('div', {
    staticClass: "preview__show-case"
  }, [_c('div', {
    staticClass: "preview__type"
  }, [_vm._v("Disabled")]), _vm._v(" "), _c('div', {
    staticClass: "preview__info"
  }, [_c('el-checkbox', {
    attrs: {
      "disabled": ""
    },
    model: {
      value: (_vm.checkBox.value3),
      callback: function($$v) {
        _vm.$set(_vm.checkBox, "value3", $$v)
      },
      expression: "checkBox.value3"
    }
  }, [_vm._v(_vm._s(_vm.t('login.fast_login')))])], 1)])]), _vm._v(" "), _c('div', {
    staticClass: "preview__block"
  }, [_c('h4', {
    staticClass: "preview__title"
  }, [_vm._v("Primary Button")]), _vm._v(" "), _c('div', {
    staticClass: "preview__show-case"
  }, [_c('div', {
    staticClass: "preview__type"
  }, [_vm._v("Default")]), _vm._v(" "), _c('div', {
    staticClass: "preview__info"
  }, [_c('el-button', [_vm._v("Verify Account")])], 1)]), _vm._v(" "), _c('div', {
    staticClass: "preview__show-case"
  }, [_c('div', {
    staticClass: "preview__type"
  }, [_vm._v("Hover")]), _vm._v(" "), _c('div', {
    staticClass: "preview__info"
  }, [_c('el-button', {
    staticClass: "btn-primary-hover"
  }, [_vm._v("Verify Account")])], 1)]), _vm._v(" "), _c('div', {
    staticClass: "preview__show-case"
  }, [_c('div', {
    staticClass: "preview__type"
  }, [_vm._v("Disabled")]), _vm._v(" "), _c('div', {
    staticClass: "preview__info"
  }, [_c('el-button', {
    attrs: {
      "disabled": ""
    }
  }, [_vm._v("Verify Account")])], 1)])]), _vm._v(" "), _c('div', {
    staticClass: "preview__block"
  }, [_c('h4', {
    staticClass: "preview__title"
  }, [_vm._v("Icon Link")]), _vm._v(" "), _c('div', {
    staticClass: "preview__show-case"
  }, [_c('div', {
    staticClass: "preview__type"
  }, [_vm._v("Default")]), _vm._v(" "), _c('div', {
    staticClass: "preview__info"
  }, [_c('ba-icon-link', {
    attrs: {
      "icon_type": "far",
      "icon_name": "arrow-left",
      "icon_class": "u-pl2",
      "label": _vm.t('documents.back')
    }
  })], 1)]), _vm._v(" "), _c('div', {
    staticClass: "preview__show-case"
  }, [_c('div', {
    staticClass: "preview__type"
  }, [_vm._v("Hover")]), _vm._v(" "), _c('div', {
    staticClass: "preview__info"
  }, [_c('ba-icon-link', {
    attrs: {
      "icon_type": "far",
      "icon_name": "arrow-left",
      "icon_class": "u-pl2",
      "label": _vm.t('documents.back')
    }
  })], 1)])]), _vm._v(" "), _c('div', {
    staticClass: "preview__block"
  }, [_c('h4', {
    staticClass: "preview__title"
  }, [_vm._v("Link")]), _vm._v(" "), _c('div', {
    staticClass: "preview__show-case"
  }, [_c('div', {
    staticClass: "preview__type"
  }, [_vm._v("Default")]), _vm._v(" "), _c('div', {
    staticClass: "preview__info"
  }, [_c('a', [_vm._v("Forgot your password or email?")])])]), _vm._v(" "), _c('div', {
    staticClass: "preview__show-case"
  }, [_c('div', {
    staticClass: "preview__type"
  }, [_vm._v("Hover")]), _vm._v(" "), _c('div', {
    staticClass: "preview__info"
  }, [_c('a', {
    staticClass: "ba-link--hover"
  }, [_vm._v("Forgot your password or email?")])])])]), _vm._v(" "), _c('div', {
    staticClass: "preview__block"
  }, [_c('h4', {
    staticClass: "preview__title"
  }, [_vm._v("Language Selector")]), _vm._v(" "), _c('div', {
    staticClass: "preview__show-case"
  }, [_c('div', {
    staticClass: "preview__type"
  }, [_vm._v("Default")]), _vm._v(" "), _c('div', {
    staticClass: "preview__info"
  }, [_c('ba-language-selector')], 1)])]), _vm._v(" "), _c('div', {
    staticClass: "preview__block"
  }, [_c('h4', {
    staticClass: "preview__title"
  }, [_vm._v("Tooltip (Popover)")]), _vm._v(" "), _c('div', {
    staticClass: "preview__show-case"
  }, [_c('div', {
    staticClass: "preview__type"
  }, [_vm._v("Default")]), _vm._v(" "), _c('div', {
    staticClass: "preview__info"
  }, [_c('el-popover', {
    attrs: {
      "placement": "top-start",
      "title": "Title",
      "width": "200",
      "trigger": "hover",
      "content": "this is content, this is content, this is content"
    }
  }, [_c('div', {
    attrs: {
      "slot": "reference"
    },
    slot: "reference"
  }, [_c('i', {
    staticClass: "el-icon-info"
  })])])], 1)])]), _vm._v(" "), _c('div', {
    staticClass: "preview__block"
  }, [_c('h4', {
    staticClass: "preview__title"
  }, [_vm._v("Tabs")]), _vm._v(" "), _c('div', {
    staticClass: "preview__show-case"
  }, [_c('div', {
    staticClass: "preview__type"
  }, [_vm._v("Default")]), _vm._v(" "), _c('div', {
    staticClass: "preview__info"
  }, [_c('ba-tab', {
    attrs: {
      "sign": "Sign Up"
    }
  })], 1)])])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "span": 8
    }
  }, [_c('h2', [_vm._v("Colors")]), _vm._v(" "), _c('div', {
    staticClass: "colors-list"
  }, [_c('div', {
    staticClass: "colors-list__palette"
  }, [_c('div', {
    staticClass: "colors-list__palette_color-primary"
  }, [_vm._v("Primary")]), _vm._v(" "), _c('div', {
    staticClass: "colors-list__palette_color-success"
  }, [_vm._v("Success")]), _vm._v(" "), _c('div', {
    staticClass: "colors-list__palette_color-danger"
  }, [_vm._v("Danger")]), _vm._v(" "), _c('div', {
    staticClass: "colors-list__palette_color-warning"
  }, [_vm._v("Warning")]), _vm._v(" "), _c('div', {
    staticClass: "colors-list__palette_color-info"
  }, [_vm._v("Info")]), _vm._v(" "), _c('div', {
    staticClass: "colors-list__palette_color-link"
  }, [_vm._v("Link")]), _vm._v(" "), _c('div', {
    staticClass: "colors-list__palette_color-text"
  }, [_vm._v("Text Color")]), _vm._v(" "), _c('div', {
    staticClass: "colors-list__palette_color-text--secondary"
  }, [_vm._v("Text secondary")]), _vm._v(" "), _c('div', {
    staticClass: "colors-list__palette_color-text--disabled"
  }, [_vm._v("Text disable")])])])])], 1)], 1)
},staticRenderFns: []}