module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    staticClass: "language-selector"
  }, [_c('el-popover', {
    attrs: {
      "placement": "bottom-start",
      "width": "110",
      "trigger": "click"
    },
    model: {
      value: (_vm.visible),
      callback: function($$v) {
        _vm.visible = $$v
      },
      expression: "visible"
    }
  }, [(_vm.availableLanguages) ? _c('div', _vm._l((_vm.availableLanguages), function(lang, index) {
    return _c('div', {
      key: index
    }, [_c('div', {
      staticClass: "language-selector__data",
      on: {
        "click": function($event) {
          return _vm.changeLanguage(lang)
        }
      }
    }, [_c('span', [_vm._v(_vm._s(_vm.flagFor(lang)))]), _vm._v(" "), _c('span', [_vm._v(_vm._s(_vm._f("capitalize")(lang)))])])])
  }), 0) : _vm._e(), _vm._v(" "), _c('el-button', {
    staticClass: "language-selector__btn",
    attrs: {
      "slot": "reference",
      "type": "info"
    },
    on: {
      "click": function($event) {
        _vm.visible != _vm.visible
      }
    },
    slot: "reference"
  }, [_c('div', {
    staticClass: "language-selector__content"
  }, [_c('div', [_c('span', {
    staticClass: "language-selector__btn--text"
  }, [_vm._v(_vm._s(_vm.flagFor(_vm.selectedLanguage)))]), _vm._v(" "), _c('span', {
    staticClass: "language-selector__btn--text"
  }, [_vm._v(_vm._s(_vm._f("capitalize")(_vm.selectedLanguage)))])]), _vm._v(" "), _c('div', {
    staticClass: "language-selector__btn--text u-pl1"
  }, [_c('fa-icon', {
    class: _vm.visible ? 'u-rotate-180' : '',
    attrs: {
      "icon": ['fas', 'caret-down']
    }
  })], 1)])])], 1)], 1)
},staticRenderFns: []}