module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return (_vm.dialogVisible) ? _c('el-dialog', {
    attrs: {
      "visible": _vm.dialogVisible,
      "width": "30%",
      "custom-class": "phone-number-dialog",
      "close-on-press-escape": false
    },
    on: {
      "update:visible": function($event) {
        _vm.dialogVisible = $event
      },
      "close": _vm.closeDialog
    }
  }, [_c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: (_vm.loading),
      expression: "loading"
    }],
    staticClass: "ba-intl-tel-input__wrapper"
  }, [_c('h2', {
    staticClass: "secondary-header u-mt0 u-mb2"
  }, [_vm._v("\n       " + _vm._s(_vm.t('enrollment.add_phone_link')) + "\n     ")]), _vm._v(" "), _c('el-form', {
    staticClass: "form-edit-contact-info"
  }, [_c('el-form-item', {
    staticClass: "u-max-width"
  }, [_c('span', {
    staticClass: "subheader u-text-uppercase form-edit-contact-info__label"
  }, [_vm._v(_vm._s(_vm.t('enrollment.phone_number_input_label')))]), _vm._v(" "), _c('ba-intl-tel-input', {
    class: _vm.uiPhone.validationMessage ? 'phone-number-u-warning' : '',
    attrs: {
      "customClass": "el-input__inner"
    },
    nativeOn: {
      "keyup": function($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.saveButtonClick.apply(null, arguments)
      }
    },
    model: {
      value: (_vm.uiPhone.phone),
      callback: function($$v) {
        _vm.$set(_vm.uiPhone, "phone", $$v)
      },
      expression: "uiPhone.phone"
    }
  }), _vm._v(" "), (_vm.uiPhone.validationMessage) ? _c('div', {
    staticClass: "u-warning"
  }, [_c('i', {
    staticClass: "material-icons md-text",
    attrs: {
      "aria-hidden": "true"
    }
  }, [_vm._v("warning")]), _vm._v(" " + _vm._s(_vm.uiPhone.validationMessage) + "\n        ")]) : _vm._e()], 1)], 1), _vm._v(" "), _c('el-button', {
    staticClass: "el-button--wide class u-text-uppercase",
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.saveButtonClick
    }
  }, [_vm._v("\n      " + _vm._s(_vm.t('global.save_changes')) + "\n    ")])], 1)]) : _vm._e()
},staticRenderFns: []}