module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', [(_vm.challenge.type === 'portal_code') ? _c('div', [_c('p', {
    staticClass: "u-mt3 u-mb3"
  }, [_vm._v("\n      " + _vm._s(_vm.t('enrollment.please_enter_your_security_code')) + "\n      "), _c('span', {
    staticClass: "portal-code-links"
  }, [(_vm.getContactMethods && _vm.getContactMethods.userEmail) ? _c('a', {
    on: {
      "click": function($event) {
        return _vm.requestCode('email')
      }
    }
  }, [_vm._v(_vm._s(_vm.t('enrollment.send_it_to_my_email')))]) : _vm._e(), _vm._v(" "), (_vm.getContactMethods && _vm.getContactMethods.userEmail && _vm.getContactMethods.userPhone) ? _c('span', [_vm._v(" " + _vm._s(_vm.t('enrollment.or')) + " ")]) : _vm._e(), _vm._v(" "), (_vm.getContactMethods && _vm.getContactMethods.userPhone) ? _c('a', {
    on: {
      "click": function($event) {
        return _vm.requestCode('phone')
      }
    }
  }, [_vm._v(_vm._s(_vm.t('enrollment.send_it_to_my_phone')))]) : _vm._e()])])]) : _vm._e(), _vm._v(" "), (_vm.callToActionText) ? _c('div', {
    staticClass: "u-mb2"
  }, [_vm._v(_vm._s(_vm.callToActionText))]) : _vm._e(), _vm._v(" "), _c('el-form', {
    nativeOn: {
      "submit": function($event) {
        $event.preventDefault();
      }
    }
  }, [(_vm.challenge.type === 'portal_code' || _vm.challenge.type === 'ssn') ? _c('el-form-item', {
    attrs: {
      "label": _vm.challenge.type === 'ssn' ? _vm.t('enrollment.last_4_digits_of_ssn') : _vm.t('enrollment.portal_code')
    }
  }, [(_vm.challenge.type === 'portal_code') ? _c('span', {
    staticClass: "portal-code-info"
  }, [_c('el-popover', {
    attrs: {
      "placement": "top-start",
      "title": "",
      "width": "200",
      "trigger": "hover",
      "content": _vm.t('enrollment.title_where_do_i_find_enrollment_code_message')
    }
  }, [_c('span', {
    attrs: {
      "slot": "reference"
    },
    slot: "reference"
  }, [_c('fa-icon', {
    attrs: {
      "icon": "exclamation-circle"
    }
  })], 1)])], 1) : _vm._e(), _vm._v(" "), _c('el-input', {
    attrs: {
      "autofocus": "",
      "type": "text",
      "maxlength": _vm.challenge.type === 'ssn' ? 4 : 6
    },
    model: {
      value: (_vm.challenge.code),
      callback: function($$v) {
        _vm.$set(_vm.challenge, "code", $$v)
      },
      expression: "challenge.code"
    }
  })], 1) : _vm._e(), _vm._v(" "), (_vm.challenge.sentMessage) ? _c('el-form-item', [_c('div', {
    staticClass: "u-text--light"
  }, [_vm._v(_vm._s(_vm.challenge.sentMessage))])]) : _vm._e(), _vm._v(" "), (_vm.challenge.type === 'dob') ? _c('el-form-item', {
    staticClass: "dob-form-field"
  }, [_c('date-picker', {
    attrs: {
      "showPage": 'challenge'
    },
    on: {
      "updateDatePicker": _vm.updateDatePicker
    }
  })], 1) : _vm._e()], 1), _vm._v(" "), (_vm.errorMessage) ? _c('div', {
    staticClass: "u-text--danger"
  }, [_vm._v(_vm._s(_vm.errorMessage))]) : _vm._e()], 1)
},staticRenderFns: []}