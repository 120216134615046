<template>
  <div>
    <div v-if="challenge.type === 'portal_code'">
      <p class="u-mt3 u-mb3">
        {{ t('enrollment.please_enter_your_security_code') }}
        <span class="portal-code-links">
                <a v-if="getContactMethods && getContactMethods.userEmail" @click="requestCode('email')">{{ t('enrollment.send_it_to_my_email') }}</a>
                <span v-if="getContactMethods && getContactMethods.userEmail && getContactMethods.userPhone"> {{ t('enrollment.or') }} </span>
                <a v-if="getContactMethods && getContactMethods.userPhone" @click="requestCode('phone')">{{ t('enrollment.send_it_to_my_phone') }}</a>
              </span>
      </p>
    </div>
    <div v-if="callToActionText" class="u-mb2">{{callToActionText}}</div>
    <el-form @submit.native.prevent>
      <el-form-item v-if="challenge.type === 'portal_code' || challenge.type === 'ssn'"
                    :label="challenge.type === 'ssn' ? t('enrollment.last_4_digits_of_ssn') : t('enrollment.portal_code')">
              <span v-if="challenge.type === 'portal_code'" class="portal-code-info">
                <el-popover
                    placement="top-start"
                    title=""
                    width="200"
                    trigger="hover"
                    :content="t('enrollment.title_where_do_i_find_enrollment_code_message')">
                <span slot="reference"><fa-icon icon="exclamation-circle"></fa-icon></span>
              </el-popover>
              </span>
        <el-input autofocus
                  v-model="challenge.code"
                  type="text" :maxlength="challenge.type === 'ssn' ? 4 : 6"/>
      </el-form-item>
      <el-form-item v-if="challenge.sentMessage"><div class="u-text--light">{{challenge.sentMessage}}</div></el-form-item>
      <el-form-item v-if="challenge.type === 'dob'" class="dob-form-field">
        <date-picker @updateDatePicker="updateDatePicker"
                     :showPage="'challenge'"/>
      </el-form-item>

    </el-form>
    <div v-if="errorMessage" class="u-text--danger">{{errorMessage}}</div>
  </div>
</template>
<script>
  import DatePicker from '../../pages/enrollment/date-picker'

  export default {
    name: 'ba-policy-challenge',
    components: {
      DatePicker,
    },
    props: {
      challenge: Object,
      selectedInsured: Object,
      errorMessage: String,
    },
    computed: {
      callToActionText () {
        if (this.challenge.type === 'dob') {
          return this.t('enrollment.please_enter_your_birthdate')
        }
        if (this.challenge.type === 'ssn') {
          return this.t('enrollment.please_enter_the_last_4_digits_of_your_ssn')
        }
      },
      getContactMethods () {
        if (this.selectedInsured) {
          let checkExistingType = (list, type) => list.some(el => el[type] && el[type].length)
          return {
            userEmail: checkExistingType(this.selectedInsured.contactMethods, 'email'),
            userPhone: checkExistingType(this.selectedInsured.contactMethods, 'phone'),
          }
        }
      },
    },
    methods: {
      requestCode (via) {
        this.$emit('requestCode', via)
      },
      updateDatePicker (newValue) {
        // todo use v-bind
        this.challenge.code = newValue
      },
    },
  }
</script>

<style lang="scss">
    .dob-form-field {
        max-width: none !important;
    }
</style>
