module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.t('account.email_update_confirmation_sent', {
        new_email: this.email
      }))
    }
  }), _vm._v(" "), _c('el-input', {
    staticClass: "u-mt3",
    attrs: {
      "type": "text",
      "placeholder": "ex: 123456"
    },
    model: {
      value: (_vm.internalValue),
      callback: function($$v) {
        _vm.internalValue = $$v
      },
      expression: "internalValue"
    }
  }), _vm._v(" "), (_vm.verificationFailure) ? _c('div', {
    staticClass: "u-danger u-mt2"
  }, [_vm._v("\n      " + _vm._s(_vm.verificationFailure) + "\n    ")]) : _vm._e()], 1)
},staticRenderFns: []}