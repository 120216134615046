<template>
  <div class="preview">
    <el-row :gutter="20">
      <el-col :span="16">
        <h1>Preview</h1>
        <!-- ALERT -->
        <div class="preview__block">
          <h4 class="preview__title">Alert</h4>
          <div class="preview__show-case">
            <div class="preview__type">Success</div>
            <ba-alert type="success">
              An account already exists with the information you provided, please login.
            </ba-alert>
          </div>
          <div class="preview__show-case">
            <div class="preview__type">Warning</div>
            <ba-alert type="warning">
              An account already exists with the information you provided, please login.
            </ba-alert>
          </div>
          <div class="preview__show-case">
            <div class="preview__type">Error</div>
            <ba-alert type="error">
              An account already exists with the information you provided, please login.
            </ba-alert>
          </div>
        </div>
        
        <!-- INPUT -->
        <div class="preview__block">
          <h4 class="preview__title">Input</h4>
          <div class="preview__show-case">
            <div class="preview__type">Default</div>
            <div class="preview__info">
              <span class="u-input-label subheader">Email Address</span>
              <el-input class="u-input--default" type="tel" placeholder="Default placeholder"/>
            </div>
          </div>
          <div class="preview__show-case">
            <div class="preview__type">Hover</div>
            <div class="preview__info">
              <span class="u-input-label subheader">Email Address</span>
              <el-input class="u-input-preview--hover" type="tel" placeholder="Default placeholder"/>
            </div>
          </div>
          <div class="preview__show-case">
            <div class="preview__type">Focused</div>
            <div class="preview__info">
              <span class="u-input-label subheader">Email Address</span>
              <el-input class="u-input-preview--focused" type="tel" placeholder="Default placeholder"/>
            </div>
          </div>
          <div class="preview__show-case">
            <div class="preview__type">Disabled</div>
            <div class="preview__info">
              <span class="u-input-label subheader">Email Address</span>
              <el-input type="tel" placeholder="Default placeholder" disabled/>
            </div>
          </div>
        </div>
        
        <!-- RADIO -->
        <div class="preview__block">
          <h4 class="preview__title">Radio Card</h4>
          <div class="preview__show-case">
            <div class="preview__type">Deselected</div>
            <div class="preview__info">
              <el-radio v-model="radioButton.radio1" border>John Smith</el-radio>
            </div>
          </div>
          <div class="preview__show-case">
            <div class="preview__type">Hover</div>
            <div class="preview__info">
              <el-radio v-model="radioButton.radio2" class="u-radio-preview--hover" label="1" border>John Smith</el-radio>
            </div>
          </div>
          <div class="preview__show-case">
            <div class="preview__type">Selected</div>
            <div class="preview__info">
              <el-radio v-model="radioButton.radio3" label="1" border>John Smith</el-radio>
            </div>
          </div>
          <div class="preview__show-case">
            <div class="preview__type">Disabled</div>
            <div class="preview__info">
              <el-radio v-model="radioButton.radio4" label="1" border disabled>John Smith</el-radio>
            </div>
          </div>
        </div>
  
        <!-- CHECKBOX -->
        <div class="preview__block">
          <h4 class="preview__title">Checkbox</h4>
          <div class="preview__show-case">
            <div class="preview__type">Unchecked</div>
            <div class="preview__info">
              <el-checkbox v-model="checkBox.value1">{{ t('login.fast_login') }}</el-checkbox>
            </div>
          </div>
          <div class="preview__show-case">
            <div class="preview__type">Checked</div>
            <div class="preview__info">
              <el-checkbox v-model="checkBox.value2">{{ t('login.fast_login') }}</el-checkbox>
            </div>
          </div>
          <div class="preview__show-case">
            <div class="preview__type">Disabled</div>
            <div class="preview__info">
              <el-checkbox v-model="checkBox.value3" disabled>{{ t('login.fast_login') }}</el-checkbox>
            </div>
          </div>
        </div>
        
      <!-- BUTTON -->
        <div class="preview__block">
          <h4 class="preview__title">Primary Button</h4>
          <div class="preview__show-case">
            <div class="preview__type">Default</div>
            <div class="preview__info">
              <el-button>Verify Account</el-button>
            </div>
          </div>
          <div class="preview__show-case">
            <div class="preview__type">Hover</div>
            <div class="preview__info">
              <el-button class="btn-primary-hover">Verify Account</el-button>
            </div>
          </div>
          <div class="preview__show-case">
            <div class="preview__type">Disabled</div>
            <div class="preview__info">
              <el-button disabled>Verify Account</el-button>
            </div>
          </div>
        </div>
  
        <!-- Icon Link -->
        <div class="preview__block">
          <h4 class="preview__title">Icon Link</h4>
          <div class="preview__show-case">
            <div class="preview__type">Default</div>
            <div class="preview__info">
              <ba-icon-link icon_type="far" icon_name="arrow-left" icon_class="u-pl2" :label="t('documents.back')"></ba-icon-link>
            </div>
          </div>
          <div class="preview__show-case">
            <div class="preview__type">Hover</div>
            <div class="preview__info">
              <ba-icon-link icon_type="far" icon_name="arrow-left" icon_class="u-pl2" :label="t('documents.back')"></ba-icon-link>
            </div>
          </div>
        </div>
  
        <!-- Link -->
        <div class="preview__block">
          <h4 class="preview__title">Link</h4>
          <div class="preview__show-case">
            <div class="preview__type">Default</div>
            <div class="preview__info">
              <a>Forgot your password or email?</a>
            </div>
          </div>
          <div class="preview__show-case">
            <div class="preview__type">Hover</div>
            <div class="preview__info">
              <a class="ba-link--hover">Forgot your password or email?</a>
            </div>
          </div>
        </div>
  
        <!-- Language Selector -->
        <div class="preview__block">
          <h4 class="preview__title">Language Selector</h4>
          <div class="preview__show-case">
            <div class="preview__type">Default</div>
            <div class="preview__info">
              <ba-language-selector/>
            </div>
          </div>
        </div>
  
        <!-- Tooltip (popover) -->
        <div class="preview__block">
          <h4 class="preview__title">Tooltip (Popover)</h4>
          <div class="preview__show-case">
            <div class="preview__type">Default</div>
            <div class="preview__info">
              <el-popover
                placement="top-start"
                title="Title"
                width="200"
                trigger="hover"
                content="this is content, this is content, this is content">
                <div slot="reference"><i class="el-icon-info"></i></div>
              </el-popover>
            </div>
          </div>
        </div>
  
        <!-- Tabs -->
        <div class="preview__block">
          <h4 class="preview__title">Tabs</h4>
          <div class="preview__show-case">
            <div class="preview__type">Default</div>
            <div class="preview__info">
              <ba-tab sign="Sign Up"></ba-tab>
            </div>
          </div>
        </div>
        
      </el-col>
      
      
      <el-col :span="8">
        <h2>Colors</h2>
        <div class="colors-list">
          <div class="colors-list__palette">
            <div class="colors-list__palette_color-primary">Primary</div>
            <div class="colors-list__palette_color-success">Success</div>
            <div class="colors-list__palette_color-danger">Danger</div>
            <div class="colors-list__palette_color-warning">Warning</div>
            <div class="colors-list__palette_color-info">Info</div>
            <div class="colors-list__palette_color-link">Link</div>
            <div class="colors-list__palette_color-text">Text Color</div>
            <div class="colors-list__palette_color-text--secondary">Text secondary</div>
            <div class="colors-list__palette_color-text--disabled">Text disable</div>
          </div>
        </div>
      </el-col>
    </el-row>
  
  </div>
</template>

<script>
  import alert from '../../ui/alert.component'
  import iconLink from '../../ui/icon-link.component'
  import baTab from '../../ui/tab.component'
  import baLanguageSelector from '../../components/language-selector.component'

  export default {
    name: 'preview',
    components: {
      'ba-alert': alert,
      'ba-icon-link': iconLink,
      'ba-language-selector': baLanguageSelector,
      'ba-tab': baTab,
    },
    data () {
      return {
        radioButton: {
          radio1: '',
          radio2: '',
          radio3: '1',
          radio4: '',
        },
        checkBox: {
          value1: false,
          value2: true,
          value3: false,
        },
      }
    }
  }
</script>

<style lang="scss">
  @import '../../sass/_variables.scss';
  
  .colors-list {
    &__palette {
      width: 100%;
      & > div {
        height: 80px;
        margin-bottom: 10px;
        color: $color-white;
        padding: 20px;
      }
      &_color {
        &-primary {
          background-color: $color-primary;
        }
        &-success {
          background-color: $color-success;
        }
        &-danger {
          background-color: $color-danger;
        }
        &-warning {
          background-color: $color-warning;
        }
        &-info {
          background-color: $color-info;
        }
        &-link {
          background-color: $color-link;
        }
        &-text {
          background-color: $color-text;
        }
        &-text--secondary {
          background-color: $color-text--secondary;
        }
        &-text--disabled {
          background-color: $color-text--disabled;
        }
      }
    }
  }
  .preview {
    margin: 50px 0;
    &__block {
      margin-top: 60px;
    }
    &__show-case {
      display: flex;
      align-items: center;
      margin-top: 30px;
      width: 490px;
    }
    &__type {
      width: 100px;
    }
    &__info {
      width: 390px;
      .label[role="radio"] {
        width: 100%;
      }
    }
    &__title {
      font-weight: 600;
      font-size: 20px;
      color: $color-text--secondary;
      padding-left: 100px;
    }
  }
  
  // Inputs preview
  .u-input-preview {
    &--hover {
      & > .el-input__inner {
        border-color: #C0C0C0 !important;
      }
    }
    &--focused {
      & > .el-input__inner {
        border-color: $color-primary !important;
      }
    }
  }
  // Radio Button preview
  .u-radio-preview--hover {
    padding-top: 10px !important;
    border-color: $color-primary !important;
  }
  
  // Buttons
  .btn-primary {
    &-hover {
      color: $color-white;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), $color-primary !important;
    }
  }
  
  // Icon Link
  .ba-icon-link--hover {
    color: $color-primary !important;
  }
  
  // Link
  .ba-link--hover {
    text-decoration: underline;
  }
  
</style>
