<template>
  <div class="pay-schedule__root">
    <div v-if="accountHistory && policy" class="list-block">
      <ul class="invoices-list u-pl0">
        <li class="invoices-list__item" v-for="invoiceOrPayment in filteredHistory" @click="clickOnInvoice(invoiceOrPayment)">
          <div class="item-content">
            <div class="item-inner u-mt3 u-mb3">
              <div class="u-mt2 u-mb2">
                ${{ invoiceOrPayment.amount | currency(2) }}{{ getDisplayDateText(invoiceOrPayment) }}
              </div>
              <el-badge class="badge u-mt1 u-mb3" :class="getInvoiceStyles(invoiceOrPayment).div_class">
                {{ getInvoiceStyles(invoiceOrPayment).span_text }}
              </el-badge>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  // Component structure replicated from '.pages/payments/pay-schedule.page.vue'
  
  import {mapGetters, mapState} from 'vuex'
  import invoiceToText from '@shared_src/invoice-details'
  
  export default {
    name: 'pay-schedule',
    data () {
      let policyId = this.$route.params.policyId
      return {
        policyId,
      }
    },
    methods: {
      getInvoiceStyles (invoice) {
        if (invoice.type === 'Payment') {
          return {
            div_class: 'badge-payment',
            span_text: 'Payment',
          }
        }
        if (invoice.data.details.paidInFull) {
          return {
            div_class: 'badge-success',
            span_text: 'Invoice',
          }
        } else {
          if (new Date() > new Date(invoice.data.details.dueDate)) {
            return {
              div_class: 'badge-danger',
              span_text: 'Invoice',
            }
          } else {
            return {
              div_class: 'badge-default',
              span_text: 'Invoice',
            }
          }
        }
      },
      clickOnInvoice (invoiceOrPayment) {
        if (invoiceOrPayment.type === 'Invoice') {
          let fileKey = invoiceOrPayment.fileKey
          this.$router.push({path: `/payments/invoice-view/${fileKey}`})
        } else if (invoiceOrPayment.type === 'Payment') {
          this.$alert(
            invoiceToText(invoiceOrPayment),
            'Payment details',
            {dangerouslyUseHTMLString: true}
          )
        }
      },
      getDisplayDateText (invoiceOrPayment) {
        if (invoiceOrPayment.type === 'Payment') {
          return ' on ' + invoiceOrPayment.date
        }
        return ' due on ' + invoiceOrPayment.data.details.dueDate
      },
    },
    computed: {
      ...mapState('common', [
        'settings',
      ]),
      ...mapGetters('policies', [
        'policyById'
      ]),
      ...mapGetters('payments', ['acctHistoryByPolicyId']),
      accountHistory () {
        return this.acctHistoryByPolicyId(this.policyId)
      },
      filteredHistory () {
        let history = this.accountHistory
        return history.filter(
          _ => (this.showPayments && _.type === 'Payment') || (this.showInvoices && _.type === 'Invoice'))
      },
      showPayments () {
        let value = this.setting('payments.payment_schedule')
        return value === 'payments' || value === 'invoices_payments'
      },
      showInvoices () {
        let value = this.setting('payments.payment_schedule')
        return value === 'invoices' || value === 'invoices_payments'
      },
      policy () {
        return this.policyById(this.policyId)
      },
    },
  }
</script>
<style scoped lang="scss">
  @import '../../../sass/_variables.scss';

  .invoices-list {
    &__item {
      cursor: pointer;
      transition: opacity .3s ease;
      &:hover {
        opacity: .7;
        transition: opacity .3s ease;
      }
    }
  }
  
  .badge {
    font-size: 1em;
    width: 100%;
    text-align: center;
    max-width: 150px;
    color: white;
    padding: 3px 0 3px 0;
    border-radius: 3px;
  }
  
  .badge-success {
    background-color: $color-success;
  }
  
  .badge-danger {
    background-color: $color-danger;
  }
  
  .badge-default {
    background-color: $color-text--secondary;
  }
  
  .badge-payment {
    background-color: $color-info;
  }
  .item-content, .list-block {
    position: relative;
  }
  .item-inner {
    display: flex;
    justify-content: space-between;
    
    @media only screen and (max-width: $s-screen) {
      display: block;
    }
  }
  
  .item-inner:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    right: auto;
    top: auto;
    height: 1px;
    width: 100%;
    background-color: #c8c7cc;
    opacity: 0.5;
    display: block;
    z-index: 15;
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
  }
</style>
