<template>
  <div class="submenu">
    <div class="submenu__menu-outer">
      <ba-add-missing-policy v-if="screenType === 'policies'" class="u-mt1"/>
      <div v-if="policiesListByType" class="submenu__nested-menu u-mt4">
        <div v-for="(type, index) in getPoliciesTypes" :key="index">
          <h2 v-if="policyTitle(policiesListByType, type).length > 0" class="u-mb3 submenu__link-type-title">
            {{ policyTitle(policiesListByType, type) }}
          </h2>
          <div class="submenu__block-links u-mb2">
            <router-link v-for="policy in policiesListByType[type]" :key="policy.id" :to="generateMenuLink(screenType, policy.id)"
                         :class="[linkActiveClass(policy.id), 'submenu__link']">
              <div class="u-p1">
                <div class="submenu__link-line-1">
                  <fa-icon :icon="determinePolicyIcon(policy)" :class="'link-icon-medium'" />
                  <span class="submenu__link_bold u-pl1">{{ policyLine1Heading(policy) }}</span>
                </div>
                <div>{{  policy.policy_number }}</div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="submenu__view">
      <router-view :key="$route.fullPath"></router-view>
    </div>
  </div>
</template>

<script>
import BaAddMissingPolicy from '../pages/add-missing-policy/add-missing-policy.modal'

import {mapGetters} from 'vuex'

export default {
  components: {
    BaAddMissingPolicy,
  },
  name: 'policy-list',
  props: {
    policiesListByType: {
      type: Object,
      required: true,
    },
    screenType: {
      type: String,
      required: true,
    }
  },
  computed: {
    ...mapGetters('policies', ['determinePolicyIcon', 'riskLabelByPolicy', 'riskDataForPolicyCard']),
    getPoliciesTypes () {
      return Object.keys(this.policiesListByType)
    },
  },
  methods: {
    generateMenuLink (screenType, policyId) {
      let link = `/${screenType}/${policyId}`
      return (screenType === 'documents') ? link + '/folders/root' : link
    },
    linkActiveClass (policyId) {
      const boundRouteList = [ '/document-view', '/documents' ]
      const url = decodeURIComponent(this.$route.fullPath)
      let res = ''
      boundRouteList.reduce((prev, item) => {
        if (url.includes(item) && url.includes(policyId)) {
          res = 'router-link-exact-active'
        }
      }, '')
      return res
    },
    riskData (policy) {
      return this.riskDataForPolicyCard(policy.id)
    },
    policyLine1Heading (policy) {
      if (policy.isGen3) {
        let riskData = this.riskData(policy)
        if (riskData) {
          let totalRisks = riskData.length
          let label = this.riskLabelByPolicy(policy, true)
          if (totalRisks > 1) {
            return `${totalRisks} ${label}`
          } else {
            return policy.policy_type
          }
        } else {
          return policy.policy_type
        }
      }
      if (policy.properties && policy.properties.length > 0) {
        return policy.properties[0].address_line1
      } else {
        return policy.policy_type
      }
    },

    policyHeadline (policy) {
      let heading = policy.policy_type
      let data = this.risks.find(p => p.policyId === policy.id)
      if (data != null && data.riskData.description != null) {
        return heading + ' - ' + data.riskData.description
      }

      if (policy.properties.length === 0) {
        return heading
      }

      if (policy.properties.length === 1) {
        return heading + ' - ' + policy.properties[0].address_line1
      }
      if (policy.properties.length > 1) {
        return heading + ' - ' + this.t('payments.properties_number', {number: policy.properties.length})
      }
    },
    policyTitle (data, type) {
      if (type === 'active' && data[type].length > 0) {
        return this.t('policies.active')
      }
      if (type === 'canceled' && data[type].length > 0) {
        return this.t('policies.canceled')
      }
      return ''
    }
  }
}
</script>

<style>

</style>
