module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: (_vm.loading),
      expression: "loading"
    }]
  }, [_c('div', {
    staticClass: "u-mt4 u-mb4"
  }, [_c('h2', {
    staticClass: "secondary-header"
  }, [_vm._v(_vm._s(_vm.t('enrollment.document_preferences')))])]), _vm._v(" "), _c('div', [_c('el-radio-group', {
    model: {
      value: (_vm.eDocumentsEnabled),
      callback: function($$v) {
        _vm.eDocumentsEnabled = $$v
      },
      expression: "eDocumentsEnabled"
    }
  }, [_c('div', [_c('label', {
    staticClass: "el-radio u-mt3",
    attrs: {
      "role": "radio"
    }
  }, [_c('span', {
    staticClass: "el-radio__input",
    class: _vm.eDocumentsEnabled ? 'is-checked' : ''
  }, [_c('span', {
    staticClass: "el-radio__inner"
  }), _vm._v(" "), _c('input', {
    staticClass: "el-radio__original",
    attrs: {
      "type": "radio"
    },
    domProps: {
      "checked": _vm.eDocumentsEnabled
    },
    on: {
      "click": function($event) {
        return _vm.onDocDeliveryUpdate(true)
      }
    }
  })]), _vm._v(" "), _c('span', {
    staticClass: "el-radio__label u-text-capitalize"
  }, [_vm._v(_vm._s(_vm.t('enrollment.go_paperless')) + "*")])])]), _vm._v(" "), _c('div', [_c('el-radio', {
    staticClass: "u-mt3",
    attrs: {
      "label": false,
      "checked": !_vm.eDocumentsEnabled
    },
    on: {
      "change": function($event) {
        return _vm.onDocDeliveryUpdate(false)
      }
    }
  }, [_vm._v("\n          " + _vm._s(_vm.t('enrollment.receive_paper_documents')) + "\n        ")])], 1)]), _vm._v(" "), _c('div', {
    staticClass: "subheader u-mt4 u-mb0"
  }, [_vm._v("\n      " + _vm._s(_vm.t('enrollment.documents_will_always_be_available_from_the_app')) + "\n    ")]), _vm._v(" "), _c('div', {
    staticClass: "subheader u-mt2 u-mb0"
  }, [_vm._v("\n      *" + _vm._s(_vm.t('enrollment.you_will_receive_an_email_when_documents_are_ready_to_be_viewed')) + "\n    ")])], 1), _vm._v(" "), _c('dialog-info', {
    attrs: {
      "visibleDialogInfo": _vm.visibleDialogInfo,
      "title": _vm.t('e_delivery.acknowledge_info_title'),
      "description": _vm.t('e_delivery.acknowledge_info')
    },
    on: {
      "userApproved": _vm.userApprovedDeliveryType,
      "handleCloseDialog": _vm.handleCloseDialog
    }
  })], 1)
},staticRenderFns: []}