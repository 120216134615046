module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', [_c('bc-navbar', {
    attrs: {
      "title": _vm.t('payments.payments_label')
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "submenu"
  }, [_c('div', {
    staticClass: "submenu__menu-outer"
  }, [_c('div', {
    staticClass: "submenu__nested-menu u-mt4"
  }, [_c('div', {
    staticClass: "submenu__block-links u-mb2"
  }, [_c('router-link', {
    staticClass: "submenu__link",
    attrs: {
      "to": "/payments"
    }
  }, [_c('div', {
    staticClass: "submenu__link-line-1 u-p1"
  }, [_c('span', {
    staticClass: "submenu__link_bold"
  }, [_vm._v(_vm._s(_vm.t('payments.make_payments')))])])]), _vm._v(" "), _c('router-link', {
    staticClass: "submenu__link",
    attrs: {
      "to": "/payments/payment-methods"
    }
  }, [_c('div', {
    staticClass: "submenu__link-line-1 u-p1"
  }, [_c('span', {
    staticClass: "submenu__link_bold"
  }, [_vm._v(_vm._s(_vm.t('payments.payment_methods')))])])]), _vm._v(" "), (_vm.displayPaymentsPlans) ? _c('router-link', {
    staticClass: "submenu__link",
    attrs: {
      "to": "/payments/payment-plans"
    }
  }, [_c('div', {
    staticClass: "submenu__link-line-1 u-p1"
  }, [_c('span', {
    staticClass: "submenu__link_bold"
  }, [_vm._v(_vm._s(_vm.t('payments.payment_plans')))])])]) : _vm._e(), _vm._v(" "), _c('router-link', {
    class: [_vm.isActivePayHistoryLink, 'submenu__link'],
    attrs: {
      "to": _vm.getFirstPolicyLink
    }
  }, [_c('div', {
    staticClass: "submenu__link-line-1 u-p1"
  }, [_c('span', {
    staticClass: "submenu__link_bold"
  }, [_vm._v(_vm._s(_vm.t('payments.payment_history')))])])]), _vm._v(" "), (_vm.setting('payments.allow_payment_reminders')) ? _c('router-link', {
    staticClass: "submenu__link",
    attrs: {
      "to": "/payments/reminders"
    }
  }, [_c('div', {
    staticClass: "submenu__link-line-1 u-p1"
  }, [_c('span', {
    staticClass: "submenu__link_bold",
    domProps: {
      "innerHTML": _vm._s(_vm.t('payments.reminders_side_menu_link_title'))
    }
  })])]) : _vm._e()], 1)])]), _vm._v(" "), _c('div', {
    staticClass: "submenu__view"
  }, [_c('router-view', {
    key: _vm.$route.fullPath
  })], 1)])], 1)
},staticRenderFns: []}