<template>
  <div class="init-screen" v-loading="loading">
    <init-sidebar/>
    <div class="init-screen__info">
      <div class="init-block">
        <div class="navigation">
          <div class="init-block__back" @click="$router.back()">
            <ba-icon-link icon_type="far" icon_name="arrow-left" icon_class="u-pl2" :label="t('documents.back')"/>
          </div>
        </div>
        <h1 class="init-block--heading">{{ t('reset_password.forgot_password') }}</h1>
        <div class="subheader u-mb3" v-html="usersAreIdentifiedByEmailAddress ? t('reset_password.enter_email_address_when_created_your_account') : t('reset_password.enter_username_when_created_your_account')"></div>
        <el-form>
          <el-form-item :label="usersAreIdentifiedByEmailAddress? t('login.email') : t('enrollment.username')">
            <el-input @keyup.enter.native="searchAccount" v-model="search_input" :type="usersAreIdentifiedByEmailAddress ? 'email' : 'text'" class="item-input-field"/>
          </el-form-item>
        </el-form>
        <div class="u-mt2">
          <el-button :disabled="!search_input" @click="searchAccount" type="primary" class="el-button--wide u-text-uppercase">
            {{ t('global.submit') }}
          </el-button>
        </div>
        <div class="u-mt3">
          <a @click="showNotification">
            {{ usersAreIdentifiedByEmailAddress ? t('login.forgot_your_email') : t('login.forgot_your_username') }}
          </a>
        </div>
      </div>
    </div>
    <div class="init-screen__logo-language">
      <bc-footer bgClass="bg-login"></bc-footer>
      <div class="init-screen__bc-language" v-if="displayLanguageLink">
        <ba-language-selector/>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import {mapState, mapMutations} from 'vuex'
import config from '../../../config/britecore'
import settings from '../../../config/settings'
import initSidebar from '@/components/init-sidebar'
import Footer from '@/components/footer'
import alert from '@/ui/alert.component'
import BaLanguageSelector from '@/components/language-selector.component'
import iconLink from '@/ui/icon-link.component'

export default {
  name: 'bc-search-account-step',
  components: {
    initSidebar,
    'bc-footer': Footer,
    'ba-alert': alert,
    'ba-icon-link': iconLink,
    BaLanguageSelector,
  },
  computed: {
    ...mapState('passwordReset', ['unauthorizedUser']),
    usersAreIdentifiedByEmailAddress () {
      return this.setting('enrollment.users_are_identified_by_email_address')
    },
    displayLanguageLink () {
      return this.setting('global.display_spanish_link') === true
    },
  },
  methods: {
    ...mapMutations('passwordReset', ['setUnauthorizedUser']),
    getUnauthorizedContactData (searchInput, successCallback, failureCallback) {
      this.loading = true
      axios.post(config.get_unauthorized_contact_data, {search_input: searchInput, company_id: settings.company_id})
        .then((result) => {
          let data = result.data
          this.loading = false
          successCallback(data)
        })
        .catch(() => {
          console.log('Error occurred during unauthorized_contact_data fetch')
          this.loading = false
          failureCallback()
        })
    },
    searchAccount () {
      let thisVar = this
      thisVar.getUnauthorizedContactData(
        thisVar.search_input,
        (data) => {
          if (data.success) {
            if (data.emails.length === 0 && data.phones.length === 0) {
              thisVar.$elAlert(thisVar.t('reset_password.no_email_phone'), 'Note')
            } else {
              thisVar.user.emails = data.emails
              thisVar.user.phones = data.phones
              thisVar.user.name = data.username

              thisVar.setUnauthorizedUser(thisVar.user)
              console.log('hello')
              thisVar.$router.push('/password-reset/code-delivery')
            }
          } else {
            let message = 'Search failed'
            if (data.cause) {
              message = thisVar.causeToString(data.cause)
            }
            thisVar.$elAlert(message, thisVar.t('global.error'), null, thisVar.t('documents.close'))
          }
        },
        () => {
          thisVar.$elAlert(
            thisVar.t('reset_password.internal_error_occurred_during_account_search'), thisVar.t('global.error'))
//          thisVar.$router.push('')
//          thisVar.$router.load('')
        },
      )
    },
    showNotification () {
      this.$alert(
          this.t('login.contact_your_carrier_for_assistance'),
          this.t('global.notice'), {
            dangerouslyUseHTMLString: true,
          },
      )
    },
  },
  data () {
    return {
      visible: false,
      search_input: '',
      user: {
        name: '',
        emails: [],
        phones: [],
      },
      loading: false
    }
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .popover-description {
    padding: 15px 0;
    font-size: 15px;
  }
  .popover-btn-block {
    text-align: right;
  }
</style>
