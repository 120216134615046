<template>
  <div>
    <bc-navbar :title="t('dashboard.claims')"/>
    <bc-claims-list v-if="claimsToShow && claimsToShow.open.length > 0 || claimsToShow.closed.length > 0"
                    :claims="claimsToShow"/>
  </div>
</template>

<script>

import {mapGetters} from 'vuex'
import ClaimsList from './claims-list.component.vue'

export default {
  components: {
    'bc-claims-list': ClaimsList
  },
  computed: {
    ...mapGetters('claims', ['openClaims', 'closedClaims']),
    claimsToShow () {
      return {
        closed: this.closedClaims || [],
        open: this.openClaims || []
      }
    },
  },
}
</script>
<style scoped>

</style>
