module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: (_vm.loading),
      expression: "loading"
    }],
    staticClass: "init-screen"
  }, [_c('init-sidebar'), _vm._v(" "), _c('div', {
    staticClass: "init-screen__info",
    class: _vm.showPage !== 'search' ? 'centered-block' : ''
  }, [_c('div', {
    staticClass: "init-block"
  }, [_c('div', {
    staticClass: "navigation"
  }, [(_vm.showPage === 'search' && _vm.initialSearchAttempt) ? _c('div', {
    staticClass: "init-block__tabs"
  }, [_c('div', {
    staticClass: "tab-switch",
    on: {
      "click": function($event) {
        return _vm.activateTab('logIn')
      }
    }
  }, [_c('ba-tab', {
    attrs: {
      "sign": _vm.t('account.login_header'),
      "isActive": _vm.activeTypeTab === 'logIn',
      "type": "logIn"
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "tab-switch",
    attrs: {
      "data-cy": "link--sign-up"
    },
    on: {
      "click": function($event) {
        return _vm.activateTab('signUp')
      }
    }
  }, [_c('ba-tab', {
    attrs: {
      "sign": _vm.t('enrollment.sign_up'),
      "isActive": _vm.activeTypeTab === 'signUp',
      "type": "signUp"
    }
  })], 1)]) : _c('div', {
    staticClass: "init-block__back",
    on: {
      "click": function($event) {
        return _vm.goBack()
      }
    }
  }, [_c('ba-icon-link', {
    attrs: {
      "icon_type": "far",
      "icon_name": "arrow-left",
      "icon_class": "u-pl2",
      "label": _vm.t('documents.back')
    }
  })], 1)]), _vm._v(" "), (_vm.showPage == 'search') ? _c('div', [(_vm.userBasedEnrollment) ? _c('div', [(_vm.initialSearchAttempt) ? _c('div', [_c('h1', {
    staticClass: "init-block--heading"
  }, [_vm._v(_vm._s(_vm.t('global.sign_up')))]), _vm._v(" "), _c('div', {
    staticClass: "subheader u-mb3"
  }, [_vm._v(_vm._s(_vm.subheaderTexting))]), _vm._v(" "), _c('el-form', {
    nativeOn: {
      "submit": function($event) {
        $event.preventDefault();
      }
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.t('enrollment.last_name')
    }
  }, [_c('el-input', {
    attrs: {
      "type": "text"
    },
    nativeOn: {
      "keyup": function($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.findUserForEnrollment.apply(null, arguments)
      }
    },
    model: {
      value: (_vm.insuredLastName),
      callback: function($$v) {
        _vm.insuredLastName = $$v
      },
      expression: "insuredLastName"
    }
  })], 1), _vm._v(" "), (_vm.validateInputValuesDictCopy.lastName) ? _c('div', {
    staticClass: "u-warning u-mb3"
  }, [_c('i', {
    staticClass: "material-icons md-text",
    attrs: {
      "aria-hidden": "true"
    }
  }, [_vm._v("warning")]), _vm._v(" " + _vm._s(_vm.validateInputValuesDictCopy.lastName) + "\n              ")]) : _vm._e(), _vm._v(" "), _c('date-picker', {
    attrs: {
      "showPage": _vm.showPage
    },
    on: {
      "updateDatePicker": _vm.updateDatePicker
    }
  }), _vm._v(" "), (_vm.validateInputValuesDictCopy.dob) ? _c('div', {
    staticClass: "u-warning u-mb3"
  }, [_c('i', {
    staticClass: "material-icons md-text",
    attrs: {
      "aria-hidden": "true"
    }
  }, [_vm._v("warning")]), _vm._v(" " + _vm._s(_vm.validateInputValuesDictCopy.dob) + "\n              ")]) : _vm._e(), _vm._v(" "), _c('el-form-item', [_c('el-button', {
    staticClass: "el-button--wide u-text-uppercase",
    attrs: {
      "type": "primary",
      "disabled": !_vm.validDate
    },
    on: {
      "click": _vm.findUserForEnrollment
    }
  }, [_vm._v("\n                  " + _vm._s(_vm.t('enrollment.search_insured_button_caption')) + "\n                ")])], 1)], 1)], 1) : _vm._e(), _vm._v(" "), (!_vm.initialSearchAttempt) ? _c('div', [_c('h1', [_vm._v(_vm._s(_vm.t('enrollment.account_not_found')))]), _vm._v(" "), _c('p', {
    staticClass: "u-mt1",
    domProps: {
      "innerHTML": _vm._s(_vm.t('enrollment.account_not_found_message'))
    }
  }), _vm._v(" "), _c('el-form', {
    nativeOn: {
      "submit": function($event) {
        $event.preventDefault();
      }
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.t('enrollment.policy_number_input_label')
    }
  }, [_c('el-input', {
    class: {
      'u-input-error': _vm.validateInputValuesDictCopy.policyNumber
    },
    attrs: {
      "type": "text",
      "data-test-id": "policy-number"
    },
    nativeOn: {
      "keyup": function($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.linkPolicy.apply(null, arguments)
      }
    },
    model: {
      value: (_vm.policy_number),
      callback: function($$v) {
        _vm.policy_number = $$v
      },
      expression: "policy_number"
    }
  }), _vm._v(" "), (_vm.validateInputValuesDictCopy.policyNumber) ? _c('div', {
    staticClass: "u-input-validation u-danger u-pt2 u-pb1"
  }, [_vm._v("\n                  " + _vm._s(_vm.validateInputValuesDictCopy.policyNumber) + "\n                ")]) : _vm._e()], 1), _vm._v(" "), _c('el-form-item', [_c('el-button', {
    staticClass: "el-button--wide u-text-uppercase",
    attrs: {
      "type": "primary",
      "data-test-id": "search-policy"
    },
    on: {
      "click": _vm.linkPolicy
    }
  }, [_vm._v("\n                  " + _vm._s(_vm.t('enrollment.search_insured_button_caption')) + "\n                ")])], 1), _vm._v(" "), _c('div', {
    staticClass: "u-text-center"
  }, [_c('el-popover', {
    attrs: {
      "placement": "top-start",
      "title": "",
      "width": "300",
      "trigger": "click",
      "content": _vm.t('login.contact_your_carrier_for_assistance')
    }
  }, [_c('div', {
    attrs: {
      "slot": "reference"
    },
    slot: "reference"
  }, [_c('a', {
    domProps: {
      "innerHTML": _vm._s(_vm.t('enrollment.can_not_find_your_policy_number'))
    }
  })])])], 1)], 1)], 1) : _vm._e()]) : _c('div', [_c('h1', {
    staticClass: "init-block--heading"
  }, [_vm._v(_vm._s(_vm.t('enrollment.sign_up')))]), _vm._v(" "), _c('div', {
    staticClass: "subheader u-mb3"
  }, [_vm._v(_vm._s(_vm.subheaderTexting))]), _vm._v(" "), _c('el-form', {
    nativeOn: {
      "submit": function($event) {
        $event.preventDefault();
      }
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.t('enrollment.policy_number_input_label')
    }
  }, [_c('el-input', {
    class: {
      'u-input-error': _vm.validateInputValuesDictCopy.policyNumber
    },
    attrs: {
      "data-cy": "policy-number",
      "type": "text",
      "data-test-id": "policy-number"
    },
    nativeOn: {
      "keyup": function($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.linkPolicy.apply(null, arguments)
      }
    },
    model: {
      value: (_vm.policy_number),
      callback: function($$v) {
        _vm.policy_number = $$v
      },
      expression: "policy_number"
    }
  }), _vm._v(" "), (_vm.validateInputValuesDictCopy.policyNumber) ? _c('div', {
    staticClass: "u-input-validation u-danger u-pt2 u-pb1"
  }, [_vm._v("\n                " + _vm._s(_vm.validateInputValuesDictCopy.policyNumber) + "\n              ")]) : _vm._e()], 1), _vm._v(" "), _c('el-form-item', [_c('el-button', {
    staticClass: "el-button--wide u-text-uppercase",
    attrs: {
      "data-cy": "search-policy",
      "type": "primary",
      "data-test-id": "search-policy"
    },
    on: {
      "click": _vm.linkPolicy
    }
  }, [_vm._v("\n                " + _vm._s(_vm.t('enrollment.search_insured_button_caption')) + "\n              ")])], 1)], 1)], 1)]) : _vm._e(), _vm._v(" "), (_vm.showPage == 'insuredSelection') ? _c('div', [_c('h1', [_vm._v(_vm._s(_vm.t('enrollment.accounts_found')))]), _vm._v(" "), (_vm.insureds.length) ? _c('div', [_c('p', {
    staticClass: "u-mt1"
  }, [_vm._v(_vm._s(_vm.t('enrollment.please_identify_yourself')))]), _vm._v(" "), _c('el-form', [_vm._l((_vm.registered_insureds), function(insured, idx) {
    return _c('el-form-item', {
      key: insured.id,
      staticClass: "umb-1"
    }, [_c('el-radio', {
      staticClass: "el-radio--wide el-radio--main-bg",
      attrs: {
        "value": "false",
        "disabled": "",
        "border": ""
      }
    }, [_vm._v(_vm._s(insured.name) + " - " + _vm._s(_vm.t('enrollment.user_already_enrolled')))])], 1)
  }), _vm._v(" "), _vm._l((_vm.insureds), function(insured, idx) {
    return _c('el-form-item', {
      key: insured.id,
      staticClass: "umb-1"
    }, [_c('el-radio', {
      staticClass: "el-radio--wide el-radio--main-bg",
      attrs: {
        "data-cy": 'insured__' + insured.id,
        "label": insured.id,
        "border": ""
      },
      model: {
        value: (_vm.selectedInsuredId),
        callback: function($$v) {
          _vm.selectedInsuredId = $$v
        },
        expression: "selectedInsuredId"
      }
    }, [_vm._v(_vm._s(insured.name))])], 1)
  }), _vm._v(" "), _c('el-form-item', {
    staticClass: "u-mt2"
  }, [_c('el-button', {
    staticClass: "el-button--wide u-text-uppercase",
    attrs: {
      "data-cy": "pick-insured",
      "type": "primary",
      "data-test-id": "search-policy"
    },
    on: {
      "click": _vm.pickInsured
    }
  }, [_vm._v("\n                " + _vm._s(_vm.t('enrollment.verify_account')) + "\n              ")])], 1)], 2)], 1) : _vm._e()]) : _vm._e(), _vm._v(" "), (_vm.showPage == 'challenge') ? _c('div', [_c('h1', [_vm._v(_vm._s(_vm.t('enrollment.account_found')))]), _vm._v(" "), (_vm.challengeType == 'portal_code') ? _c('div', [_c('p', {
    staticClass: "u-mt3 u-mb3"
  }, [_vm._v("\n            " + _vm._s(_vm.t('enrollment.please_enter_your_security_code')) + "\n            "), _c('span', {
    staticClass: "portal-code-links"
  }, [(_vm.getContactMethods && _vm.getContactMethods.userEmail) ? _c('a', {
    on: {
      "click": function($event) {
        return _vm.showDialog('email')
      }
    }
  }, [_vm._v(_vm._s(_vm.t('enrollment.send_it_to_my_email')))]) : _vm._e(), _vm._v(" "), (_vm.getContactMethods && _vm.getContactMethods.userEmail && _vm.getContactMethods.userPhone) ? _c('span', [_vm._v(" " + _vm._s(_vm.t('enrollment.or')) + " ")]) : _vm._e(), _vm._v(" "), (_vm.getContactMethods && _vm.getContactMethods.userPhone) ? _c('a', {
    on: {
      "click": function($event) {
        return _vm.showDialog('phone')
      }
    }
  }, [_vm._v(_vm._s(_vm.t('enrollment.send_it_to_my_phone')))]) : _vm._e()])])]) : _vm._e(), _vm._v(" "), (_vm.challengeType == 'dob') ? _c('div', {
    staticClass: "u-mb2"
  }, [_vm._v(_vm._s(_vm.t('enrollment.please_enter_your_birthdate')))]) : _vm._e(), _vm._v(" "), (_vm.challengeType == 'ssn') ? _c('div', {
    staticClass: "u-mb2"
  }, [_vm._v(_vm._s(_vm.t('enrollment.please_enter_the_last_4_digits_of_your_ssn')))]) : _vm._e(), _vm._v(" "), _c('el-form', {
    nativeOn: {
      "submit": function($event) {
        $event.preventDefault();
      }
    }
  }, [(_vm.challengeType == 'portal_code' || _vm.challengeType == 'ssn') ? _c('el-form-item', {
    attrs: {
      "label": _vm.challengeType === 'ssn' ? _vm.t('enrollment.last_4_digits_of_ssn') : _vm.t('enrollment.portal_code')
    }
  }, [(_vm.challengeType === 'portal_code') ? _c('span', {
    staticClass: "portal-code-info"
  }, [_c('el-popover', {
    attrs: {
      "placement": "top-start",
      "title": "",
      "width": "325",
      "trigger": "hover"
    }
  }, [_c('span', {
    attrs: {
      "slot": "reference"
    },
    slot: "reference"
  }, [_c('fa-icon', {
    attrs: {
      "icon": "exclamation-circle"
    }
  })], 1), _vm._v(" "), _c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.t('enrollment.title_where_do_i_find_enrollment_code_message'))
    }
  })])], 1) : _vm._e(), _vm._v(" "), _c('el-input', {
    attrs: {
      "data-cy": "code-input",
      "autofocus": "",
      "type": "text",
      "maxlength": _vm.challengeType == 'ssn' ? 4 : 6
    },
    nativeOn: {
      "keyup": function($event) {
        return _vm.tryToConfirmCodeChallengeAndAdvance.apply(null, arguments)
      }
    },
    model: {
      value: (_vm.code),
      callback: function($$v) {
        _vm.code = $$v
      },
      expression: "code"
    }
  })], 1) : _vm._e(), _vm._v(" "), (_vm.challengeType == 'dob') ? _c('el-form-item', {
    staticClass: "dob-form-field"
  }, [_c('date-picker', {
    attrs: {
      "showPage": _vm.showPage
    },
    on: {
      "updateDatePicker": _vm.updateDatePicker,
      "willSubmit": _vm.tryToConfirmChallengeAndAdvance
    }
  })], 1) : _vm._e(), _vm._v(" "), (_vm.validateInputValuesDictCopy.dob) ? _c('div', {
    staticClass: "u-warning u-mb3"
  }, [_c('i', {
    staticClass: "material-icons md-text",
    attrs: {
      "aria-hidden": "true"
    }
  }, [_vm._v("warning")]), _vm._v(" " + _vm._s(_vm.validateInputValuesDictCopy.dob) + "\n          ")]) : _vm._e(), _vm._v(" "), _c('el-form-item', [(_vm.challengeType === 'dob') ? _c('el-button', {
    staticClass: "el-button--wide u-text-uppercase",
    attrs: {
      "disabled": !_vm.validDate,
      "type": "primary"
    },
    on: {
      "click": _vm.confirmChallengeAndAdvance
    }
  }, [_vm._v("\n              " + _vm._s(_vm.t('enrollment.verify_account')) + "\n            ")]) : _c('el-button', {
    staticClass: "el-button--wide u-text-uppercase",
    attrs: {
      "disabled": !_vm.code,
      "type": "primary",
      "data-cy": "code-confirm-button"
    },
    on: {
      "click": _vm.confirmChallengeAndAdvance
    }
  }, [_vm._v("\n              " + _vm._s(_vm.t('enrollment.verify_account')) + "\n            ")])], 1)], 1)], 1) : _vm._e(), _vm._v(" "), (_vm.showPage === 'notfound') ? _c('div', [_c('div', {
    staticClass: "u-mb3"
  }, [_c('h1', [_vm._v(_vm._s(_vm.t('enrollment.unable_to_find_your_account')))]), _vm._v(" "), _c('div', {
    staticClass: "subheader u-mt1"
  }, [(_vm.userBasedEnrollment && _vm.errorMessage && _vm.errorMessageDetail) ? _c('span', [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.errorMessage)
    }
  }), _vm._v(" - "), _c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.errorMessageDetail)
    }
  })]) : _c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.t('enrollment.no_account_found_information'))
    }
  })])])]) : _vm._e(), _vm._v(" "), (_vm.showPage === 'notVerified') ? _c('div', [_c('div', {
    staticClass: "u-mb3"
  }, [_c('h1', {
    domProps: {
      "innerHTML": _vm._s(_vm.errorMessage)
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "subheader u-mt1"
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.errorMessageDetail)
    }
  })])])]) : _vm._e()])]), _vm._v(" "), _c('div', {
    staticClass: "init-screen__logo-language"
  }, [_c('bc-footer', {
    attrs: {
      "bgClass": "bg-login"
    }
  }), _vm._v(" "), (_vm.displayLanguageLink) ? _c('div', {
    staticClass: "init-screen__bc-language"
  }, [_c('ba-language-selector')], 1) : _vm._e()], 1), _vm._v(" "), _c('el-dialog', {
    attrs: {
      "title": _vm.t('enrollment.please_select_how_you_wish'),
      "visible": _vm.showDialogContacts,
      "width": "40%",
      "close-on-press-escape": false,
      "close-on-click-modal": false,
      "show-close": false,
      "custom-class": "dialog-info"
    },
    on: {
      "update:visible": function($event) {
        _vm.showDialogContacts = $event
      }
    }
  }, [_c('div', [_c('el-form', _vm._l((_vm.actualMethods), function(contactMethod) {
    return _c('el-form-item', {
      key: contactMethod.id
    }, [_c('el-radio', {
      attrs: {
        "label": contactMethod
      },
      model: {
        value: (_vm.selectedContactMethod),
        callback: function($$v) {
          _vm.selectedContactMethod = $$v
        },
        expression: "selectedContactMethod"
      }
    }, [_vm._v(_vm._s(_vm.labelForContactMethod(contactMethod)))])], 1)
  }), 1)], 1), _vm._v(" "), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('div', {
    staticClass: "u-mt3 dialog-footer__portal-code"
  }, [_c('el-button', {
    attrs: {
      "type": "primary",
      "data-test-id": "search-policy",
      "disabled": !Object.keys(_vm.selectedContactMethod).length
    },
    on: {
      "click": _vm.chooseContactMethodId
    }
  }, [_vm._v("\n          " + _vm._s(_vm.t('enrollment.send_security_code')) + "\n        ")]), _vm._v(" "), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.closeDialogContacts
    }
  }, [_vm._v("\n           " + _vm._s(_vm.t('documents.documents_popover_menu_cancel')) + "\n        ")])], 1)])])], 1)
},staticRenderFns: []}