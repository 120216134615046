<template>
  <div>
     <bc-navbar :title="t('payments.payment_methods')"></bc-navbar>
     <ba-add-payment-method :available-insureds="insuredIds"></ba-add-payment-method>
    <div v-loading="loading" v-if="ownedPaymentMethods">
      <ba-alert v-if="!isSingleContactAccount" type="warning">
        {{ t('payments.duplicate_payment_methods_notification') }}
      </ba-alert>
      <div class="methods" v-for="paymentMethod in ownedPaymentMethods" :key="paymentMethod.id">
        <ba-single-payment-method :payment-method="paymentMethod" @willDelete="willDeletePaymentMethod"></ba-single-payment-method>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
// eslint-disable-next-line no-unused-vars
import {PaymentMethod} from '@shared_src/models/flow.typings'
import BaSinglePaymentMethod from '@/pages/payments-redesign/payment-methods/single-payment-method.component'
import BaAddPaymentMethod from '@/pages/payments-redesign/payment-methods/add-payment-method.component'
import alert from '@/ui/alert.component'

export default {
  name: 'payment-methods',
  components: {
    BaAddPaymentMethod,
    BaSinglePaymentMethod,
    'ba-alert': alert
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    ...mapActions('payments', ['deletePaymentMethod', 'getOwnedPaymentMethods']),

    processDeletePaymentMethodError (error, paymentMethod) {
      let errorCause = error.response.cause || 'payments.cannot_remove_pm'
      if (errorCause.key === 'payments.cannot_remove_pm_autopay') {
        // very specific scenario
        let data = errorCause.data
        let policiesWithAutoPaymentMethod = data.policies_with_auto_payment_method

        let actualPoliciesWithAutoPaymentMethod = policiesWithAutoPaymentMethod.filter(policyData =>
          policyData.terms.find(term => term.term_type !== 'previous')
        )

        if (actualPoliciesWithAutoPaymentMethod.length) {
          // inform user that cannot remove payment method
          let policiesListHtml = ''
          actualPoliciesWithAutoPaymentMethod.forEach(policyData => {
            policiesListHtml = policiesListHtml + policyData.policy_number + '</br>'
          })

          let msgHtml = this.t('payments.cannot_remove_pm_autopay', {policiesList: policiesListHtml})
          this.$elAlertHTML(msgHtml, paymentMethod.label)
          return
        }
      }
      this.$elAlert(this.t(errorCause), this.t('payments.cannot_remove_pm'))
    },

    async willDeletePaymentMethod (paymentMethod: PaymentMethod) {
      // ask user
      try {
        let slug = this.ownedPaymentMethods.length === 1
            ? 'payments.delete_warning_last_payment' : 'payments.delete_warning_default'
        await this.$confirm(this.t(slug), paymentMethod.label)
      } catch (e) {
        return
      }

      try {
        this.loading = true
        await this.deletePaymentMethod(paymentMethod.id)
      } catch (e) {
        this.loading = false
        this.processDeletePaymentMethodError(e, paymentMethod)
        return
      }
      try {
        await this.getOwnedPaymentMethods()
      } catch (e) {
        this.$elAlert(this.t('payments.failed_to_reload_contact'))
      }
      this.loading = false
    },
  },
  computed: {
    ...mapGetters('common', ['isSingleContactAccount']),
    ...mapGetters('account', ['insuredIds']),
    ...mapState('payments', ['ownedPaymentMethods']),
  },
}
</script>
