module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    staticClass: "pay-schedule__root"
  }, [(_vm.accountHistory && _vm.policy) ? _c('div', {
    staticClass: "list-block"
  }, [_c('ul', {
    staticClass: "invoices-list u-pl0"
  }, _vm._l((_vm.filteredHistory), function(invoiceOrPayment) {
    return _c('li', {
      staticClass: "invoices-list__item",
      on: {
        "click": function($event) {
          return _vm.clickOnInvoice(invoiceOrPayment)
        }
      }
    }, [_c('div', {
      staticClass: "item-content"
    }, [_c('div', {
      staticClass: "item-inner u-mt3 u-mb3"
    }, [_c('div', {
      staticClass: "u-mt2 u-mb2"
    }, [_vm._v("\n              $" + _vm._s(_vm._f("currency")(invoiceOrPayment.amount, 2)) + _vm._s(_vm.getDisplayDateText(invoiceOrPayment)) + "\n            ")]), _vm._v(" "), _c('el-badge', {
      staticClass: "badge u-mt1 u-mb3",
      class: _vm.getInvoiceStyles(invoiceOrPayment).div_class
    }, [_vm._v("\n              " + _vm._s(_vm.getInvoiceStyles(invoiceOrPayment).span_text) + "\n            ")])], 1)])])
  }), 0)]) : _vm._e()])
},staticRenderFns: []}