import axios from 'axios'

export default async function detectIP () {
  let data = await axios.get('https://ident.me/.json')
  try {
    if (data.status === 200) {
      return data.data.address
    }
  } catch (e) {
    console.log('error: ', e)
  }
}
