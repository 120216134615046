<template>
  <div>
    <el-button @click="openDialog" type="info">{{ t('enrollment.add_missing_policy_label') }}</el-button>

    <el-dialog
        :visible.sync="state.dialogVisible"
        @handleCloseDialog="dialogWillClose"
        :close-on-press-escape="false"
        :close-on-click-modal="false">
      <div slot="title" class="el-dialog__title_border add-missing-policy__dialog-title">
        <el-button v-if="canGoBack && !state.loading"
                   @click="back" class="u-mr3"
                   :icon="['far', 'chevron-left']"
                   :type="'text'">
        </el-button>
        <div class="el-dialog__title">{{ dialogTitle }}</div>
      </div>
      <div>
        <div v-if="state.debug">
          <div>page `{{workflow.page}}`</div>
          <div>policy_number `{{state.policy_number}}`</div>
          <div>insured_id `{{searchState.selectedInsuredId}}`</div>
          <div>insured `{{searchState.selectedInsured}}`</div>
          <div>selectedContactMethod `{{challenge.selectedContactMethod}}`</div>
          <div>send_via `{{challenge.sendVia}}`</div>
          <div>code `{{challenge.code}}`</div>
          <div>emails: {{emails}}</div>
          <hr/>
        </div>
        <ba-search-by-policy-number v-if="workflow.page === 'search'" v-model="state.policy_number"/>
        <ba-insured-selection
            v-if="workflow.page === 'insuredSelection'"
            :insureds="searchState.insureds"
            :registered_insureds="searchState.registered_insureds"
            v-model="searchState.selectedInsuredId"
        />

        <ba-policy-challenge
            v-if="workflow.page === 'challenge'"
            @requestCode="requestCode"
            :challenge="challenge"
            :selectedInsured="searchState.selectedInsured"
            :errorMessage="state.errorMessageDetail"
        />
        <ba-code-delivery v-if="workflow.page === 'sendCode'"
            v-model="challenge.selectedContactMethod"
            :selectedInsured="searchState.selectedInsured"
            :sendVia="challenge.sendVia"/>

        <ba-use-different-email v-if="workflow.page === 'useAnotherEmail'"
                                v-model="emails.toUse"
                                :available-emails="emails.availableEmails"
                                :loading="state.loading"/>
        <ba-new-email-verification v-if="workflow.page === 'emailVerification'"
                                   v-model="emails.verificationCode"
                                   :email="emails.toUse"
                                   :verificationFailure="emails.verificationFailure" />

        <ba-missing-policy-added v-if="workflow.page === 'success'" :state="state" />

      </div>
      <div v-if="workflow.page !== 'success' || (workflow.page === 'success' && !state.loading)"
           slot="footer" class="u-flex-justify-content-end">
        <el-button v-loading="state.loading" :disabled="!canGoNext || state.loading" @click="next()" type="primary" class="u-text-capitalize">
          {{nextButtonText}}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import _ from 'lodash'

  import {mapActions, mapGetters} from 'vuex'
  import {computed, getCurrentInstance, reactive} from '@vue/composition-api'

  import {localize as t} from '../../utils/i18n'
  import settings from '../../../config/settings'
  import config from '@/../config/britecore'
  import { getCauseData } from '@/../shared_src/utils/api.utils'

  import BaSearchByPolicyNumber from '@/pages/add-missing-policy/search-by-policy-number.component'
  import BaInsuredSelection from '@/pages/add-missing-policy/insured-selection.component'

  import BaPolicyChallenge from '@/pages/add-missing-policy/policy-challenge.component'
  import BaCodeDelivery from '@/pages/add-missing-policy/code-delivery.component'
  import BaMissingPolicyAdded from '@/pages/add-missing-policy/missing-policy-added.component'
  import BaUseDifferentEmail from './use-different-email.component'
  import BaNewEmailVerification from './new-email-verification.component'
  import axios from 'axios'

  export default {
    components: {
      BaNewEmailVerification,
      BaUseDifferentEmail,
      BaMissingPolicyAdded,
      BaCodeDelivery,
      BaPolicyChallenge,
      BaInsuredSelection,
      BaSearchByPolicyNumber
    },
    setup () {
      let self = getCurrentInstance().proxy

      const workflow = reactive({
        page: 'search',
      })

      const state = reactive({
        debug: false,
        dialogVisible: false,
        loading: false,
        policy_number: '',
        errorMessage: '',
        errorMessageDetail: '',
      })

      const searchState = reactive({
        insureds: [],
        registered_insureds: [],
        selectedInsuredId: '',
        selectedInsured: null,
      })

      const challenge = reactive({
        type: '',
        code: '',
        // [email | phone]
        sendVia: '',
        sentMessage: null,
        selectedContactMethod: null,
      })

      const emails = reactive({
        toUse: '',
        verificationCode: '',
        availableEmails: [],
        verificationFailure: '',
      })

      let contactDataToAttach = null
      let unexpectedErrorMessage = 'Unexpected error occurred. Check network connection or try again latter'

      const canGoNext = computed(_canGoNext)
      const canGoBack = computed(_canGoBack)

      function openDialog () {
        state.dialogVisible = true
        resetState()
      }

      async function next () {
        // const workflowChain = ['search', 'insuredSelection', 'challenge', 'sendCode', 'success']
        //                                 ->                  ->           ->          ->
        //                                                                  <-
        // Or, alternatively, for organization, additional 2 steps included:
        //  ... challenge -> useAnotherEmail -> emailVerification -> success
        switch (workflow.page) {
        case 'search':
          await searchForPolicy()
          break
        case 'insuredSelection':
          goToChallenge()
          break
        case 'sendCode':
          await sendSecurityCode()
          break
        case 'challenge':
          await confirmChallenge()
          break
        case 'useAnotherEmail':
          await preVerifyEmail()
          break
        case 'emailVerification':
          await submitEmailVerificationCode()
          break
        case 'success':
          dialogWillClose()
          break
        }
      }

      function back () {
        switch (workflow.page) {
        case 'insuredSelection':
          goTo('search')
          break
        case 'challenge':
          let targetPage = 'insuredSelection'
          if (onlyOneInsuredOnPolicy()) {
            targetPage = 'search'
          }
          challenge.sentMessage = null
          state.errorMessageDetail = null
          goTo(targetPage)
          break
        case 'sendCode':
          goTo('challenge')
          break
        case 'emailVerification':
          goTo('useAnotherEmail')
          break
        }
      }

      function resetState () {
        workflow.page = 'search'
        challenge.sentMessage = null
        state.loading = false
        state.policy_number = ''
        challenge.code = ''
        state.errorMessageDetail = null
      }

      async function searchForPolicy () {
        beginLoading()
        let postData = {
          policy_number: state.policy_number,
          token: localStorage.token,
        }
        try {
          let response = await self.addMissingPolicy(postData)
          let resp = response.data
          if (!resp.success) {
            self.$elAlert(self.causeToString(resp.cause), self.t('global.error'))
            endLoading()
            return
          }
          if (resp.automatic_verification.available === true) {
            let promise = self.$store.dispatch('loadUserData')
            beginLoading()
            goTo('success')

            await promise
            endLoading()
            return
          }

          endLoading()
          searchState.insureds = resp.data
          searchState.registered_insureds = resp.registered_insureds
          searchState.selectedInsuredId = resp.data[0].id
          if (onlyOneInsuredOnPolicy()) {
            goToChallenge()
          } else {
            goTo('insuredSelection')
          }
        } catch (e) {
          endLoading()
          self.$elAlert('Failed to perform search. Check network connection or try again latter')
        }
      }

      function goTo (page) {
        workflow.page = page
      }

      function requestCode (via) {
        challenge.sendVia = via
        let actualMethods = searchState.selectedInsured.contactMethods.filter(el => el[via])
        challenge.selectedContactMethod = actualMethods[0]
        goTo('sendCode')
      }

      function setupCustomEmails () {
        let rawList = _.map(contactDataToAttach.contact.emails, (emailObj) => { return emailObj.email })
        let baEmails = _.map(self.account.insureds, insured => { return insured.email.email })
        rawList.push(...baEmails)
        rawList = _.compact(_.uniq(rawList))
        emails.availableEmails = rawList
        let baEmail = self.account.insureds[contactDataToAttach.insured_id].email.email
        emails.toUse = baEmail || ''

        goTo('useAnotherEmail')
      }

      async function confirmChallenge () {
        state.errorMessageDetail = null

        let payload = {
          company_id: settings.company_id,
          policy_number: state.policy_number,
          contact_id: searchState.selectedInsuredId,
          code: challenge.code,
          challengeType: challenge.type,
          token: localStorage.token
        }

        beginLoading()
        try {
          let response = await self.answerChallengeAndAttachContact(payload)
          endLoading()
          if (!response.data.success) {
            setErrorFromResponse()
            return
          }
          contactDataToAttach = response.data.data
          let promise = self.$store.dispatch('loadUserData')
          beginLoading()
          let alwaysCustomizeEmails = contactDataToAttach.offer_email_customization
          if (alwaysCustomizeEmails) {
            await promise
            endLoading()
            await setupCustomEmails()
          } else {
            goTo('success')
            await promise
            endLoading()
          }
        } catch (e) {
          endLoading()
          self.$elAlert(
            self.t('enrollment.error_msg_linked_policy_failure'),
            self.t('enrollment.error_msg_label')
          )
        }
      }

      function goToChallenge () {
        let challengeType
        let insured = _.find(searchState.insureds, {id: searchState.selectedInsuredId})
        searchState.selectedInsured = insured
        if (searchState.selectedInsured.ssn) {
          challengeType = 'ssn'
        } else if (searchState.selectedInsured.dob) {
          challengeType = 'dob'
        } else {
          challengeType = 'portal_code'
        }
        challenge.type = challengeType
        goTo('challenge')
      }

      async function sendSecurityCode () {
        beginLoading()
        let payload = buildPayloadToSendContactChallenge()
        try {
          let result = await self.sendContactChallenge(payload)
          endLoading()
          let ret = result.data
          if (!ret.success) {
            let message = ret.message !== 'General Error' ? ret.message : null
            message = message || self.t('enrollment.it_wasnt_possible_to_send_a_security_code')
            self.$elAlert(message, self.t('global.error'))
            return
          }

          securityCodeSent()
        } catch (e) {
          endLoading()
          self.$elAlert(self.t('enrollment.it_wasnt_possible_to_send_a_security_code'), self.t('global.error'))
        }
      }

      function securityCodeSent () {
        let targetMessage
        if (challenge.selectedContactMethod.email) {
          targetMessage = self.t('enrollment.your_security_code_was_sent_via_email',
            {email: challenge.selectedContactMethod.email})
        } else {
          targetMessage = self.t('enrollment.your_security_code_was_sent_via_sms',
            {number: challenge.selectedContactMethod.phone})
        }
        challenge.sentMessage = targetMessage

        goTo('challenge')
      }

      async function submitEmailVerificationCode () {
        resetEmailVerificationFailure()
        beginLoading()

        try {
          let response = await axios.post(config.confirm_email_by_code, {
            token: localStorage.token,
            confirmation_code: emails.verificationCode,
            insured_id: contactDataToAttach.insured_id,
            email: emails.toUse
          })
          endLoading()
          let cause = getCauseData(response)

          if (!cause.key) {
            didFailEmailVerificationWithReason(unexpectedErrorMessage)
            return
          }
          if (response.data.success) {
            await reloadAccountAndShowSuccess()
          } else {
            didFailEmailVerificationWithReason(t(cause.key, cause.params))
          }
        } catch (e) {
          didFailEmailVerificationWithReason(t('account.error_saving_contact_information'))
          endLoading()
        }
      }

      async function reloadAccountAndShowSuccess () {
        beginLoading()
        let promise = self.$store.dispatch('account/loadAccount')
        goTo('success')
        await promise
        endLoading()
      }

      async function preVerifyEmail () {
        // outcomes:
        // 1. need confirmation
        // 2. Added successfully
        // 3. Some error
        let needVerification = false

        // Happens when Britecore already have such email bound to this contact
        let confirmedAutomatically = false

        emails.verificationCode = ''
        resetEmailVerificationFailure()
        beginLoading()

        try {
          let response = await axios.post(config.change_email, {
            token: localStorage.token,
            new_email: emails.toUse,
            insured_id: contactDataToAttach.insured_id,
          })
          endLoading()
          let cause = getCauseData(response)

          if (response.data.success) {
            switch (cause.key) {
            case 'account.email_update_confirmation_sent':
              needVerification = true
              break
            case 'account.email_updated_immediately':
              confirmedAutomatically = true
              break
            }
          } else {
            // TODO: maybe this case should return true from backend?
            if (cause.key === 'account.no_actions_emails_equal') {
              confirmedAutomatically = true
            } else {
              self.$elAlert(t('account.error_saving_contact_information'), t('global.warning'))
              return
            }
          }
        } catch (e) {
          self.$elAlert(unexpectedErrorMessage)
          return
        }

        if (!needVerification && !confirmedAutomatically) {
          // Reached unexpected state, user will need to try again.
          return
        }

        if (needVerification) {
          goTo('emailVerification')
          return
        }

        if (confirmedAutomatically) {
          await reloadAccountAndShowSuccess()
        }
      }

      function resetEmailVerificationFailure () {
        emails.verificationFailure = ''
      }

      function didFailEmailVerificationWithReason (reason) {
        emails.verificationFailure = reason
      }

      function _canGoNext () {
        if (workflow.page === 'search') {
          return state.policy_number !== ''
        }
        if (workflow.page === 'challenge') {
          return challenge.code !== ''
        }
        if (workflow.page === 'useAnotherEmail') {
          return emails.toUse !== ''
        }
        if (workflow.page === 'emailVerification') {
          return emails.verificationCode !== ''
        }

        return true
      }

      function _canGoBack () {
        if (workflow.page === 'search') {
          return false
        }
        if (workflow.page === 'success') {
          return false
        }
        if (workflow.page === 'useAnotherEmail') {
          return false
        }
        return true
      }
      const dialogTitle = computed(() => {
        switch (workflow.page) {
        case 'search':
          return t('enrollment.add_missing_policy_label')
        case 'insuredSelection':
          return t('enrollment.accounts_found')
        case 'challenge':
          return t('enrollment.verify_policy')
        case 'sendCode':
          return t('enrollment.verify_policy')
        case 'useAnotherEmail':
          return t('enrollment.choose_email_step_headline')
        case 'emailVerification':
          return t('enrollment.choose_email_step_headline')
        default:
          return t('enrollment.add_missing_policy_label')
        }
      })

      const nextButtonText = computed(() => {
        if (workflow.page === 'insuredSelection') {
          return t('enrollment.verify_account')
        }
        if (workflow.page === 'success') {
          return t('global.done')
        }
        if (workflow.page === 'sendCode') {
          return t('enrollment.send_security_code')
        }
        return t('global.next')
      })

      function onlyOneInsuredOnPolicy () {
        return searchState.insureds.length === 1
      }

      function setErrorFromResponse () {
        let challengeMismatchErrs = {
          portal_code: 'policy_lookup_err_not_matching_code',
          ssn: 'policy_lookup_err_not_matching_ssn',
          dob: 'policy_lookup_err_not_matching_dob',
        }

        state.errorMessage = self.t('enrollment.policy_lookup_fail_reason')
        state.errorMessageDetail = self.t('enrollment.' + challengeMismatchErrs[challenge.type])
      }

      function buildPayloadToSendContactChallenge () {
        return {
          company_id: settings.company_id,
          policy_number: state.policy_number,
          contact_id: searchState.selectedInsuredId,
          contact_method_id: challenge.selectedContactMethod['id'],
          contact_method_type: challenge.selectedContactMethod.hasOwnProperty('email') ? 'emails' : 'phones',
          preferred_language: localStorage.getItem('languageCode')
        }
      }

      function beginLoading () {
        state.loading = true
      }

      function endLoading () {
        state.loading = false
      }

      function dialogWillClose () {
        state.dialogVisible = false
      }

      return {
        state,
        searchState,
        workflow,
        challenge,
        emails,
        openDialog,
        dialogWillClose,
        requestCode,
        canGoNext,
        canGoBack,
        nextButtonText,
        dialogTitle,
        next,
        back,
      }
    },
    name: 'ba-add-missing-policy',
    methods: {
      ...mapActions('enrollment', [
        'getInsuredDetailsFromPolicyNumber',
        'addMissingPolicy',
        'sendContactChallenge',
        'answerChallengeAndAttachContact',
        'findInsuredByProvidedData',
        'isInsuredEnrolled']),
    },
    computed: {
      ...mapGetters('account', ['account']),
    },
  }
</script>


<style scoped lang="scss">
  .add-missing-policy__dialog-title {
    display: flex;
    align-items: center;
  }
</style>

