<template>
  <div v-if="uiAgents.length" data-cy="agent-card">
    <div class="dashboard__outer-card_info u-height100">
      <div class="el-card__header">{{ t('agent.your_agent')}}</div>
      <div v-if="uiAgents.length < 5">
        <div v-for="agent in uiAgents" :key="agent.id" class="u-mb2">
          <agency-card-content :agentOrAgency="agent" :agentsAmount="uiAgents.length"/>
        </div>
      </div>
      <div v-else class="u-height100 u-flex-vertical-space-between">
        <h2>{{ t('agent.agents_number', { number: uiAgents.length }) }}</h2>
        <el-button @click="$router.push('/agents/list')" type="info" class="u-text-capitalize u-width100" data-test-id="contact-agent">
          {{ t('agent.view_agents') }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'

  import AgencyCardContent from '@/components/agency-card-content.vue'

  export default {
    name: 'agency-card',
    components: {
      AgencyCardContent
    },
    computed: {
      ...mapGetters('agents', ['uiAgents']),
    }
  }
</script>

