<template>
  <el-dialog
    v-if="dialogVisible"
    :visible.sync="dialogVisible"
    width="30%"
    custom-class="phone-number-dialog"
    :close-on-press-escape="false"
    @close="closeDialog"
  >
    <div v-loading="loading" class="ba-intl-tel-input__wrapper">
       <h2 class="secondary-header u-mt0 u-mb2">
         {{ t('enrollment.add_phone_link') }}
       </h2>
      <el-form class="form-edit-contact-info">
        <el-form-item class="u-max-width">
          <span class="subheader u-text-uppercase form-edit-contact-info__label">{{ t('enrollment.phone_number_input_label') }}</span>
          <ba-intl-tel-input
            @keyup.enter.native="saveButtonClick"
            v-model="uiPhone.phone"
            :class="uiPhone.validationMessage ? 'phone-number-u-warning' : ''"
            customClass="el-input__inner"
          >
          </ba-intl-tel-input>
          <div class="u-warning" v-if="uiPhone.validationMessage">
            <i class="material-icons md-text" aria-hidden="true">warning</i> {{ uiPhone.validationMessage }}
          </div>
        </el-form-item>
      </el-form>
      <el-button class="el-button--wide class u-text-uppercase"
                 type="primary"
                 @click="saveButtonClick"
      >
        {{ t('global.save_changes') }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { mapActions, mapMutations, mapState } from 'vuex'
  import { validateUSFormatPhoneNumber } from '@/shared/data-validators'
  import eventBus from '@shared_src/eventHub'
  import {isValidPhoneNumber} from 'libphonenumber-js'
  import BaIntlTelInput from '@/../shared_src/phone-wrap/intl-tel-input.wrapper'
  
  export default {
    name: 'new-phone-number',
    components: {
      BaIntlTelInput
    },
    data () {
      return {
        uiPhone: {
          phone: '',
          type: 'Cell',
          id: Math.round(Math.random()*1000000000),
          deleted: false,
          added: true,
          validationMessage: null
        },
        loading: false,
      }
    },
    computed: {
      ...mapState('common', ['userPhones', 'dialogNewPhoneNumber']),
      initPhones () {
        return this.userPhones
      },
      dialogVisible () {
        return this.dialogNewPhoneNumber && this.dialogNewPhoneNumber.isDialogVisible
      }
    },
    methods: {
      ...mapActions('common', ['updateUserPhoneNumbers']),
      ...mapMutations('common', ['handleDialogCreateClaim', 'handleDialogNewPhoneNumber']),
      closeDialog () {
        this.handleDialogNewPhoneNumber(false)
      },
      validatePhoneNumbers (val) {
        this.uiPhone.validationMessage = null
        if (this.uiPhone.deleted) {
          this.uiPhone.validationMessage = null
          return
        }
        let phone = val.trim()
        let isExistPhone = this.initPhones.some(item => {
          return (item.phone.length === this.uiPhone.phone.length) && (item.phone === this.uiPhone.phone)
        })
        if (isExistPhone) {
          this.uiPhone.validationMessage = this.t('global.phone_number_already_exists')
          return
        }
        if (phone.length === 0) {
          this.uiPhone.validationMessage = this.t('enrollment.phone_not_defined_notification')
          return
        } else {
          let fallbackValidity = validateUSFormatPhoneNumber(this.uiPhone.phone)
          let isValid = isValidPhoneNumber(this.uiPhone.phone, 'US')
          if (isValid || fallbackValidity) {
            this.uiPhone.validationMessage = null
          } else {
            this.uiPhone.validationMessage = this.t('enrollment.please_check_the_correct_phone_number_format')
            return
          }
        }
      },
      saveButtonClick () {
        this.validatePhoneNumbers(this.uiPhone.phone)
        if (this.uiPhone.validationMessage !== null) {
          this.$elAlert(this.t('enrollment.some_error_happened_notification'), this.t('global.notice'))
          return
        }
        this.saveData()
      },
      async saveData () {
        this.loading = true
        try {
          let result = await this.updateUserPhoneNumbers({
            initPhones: this.initPhones,
            updatedPhones: this.initPhones.concat(this.uiPhone)
          })
          if (!result.data.success) {
            this.$elAlert(this.t('account.error_saving_contact_information'), this.t('global.warning'))
          }
        } catch (e) {
          this.$elAlert(this.t('account.error_saving_contact_information'), this.t('global.warning'))
        } finally {
          this.updateStorageInfo()
        }
      },
      async updateStorageInfo () {
        this.loading = true
        try {
          await this.$store.dispatch('common/loadContact')
          eventBus.$emit('phone-added', this.uiPhone.phone)
        } catch (e) {
          this.$elAlert(this.t('enrollment.some_error_happened_notification'), this.t('global.error'))
        } finally {
          this.uiPhone.phone = ''
          this.loading = false
          this.closeDialog()
        }
      },
    }
  }
</script>

<style lang="scss">
  .phone-number-dialog {
    .el-dialog__header {
      padding: 0;
      top: 8px;
      margin-right: 5px;
      .el-dialog__headerbtn .el-dialog__close {
        font-size: 24px;
        color: #626262;
      }
    }
  }
</style>
