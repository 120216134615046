import Vue from 'vue'
import {localize as t} from '@/utils/i18n'

function policyHasNextTerm (policy) {
  if (!policy) return
  return policy.next_due > 0
}

export function policyDue (policy) {
  let res = {
    message: t('global.nothing_due'),
    dateTxt: '',
    amount: '$0.00',
    cssClass: '',
  }
  if (!policy) return res
  let currentDue = policy.current_due
  if (currentDue > 0) {
    let dueDate = new Date(policy.due_date)
    let currentDate = new Date()
    if (currentDate > dueDate) {
      res.dateTxt = t('payments.due_date')
      res.message = t('payments.overdue')
      res.amount = Vue.prototype.asCurrency(currentDue)
      res.cssClass = 'u-danger'
    } else {
      res.dateTxt = t('payments.due_date')
      res.message = t('payments.currently_due')
      res.amount = Vue.prototype.asCurrency(currentDue)
      res.cssClass = ''
    }
  } if (currentDue <= 0 && policyHasNextTerm(policy)) {
    res.dateTxt = t('payments.due_date')
    res.message = policy.ar_details.next_due_date
  }
  return res
}

export function defineSinglePaymentOptions (paymentType, policy, amount, cssClass, address) {
  if (!policy) return null
  return {
    paymentType,
    policyId: policy.id,
    currentAmount: amount,
    dollarAmount: Vue.prototype.asCurrency(amount),
    policyNumber: policy.policy_number,
    cssClass,
    address
  }
}

export function isPaymentAllowed (policy) {
  if (!policy) return false
  if (policy.is_canceled) return false
  return policy.payments_allowed
}

export function getCreditCardType (cardNumber) {
  let cardType = ''

  // https://stackoverflow.com/questions/9315647/regex-credit-card-number-tests
  if (/^4[0-9]{12}(?:[0-9]{3})?$/.test(cardNumber)) {
    cardType = 'VISA'
  } else if (
    /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/
      .test(cardNumber)) {
    cardType = 'MasterCard'
  } else if (/^3[47][0-9]{13}$/.test(cardNumber)) {
    cardType = 'AmericanExpress'
  // eslint-disable-next-line
  } else if (/^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/.test(cardNumber)) {
    cardType = 'Discover'
  }

  return cardType
}
