module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', [_c('bc-navbar', {
    attrs: {
      "title": _vm.t('payments.payment_methods')
    }
  }), _vm._v(" "), _c('ba-add-payment-method', {
    attrs: {
      "available-insureds": _vm.insuredIds
    }
  }), _vm._v(" "), (_vm.ownedPaymentMethods) ? _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: (_vm.loading),
      expression: "loading"
    }]
  }, [(!_vm.isSingleContactAccount) ? _c('ba-alert', {
    attrs: {
      "type": "warning"
    }
  }, [_vm._v("\n      " + _vm._s(_vm.t('payments.duplicate_payment_methods_notification')) + "\n    ")]) : _vm._e(), _vm._v(" "), _vm._l((_vm.ownedPaymentMethods), function(paymentMethod) {
    return _c('div', {
      key: paymentMethod.id,
      staticClass: "methods"
    }, [_c('ba-single-payment-method', {
      attrs: {
        "payment-method": paymentMethod
      },
      on: {
        "willDelete": _vm.willDeletePaymentMethod
      }
    })], 1)
  })], 2) : _vm._e()], 1)
},staticRenderFns: []}