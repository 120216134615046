<template>
  <div v-if="policies" class="dashboard__outer-card u-mt3" :class="{'card--has-photo': singlePolicyPhotoId}">
    <div class="el-card__header">{{ t('global.policies_header') }}</div>

    <div v-if="!hasAtLeastOnePolicy">
      <h2 class="subheader u-mb1" v-html="t('enrollment.you_have_no_active_policies')"></h2>
    </div>
  
    <div v-else-if="policies.policies.length === 1">
      <router-link :to="{ path: '/policies/' + policies.policies[0].id }" data-test-id="policy-link">
        <h2>{{ policies.policies[0].policy_type }}</h2>
        <h3 class="subheader u-mb2">
          <span>{{ t('payments.policy_#', {number: policies.policies[0].policy_number}) }}</span>
          <span :class="setPolicyClassByStatus(policies.policies[0])">({{policies.policies[0].policy_status }})</span>
        </h3>
      </router-link>
      <div v-if="singlePolicyPhotoId">
        <bc-general-image class="image-card__picture" :fileId="singlePolicyPhotoId"></bc-general-image>
      </div>
    </div>
  
    <div v-else-if="policies.policies.length < 5">
      <div v-for="(policy, index) in policies.policies" :key="policy.id" :class="{ 'u-mt2': index !== 0 }">
        <router-link :to="{ path: '/policies/' + policy.id }" :data-test-id="'policy-link-' + index">
          <h2>{{ policyHeadline(policy) }}</h2>
          <h3 class="subheader">
            <span>{{ t('payments.policy_#', {number: policy.policy_number}) }}</span>
            <span :class="setPolicyClassByStatus(policy)">({{  policy.policy_status }})</span>
          </h3>
        </router-link>
      </div>
    </div>
  
    <div v-else-if="policies.policies.length >= 5">
      <div class="list-block no-hairlines-between">
        <router-link :to="{ path: linkForPolicies }" class="item-link item-content" data-test-id="policy-link">
          <div class="item-inner">
            <h2 class="u-mb0 u-mt0">
              {{ t('payments.active_policies', { number:  policiesListByType.active.length }) }}
            </h2>
          </div>
          <div v-if="policiesListByType.canceled.length" class="policy-canceled-type subheader u-mt1 u-mb1">
            {{ t('policies.canceled_policies', { number: policiesListByType.canceled.length }) }}
          </div>
        </router-link>
      </div>
    </div>
  
    <ba-add-missing-policy class="u-mt4 u-mb2"/>
  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex'
  import BaAddMissingPolicy from '@/pages/add-missing-policy/add-missing-policy.modal'
  
  export default {
    name: 'policy-card',
    components: {
      BaAddMissingPolicy,
    },
    computed: {
      ...mapState('policies', ['policies']),
      ...mapGetters('policies', ['hasAtLeastOnePolicy', 'policiesListByType']),
      linkForPolicies () {
        if (!this.policies || this.policies.policies.length === 0) {
          return ''
        }
        if (this.policiesListByType && this.policiesListByType.active.length > 0) {
          return '/policies/' + this.policiesListByType.active[0].id
        } else {
          return '/policies/' + this.policies.policies[0].id
        }
      },
      singlePolicyPhotoId () {
        // will return photo id if policy-list length == 1 and property of this policy contains photos
        if (this.policies && this.policies.policies.length === 1) {
          let properties = this.policies.policies[0].properties
          if (properties && properties.length) {
            if (properties[0].photo && properties[0].photo.length) {
              return properties[0].photo
            }
          }
        }
        return null
      }
    },
    methods: {
      policyHeadline (policy) {
        let heading = policy.policy_type
        if (policy.properties.length === 0) {
          return heading
        }
        if (policy.properties.length === 1) {
          return heading + ' - ' + policy.properties[0].address_line1
        }
        if (policy.properties.length > 1) {
          return heading + ' - ' + this.t('payments.properties_number', {number: policy.properties.length})
        }
      },
      setPolicyClassByStatus (policy) {
        // actually Cancellation Pending is "Active" too, but let's highlight them too
        return (policy && policy.policy_status === 'Active') ? 'policy-active-type' : 'policy-canceled-type'
      },
    }
  }
</script>

<style scoped lang="scss">
  @import '~styles/_variables.scss';
  .policy {
    &-active-type {
      display: none;
    }
    &-canceled-type {
      color: $color-danger !important;
    }
  }
  
</style>
