/* eslint-disable */
import axios from 'axios'
import config from '../../config/britecore'


const messageCenterModule = {
  namespaced: true,
  state: {
    notifications: null,
    badge_counter: null,
  },
  mutations: {
    notificationsLoaded (state, payload) {
      state.notifications = payload
      state.badge_counter = payload.badge_counter
    },
  },
  actions: {
    async loadNotifications (context, payload) {
      try {
        let response = await axios.get(config.url + '/insured-notifications/',
        {headers: {Authorization: localStorage.token}})
        context.commit('notificationsLoaded', response.data)
      }
      catch (e) {

      }
    },
    async dismiss_all (context, payload) {
      let response = await axios.delete(config.url + '/insured-notifications/',
        {headers: {Authorization: localStorage.token}})
      context.dispatch('loadNotifications')
    },
    async dismiss_message (context, message) {
      let response = await axios.delete(`${config.url}/insured-notifications/${message.id}/`,
        {headers: {Authorization: localStorage.token}})
      context.dispatch('loadNotifications')
    },
    async mark_all_as_read (context, payload) {
      let notifications = context.state.notifications
      if (notifications === null) {
        return
      }

      let unread_messages = notifications.new
      let ids = unread_messages.map(_ => _.id)
      if (ids.length === 0) {
        return
      }
      let response = await axios.patch(config.url + '/insured-notifications/', {mark_as_read: ids},
        {headers: {Authorization: localStorage.token}})
    },
  },
  getters: {
    badge: (state, getters) => {
      if (state.badge_counter === null) {
        return null
      }

      if (state.badge_counter > 9) {
        return '9+'
      }
      return state.badge_counter
    },
    has_at_least_one_message: (state, getters) => {
      if (state.notifications === null) {
        return false
      }

      return state.notifications.new.length > 0 ||  state.notifications.seen.length > 0
    }
  }
}

export default messageCenterModule
