module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return (_vm.claimsList) ? _c('div', {
    staticClass: "submenu__nested-menu u-mt4"
  }, [_c('h2', {
    staticClass: "u-mb3 submenu__link-type-title"
  }, [_vm._v("\n    " + _vm._s(_vm.type === 'open' ? _vm.t('claims.open_claims') : _vm.t('claims.closed_claims')) + "\n  ")]), _vm._v(" "), _vm._l((_vm.claimsList), function(claim) {
    return _c('div', {
      key: claim.id
    }, [_c('router-link', {
      staticClass: "submenu__link",
      attrs: {
        "to": '/claims/' + claim.id
      }
    }, [_c('div', {
      staticClass: "u-p1"
    }, [_c('div', {
      staticClass: "submenu__link-line-1"
    }, [_c('span', {
      staticClass: "submenu__link_bold"
    }, [_vm._v(_vm._s(claim.claim_number))])]), _vm._v(" "), _c('div', [_vm._v("\n          " + _vm._s(_vm._f("dateFormat")(claim.loss_date, 'MM/DD/YYYY')) + "\n        ")])])])], 1)
  })], 2) : _vm._e()
},staticRenderFns: []}