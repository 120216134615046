import { library } from '@fortawesome/fontawesome-svg-core'

import {faCar} from '@fortawesome/pro-light-svg-icons/faCar'
import {faCar as faCarSolid} from '@fortawesome/pro-solid-svg-icons/faCar'
import { faRv } from '@fortawesome/pro-light-svg-icons/faRv'

import { faCaravanAlt } from '@fortawesome/pro-light-svg-icons/faCaravanAlt'
import { faTrailer } from '@fortawesome/pro-light-svg-icons/faTrailer'

import { faGolfCart } from '@shared_src/icons/custom/faGolfCart'
import { faRetroCar } from '@shared_src/icons/custom/faRetroCar'

library.add(faCarSolid)
library.add(faGolfCart)
library.add(faRetroCar)
library.add(faRv)
library.add(faCaravanAlt)
library.add(faCar)
library.add(faTrailer)
