module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    staticClass: "pay-history"
  }, [_c('div', {
    staticClass: "pay-history__heading u-flex-horizontal-space-between u-pt3 u-pb4"
  }, [_c('bc-navbar', {
    staticClass: "u-m0",
    attrs: {
      "title": _vm.t('payments.payment_history')
    }
  }), _vm._v(" "), _c('div', [_c('el-select', {
    attrs: {
      "placeholder": "Select"
    },
    on: {
      "change": function($event) {
        return _vm.changePolicy(_vm.value)
      }
    },
    model: {
      value: (_vm.value),
      callback: function($$v) {
        _vm.value = $$v
      },
      expression: "value"
    }
  }, _vm._l((_vm.policySelectList), function(item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.label,
        "value": item.value
      }
    })
  }), 1)], 1)], 1), _vm._v(" "), _c('pay-schedule', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: (_vm.loading),
      expression: "loading"
    }]
  })], 1)
},staticRenderFns: []}