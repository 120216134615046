/* eslint-disable */
import config from '../../config/britecore'
import settings from '../../config/settings'

export function urlForAttachment (fileId, resized = false) {
  let url = config.url + '/get_attachment_all/?file_id=' + fileId +
      '&company_id=' + settings.company_id + '&token=' + localStorage.token +
      '&resized=' + resized

  return url
}