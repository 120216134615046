<template>
  <div class="ba-tab u-pt2" :class="isActive ? 'is-active' : ''">
    <div class="ba-tab__icon">
      <sign-icon v-if="type === 'signUp'"/>
      <login-icon v-if="type === 'logIn'"/>
    </div>
    <div class="ba-tab__sign">
      {{ sign }}
    </div>
  </div>
</template>

<script>
  import signIcon from './icons/sign-icon'
  import logInIcon from './icons/login-icon'
  
  export default {
    name: 'ba-tab',
    components: {
      'sign-icon': signIcon,
      'login-icon': logInIcon
    },
    props: {
      sign: String,
      type: String,
      isActive: Boolean,
    },
  }
</script>

<style lang="scss">
  @import '../sass/_variables.scss';

  .ba-tab {
    height: 80px;
    text-align: center;
    color: #626262;
    border-bottom: 4px solid #E5E5E5;
    cursor: pointer;
    &:hover {
      color: $color-primary;
      border-bottom-color: darken($color-primary, 0.6);
      #icon-sign, #icon-login {
        path {
          fill: $color-primary;
        }
      }
    }
    &__icon {
      height: 30px;
      &_image {
        width: 30px;
      }
    }
    &__sign {
      text-transform: uppercase;
    }
    &--active {
    
    }
  }
  
  .is-active {
    color: $color-primary;
    border-bottom-color: darken($color-primary, 0.6);
    #icon-sign,
    #icon-login {
      path {
        fill: $color-primary;
      }
    }
  }
</style>
