module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', [_c('div', [_c('el-switch', {
    attrs: {
      "active-color": "#5AB67F",
      "inactive-color": "#CB6B0B",
      "active-text": _vm.t('global.accept'),
      "inactive-text": _vm.t('global.decline')
    },
    on: {
      "change": function($event) {
        return _vm.onChangeConsent()
      }
    },
    model: {
      value: (_vm.consentToText),
      callback: function($$v) {
        _vm.consentToText = $$v
      },
      expression: "consentToText"
    }
  })], 1), _vm._v(" "), _c('dialog-info', {
    attrs: {
      "visibleDialogInfo": _vm.visibleDialogInfo,
      "title": _vm.t('account.consent_text_info_title'),
      "description": _vm.t('account.consent_text_info', {
        company_name: _vm.companyName
      })
    },
    on: {
      "userApproved": _vm.userApprovedConsent,
      "handleCloseDialog": _vm.handleCloseDialog
    }
  })], 1)
},staticRenderFns: []}