<template>
  <div v-loading="!claimFromList">
    <div v-if="claimFromList">
      <div v-if="isGen3">
        <view-claim-gen3></view-claim-gen3>
      </div>
      <div v-else>
        <view-claim-gen2></view-claim-gen2>
      </div>
    </div>
  </div>
</template>


<script>
import ViewClaimGen2 from './claim-details.gen2.component.vue'
import ViewClaimGen3 from './claim-details.gen3.component.vue'

import { mapGetters } from 'vuex'

export default {
  components: {
    'view-claim-gen2': ViewClaimGen2,
    'view-claim-gen3': ViewClaimGen3,
  },
  computed: {
    ...mapGetters('claims', ['claimById']),
    isGen3 () {
      return this.claimFromList.isGen3
    },
    claimFromList () {
      return this.claimById(this.claimId)
    },
  },
  created () {
    if (this.$route.query && this.$route.query.newclaim) {
      this.$elAlert(this.t('claims.new_claim_just_submitted_alert_message'), this.t('global.notice'))
    }
  },
  data () {
    return {
      claimId: this.$route.params.claimId,
    }
  }
}
</script>

