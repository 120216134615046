<template>
  <div>
    <ba-password-field v-if="showPassword" v-model="currentPassword"
                       :inputLabel="t('account.currentPassword')"
                       :displayShowPasswordCheckbox="displayForCurrentPasswordShowPasswordCheckbox" @enterKeyPressed="enterKeyPressed"></ba-password-field>
    <ba-new-password ref="newPasswordRef"
                     @setNewPassword="setNewPassword"
                     :currentPassword="currentPassword"
                     :setNewPasswordButtonCaption="setNewPasswordButtonCaption"></ba-new-password>
  </div>
</template>

<script>
import BaNewPassword from '@/components/new-password.component'
import BaPasswordField from '@/components/password-field.component'

export default {
  name: 'ba-password-update',
  props: {
    displayForCurrentPasswordShowPasswordCheckbox: {type: Boolean, default: true},
    setNewPasswordButtonCaption: {type: String, default: null},
    showPassword: {type: Boolean, default: true},
  },
  components: {
    'ba-new-password': BaNewPassword,
    'ba-password-field': BaPasswordField,
  },
  computed: {},
  methods: {
    enterKeyPressed () {
      this.$refs.newPasswordRef.setNewPassword()
    },
    setNewPassword (newPassword) {
      this.$emit('updatePassword', {
        currentPassword: this.currentPassword,
        newPassword: newPassword
      })
    },
    resetPasswordFields () {
      this.currentPassword = ''
      this.$refs.newPasswordRef.resetState()
    }
  },
  data () {
    return {
      currentPassword: '',
    }
  },
}
</script>
