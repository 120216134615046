module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    staticClass: "reminder"
  }, [_c('bc-navbar', {
    staticClass: "u-mt0 u-mb4",
    attrs: {
      "title": _vm.t('payments.reminders_page_title')
    }
  }), _vm._v(" "), _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: (_vm.loading),
      expression: "loading"
    }]
  }, [(_vm.paymentReminders && _vm.accountData) ? _c('div', {
    staticClass: "reminder__page reminder__options"
  }, [_c('div', {
    staticClass: "reminder__info u-text-center"
  }, [_c('div', {
    staticClass: "u-text-uppercase u-mb1",
    domProps: {
      "innerHTML": _vm._s(_vm.t('payments.reminders_switch_label'))
    }
  }), _vm._v(" "), _c('el-switch', {
    attrs: {
      "active-text": _vm.t('global.on'),
      "inactive-text": _vm.t('global.off'),
      "active-color": "#5AB67F",
      "inactive-color": "#CB6B0B"
    },
    model: {
      value: (_vm.paymentReminders.enabled),
      callback: function($$v) {
        _vm.$set(_vm.paymentReminders, "enabled", $$v)
      },
      expression: "paymentReminders.enabled"
    }
  })], 1), _vm._v(" "), (_vm.paymentReminders.enabled) ? _c('div', [_c('div', {
    staticClass: "reminder__info_subtitle u-mb1 ",
    domProps: {
      "innerHTML": _vm._s(_vm.t('payments.reminders_schedule_help'))
    }
  }), _vm._v(" "), _c('el-checkbox-group', {
    model: {
      value: (_vm.paymentReminders.schedule),
      callback: function($$v) {
        _vm.$set(_vm.paymentReminders, "schedule", $$v)
      },
      expression: "paymentReminders.schedule"
    }
  }, _vm._l((_vm.remindersScheduleUiList), function(schedule) {
    return _c('div', {
      key: schedule,
      staticClass: "u-mb2"
    }, [_c('el-checkbox', {
      attrs: {
        "label": schedule
      }
    }, [_c('span', {
      domProps: {
        "innerHTML": _vm._s(_vm.t('payments.reminders_schedule_day', schedule))
      }
    })])], 1)
  }), 0), _vm._v(" "), (!_vm.isAnyScheduleChecked) ? _c('div', {
    staticClass: "u-warning"
  }, [_c('i', {
    staticClass: "material-icons md-text",
    attrs: {
      "aria-hidden": "true"
    }
  }, [_vm._v("warning")]), _vm._v(" "), _c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.t('payments.reminders_schedule_warning'))
    }
  })]) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "reminder__info_subtitle u-mt3 u-mb1",
    domProps: {
      "innerHTML": _vm._s(_vm.t('payments.reminders_types'))
    }
  }), _vm._v(" "), _c('el-checkbox-group', {
    model: {
      value: (_vm.checkList),
      callback: function($$v) {
        _vm.checkList = $$v
      },
      expression: "checkList"
    }
  }, _vm._l((_vm.reminderTypesToDisplay), function(el) {
    return _c('div', {
      key: el,
      staticClass: "u-mb2"
    }, [_c('el-checkbox', {
      attrs: {
        "label": el,
        "disabled": _vm.isReminderTypeDisabled(el)
      }
    }, [_c('span', {
      domProps: {
        "innerHTML": _vm._s(_vm.t('global.' + el))
      }
    }), _vm._v(" "), ((el === 'text') && !_vm.isConsentToText) ? _c('el-popover', {
      attrs: {
        "placement": "top-start",
        "title": "",
        "width": "325",
        "trigger": "hover"
      }
    }, [_c('span', {
      attrs: {
        "slot": "reference"
      },
      slot: "reference"
    }, [_c('fa-icon', {
      attrs: {
        "icon": "exclamation-circle"
      }
    })], 1), _vm._v(" "), _c('span', {
      domProps: {
        "innerHTML": _vm._s(_vm.t('payments.reminders_requires_consent_to_text'))
      }
    })]) : _vm._e(), _vm._v(" "), ((el === 'push') && !_vm.isPushAvailable) ? _c('el-popover', {
      attrs: {
        "placement": "top-start",
        "title": "",
        "width": "325",
        "trigger": "hover"
      }
    }, [_c('span', {
      attrs: {
        "slot": "reference"
      },
      slot: "reference"
    }, [_c('fa-icon', {
      attrs: {
        "icon": "exclamation-circle"
      }
    })], 1), _vm._v(" "), _c('span', {
      domProps: {
        "innerHTML": _vm._s(_vm.t('payments.reminders_requires_mobile_app_and_push_permissions'))
      }
    })]) : _vm._e()], 1)], 1)
  }), 0), _vm._v(" "), (!_vm.isAnyReminderTypeChecked) ? _c('div', {
    staticClass: "u-warning"
  }, [_c('i', {
    staticClass: "material-icons md-text",
    attrs: {
      "aria-hidden": "true"
    }
  }, [_vm._v("warning")]), _vm._v(" "), _c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.t('payments.reminders_type_warning'))
    }
  })]) : _vm._e(), _vm._v(" "), (_vm.isTextChecked) ? _c('div', {
    staticClass: "u-mt3"
  }, [(_vm.userPhones) ? _c('el-form', {
    staticClass: "u-max-width"
  }, [_c('div', {
    staticClass: "inputs-list"
  }, [_c('div', {
    staticClass: "list-item--select"
  }, [_c('span', {
    staticClass: "u-block u-text-uppercase subheader"
  }, [_vm._v("\n                  " + _vm._s(_vm.t('account.cell_phone_number')) + " *\n                ")]), _vm._v(" "), _c('div', {
    staticClass: "u-flex-align-items"
  }, [_c('div', {
    staticClass: "reminder__info_phone"
  }, [_c('el-select', {
    staticClass: "item-input-field u-block",
    model: {
      value: (_vm.textRecipientNumber),
      callback: function($$v) {
        _vm.textRecipientNumber = $$v
      },
      expression: "textRecipientNumber"
    }
  }, _vm._l((_vm.userPhones), function(phone) {
    return _c('el-option', {
      key: phone.id,
      attrs: {
        "value": phone.phone,
        "label": _vm.getPhoneNationalFormat(phone.phone)
      }
    })
  }), 1)], 1), _vm._v(" "), _c('div', {
    staticClass: "u-ml3",
    on: {
      "click": _vm.handlePhoneNumberDialog
    }
  }, [_c('fa-icon', {
    staticClass: "list-item--select__slot-icon u-primary",
    attrs: {
      "icon": ['fal', 'plus-circle']
    }
  })], 1)]), _vm._v(" "), (!_vm.textRecipientNumber) ? _c('div', {
    staticClass: "u-warning"
  }, [_c('i', {
    staticClass: "material-icons md-text",
    attrs: {
      "aria-hidden": "true"
    }
  }, [_vm._v("warning")]), _vm._v(" "), _c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.t('enrollment.phone_not_defined_notification'))
    }
  })]) : _vm._e()])])]) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "u-mt3",
    domProps: {
      "innerHTML": _vm._s(_vm.t('payments.reminders_text_expenses_warning'))
    }
  })], 1) : _vm._e()], 1) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "u-mt3"
  }, [_c('el-button', {
    staticClass: "dialog-info__btn u-text-uppercase",
    attrs: {
      "type": "primary",
      "disabled": _vm.saveButtonDisabled
    },
    on: {
      "click": _vm.updateRemindersSetup
    }
  }, [_vm._v("\n          " + _vm._s(_vm.t('global.save_changes')) + "\n        ")])], 1), _vm._v(" "), _c('phone-number-dialog')], 1) : _vm._e()])], 1)
},staticRenderFns: []}