<template>
  <div>
    <ba-password-field v-model="newPassword"
                       :inputLabel="t('global.new_password')"
                       @onShowPasswordCheckboxChange="onShowPasswordCheckboxChange" @enterKeyPressed="enterKeyPressed"></ba-password-field>
    <ba-password-field  v-if="showVerificationPassword"
                        v-model="repeatNewPassword"
                        :inputLabel="t('enrollment.confirm_password')"
                        :showPassword="showPassword"
                        :displayShowPasswordCheckbox="false" @enterKeyPressed="enterKeyPressed"></ba-password-field>
    <div class="progressbar-info u-mt3 u-mb3" v-if="newPassword">
      <el-progress v-model="passwordValidationResult.passwordStrengthValue" :fill-status="passwordValidationResult.passwordStrengthColor"></el-progress>
      <div class="progressbar-info__strength u-color-secondary">
        {{ t('global.password_strength') }}
      </div>
    </div>
    <div class="u-mt1" v-if="!passwordValidationResultUi.valid">
      <div v-for="warningMessages in passwordValidationResultUi.messages"  :key="warningMessages" class="u-warning">
        <i class="material-icons md-text" aria-hidden="true">warning</i> {{ warningMessages }}
      </div>
    </div>
    <div class="u-mt3" v-if="showSubmitButton">
      <el-button type="primary" class="el-button--wide u-text-uppercase" @click="setNewPassword">{{ _setNewPasswordButtonCaption }}</el-button>
    </div>
  </div>
</template>

<script>
import {validatePassword} from '@/shared/data-validators'
import {deepClone} from '@shared_src/utils/misc.util'
import BaPasswordField from '@/components/password-field.component'

export default {
  name: 'ba-new-password',
  components: {
    'ba-password-field': BaPasswordField,
  },
  props: {
    overrideShowVerificationPassword: {type: Boolean, default: null},
    showSubmitButton: {type: Boolean, default: true},
    currentPassword: {type: String, default: null},
    setNewPasswordButtonCaption: {type: String, default: null},
  },
  watch: {
    newPassword: {
      handler (newPassword) {
        this.$emit('newPasswordValue', newPassword) // this signal used in case we dun't want to use component's submit button
      },
      immediate: true
    },
  },
  methods: {
    enterKeyPressed () {
      if (this.showSubmitButton) {
        this.setNewPassword()
      } else {
        this.$emit('enterKeyPressed', this.newPassword)
      }
    },
    resetState () {
      this.newPassword = ''
      this.repeatNewPassword = ''
      this.passwordValidationResultUi = {valid: true, messages: []}
    },
    onShowPasswordCheckboxChange (value) {
      this.showPassword = value
    },
    checkPasswordValidAndRenderErrors () {
      // show errors if any. Returns true if password valid
      this.passwordValidationResultUi = deepClone(this.passwordValidationResult)
      let valid = this.passwordValidationResultUi.valid
      if (!valid) {
        this.$elAlert(this.t('enrollment.some_error_happened_notification'), this.t('global.notice'))
      }
      return valid
    },
    setNewPassword () {
      if (!this.checkPasswordValidAndRenderErrors()) return
      this.$emit('setNewPassword', this.newPassword)
    },
  },
  computed: {
    _setNewPasswordButtonCaption () {
      return this.setNewPasswordButtonCaption !== null ? this.setNewPasswordButtonCaption : this.t('global.submit')
    },
    passwordValidationResult () {
      // performs all password checks. Updates immediately. Used in password-strenght-meter. Being cloned to UI variable on button click
      let passwordValidatorResult = validatePassword(this.newPassword)
      if (this.showVerificationPassword && (this.newPassword !== this.repeatNewPassword)) {
        passwordValidatorResult.valid = false
        passwordValidatorResult.messages.push(this.t('enrollment.passwords_must_match'))
      }
      if (this.currentPassword && this.currentPassword === this.newPassword) {
        passwordValidatorResult.valid = false
        passwordValidatorResult.messages.push(this.t('account.new_password_must_differ_from_current'))
      }
      return passwordValidatorResult
    },
    showVerificationPassword () {
      return this.overrideShowVerificationPassword === null
          ? this.setting('enrollment.use_additional_verification_password_field')
          : this.overrideShowVerificationPassword
    },
  },
  data () {
    return {
      showPassword: false,
      newPassword: '',
      repeatNewPassword: '',

      passwordValidationResultUi: {valid: true, messages: []},
    }
  }
}
</script>

<style lang="scss">
  @import '../sass/_variables.scss';

  .progressbar-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 10px;

    @media only screen and (max-width: $m-screen) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }

    .el-form-item {
      width: 100%;
      margin-bottom: 0;
    }
    progress.c-progress__bar[value] {
      height: 10px;

      @media only screen and (max-width: $m-screen) {
        width: 100%;
      }
    }

    &__strength {
      white-space: nowrap;
      font-size: 14px;
      margin-left: 10px;

      @media only screen and (max-width: $m-screen) {
        margin-top: 5px;
      }
    }
  }
</style>
