<template>
  <div>
    <bc-navbar :title="t('global.policies_header')"></bc-navbar>
    <bc-policy-list v-if="policiesListByType" :policiesListByType="policiesListByType" screenType="policies" />
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import PolicyList from '@/components/policy-list.vue'

export default {
  components: {
    'bc-policy-list': PolicyList
  },
  computed: {
    ...mapGetters('policies', ['policiesListByType'])
  }
}
</script>
