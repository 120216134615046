<template>
  <div>
    <!-- This is standalone component to file a claim (it is possible to get here from the dashboard) -->
    <bc-navbar :title="t('dashboard.claims')"></bc-navbar>
    <div class="submenu">
      <div v-if="hasNoClaims" class="file-claim-info no-claims u-flex-vertical-center u-text-center u-mr4">
        <!-- Only show this when no open claims are on current account -->
        <ba-icon-link icon_type="far" icon_name="file-alt" icon_class="file-claim-info__icon" />
        <div class="no-claim__notice u-mt4">{{ t('claims.your_account_does_not_have_claims_yet') }}</div>
        <div class="no-claim__form-info u-mt2">{{ t('claims.use_form_to_file_your_first_claim') }}</div>
      </div>
      <ba-file-claim-component/>
    </div>
  </div>
</template>

<script>
  import BaFileClaimComponent from '@/pages/claims/file-claim.component'
  import iconLink from '@/ui/icon-link.component'
  import {mapGetters} from 'vuex'
  
  export default {
    name: 'file-claim.page',
    components: {
      'ba-icon-link': iconLink,
      'ba-file-claim-component': BaFileClaimComponent,
    },
    computed: {
      ...mapGetters('claims', ['openClaims', 'closedClaims']),
      hasNoClaims () {
        let zeroOpenClaims = this.openClaims && this.openClaims.length === 0
        let zeroClosedClaims = this.closedClaims && this.closedClaims.length === 0
        return zeroOpenClaims && zeroClosedClaims
      },
    },
  }
</script>

<style scoped lang="scss">
  .file-claim-info {
    width: 250px;
    height: 400px;
    background: #F8F8F8;
    padding: 30px;

    &__icon {
      font-size: 50px;
      line-height: 50px;
      color: #C0C0C0;
    }
    .no-claim {
      &__notice {
        font-size: 18px;
        color: #000000;
      }
      &__form-info {
        font-size: 16px;
        color: #626262;
      }
    }
  }

</style>
