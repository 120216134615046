module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: (_vm.loading),
      expression: "loading"
    }]
  }, [_c('div', {
    staticClass: "u-mt4 u-mb4"
  }, [_c('h2', {
    staticClass: "secondary-header"
  }, [_vm._v(_vm._s(_vm.t('account.change_password')))])]), _vm._v(" "), (_vm.successPasswordChanged) ? _c('ba-alert', {
    staticClass: "u-width100",
    attrs: {
      "type": "success"
    }
  }, [_vm._v("\n    " + _vm._s(_vm.t('reset_password.password_changed_successfully')) + "\n  ")]) : _vm._e(), _vm._v(" "), _c('ba-password-update', {
    ref: "passwordUpdateRef",
    attrs: {
      "setNewPasswordButtonCaption": _vm.t('account.change_password')
    },
    on: {
      "updatePassword": _vm.updatePassword
    }
  })], 1)
},staticRenderFns: []}