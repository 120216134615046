<template>
  <div>
    <div v-html="t('account.email_update_confirmation_sent', {new_email: this.email})"></div>

    <el-input class="u-mt3" type="text" placeholder="ex: 123456" v-model="internalValue"></el-input>
      <div v-if="verificationFailure" class="u-danger u-mt2">
        {{verificationFailure}}
      </div>

  </div>
</template>
<script>
  export default {
    name: 'ba-new-email-verification',
    props: ['email', 'verificationFailure'],
    computed: {
      internalValue: {
        get () {
          return this.value
        },
        set (val) {
          this.$emit('input', val)
        }
      },
    },
  }
</script>
