export default function downscaleImage (img, targetWidth) {
  // from http://jsfiddle.net/sp3c7jeq/
  let step = 0.5
  let canvas = document.createElement('canvas')
  let ctx = canvas.getContext('2d')
  let oc = document.createElement('canvas')
  let octx = oc.getContext('2d')

  if (img.width < targetWidth) {
    // make sure image is not wider that original
    // if original width - 100px, do not make it 1920px
    targetWidth = img.width
  }

  canvas.width = targetWidth // destination canvas size
  canvas.height = canvas.width * img.height / img.width

  if (img.width * step > targetWidth) { // For performance avoid unnecessary drawing
    var mul = 1 / step
    var cur = {
      width: Math.floor(img.width * step),
      height: Math.floor(img.height * step),
    }

    oc.width = cur.width
    oc.height = cur.height

    octx.drawImage(img, 0, 0, cur.width, cur.height)

    while (cur.width * step > targetWidth) {
      cur = {
        width: Math.floor(cur.width * step),
        height: Math.floor(cur.height * step),
      }
      octx.drawImage(oc, 0, 0, cur.width * mul, cur.height * mul, 0, 0, cur.width, cur.height)
    }

    ctx.drawImage(oc, 0, 0, cur.width, cur.height, 0, 0, canvas.width, canvas.height)
  } else {
    ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
  }

  let quality = 0.6
  // force image to be jpeg always.
  return canvas.toDataURL('image/jpeg', quality)
}
