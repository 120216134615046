module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', [(_vm.showPassword) ? _c('ba-password-field', {
    attrs: {
      "inputLabel": _vm.t('account.currentPassword'),
      "displayShowPasswordCheckbox": _vm.displayForCurrentPasswordShowPasswordCheckbox
    },
    on: {
      "enterKeyPressed": _vm.enterKeyPressed
    },
    model: {
      value: (_vm.currentPassword),
      callback: function($$v) {
        _vm.currentPassword = $$v
      },
      expression: "currentPassword"
    }
  }) : _vm._e(), _vm._v(" "), _c('ba-new-password', {
    ref: "newPasswordRef",
    attrs: {
      "currentPassword": _vm.currentPassword,
      "setNewPasswordButtonCaption": _vm.setNewPasswordButtonCaption
    },
    on: {
      "setNewPassword": _vm.setNewPassword
    }
  })], 1)
},staticRenderFns: []}