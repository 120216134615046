module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    staticClass: "block-link"
  }, [_c('div', {
    staticClass: "block-link__icon u-flex-vertical-center"
  }, [(_vm.icon_type) ? _c('fa-icon', {
    class: _vm.icon_class,
    attrs: {
      "icon": [_vm.icon_type, _vm.icon_name]
    }
  }) : _c('i', {
    staticClass: "fa-lg",
    class: _vm.icon_name
  })], 1), _vm._v(" "), (_vm.label) ? _c('div', {
    staticClass: "block-link__sign"
  }, [_vm._v("\n    " + _vm._s(_vm.label) + "\n  ")]) : _vm._e()])
},staticRenderFns: []}