<template>
  <div v-loading="loading" :class="{'u-mt4': loading}">
    <div class="claim-block" v-if="claim">
      <!--  Claim Info  -->
      <div class="claim-description u-mb4">
        <div class="claim-description__main">
          <div class="claim-description__title">
            <h1>{{ t('claims.claim_#', {number: claim.claim_number}) }}</h1>
            <div>{{ getLossDate }}</div>
          </div>
          <div class="claim-description__title_subtitle">
            {{t('claims.policy_select_prefix')}}{{claim.policy.policy_number}}
          </div>
        </div>
        <el-row :gutter="20">
          <el-col :sm="24" :md="12">
            <h3 class="subheader">{{ t('claims.location_title') }}</h3>
            <div class="claim-description__content">
              <div>{{ claim.loss_address.addressLine1 }}</div>
              <div v-if="claim.loss_address.addressLine2">{{ claim.loss_address.addressLine2 }}</div>
              <div>
                {{ claim.loss_address.addressCity }}, {{ claim.loss_address.addressState }}, {{ claim.loss_address.addressZip }}
              </div>
            </div>
            <div v-if="insuredContactNumber">
              <h3 class="subheader">{{ t('global.phone_number') }}</h3>
              <div class="claim-description__content">
                <bc-phone-national-format :phone="insuredContactNumber" />
              </div>
            </div>
          </el-col>
          <el-col :sm="24" :md="12">
            <h3 class="subheader">{{ t('claims.loss_description_title') }}</h3>
            <div class="claim-description__content claim-description__content_scroll">{{ claim.description }}</div>
          </el-col>
        </el-row>
      </div>
  
      <!--  Photos Section  -->
      <div class="u-mb4">
        <bc-claim-photo-preview v-if="!hideClaimsLossPhotos" :photos="claim.photos" @reloadClaimImage="loadClaim"/>
      </div>
      
      <!--  Adjusters Section  -->
      <div v-if="!hideClaimsAdjusters">
        <h2 class="secondary-title u-mb3">
          {{ t('claims.adjusters_section_title') }}
          <span v-if="claim.adjusters && claim.adjusters.length > 0">({{ claim.adjusters.length }})</span>
        </h2>
        <div v-if="claim.adjusters.length">
          <div v-for="adjuster in claim.adjusters" :key="adjuster.id"  class="claim-adjusters u-mb4">
            <bc-adjusters-list :name="adjuster.name" :imageData="{photoId: adjuster.photo_id}" :contact="adjuster"/>
          </div>
        </div>
        <div v-else class="claim-adjusters u-mb4">
          <div v-if="!claim.adjusters.length" class="u-mt2 u-mb4">
            {{ t('claims.claim_no_adjusters_yet_text') }}
          </div>
        </div>
      </div>
      
    </div>
  </div>
</template>


<script>
import ClaimPhotoPreview from '@/pages/claims/claims-photo-preview.component'
import AdjustersList from '@/components/adjusters-list'

import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    'bc-claim-photo-preview': ClaimPhotoPreview,
    'bc-adjusters-list': AdjustersList,
  },
  computed: {
    ...mapGetters('policies', ['policyById']),
    policy () {
      return this.policyById(this.$route.params.policyId)
    },
    getLossDate () {
      if (this.claim && this.claim.loss_date) {
        return this.t('claims.loss_date_title') + ': ' + this.dateLong(this.claim.loss_date)
      } else {
        return ''
      }
    },
    hideClaimsAdjusters () {
      return this.setting('claims.hide_claims_adjusters')
    },
    hideClaimsLossPhotos () {
      return this.setting('claims.hide_claims_loss_photos')
    },
    insuredContactNumber () {
      return this.claim ? this.claim.additional_information['Insured Contact Number'] : null
    }
  },
  methods: {
    ...mapActions('claims', ['getGen2Claim']),
    async loadClaim () {
      this.loading = true
      try {
        let response = await this.getGen2Claim(this.$route.params.claimId)
        this.claim = response.data.claim
      } catch (e) {
        this.loading = false
        let cause = (e.response && e.response.cause) ? e.response.cause : 'global.error'
        this.$elAlert(this.t(cause), this.t('global.notice'))
        return
      }
      this.loading = false
    },
  },
  created () {
    this.loadClaim()
  },
  data () {
    return {
      claim: null,
      loading: false
    }
  }
}
</script>
