<template>
  <div @click="getFile" class="upload-photos-border u-flex-vertical-center">
      <div class="upload-link u-text-center"
           @drop.prevent="handleDrop"
           @dragover.prevent>
        <fa-icon :icon="['far', 'cloud-upload']" class="link-icon-medium" />
        <div class="upload-link__sign u-text-capitalize u-text--center u-mt1">{{ t('claims.upload_photos') }}</div>
        <div class="upload-link__description u-text--center u-mt1">
          {{ t('policies.drop_file_here_or_click_to_upload') }}
        </div>
        <input type="file"
               accept="image/*; capture=camera"
               ref="uploadImageToClaimRef"
               style="display: none;"
               @change="performPictureUpload"/>
      </div>
    </div>
</template>

<script>

import downscaleImage from '@shared_src/image-rescale'

export default {
  name: 'photo-upload',
  methods: {
    getFile () {
      this.$refs.uploadImageToClaimRef.click()
    },
    handleDrop (e) {
      let file = e.dataTransfer.files[0]
      this.onFileSelected(file)
    },
    performPictureUpload (e) {
      const file = e.target.files[0]
      this.onFileSelected(file)
    },
    onFileSelected (file) {
      if (!file.type.match('image.*')) return
      return new Promise((resolve, reject) => {
        let reader = new FileReader()
        reader.onloadend = () => {
          let userClaimImage = new Image()
          userClaimImage.crossOrigin = 'Anonymous'
          userClaimImage.onload = () => {
            let downscaledImage = downscaleImage(userClaimImage, 1920)
            let defaultMime = 'image/jpeg'
            let uploaded = {
              image: downscaledImage,
              fileName: file.name,
              mimeType: defaultMime,
            }
            this.$emit('onPhotoUpload', uploaded)
          }
          userClaimImage.src = reader.result
        }
        if (file) {
          reader.readAsDataURL(file)
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
  @import '../sass/variables';

  .upload-photos-border {
    border: 2px dashed $color-border;
    border-radius: 4px;
    padding: 15px;
    margin-top: 25px;
    min-height: 150px;

    @media only screen and (max-width: $m-screen) {
      width: 100%;
      margin-top: 20px;
    }
  }

  .upload-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    .svg-inline--fa {
        font-size: 30px;
        color: #C0C0C0;
        width: 1em;
    }
    &__sign {
        font-size: 18px;
        color: $color-black;
        @media only screen and (max-width: $l-screen) {
            justify-content: center;
        }
    }
    &__description {
        font-size: 14px;
        color: #626262;

        @media only screen and (max-width: $m-screen) {
            display: none;
        }
    }
}

</style>
