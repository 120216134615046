<template>
  <div v-loading="loading" element-loading-spinner="no">
    <div class="u-mt1 u-mb2">{{ t('enrollment.choose_email_page_text') }}</div>
    <el-form>
      <el-form-item class="u-mb3 u-max-width " v-for="email in availableEmails" :key="email">
        <el-radio v-model="internalValue" :label="email" class="el-radio--wide el-radio--main-bg" border>{{ email }}</el-radio>
      </el-form-item>
      <el-form-item class="custom-email u-mb3 u-max-width">
        <div class="pos-rel">
          <el-input
              @click.native="willToggleOnRadioButton"
              type="text"
              class="custom-email__input"
              :placeholder="t('enrollment.add_new_email_placeholder')"
              v-model="customEmail">
          </el-input>
          <!--
            from source code of radio:    'is-checked': model === label
            so, set up this in hack way, and hide label
          -->
          <el-radio v-model="internalValue" :label="customEmail"  class="custom-email__radio el-radio--main-bg" >
        </el-radio>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
  import {isEmailValid} from '../../../shared_src/utils/misc.util'

  export default {
    name: 'ba-use-different-email',
    props: {
      value: String,
      availableEmails: Array,
      loading: Boolean,
    },
    data () {
      return {
        possibleCustomEmail: '',
      }
    },
    methods: {
      willToggleOnRadioButton () {
        this.internalValue = this.customEmail
      }
    },
    computed: {
      customEmail: {
        get () {
          if (this.availableEmails.indexOf(this.value) < 0) {
            return this.internalValue
          }

          return this.possibleCustomEmail
        },
        set (val) {
          this.possibleCustomEmail = val

          if (!isEmailValid(val)) {
            this.internalValue = ''
            return
          }

          this.internalValue = val
        }
      },
      internalValue: {
        get () {
          return this.value
        },
        set (val) {
          this.$emit('input', val)
        }
      },
    },
  }
</script>
<style>
  .custom-email__radio{
    position: absolute !important;
    background: none !important;
    top: 3px;
    left: 1px;
    z-index: 1;
    /* This are same values taken from element so radio check icon is aligned with others */
    padding: 12px 20px 0 10px;
    padding-top: 8px !important;
  }
  .custom-email__input .el-input__inner{
   padding-left: 40px !important;
  }
  .custom-email .el-radio__label {
    display: none;
  }

  .pos-rel {
    position: relative;
  }
</style>
