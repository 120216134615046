import store from '../store'
import settings from '../../config/settings'
import moment from 'moment'

let causeLocalize = function causeLocalize (keyOrObject) {
  // to be used only with "cause" string / objects returned from `briteapps-admin`

  if (typeof keyOrObject === 'string') {
    return localize(keyOrObject)
  }

  // object
  let key = keyOrObject.key
  let params = keyOrObject.params

  return localize(key, params)
}

let localize = function localize (path, args) {
  let argsType = typeof args
  if (args === null) {
    argsType = 'null'
  }
  if (argsType === 'number') {
    if (args === 1) {
      path = path + '_singular'
    } else {
      path = path + '_plural'
    }
  }

  // language is fixed in config, but user-overridable
  let language = store.state.common.applicationLanguage
  let obj = store.state.common.content[language]

  let [found, localized] = findInObject(obj, path)
  if (!found) {
    return `Localization('${path}')`
  }

  let regex = /\{\{(\w+)\}\}/g

  let matches
  while ((matches = regex.exec(localized))) {
    if (argsType === 'number' && matches[1] === 'count') {
      // special case, this is singular/plural
      localized = localized.replace('{{count}}', args)
    } else if (argsType === 'object' && args.hasOwnProperty(matches[1])) {
      let replacement = args[matches[1]]

      localized = localized.replace(matches[0], replacement)
      regex = /\{\{(\w+)\}\}/g // reset regexp obj
    }
  }

  return localized
}

function momentFromObj (obj) {
  if (typeof obj === 'object' && obj.hasOwnProperty('_isAMomentObject')) return obj

  return moment(obj)
}

function findInSettings (path) {
  let [found, setting] = findInObject(settings, path)

  return found ? setting : `Setting('${path}')`
}

let fnDateFormat = function dateFormat (value) {
  return momentFromObj(value).format('MM/DD/YY')
}

let fnDateLongFormat = function dateFormat (value) {
  return momentFromObj(value).format('MM/DD/YYYY')
}

function findInObject (obj, path) {
  let parts = path.split('.')

  while (parts.length) {
    let sub = parts.shift()
    if (typeof obj[sub] === 'undefined') {
      return [false, void 0]
    }
    obj = obj[sub]
  }

  return [true, obj]
}

function install (Vue) {
  let jQuery = window.jQuery

  function block () {
    jQuery.blockUI({
      message: '<div class="loader"></div><br/><span ' +
      'style="top: -60px; font-size:1.5em; margin: 0 auto; position:relative">Processing...</span>',
      css: {
        backgroundColor: '', border: '', color: '#fff'
      }
    })
  }

  function unblock (callback) {
    jQuery.unblockUI({onUnblock: callback})
  }

  Vue.prototype.date = fnDateFormat

  Vue.prototype.dateLong = fnDateLongFormat

  Vue.prototype.setting = findInSettings

  Vue.prototype.block = block

  Vue.prototype.unblock = unblock

  Vue.prototype.t = localize

  Vue.prototype.causeToString = causeLocalize
}

export { localize, findInSettings }
export default install
