module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', [(_vm.isHuman) ? _c('div', [_c('div', {
    staticClass: "media u-mt4"
  }, [_c('div', {
    staticClass: "media__image"
  }, [(_vm.renderContactPhoto) ? _c('bc-general-image', {
    staticClass: "agent__image",
    attrs: {
      "fileId": _vm.contactPhotoId,
      "source": _vm.contactPhotoUrl
    }
  }) : _vm._e(), _vm._v(" "), (!_vm.renderContactPhoto) ? _c('h2', {
    staticClass: "u-color-secondary u-m0"
  }, [_c('span', {
    attrs: {
      "fa": "user"
    }
  })]) : _vm._e()], 1), _vm._v(" "), _c('div', {
    staticClass: "media__body"
  }, [_c('h2', {
    staticClass: "u-color-text u-m0"
  }, [_vm._v("\n          " + _vm._s(_vm.name) + "\n        ")])])])]) : _vm._e(), _vm._v(" "), (!_vm.isHuman) ? _c('div', {
    staticClass: "u-mt4 u-mb4"
  }, [(_vm.renderContactPhoto) ? _c('div', {
    staticClass: "media"
  }, [_c('div', {
    staticClass: "media__image"
  }, [_c('bc-general-image', {
    staticClass: "agency__image",
    attrs: {
      "fileId": _vm.contactPhotoId,
      "source": _vm.contactPhotoUrl
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "media__body"
  }, [_c('h2', {
    staticClass: "u-m3 color-white"
  }, [_vm._v(" " + _vm._s(_vm.name))])])]) : _vm._e(), _vm._v(" "), (!_vm.renderContactPhoto) ? _c('div', {
    staticClass: "agency"
  }, [_c('h2', {
    staticClass: "secondary-header u-mt0 u-mb0"
  }, [_vm._v(_vm._s(_vm.name))])]) : _vm._e()]) : _vm._e(), _vm._v(" "), (_vm.contact.phones && _vm.contact.phones.length) ? _c('div', [_c('div', {
    staticClass: "media media--top u-mb4"
  }, [_c('div', {
    staticClass: "media__body"
  }, [_c('h3', {
    staticClass: "subheader media__title u-mt2 u-mb2"
  }, [_vm._v(_vm._s(_vm.t('global.phone_number')))]), _vm._v(" "), _vm._l((_vm.contact.phones), function(phone) {
    return _c('div', {
      key: phone.id
    }, [_c('div', [(_vm.isShowPhoneLabel) ? _c('div', {
      staticClass: "phone-info"
    }, [(phone.type.length) ? _c('div', {
      staticClass: "phone-type"
    }, [_vm._v(_vm._s(phone.type) + ":")]) : _vm._e(), _vm._v(" "), _c('div', [_c('a', {
      attrs: {
        "href": 'tel:' + phone.phone
      }
    }, [_vm._v(_vm._s(phone.phone))])])]) : _c('div', {
      staticClass: "phone-content"
    }, [_c('a', {
      attrs: {
        "href": 'tel:' + phone.phone
      }
    }, [_vm._v(_vm._s(phone.phone))])])])])
  })], 2)])]) : _vm._e(), _vm._v(" "), (!_vm.shouldHideEmails && _vm.contact.emails && _vm.contact.emails.length) ? _c('div', [_c('div', {
    staticClass: "media media--top"
  }, [_c('div', {
    staticClass: "media__body"
  }, [_c('h3', {
    staticClass: "subheader media__title u-mt1 u-mb2"
  }, [_vm._v(_vm._s(_vm.t('global.email_address')))]), _vm._v(" "), _c('div', {
    staticClass: "u-mb4"
  }, _vm._l((_vm.contact.emails), function(email, i) {
    return _c('div', {
      key: i
    }, [_c('span', [_c('a', {
      attrs: {
        "href": 'mailto:' + email.email
      }
    }, [_vm._v(_vm._s(email.email))])])])
  }), 0)])])]) : _vm._e(), _vm._v(" "), _vm._l((_vm.contact.addresses), function(address, i) {
    return _c('div', {
      key: i
    }, [(_vm.contact.addresses.length) ? _c('div', {
      staticClass: "media media--top"
    }, [_c('div', {
      staticClass: "media__body"
    }, [_c('h3', {
      staticClass: "subheader media__title u-mt1 u-mb2"
    }, [_vm._v(_vm._s(_vm.getAddressLabel(address)) + " " + _vm._s(_vm.t('global.address')))]), _vm._v(" "), _c('div', {
      staticClass: "u-mb2"
    }, [_c('div', [_vm._v(_vm._s(address.address_line1))]), _vm._v(" "), (address.address_line2) ? _c('div', [_vm._v(_vm._s(address.address_line2))]) : _vm._e(), _vm._v(" "), _c('div', [_vm._v(_vm._s(address.address_city) + ",  " + _vm._s(address.address_state))]), _vm._v(" "), _c('div', [_vm._v(_vm._s(address.address_zip))])])])]) : _vm._e()])
  }), _vm._v(" "), (_vm.contact.website) ? _c('div', [_c('div', {
    staticClass: "media media--top"
  }, [(_vm.contact && _vm.modifiedWebsite) ? _c('div', {
    staticClass: "media__body"
  }, [_c('h3', {
    staticClass: "subheader media__title u-mt1 u-mb2"
  }, [_vm._v(_vm._s(_vm.t('global.website')))]), _vm._v(" "), _c('div', {
    staticClass: "u-mb2"
  }, [_c('a', {
    attrs: {
      "href": _vm.contact.website
    }
  }, [_vm._v(_vm._s(_vm.modifiedWebsite))])])]) : _vm._e()])]) : _vm._e(), _vm._v(" "), (_vm.showAdditionalWording) ? _c('div', [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.t('global.additional_carrier_wording'))
    }
  })]) : _vm._e()], 2)
},staticRenderFns: []}