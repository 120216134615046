module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('el-row', {
    staticClass: "adjuster",
    attrs: {
      "gutter": 20
    }
  }, [_c('el-col', {
    attrs: {
      "md": 24,
      "lg": 8
    }
  }, [_c('div', {
    staticClass: "u-flex-align-items u-mb3"
  }, [_c('div', {
    staticClass: "adjuster__image u-mr3"
  }, [(_vm.renderContactPhoto) ? _c('bc-general-image', {
    staticClass: "adjuster__image_preview",
    attrs: {
      "fileId": _vm.contactPhotoId,
      "source": _vm.contactPhotoUrl
    }
  }) : _vm._e(), _vm._v(" "), (!_vm.renderContactPhoto) ? _c('div', [_c('fa-icon', {
    attrs: {
      "icon": ['far', 'user']
    }
  })], 1) : _vm._e()], 1), _vm._v(" "), _c('div', {
    staticClass: "adjuster__name"
  }, [_vm._v("\n        " + _vm._s(_vm.name) + "\n      ")])]), _vm._v(" "), (_vm.contact.phones && _vm.contact.phones.length) ? _c('div', _vm._l((_vm.contact.phones), function(phone) {
    return _c('div', {
      key: phone.id
    }, [_c('div', {
      staticClass: "adjuster__phones u-mb2"
    }, [(_vm.isShowPhoneLabel) ? _c('div', {
      staticClass: "u-flex-align-items"
    }, [(phone.type.length) ? _c('div', {
      staticClass: "adjuster__phone-type u-text-uppercase u-mr2"
    }, [_vm._v("\n              " + _vm._s(phone.type) + ":\n            ")]) : _vm._e(), _vm._v(" "), _c('div', [_c('a', {
      staticClass: "adjuster__phone-type_link",
      attrs: {
        "href": 'tel:' + phone.phone
      }
    }, [_vm._v(_vm._s(phone.phone))])])]) : _c('div', {
      staticClass: "phone-content"
    }, [_c('a', {
      attrs: {
        "href": 'tel:' + phone.phone
      }
    }, [_vm._v(_vm._s(phone.phone))])])])])
  }), 0) : _vm._e()]), _vm._v(" "), _c('el-col', {
    attrs: {
      "md": 24,
      "lg": 8
    }
  }, [(_vm.contact.emails && _vm.contact.emails.length) ? _c('div', [_c('div', {
    staticClass: "adjuster__contact"
  }, [_c('div', {
    staticClass: "adjuster__contact_title u-text-uppercase u-mb2"
  }, [_vm._v(_vm._s(_vm.t('global.email_address')))]), _vm._v(" "), _c('div', _vm._l((_vm.contact.emails), function(email, i) {
    return _c('div', {
      key: i,
      staticClass: "u-mb1"
    }, [_c('span', [_c('a', {
      attrs: {
        "href": 'mailto:' + email.email
      }
    }, [_vm._v(_vm._s(email.email))])])])
  }), 0)])]) : _vm._e(), _vm._v(" "), (_vm.contact && _vm.contact.website && _vm.modifiedWebsite) ? _c('div', {
    staticClass: "u-mt3"
  }, [_c('div', [_c('div', {
    staticClass: "adjuster__contact_title u-text-uppercase u-mb2"
  }, [_vm._v(_vm._s(_vm.t('global.website')))]), _vm._v(" "), _c('div', [_c('a', {
    attrs: {
      "href": _vm.contact.website
    }
  }, [_vm._v(_vm._s(_vm.modifiedWebsite))])])])]) : _c('div', [_c('div', {
    staticClass: "adjuster__contact_empty"
  }, [_vm._v("No emails")])])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "md": 24,
      "lg": 8
    }
  }, [(_vm.contact.addresses.length) ? _c('div', {
    staticClass: "adjuster__contact"
  }, _vm._l((_vm.contact.addresses), function(address, i) {
    return _c('div', {
      key: i,
      staticClass: "adjuster__contact_info"
    }, [_c('div', [_c('div', {
      staticClass: "adjuster__contact_title u-text-uppercase u-mb2"
    }, [_vm._v("\n            " + _vm._s(_vm.getAddressLabel(address)) + " " + _vm._s(_vm.t('global.address')) + "\n          ")]), _vm._v(" "), _c('div', [_c('div', [_vm._v(_vm._s(address.address_line1))]), _vm._v(" "), (address.address_line2) ? _c('div', [_vm._v(_vm._s(address.address_line2))]) : _vm._e(), _vm._v(" "), _c('div', [_vm._v(_vm._s(address.address_city) + ",  " + _vm._s(address.address_state))]), _vm._v(" "), _c('div', [_vm._v(_vm._s(address.address_zip))])])])])
  }), 0) : _vm._e()])], 1)
},staticRenderFns: []}