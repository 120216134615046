module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', [_c('p', {
    staticClass: "u-mt1"
  }, [_vm._v(_vm._s(_vm.t('enrollment.please_select_how_you_wish')) + ":")]), _vm._v(" "), _c('el-form', _vm._l((_vm.actualMethods), function(contactMethod) {
    return _c('el-form-item', {
      key: contactMethod.id
    }, [_c('el-radio', {
      attrs: {
        "label": contactMethod
      },
      model: {
        value: (_vm.internalValue),
        callback: function($$v) {
          _vm.internalValue = $$v
        },
        expression: "internalValue"
      }
    }, [_vm._v(_vm._s(_vm.labelForContactMethod(contactMethod)))])], 1)
  }), 1)], 1)
},staticRenderFns: []}