<template>
  <div v-if="policiesListByType" data-test-id="payments-card">
    <div class="payments-card dashboard__outer-card_info">
      <div class="el-card__header" v-loading="!computePoliciesByType">{{ t('dashboard.payments') }}</div>
      <h2 class="u-flex-auto u-mb5" v-if="computePoliciesByType">
        <div :class="paymentCard.headlineClass" class="u-flex-align-items">
          <fa-icon v-if="computePoliciesByType.overduePolicies.length"
                   :icon="[ 'fal', 'exclamation-circle']" class="image-icon"
          />
          <div class="u-text-trim">{{ paymentCard.headline }}</div>
        </div>
        <router-link  v-if="paymentCard.description"  to="/payments" class="el-card__sub-link subheader u-mt2">
          {{ paymentCard.description }}
        </router-link>
      </h2>
      <div class="payments-card__links payment-card-links u-mt4" v-if="computePoliciesByType && numPoliciesThatAllowPayments > 0">
        <el-button @click="processPayments" class="u-text-capitalize u-width100" type="info" data-test-id="payments-link">
          {{ paymentCard.actionText }}
        </el-button>
      </div>
    </div>

    <!-- Payment Dialog-->
    <bc-payment-dialog
        @handleMakePaymentsDialog="handleMakePaymentsDialog"
        :openPaymentDialog="openPaymentDialog"
        :checkedPolicies="policiesForPay"
        :activePayType="activePayType"
    />

  </div>
</template>

<script>
import {mapGetters, mapMutations, mapState} from 'vuex'
import {getEarliestDate} from '@shared_src/utils/misc.util'
import BcPaymentDialog from '../payments-redesign/make-payments/payment-dialog'
import { isPaymentAllowed } from '@shared_src/utils/payments.utils'

export default {
  name: 'payment-card',
  components: {
    BcPaymentDialog,
  },
  watch: {
    computePoliciesByType: {
      handler (val) {
        this.policiesForSinglePayment()
      },
      immediate: true
    },
  },
  data () {
    return {
      openPaymentDialog: false,
      activePayType: '',
      maxPoliciesAmount: 1
    }
  },
  computed: {
    ...mapGetters('policies', ['policyById', 'policiesListByType']),
    ...mapState('payments', ['policiesForPay']),
    ...mapGetters('payments', ['extendedPolicyPaymentState']),
    activePolicies () {
      if (!this.policiesListByType) return []
      return this.policiesListByType.active
    },
    computePoliciesByType () {
      let policies = this.activePolicies

      let totalAmountOverdue = 0 // stores only overDue
      let totalAmountDue = 0 // stores only due

      let earliestPaymentDueDate = null

      let overduePolicies = new Set()
      let duePolicies = new Set()

      for (let index in policies) {
        let policy = policies[index]
        let payState = this.extendedPolicyPaymentState(policy.id)
        if (!payState) return null

        let dueValue = payState.simpleDueValue
        let dueDate = payState.simpleDueDate
        let overDue = payState.simpleOverDue

        if (dueValue && !overDue) {
          duePolicies.add(policy.id)
          totalAmountDue += dueValue
          earliestPaymentDueDate = getEarliestDate(earliestPaymentDueDate, dueDate)
        }

        if (dueValue && overDue) {
          overduePolicies.add(policy.id)
          totalAmountOverdue += payState.dueValue
        }
      }

      let convertIdsSetToPolicyList = (idsSet) => {
        return [...idsSet].map((id) => this.policyById(id))
      }

      overduePolicies = convertIdsSetToPolicyList(overduePolicies)
      duePolicies = convertIdsSetToPolicyList(duePolicies)

      return {
        overduePolicies,
        duePolicies,
        totalAmountOverdue,
        totalAmountDue,
        earliestPaymentDueDate
      }
    },
    numPoliciesThatAllowPayments () {
      let policyCount = 0
      for (let index in this.activePolicies) {
        let policy = this.activePolicies[index]
        if (isPaymentAllowed(policy)) {
          policyCount++
        }
      }
      return policyCount
    },
    paymentCard () {
      let result = {
        headline: '',
        headlineClass: '',
        actionText: '',
        link: '',
        description: ''
      }
      if (!this.computePoliciesByType) return result
      let {
        overduePolicies,
        duePolicies,
        totalAmountOverdue,
        totalAmountDue,
        earliestPaymentDueDate
      } = this.computePoliciesByType
      let headline = ''
      let headlineClass = ''
      let actionText = ''
      let link = ''
      let description = ''
      // Overdue
      if (overduePolicies.length > 0) {
        headline = this.t('payments.amount_overdue_title', {
          amount: this.asCurrency(totalAmountOverdue)
        })
        headlineClass = 'u-danger'
        actionText = this.t('payments.make_payment')
        link = '/payments'
      } else if (!overduePolicies.length && duePolicies.length > 0) {
        // Due
        if (earliestPaymentDueDate) {
          headline = this.t('payments.amount_due_by_date', {
            amount: this.asCurrency(totalAmountDue),
            date: this.dateLong(earliestPaymentDueDate),
          })
        } else {
          headline = this.t('dashboard.amount_due', {
            amount: this.asCurrency(totalAmountDue),
          })
        }
        headlineClass = ''
        actionText = this.t('payments.make_payment')
        link = '/payments'
      } else {
        // Nothing Due
        headline = this.t('global.nothing_due')
        headlineClass = ''
        actionText = this.t('payments.payment_plans')
        link = '/payments/payment-plans'
        description = this.t('payments.you_may_have_future_payments_due')
      }
      result = {
        headline,
        headlineClass,
        actionText,
        link,
        description
      }
      return result
    },
  },
  methods: {
    ...mapMutations('payments', ['setCheckedPolicies']),
    policiesForSinglePayment () {
      if (this.computePoliciesByType) {
        const { duePolicies, overduePolicies } = this.computePoliciesByType
        if (duePolicies.length === this.maxPoliciesAmount && !overduePolicies.length) {
          return this.setCheckedPolicies(duePolicies)
        } else if (overduePolicies.length === this.maxPoliciesAmount && !duePolicies.length) {
          return this.setCheckedPolicies(overduePolicies)
        }
      }
      return this.setCheckedPolicies([])
    },
    handleMakePaymentsDialog (val) {
      this.openPaymentDialog = val
    },
    processPayments () {
      if (this.policiesForPay.length === this.maxPoliciesAmount) {
        let firstPolicy = this.policiesForPay[0]
        let paymentState = this.extendedPolicyPaymentState(firstPolicy.id)
        this.activePayType = paymentState.nextDue || paymentState.nextOverDue ? 'nextTerm' : 'currentDue'
        this.handleMakePaymentsDialog(true)
      } else {
        this.$router.push(this.paymentCard.link)
      }
    }
  }
}
</script>

