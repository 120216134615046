module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', [_c('div', {
    staticClass: "u-mb4",
    on: {
      "click": function($event) {
        return _vm.onBackButtonClick()
      }
    }
  }, [_c('ba-icon-link', {
    attrs: {
      "icon_type": "far",
      "icon_name": "arrow-left",
      "icon_class": "u-pl2",
      "label": _vm.t('documents.back')
    }
  })], 1), _vm._v(" "), _c('bc-navbar', {
    attrs: {
      "title": _vm.pageBySlug(_vm.slug) && _vm.pageBySlug(_vm.slug).title
    }
  }), _vm._v(" "), _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: (_vm.loading),
      expression: "loading"
    }],
    staticClass: "content-root"
  }, [(_vm.page) ? _c('div', {
    staticClass: "guard scoped_todo"
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.page.html_content)
    }
  })]) : _vm._e()])], 1)
},staticRenderFns: []}