var Component = require("!../../../node_modules/vue-loader/lib/component-normalizer")(
  /* script */
  require("!!babel-loader!../../../node_modules/vue-loader/lib/selector?type=script&index=0!./upload-image.vue"),
  /* template */
  require("!!../../../node_modules/vue-loader/lib/template-compiler/index?{\"id\":\"data-v-a2f25614\"}!../../../node_modules/vue-loader/lib/selector?type=template&index=0!./upload-image.vue"),
  /* scopeId */
  null,
  /* cssModules */
  null
)

module.exports = Component.exports
