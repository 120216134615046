module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', [_vm._l((_vm.rows), function(item, i) {
    return _c('div', {
      key: i,
      staticClass: "claim-gallery__preview",
      on: {
        "click": function($event) {
          $event.stopPropagation();
          return _vm.clickPhoto(i)
        }
      }
    }, [(item.isPreferred) ? _c('div', {
      staticClass: "preferred-image-star"
    }, [_c('fa-icon', {
      staticClass: "u-primary",
      attrs: {
        "icon": "star"
      }
    })], 1) : _vm._e(), _vm._v(" "), ((_vm.claimPreview && i === 0) || !_vm.claimPreview) ? _c('div', {
      staticClass: "image claim-gallery__image"
    }, [_c('bc-general-image', {
      class: [{
        'preferred-image': item.isPreferred
      }],
      style: ({
        backgroundImage: 'url(' + item.imageUri + ')'
      }),
      attrs: {
        "fileId": item.id
      }
    })], 1) : _vm._e()])
  }), _vm._v(" "), _c('el-dialog', {
    ref: "carouselDialog",
    attrs: {
      "title": _vm.carouselCaption,
      "visible": _vm.photoViewerVisible,
      "custom-class": "dialog-slider"
    },
    on: {
      "update:visible": function($event) {
        _vm.photoViewerVisible = $event
      }
    }
  }, [_c('el-carousel', {
    ref: "carousel",
    staticClass: "property-slider",
    attrs: {
      "height": "450px",
      "trigger": "click",
      "autoplay": false,
      "indicator-position": "none",
      "arrow": "always"
    }
  }, _vm._l((_vm.rows), function(item) {
    return _c('el-carousel-item', {
      key: item.id
    }, [_c('div', {
      staticClass: "property-slider__frame"
    }, [_c('bc-general-image', {
      staticClass: "bc-general-image",
      attrs: {
        "fileId": item.id
      }
    })], 1), _vm._v(" "), _c('div', {
      staticClass: "property-slider__info u-mt2"
    }, [_c('div', {
      staticClass: "u-flex-horizontal-space-between u-width100"
    }, [_c('div', [_vm._v(_vm._s(item.caption))]), _vm._v(" "), _c('div', {
      staticClass: "property-slider__modify"
    }, [(_vm.isVisible(item)) ? _c('el-button', {
      attrs: {
        "icon": ['fas', 'trash'],
        "type": "info"
      },
      on: {
        "click": function($event) {
          $event.stopPropagation();
          return _vm.$emit('removeImage', item)
        }
      }
    }) : _vm._e()], 1)])])])
  }), 1), _vm._v(" "), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [(_vm.browseType === 'insured') ? _c('el-button', {
    attrs: {
      "icon": ['fas', 'edit']
    },
    on: {
      "click": _vm.clickPhotoEdit
    }
  }, [_vm._v(_vm._s(_vm.t('policies.edit_photo')))]) : _vm._e()], 1)], 1)], 2)
},staticRenderFns: []}