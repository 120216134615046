<template>
  <div v-loading="loading" class="file-claim-component">
    <h2 class="secondary-header u-mr3 u-mt2 u-mb2">{{ t('claims.file_claim_headline') }}</h2>
  
    <div v-if="fileClaimByForm">
      <div v-html="t('claims.custom_claims_form_html')"></div>
    </div>

    <div v-else>
      <div class="u-mb4">
        <p v-html="t('claims.file_claim_text')"></p>
      </div>
             <!-- Policy Number -->
      <div class="file-claim-forms__fields u-mnb6">
        <el-form class="inputs-list u-mb3 u-max-width ">
          <div v-if="!policySelectorReadOnly && policies" class="list-item--select">
            <span class="u-block u-text-uppercase subheader">{{ t('claims.policy_number_title') }} *</span>
            <el-select placeholder=""
                       v-model="selectedPolicyId"
                       class="item-input-field u-block select-input"
                       :disabled="policySelectorReadOnly">
              <template slot="prefix">
                <fa-icon v-if="getCurrentIcon" :icon="getCurrentIcon" class="link-icon-medium prefix-icon u-text--lg"/>
              </template>
              <el-option v-for="policy in activePoliciesList"
                         :label="t('claims.policy_select_prefix') + ' ' + policy.policy_number + ' (' + policy.policy_status + ')'"
                         :value="policy.id"
                         :key="policy.id">
                <fa-icon :icon="determinePolicyIcon(policy)" class="link-icon-medium" />
                <span class="u-pl1">{{ t('claims.policy_select_prefix') + ' ' + policy.policy_number + ' (' + policy.policy_status + ')' }}</span>
              </el-option>
            </el-select>
          </div>
          <div v-if="policySelectorReadOnly && selectedPolicy">
            <div class="item-content reduced-min-height u-mb4">
              <div class="item-inner reduced-min-height u-text--lg">
                {{ t('claims.policy_select_prefix') }}{{ selectedPolicy.policy_number }}
              </div>
            </div>
          </div>
        </el-form>
      </div>
      <div v-if="selectedPolicy">
        <div v-if="isGen3Selected">
          <file-claim-gen3 :policyId="selectedPolicyId"
                           :key="selectedPolicyId"
                           @closeClaimDialog="closeClaimDialog"></file-claim-gen3>
        </div>
        <div v-else>
          <file-claim-gen2 :policyId="selectedPolicyId"
                           :key="selectedPolicyId"
                           @closeClaimDialog="closeClaimDialog"></file-claim-gen2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import fileClaimGen2 from './file-claim.gen2.component'
import fileClaimGen3 from './file-claim.gen3.component'

export default {
  name: 'ba-file-claim',
  components: {
    'file-claim-gen2': fileClaimGen2,
    'file-claim-gen3': fileClaimGen3
  },
  props: {
    openClaimDialog: {type: Boolean, default: false},
  },
  methods: {
    closeClaimDialog () {
      if (this.openClaimDialog) this.$emit('closeClaimDialog')
    },
  },
  computed: {
    ...mapGetters('policies', ['policyById', 'policiesListByType', 'determinePolicyIcon']),
    ...mapState('policies', ['policies']),
    activePoliciesList () {
      if (this.policiesListByType) {
        return this.policiesListByType.active
      }
    },
    getCurrentIcon () {
      if (this.selectedPolicy) return this.determinePolicyIcon(this.selectedPolicy)
    },
    isGen3Selected () {
      return this.selectedPolicy && this.selectedPolicy.isGen3
    },
    selectedPolicy () {
      return this.selectedPolicyId? this.policyById(this.selectedPolicyId): null
    },
    fileClaimByForm () {
      return this.setting('claims.file_claim_by_form')
    },
  },
  data () {
    setTimeout(() => {
      if (!this.selectedPolicyId && this.activePoliciesList) {
        this.selectedPolicyId = this.activePoliciesList[0].id
      }
    }, 16)
    return {
      selectedPolicyId: this.$route.params.policyId !== 'undefined' ? this.$route.params.policyId : null,
      policySelectorReadOnly: !this.openClaimDialog && this.$route.params.policyId !== 'undefined',
      loading: false,
    }
  }
}
</script>

<style scoped lang="scss">

  @import '../../sass/element-variables.scss';
  ul {
    list-style-type: none;
  }
  .reduced-min-height {
    min-height: 18px;
  }
  .el-card {
    &__description {
      display: flex;
      justify-content: flex-end;
     }
  }
  .select-input {
    z-index: 1;
  }
  .prefix-icon {
    color: $--color-text-regular;
  }
</style>
