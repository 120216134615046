module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return (_vm.insureds.length) ? _c('div', [_c('p', {
    staticClass: "u-mt1"
  }, [_vm._v(_vm._s(_vm.t('enrollment.please_identify_yourself_on_missing_policy')))]), _vm._v(" "), _c('el-form', [_vm._l((_vm.registered_insureds), function(insured) {
    return _c('el-form-item', {
      key: insured.id,
      staticClass: "umb-1 u-max-width"
    }, [_c('el-radio', {
      staticClass: "el-radio--wide el-radio--main-bg",
      attrs: {
        "value": "false",
        "disabled": "",
        "border": ""
      }
    }, [_vm._v(_vm._s(insured.name) + " - " + _vm._s(_vm.t('enrollment.user_already_enrolled')))])], 1)
  }), _vm._v(" "), _vm._l((_vm.insureds), function(insured) {
    return _c('el-form-item', {
      key: insured.id,
      staticClass: "umb-1 u-max-width "
    }, [_c('el-radio', {
      staticClass: "el-radio--wide el-radio--main-bg",
      attrs: {
        "label": insured.id,
        "border": ""
      },
      model: {
        value: (_vm.internalValue),
        callback: function($$v) {
          _vm.internalValue = $$v
        },
        expression: "internalValue"
      }
    }, [_vm._v(_vm._s(insured.name))])], 1)
  })], 2)], 1) : _vm._e()
},staticRenderFns: []}