<template>
  <transition name="fade">
    <div class="card u-p3">
      <div class="card-content">
        <div class="notifications-list">
          <div class="u-pr2 u-mt1">
            <fa-icon :icon="['fa', 'check-circle']" class="check-circle" :class="type === 'new' ? 'check-circle--active' : ''" />
          </div>
          <div class="u-width100">
            <div class="notifications-list__info u-mb1">
              <div class="notifications-list__title u-pr2">{{ message.title }}</div>
              <div class="notifications-list__time">{{ showTime(message.created_at) }}</div>
              <div class="notifications-list__dismiss" @click="dismiss_message(message)">
                <fa-icon :icon="['fas', 'trash']" />
              </div>
            </div>
            <div>
              <div class="notifications-list__details">
                <span v-html="message.details"></span>
                <a class="u-ml2" v-if="message.action_data" @click="clickOnLink(message.action_data)">{{ message.action_data.label }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  import {mapActions} from 'vuex'
  import moment from 'moment'

  export default {
    name: 'ba-notification-card',
    props: ['message', 'type'],
    methods: {
      ...mapActions('messageCenter', ['dismiss_message']),
      showTime (time) {
        return moment(time).format('MM/DD/YYYY')
      },
      clickOnLink (actionData) {
        if (actionData.name !== 'document.open') {
          alert('Unknown Action')
        } else {
          this.$router.push({
            path: '/document-view/' + actionData.payload.document_id,
            query: {fileName: actionData.payload.document_name, folderName: actionData.payload.folder_name}
          })
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  @import '../../sass/_variables.scss';

  .notifications-list {
    display: flex;
    &__info {
      display: flex;
      align-items: baseline;
    }
    &__title {
      font-weight: 600;
      font-size: 16px;
      color: $color-black;
    }
    &__time {
      font-size: 12px;
    }
    &__details {
      color: $color-black;
    }
   &__dismiss {
     flex-grow: 1;
     padding-left: 50px;
     color: transparentize($color-text--disabled, .1);
     text-align: right;
     cursor: pointer;
     [data-icon="trash"] {
       font-size: 18px;
     }
     &:hover {
       color: transparentize($color-text--disabled, .2);
     }
   }
    .check-circle {
      font-size: 25px;
      color: transparentize($color-text--disabled, .3);
      &--active {
        color: $color-success;
      }
    }
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .3s;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
</style>
