<template>
  <div data-f7="page" v-loading="loading">
    <bc-navbar :title="t('global.policies_header')" backLink></bc-navbar>
    <div data-f7="block" v-if="insured">
      <bc-contact :name="insured.name" :imageData="{photoId: insured.photo_id}" :contact="insured" human></bc-contact>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
  import BcContact from '../../components/contact.vue'
  import {urlForAttachment} from '../../../shared_src/utils/api.utils'

  export default {
    components: {
      'bc-contact': BcContact
    },
    data () {
      this.loading = true
      let contactId = this.$route.params.contactId

      this.$store.dispatch('policies/retrieveInsured', contactId).then(() => {
        this.loading = false
      }).catch((response) => {
        this.loading = false
        this.$elAlert(this.t('account.error_retrieving_data_from_insured'));
        console.log(response.data.errors);
      })

      return {
        loading: false
      }
    },
    computed: {
      insured () {
        return this.$store.state.policies.insured
      }

    },
    methods: {
      urlForAttachment (fileId) {
        if (!fileId) return

        return urlForAttachment(fileId, true)
      }
    }
  }
</script>
<style scoped>

</style>