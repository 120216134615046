<template>
  <div>
    <div class="tabs-view__heading">
      <bc-navbar :title="t('payments.payment_plans')" class="u-mb4"></bc-navbar>
      <div>
        <el-dropdown class="payments-dropdown" trigger="click">
          <div class="box-menu">
            <fa-icon :icon="['far', 'ellipsis-v']"/>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
                @click="openAddPayMethodDialog"
                @click.native="openAddPayMethodDialog"
            >
              {{ t('payments.add_payment_method') }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div v-if="policiesListByType">
      <div v-for="policy in policiesListByType.active">
        <bc-pay-terms :policy="policy" class="bc-pay-plan" />
      </div>
    </div>
    <ba-add-payment-method-modal :available-insureds="insuredIds"/>
  </div>
</template>

<script>
  import {mapState, mapGetters, mapMutations} from 'vuex'
  import payTerms from './pay-terms.component'
  import BaAddPaymentMethodModal from '@/pages/payments-redesign/add-payment-method.modal'

  export default {
    name: 'payment-plans',
    components: {
      'bc-pay-terms': payTerms,
      BaAddPaymentMethodModal
    },
    computed: {
      ...mapState('policies', ['policies']),
      ...mapGetters('account', ['insuredIds']),
      ...mapGetters('policies', ['policiesListByType']),
    },
    methods: {
      ...mapMutations('common', ['handlePayDialog']),
      openAddPayMethodDialog () {
        return this.handlePayDialog({
          isDialogVisible: true,
          currentSubMenu: 'payment-plans'
        })
      },
    }
  }
</script>

<style scoped lang="scss">
  .bc-pay-plan {
    border: 2px solid #E5E5E5;
    border-radius: 4px;
    padding: 20px;
    margin-bottom: 20px;
  }
</style>
