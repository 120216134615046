/* eslint-disable */

import MyPolifils from './polyfill'
import Vue from 'vue'
import VueRouter from 'vue-router'
import BcDesignSystem from '@britecore/bc-design-system'
import ElementUtils from './utils/element-utils'
import App from './components/app'
import i18n from './utils/i18n'
import '@shared_src/filters'

import Meta from 'vue-meta'
import apiUtils from '@shared_src/utils/api.utils'
import {logInsuredAction} from '@shared_src/utils/api.utils'
import additionalVueFunctions from "@shared_src/utils/additionalVueFunctions"

import VueCompositionAPI from '@vue/composition-api'

import 'intl-tel-input/build/css/intlTelInput.css'

Vue.use(VueRouter)
Vue.use(BcDesignSystem)
Vue.use(ElementUtils)
Vue.use(i18n)
Vue.use(Meta)
Vue.use(apiUtils)
Vue.use(additionalVueFunctions)

Vue.use(VueCompositionAPI)



import 'font-awesome/css/font-awesome.css'
import AppStyles from './sass/main.scss'

import store from '../web-src/store'
import '../web-src/shared/axios.interceptors'

import PaymentMethodIcon from './pages/payments-redesign/payment-methods/payment-method-icon.component.vue'
import Navbar from './components/navbar.vue'
import Footer from './components/footer.vue'
import GeneralImage from './components/general-image.vue'
import PhoneNationalFormat from '../shared_src/phone-wrap/phone-national-format.component.vue'

import '@shared_src/icons/font-awesome.includes'




import moment from 'moment'
import Routes from './routes.js'

Vue.component('bc-navbar', Navbar)
Vue.component('bc-footer', Footer)
Vue.component('bc-payment-method-icon', PaymentMethodIcon)
Vue.component('bc-general-image', GeneralImage)
Vue.component('bc-phone-national-format', PhoneNationalFormat)

const router = new VueRouter({routes: Routes})

router.afterEach((to, from) => {
  let message = 'Navigating to ' + to.fullPath
  let messageCode = 'router_navigation'
  let extraVars = {url: to.fullPath}
  logInsuredAction(message, messageCode, extraVars)
})

window.__vue_router = router


// Init App
window.vueInstance = new Vue({
  el: '#app',
  store,
  router,
  template: '<app @startApp="startApp" />',
  components: {
    app: App
  },
  methods: {
    startApp() {
      if (this.$store.state.login.isAuthorized) {
        this.$store.dispatch('loadUserData').then(() => {
          let last_user =  store.getters['account/username']
          localStorage.setItem('last_user', last_user)

          if (store.getters['account/tos']) {
            /* If ToS is loaded, needs to be agreed */
            router.push('/enrollment/terms-of-service/0')
            return
          }

          if (!this.$route.matched.length) {
            router.push('/dashboard')
          }
        })
      } else {
        let routerPath = this.$router.currentRoute.fullPath
        if (routerPath.startsWith('/enrollment/')) {
          // allow the user to proceed with enrollment
          return
        }
        if (!routerPath.startsWith('/login')) {
          router.push('/login')
        }
      }
    }
  },
  created: function () {
    console.log('created')
    this.initPromise = store.dispatch('init')
  },
  mounted: function () {
    this.initPromise.then(() => {
      this.startApp();
    })
  },
});
