export default {
    "account": {
        "allow_edit_phone_number": true,
        "show_phone_labels": true
    },
    "agent": {},
    "allowed_sections": {
        "account": true,
        "agent": true,
        "appstore": true,
        "authentication": true,
        "auto": true,
        "build": true,
        "claims": true,
        "contacts": true,
        "dashboard": true,
        "documents": true,
        "e_delivery": true,
        "email_templates": true,
        "enrollment": true,
        "global": true,
        "help": true,
        "homepage": true,
        "insured_profile": true,
        "invoices": true,
        "kubernetes": true,
        "login": true,
        "messaging": true,
        "notifications": true,
        "payments": true,
        "policies": true,
        "reset_password": true,
        "smoke_tests": true,
        "tests": true,
        "watercraft": true
    },
    "appstore": {},
    "authentication": {
        "session_expiration_time": 600
    },
    "auto": {},
    "britecore_url": "https://ph-portal-demo.britecorepro.com",
    "build": {
        "scss_desktop": ".blackout {\n    background-color: rgba(0,0,0,0.1) !important;\n}\nfooter {\n    background-color: whitesmoke !important;\n    color: black !important;\n    padding: 2em 3em !important;\n    text-align: center;\n}",
        "scss_mobile": ".pay-plan__root .select-autopay__dropdown select {\n    padding-left: 64px;\n}"
    },
    "claims": {
        "always_show_claims_menu_link": false,
        "claims_enabled": true,
        "file_claim_by_form": false,
        "hide_claims_adjusters": false,
        "hide_claims_loss_photos": false,
        "hide_upload_photos_and_update_claim": false,
        "require_contact_phone_new_claim": false
    },
    "company_id": "8f989eed-0c2c-48bb-8be9-58da7b78af57",
    "contacts": {},
    "dashboard": {},
    "documents": {},
    "e_delivery": {
        "e_delivery_acknowledgement": false,
        "enable_e_delivery": false,
        "send_notification_to_enrolled_co_insured_if_recipient_not_enrolled": false,
        "use_bc_email_if_no_email_in_ba": false,
        "use_unconfirmed_ba_email": false
    },
    "email_templates": {},
    "enrollment": {
        "allow_adding_additional_phone_numbers": true,
        "required_password_strength": 3,
        "send_email_on_auto_enrollment": false,
        "send_portal_code_via_email": true,
        "send_portal_code_via_sms": true,
        "show_detailed_error_information_on_policy_lookup": false,
        "terms_of_service": true,
        "use_additional_verification_password_field": false,
        "use_dob_if_available": true,
        "use_link_in_auto_enrollment_email": false,
        "use_ssn_if_available": true,
        "use_user_based_search_during_enrollment": false,
        "users_are_identified_by_email_address": true,
        "where_do_i_find_enrollment_code_message": ""
    },
    "global": {
        "allowed_files_to_upload": "image/*, video/*, application/pdf, .xls, .xlsx, .doc, .docx, .txt, .rtf",
        "application_versions_supported": "version_web_and_mobile",
        "aws_custom_translation": "<tmx version=\"1.4\"><header creationtool=\"XYZTool\" creationtoolversion=\"0\" datatype=\"PlainText\" segtype=\"sentence\" adminlang=\"en\" srclang=\"en\" o-tmf=\"test\"/><body><tu><tuv  xml:lang=\"en\"><seg>policy</seg></tuv><tuv xml:lang=\"es\"><seg>p\u00f3liza</seg></tuv></tu><tu><tuv xml:lang=\"en\"><seg>Policy</seg></tuv><tuv xml:lang=\"es\"><seg>P\u00f3liza</seg></tuv></tu><tu><tuv  xml:lang=\"en\"><seg>policies</seg></tuv><tuv xml:lang=\"es\"><seg>p\u00f3lizas</seg></tuv></tu><tu><tuv xml:lang=\"en\"><seg>Policies</seg></tuv><tuv xml:lang=\"es\"><seg>P\u00f3lizas</seg></tuv></tu></body></tmx>",
        "aws_language": "{\"english\": \"en\", \"spanish\": \"es\", \"french\": \"fr\"}",
        "carrier_additional_wording": false,
        "carrier_name": "Panhandle Farmers Mutual Insurance",
        "carrier_website_address": "https://panhandleins.com",
        "currency_format": "$0.00",
        "default_font": "font-galano-grotesque",
        "display_spanish_link": false,
        "edelivery_expiration_age": 180,
        "electronic_delivery_option": true,
        "emails_expiration_age": 180,
        "enable_aws_translation": true,
        "enable_touch_id": false,
        "google_maps_api_key": "AIzaSyC-rMrsauObzwbIr2wbX8oJadjNvfEhXkM",
        "hide_agent_and_agency_contact_info": false,
        "hide_agent_emails": false,
        "hide_agent_loss_photos": false,
        "hide_agent_property_photos": false,
        "hide_location_details": false,
        "insured_messages_expiration_age": 180,
        "language": "english",
        "mobile_application_name": "Panhandle Ins",
        "mobile_application_version": "0.23",
        "show_canceled_policies": false,
        "text_message_expiration_age": 180,
        "use_type_label_for_other_addresses": false,
        "user_actions_expiration_age": 360
    },
    "help": {},
    "homepage": {},
    "insured_profile": {},
    "invoices": {},
    "kubernetes": {
        "env_tls_issuer": "briteapps-panhandle-letsencrypt-real-staging"
    },
    "login": {},
    "messaging": {
        "payment_method_expiration_notice_period": 30
    },
    "notifications": {
        "additional_email_addresses_to_notify_when_insured_enables_auto_pay": "autopay@gmail.com",
        "additional_email_addresses_to_notify_when_insured_uploads_a_file": "fileupload@gmail.com",
        "additional_email_addresses_to_notify_when_insured_uploads_a_property_photo": "propertyphoto@gmail.com",
        "additional_email_addresses_to_notify_whenever_claim_is_filled": "",
        "additional_email_addresses_to_notify_whenever_insured_changes_payment_plan": "",
        "additional_email_addresses_to_notify_whenever_insured_updates_email_address": "",
        "additional_email_addresses_to_notify_whenever_insured_updates_his_phone": "phonenumber@gmail.com",
        "enable_old_edelivery": false,
        "notify_to_agency_when_insureds_enable_auto_pay": true,
        "notify_to_agency_whenever_insured_changes_payment_plan": true,
        "notify_to_agency_whenever_insured_files_a_claim": true,
        "notify_to_agency_whenever_insured_updates_email_address": true,
        "notify_to_agency_whenever_insured_updates_his_phone": true,
        "notify_to_agency_whenever_insured_uploads_a_file": true,
        "notify_to_agency_whenever_insured_uploads_a_property_photo": true,
        "notify_to_agent_whenever_insured_changes_payment_plan": true,
        "notify_to_agent_whenever_insured_files_a_claim": true,
        "notify_to_agent_whenever_insured_updates_email_address": true,
        "notify_to_agent_whenever_insured_updates_his_phone": true,
        "notify_to_agent_whenever_insured_uploads_a_file": true,
        "notify_to_agent_whenever_insured_uploads_a_property_photo": true,
        "notify_to_agents_when_insureds_enable_auto_pay": true,
        "send_emails_from": "noreply@panhandleins.com"
    },
    "payments": {
        "allow_auto_pay": true,
        "allow_auto_pay_change_on_escrow": false,
        "allow_billing_schedule_change": true,
        "allow_next_term_payments": true,
        "allow_payment_reminders": true,
        "auto_pay_confirmation_modal": true,
        "autopay_payment_methods": "ap_ba_and_cc",
        "available_payment_methods": "ba_and_cc",
        "display_payment_schedule_preview": false,
        "payment_schedule": "invoices_payments",
        "show_payment_plan": true
    },
    "policies": {
        "only_display_agency_contact_information": false,
        "show_declaration_link": false
    },
    "reset_password": {},
    "smoke_tests": {
        "policy_number": "10-2014-3",
        "portal_code": "6769"
    },
    "tests": {},
    "watercraft": {}
}