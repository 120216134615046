<template>
  <div class="init-screen__presentation">
    <div class="init-screen__logo">
      <img class="init-screen__logo-image" src="~@/assets/desktop_logo.png" />
    </div>
    <div class="init-screen__bc-footer">
      <div>
        <div class="c-header__nav-link u-text--uppercase">
          <div class="sidenav__link u-p0 u-mb3">
            <ba-custom-pages :only-public="true" placement="top"/>
          </div>
        </div>
        <bc-footer bgClass="bg-login"></bc-footer>
      </div>
      <div class="init-screen__bc-language" v-if="displayLanguageLink">
        <ba-language-menu />
      </div>
    </div>
  </div>
</template>

<script>
  import BaLanguageMenu from '@/components/language-selector.component'
  import BaCustomPages from '@/components/custom-pages-menu'
  
  export default {
    name: 'init-sidebar',
    components: {
      'ba-language-menu': BaLanguageMenu,
      'ba-custom-pages': BaCustomPages
    },
    computed: {
      displayLanguageLink () {
        return this.setting('global.display_spanish_link') === true
      },
    }
  }
</script>

<style lang="scss">
  @import '../sass/_variables.scss';
  
  .init-screen {
    &__presentation {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      width: 600px;
      max-height: none;
      
      @media only screen and (max-width: $m-screen) {
        position: static;
        justify-content: center;
        align-items: center;
        width: auto;
      }
  
      @media only screen and (max-width: $s-screen) {
      }
      
    }
    &__bc-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 40px;
    
      @media only screen and (max-width: $m-screen) {
        display: none;
      }
      
    }
  }
</style>
