module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', [_c('div', {
    staticClass: "password-field-component u-mt2"
  }, [(_vm.inputLabel) ? _c('span', {
    staticClass: "subheader u-text-uppercase form-edit-contact-info__label u-pb2"
  }, [_vm._v(_vm._s(_vm.inputLabel) + " *")]) : _vm._e(), _vm._v(" "), _c('el-input', {
    attrs: {
      "type": _vm.innerShowPassword ? 'text' : 'password',
      "autocomplete": "off",
      "required": ""
    },
    nativeOn: {
      "keyup": function($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.$emit('enterKeyPressed')
      }
    },
    model: {
      value: (_vm.innerValue),
      callback: function($$v) {
        _vm.innerValue = (typeof $$v === 'string' ? $$v.trim() : $$v)
      },
      expression: "innerValue"
    }
  }), _vm._v(" "), _c('a', {
    staticClass: "password-field-component__show-item",
    class: _vm.innerShowPassword ? 'password-field-component__visible-password' : '',
    on: {
      "click": function($event) {
        _vm.innerShowPassword = !_vm.innerShowPassword
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/logos/eye_logo.svg"),
      "alt": _vm.t('global.show')
    }
  })])], 1)])
},staticRenderFns: []}