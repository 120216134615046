<template>
   <div v-loading="loading">
    <div class="u-mb4">
      <el-radio v-for="tab in availableTabs" v-model="selectedTab" :label="tab.id" :key="tab.id">{{tab.title}}</el-radio>
    </div>
     
     <h4 v-if="availableTabs.length === 0" class="media__title u-warning">
         <i class="material-icons md-text" aria-hidden="true">warning</i>
         <span>You cannot select a payment method here.</span>
     </h4>

    <el-form v-if="availableTabs.length">
      <bc-bank-account-details v-if="selectedTab === 'ach'" @save="addNewBankAccountMethod($event)" />
      <bc-credit-card-details v-if="selectedTab === 'card'" @save="addNewCreditCard($event)" />
    </el-form>

  </div>
</template>

<script>

import BankAccountDetails from './bank-account-details.component.vue'
import CreditCardDetails from './credit-card-details.component.vue'
import {mapActions, mapState} from 'vuex'

export default {
  name: 'r-ba-add-payment-method',
  props: ['insuredId'],
  data () {
    return {
      selectedTab: 'ach',
      loading: false
    }
  },
  computed: {
    ...mapState('common', ['dialogPayData']),
    autoPayCreditCardEnabled () {
      let autoPayMethodSetting = this.setting('payments.autopay_payment_methods')
      return autoPayMethodSetting === 'ap_credit_cards' || autoPayMethodSetting === 'ap_ba_and_cc'
    },
    autoPayBankAccountEnabled () {
      let autoPayMethodSetting = this.setting('payments.autopay_payment_methods')
      return autoPayMethodSetting === 'ap_bank_accounts' || autoPayMethodSetting === 'ap_ba_and_cc'
    },
    creditCardEnabled () {
      let payMethodSetting = this.setting('payments.available_payment_methods')
      return payMethodSetting === 'credit_cards' || payMethodSetting === 'ba_and_cc'
    },
    bankAccountEnabled () {
      let payMethodSetting = this.setting('payments.available_payment_methods')
      return payMethodSetting === 'bank_accounts' || payMethodSetting === 'ba_and_cc'
    },
    availableTabs () {
      let tabs = []
      const autoPayActivated = this.dialogPayData.context ? this.dialogPayData.context.fromAutopay : false

      if (this.bankAccountEnabled && (!autoPayActivated || (autoPayActivated && this.autoPayBankAccountEnabled))) {
        tabs.push({
          id: 'ach',
          title: this.t('payments.bank_account'),
        })
      }
      if (this.creditCardEnabled && (!autoPayActivated || (autoPayActivated && this.autoPayCreditCardEnabled))) {
        tabs.push({
          id: 'card',
          title: this.t('payments.credit_card'),
        })
      }
      if (tabs.length === 1) {
        this.selectTab(tabs[0].id)
      }
      return tabs
    },
  },
  methods: {
    ...mapActions('payments', ['addPaymentMethod']),
    ...mapActions('common', ['loadContact']),
    getAvailableTabsLength () {
      return this.availableTabs.length
    },
    buttonType (id) {
      return this.selectedTab === id ? 'primary' : 'default'
    },
    selectTab (id) {
      this.selectedTab = id
    },
    addNewBankAccountMethod (e) {
      this.post('ACH', e)
    },

    addNewCreditCard (e) {
      this.post('CC', e)
    },
    async post (type, data) {
      let payload = this.getEmptyPayload()
      this.loading = true
      payload.type = type
      if (type === 'CC') {
        let card = data
        payload.card_cvv2 = card.cvv2
        payload.card_expires_mm = card.expires_mm
        payload.card_expires_yy = card.expires_yy
        payload.card_name_on = card.name_on
        payload.card_number = card.number
      } else if (type === 'ACH') {
        let ach = data
        payload.ach_type = ach.type
        payload.ach_name_on = ach.name_on
        payload.ach_bank = ach.bank
        payload.ach_account = ach.account
        payload.ach_routing = ach.routing
      }

      payload.insured_id = this.insuredId
      this.loading = true
      try {
        await this.addPaymentMethod(payload)
        this.loading = false
        this.$emit('paymentMethodAdded')
      } catch (result) {
        this.loading = false
        if (!!result.data.action_result && result.data.action_result !== 'unknown') {
          this.$elAlertHTML(this.t('payments.action_result_' + result.data.action_result), this.t('global.error'))
        } else {
          this.$elAlertHTML(
            this.t('payments.error_occurred_while_adding_pm') + ': ' + result.data.message,
            this.t('global.error')
          )
        }
      }
    },

    getEmptyPayload: function () {
      return {
        type: 'ACH',
        card_expires_mm: '0',
        card_expires_yy: '0',
        card_name_on: '',
        card_number: '',
        card_cvv2: '',
        ach_type: '0',
        ach_name_on: '',
        ach_bank: '',
        ach_account: '',
        ach_routing: '',
      }
    },
  },
  components: {
    'bc-bank-account-details': BankAccountDetails,
    'bc-credit-card-details': CreditCardDetails,
  },
}
</script>
