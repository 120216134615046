<template>
  <div v-if="orderedPolicies" v-loading="loading">
    <div class="make-payments__page u-mb4">
      <bc-navbar :title="t('payments.make_payments')" class="u-m0"></bc-navbar>
      <div class="make-payments__btn">
        <el-button
                   @click="handleMakePaymentsDialog(true)"
                   :disabled="!checkedPolicies.length"
                   type="primary"
                   class="u-width100 u-text-uppercase"
        >
          {{ t('payments.make_payment') }}
        </el-button>
      </div>
    </div>
    <div v-for="policy in orderedPolicies" :key="policy.id" class="u-mb3">
      <div @click="processPolicy(policy)" class="card-outer" :class="`card-outer__badge_${badgeType(policy)}`">
        <!--  Active policies -->
        <div v-if="!policy.is_canceled" class="card-content-inner">
          <el-row :gutter="10">
            <el-col :xs="4" :sm="1" v-if="isAllowedToBePaid(policy)">
              <div class="checkbox">
                <div class="payments__custom-checkbox u-mt2">
                  <input v-model="checkedPolicies"
                         :value="policy"
                         type="checkbox"
                         :disabled="!isAllowedToBePaid(policy)"
                  >
                  <div class="item-media"><i class="icon icon-form-checkbox"></i></div>
                </div>
              </div>
            </el-col>
            <el-col :xs="{span: 20, offset: calculateColumnOffset(policy, 'xs')}" :sm="{span: 8, offset: calculateColumnOffset(policy, 'sm')}">
              <div class="u-ml2">
                <div class="payments__card-head">
                  <fa-icon :icon="determinePolicyIcon(policy)" class="payments__card-head_light link-icon-medium" />
                  <span class="u-pl1">
                    {{ policy.properties && policy.properties.length ? policy.properties[0].address_line1 : '' }}
                  </span>
                </div>
                <div class="payments__card-info u-mt1">
                  {{ t('policies.header_policy_number', {policy_number:policy.policy_number}) }}
                </div>
              </div>
            </el-col>
            <el-col :xs="8" :sm="5">
              <div v-if="policyHasNextTerm(policy)">
                <div class="payments__card-head">{{ definePolicyDue(policy).message }}</div>
                <div class="payments__card-info">{{ definePolicyDue(policy).dateTxt }}</div>
              </div>
              <div v-else-if="!policyHasNextTerm(policy) && isValidDueDate(policy)">
                <div class="payments__card-head">{{ policy.due_date }}</div>
                <div class="payments__card-info">{{ definePolicyDue(policy).dateTxt }}</div>
              </div>
              <div v-else>
                <div class="payments__card-head_hidden">--</div>
              </div>
            </el-col>
            <el-col :xs="8" :sm="5">
              <div v-if="policyHasEntireTerm(policy)">
                <div class="payments__card-head">{{ asCurrency(policy.payoff_amount) }}</div>
                <div class="payments__card-info">{{ t('payments.entire_term') }}</div>
              </div>
              <div v-else>
                <div class="payments__card-head_hidden">--</div>
              </div>
            </el-col>
            <el-col :xs="8" :sm="5">
              <div v-if="isAllowedNextTerm(policy)">
                <div class="payments__card-head">{{ asCurrency(policy.next_due) }}</div>
                <div class="payments__card-info">{{ t('payments.next_term') }}</div>
              </div>
              <div v-else :class="definePolicyDue(policy).cssClass">
                <div class="payments__card-head">{{ definePolicyDue(policy).amount }}</div>
                <div class="payments__card-info">{{ definePolicyDue(policy).message }}</div>
              </div>
            </el-col>
          </el-row>
          <div class="u-mt4 disallowed-message" v-if="!policy.payments_allowed">
              {{ t('payments.disallowed_payment_message') }}
          </div>
        </div>
        <!--  Canceled policies -->
        <div v-else class="card-content-inner">
          <el-row :gutter="10">
            <el-col :xs="{span: 23, offset: 2}" :sm="{span: 9, offset: 1}">
              <div>
                <div class="payments__card-head">
                  <fa-icon :icon="determinePolicyIcon(policy)" class="payments__card-head_light link-icon-medium" />
                  <span class="u-pl1">
                    {{ policy.properties && policy.properties.length ? policy.properties[0].address_line1 : '' }}
                  </span>
                </div>
                <div class="payments__card-info u-mt1">
                  {{ t('policies.header_policy_number', {policy_number: policy.policy_number}) }}
                </div>
              </div>
            </el-col>
            <el-col :xs="{span: 14, offset: 2}" :sm="{span: 5, offset: 9}">
              <div class="payments__card-info u-mt1">
                {{ policy.policy_status }}
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <!-- Payment Dialog -->
    <bc-payment-dialog
        @handleMakePaymentsDialog="handleMakePaymentsDialog"
        @resetCheckedPoliciesList="resetCheckedPoliciesList"
        @reloadPoliciesInProgress="reloadPoliciesInProgress"
        :openPaymentDialog="openPaymentDialog"
        :checkedPolicies="checkedPolicies"
        :activePayType="activePayType"
    />

  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import BcPaymentDialog from './payment-dialog'
import { policyDue, isPaymentAllowed } from '@shared_src/utils/payments.utils'

export default {
  name: 'make-payments-page',
  components: {
    BcPaymentDialog
  },
  mounted () {
    this.setCheckedPolicies([])  // Remove predefined data from dashboard
  },
  data () {
    return {
      openPaymentDialog: false,
      loading: false,
      resetCheckedPoliciesListValue: false,
    }
  },
  computed: {
    ...mapGetters('policies', ['orderedPolicies', 'determinePolicyIcon']),
    ...mapGetters('account', ['insuredIds']),
    ...mapState('payments', ['policiesForPay']),
    // this is wrong approach since object comparison fails once any of object attributes updated
    // (due amount will change after payment f.e. etc).
    // Right now we need to cleanup checkedPolicies array if any payment attempt happened. Otherwise we will have issues
    // when checkedPolicies has element(s) which are not rendered on make payment page, but rendered on bulk pay dialog
    // TODO change this approach to use policy.id only
    checkedPolicies: {
      get () {
        return this.policiesForPay
      },
      set (value) {
        this.setCheckedPolicies(value)
      }
    },
    activePayType () {
      return this.policiesForPay.length && this.policiesForPay[0].paymentType
          ? this.policiesForPay[0].paymentType : 'currentDue'
    }
  },
  methods: {
    ...mapMutations('common', ['handlePayDialog']),
    ...mapMutations('payments', ['setCheckedPolicies']),
    resetCheckedPoliciesList () {
      // reset selection. Need to be triggered if any payment triggered. Triggered by payment dialog
      // this selection reset should be postponed until all operations inside dialog ends (or we need to stop passing original array to bulk dialog etc)
      // if we reset checkedPolicies list immediately after hitting "pay" button we will see empty dialog during payment transaction(s)
      this.resetCheckedPoliciesListValue = true
    },
    reloadPoliciesInProgress (val) {
      // hide page content while reloading policies. Otherwise if policies loading is slow we could show user old state of policies which is misbehave
      this.loading = val
    },
    badgeType (policy) {
      if (policy.is_canceled) {
        return 'disabled'
      } else {
        if (policy.paymentType === 'currentDue') return 'due'
        if (policy.paymentType === 'entireTerm' || policy.paymentType === 'nextTerm') return 'nodue'
        return 'disabled'
      }
    },
    reloadPolicies () {
      this.loading = true
      this.$store.dispatch('policies/reload')
          .finally(() => {
            this.loading = false
          })
    },
    handleMakePaymentsDialog (val) {
      this.openPaymentDialog = val
      if (!val) {
        // if "hide dialog" triggered
        if (this.resetCheckedPoliciesListValue) this.setCheckedPolicies([]) // postponed list clearance on dialog close
        this.resetCheckedPoliciesListValue = false
      }
    },
    handleDialogs () {
      this.handleMakePaymentsDialog(false)
      this.handlePayDialog({
        isDialogVisible: true,
        currentSubMenu: 'make-payments'
      })
    },
    calculateColumnOffset (policy, screen) {
      if (!this.isAllowedToBePaid(policy)) {
        return screen === 'sm' ? 1 : 4
      }
      return 0
    },
    isValidDueDate (data) {
      return data.due_date !== '--'
    },
    policyHasEntireTerm (policy) {
      return policy.payoff_amount > 0
    },
    policyHasNextTerm (policy) {
      return policy.next_due > 0
    },
    isAllowedNextTerm (policy) {
      return policy.current_due <= 0 && policy.next_due > 0
    },
    isAnythingToPay (policy) {
      return policy.current_due > 0 || policy.payoff_amount > 0 || policy.next_due > 0
    },
    isAllowedToBePaid (policy) {
      if (!isPaymentAllowed(policy)) return false
      return this.isAnythingToPay(policy)
    },
    processPolicy (policy) {
      if (!this.isAllowedToBePaid(policy)) return
      if (this.checkedPolicies.some(el => el.id === policy.id)) {
        this.checkedPolicies = this.checkedPolicies.filter((el) => el.id !== policy.id)
      } else {
        let isEqualTypeOfPolicies = this.checkedPolicies.every(el => el.paymentType === policy.paymentType)
        if (isEqualTypeOfPolicies) {
          this.checkedPolicies = this.checkedPolicies.concat(policy)
        } else {
          this.checkedPolicies.length = 0
          this.checkedPolicies = this.checkedPolicies.concat(policy)
        }
      }
    },
    definePolicyDue (policy) {
      return policyDue(policy)
    }
  },
}
</script>

<style scoped lang="scss">
  @import '../../../sass/_variables.scss';

  .card-outer {
    border: 2px solid #E5E5E5;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
    &:before {
      position: absolute;
      content: '';
      left: -1px;
      top: -1px;
      bottom: -1px;
      display: inline-block;
      width: 6px;
      border-bottom-left-radius: 3px;
      border-top-left-radius: 3px;
    }
    &__badge {
      &_due:before {
        background: $color-overdue;
      }
      &_nodue:before {
        background: $color-success;
      }
      &_disabled:before {
       background: $color-border;
      }
    }
  }

  .card-content-inner {
    padding: 20px;
    color: $color-black;
  }
  .payments {
    &__custom-checkbox {
      width: 20px;
      height: 20px;
      input[type="checkbox"] {
        height: 100%;
        width: 100%;
      }
    }
    &__card-head {
      font-size: 18px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &_light {
        color: #626262;
      }
      &_hidden {
        visibility: hidden;
      }
    }
    &__card-info {
      color: #626262;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .disallowed-message {
    text-align: center;
    font-style: italic;
  }

</style>

<style lang="scss">
@import '../../../sass/_variables.scss';

.make-payments {
  &__page {
    display: flex;
    align-content: center;
    justify-content: space-between;

    @media only screen and (max-width: $l-screen) {
      margin-top: 20px;
      flex-direction: column;
    }
  }
  &__btn {
    @media only screen and (max-width: $l-screen) {
      margin-top: 20px;
    }
  }
}

.payments-dialog {
  width: 40%;
  max-width: 600px;

  @media only screen and (max-width: $l-screen) {
    width: 80%;
    margin: auto;
  }

  &__title {
    font-size: 30px;
    font-weight: 600;
    color: $color-black;
    display: flex;
    align-items: center;
  }
  button.el-dialog__headerbtn {
    top: 20px;
    svg.el-dialog__close {
      font-size: 25px;
      color: #626262;
    }
  }
}
</style>
