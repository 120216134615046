<template>
  <h2 class="navbar-header">{{ title }}</h2>
</template>

<script>
/* eslint-disable */

export default {
  name: 'bc-navbar',
  props: {
    title: {type: String, default: 'Navbar'},
    backLink: {type: Boolean, default: false},
    noRightMenu: {type: Boolean, default: false},
    showLogo: {type: Boolean, default: false},
  },
  data() {
    return {}
  }
}
</script>

<style scoped lang="scss">
.navbar-header {
  font-size: 2em;
  font-weight: 600;
  @media only screen and (max-width: 768px) {
    font-size: 1.8em;
  }
}
</style>
