module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', [_c('bc-navbar', {
    attrs: {
      "title": _vm.t('documents.documents_label')
    }
  }), _vm._v(" "), (_vm.policiesListByType) ? _c('bc-policy-list', {
    attrs: {
      "policiesListByType": _vm.policiesListByType,
      "screenType": "documents"
    }
  }) : _vm._e()], 1)
},staticRenderFns: []}