<template>
  <div class="credit-card-details">
    <div data-f7="list" form class="inputs-list no-hairlines">

      <div data-f7="list-item">
        <span data-f7="label" class="subheader u-block">{{ t('payments.credit_card_number') }} *</span>
          <div class="media__image media__image--card">
            <bc-payment-method-icon :paymentMethod="paymentMethodForIcon"></bc-payment-method-icon>
          </div>
          <el-input @keyup.enter.native="saveButtonClick" v-bind:disabled="readonly" :maxlength="19" class="credit-card-input__text2"
                    auto-complete="cc-number" v-model="masked" type="tel" placeholder="1111 2222 3333 4444" required />

      </div>
      <div data-f7="list-item" v-if="validateCardCopy.card" class="u-warning">
        <i class="material-icons md-text" aria-hidden="true">warning</i> {{ validateCardCopy.card }}
      </div>

      <div data-f7="list-item" class="u-mt3">
        <span data-f7="label" class="subheader u-block">{{ t('payments.full_name_as_it_appears') }} *</span>
        <el-input @keyup.enter.native="saveButtonClick" v-bind:disabled="readonly" class="item-input-field"
                  auto-complete="cc-name" v-model="card.name_on" type="text" required />
      </div>
      <div data-f7="list-item" v-if="validateCardCopy.fullName" class="u-warning">
        <i class="material-icons md-text" aria-hidden="true">warning</i> {{ validateCardCopy.fullName }}
      </div>


      <div data-f7="list-item" class="u-mb4">
        <div class="row u-mt3">
          <div class="col-40">
            <span data-f7="label" class="subheader u-block">{{ t('payments.month') }} *</span>
            <el-select data-f7="input" v-bind:disabled="readonly" auto-complete="cc-exp-month" class="item-input-field" v-model="card.expires_mm" placeholder="">
              <el-option value="0" disabled hidden>{{ t('payments.month') }}</el-option>
              <el-option value="01">01</el-option>
              <el-option value="02">02</el-option>
              <el-option value="03">03</el-option>
              <el-option value="04">04</el-option>
              <el-option value="05">05</el-option>
              <el-option value="06">06</el-option>
              <el-option value="07">07</el-option>
              <el-option value="08">08</el-option>
              <el-option value="09">09</el-option>
              <el-option value="10">10</el-option>
              <el-option value="11">11</el-option>
              <el-option value="12">12</el-option>
            </el-select>
          </div>
          <div class="col-10 credit-card__month-year-separator">
          </div>
          <div class="col-40">
            <span data-f7="label" class="subheader u-block">{{ t('payments.year') }} *</span>
            <el-select placeholder="" data-f7="input" v-bind:disabled="readonly" auto-complete="cc-exp-year" class="item-input-field" v-model="card.expires_yy" placeholder="">
              <el-option value="0" disabled hidden :label="t('payments.year')"></el-option>
              <el-option v-for="year in years" :value="year + ''" :key="year" :label="year"></el-option>
            </el-select>
          </div>
        </div>
        <div v-if="validateCardCopy.expiresMonth" class="u-warning item-inner">
          <i class="material-icons md-text" aria-hidden="true">warning</i> {{ validateCardCopy.expiresMonth }}
        </div>
        <div v-if="validateCardCopy.expiresYear" class="u-warning item-inner">
          <i class="material-icons md-text" aria-hidden="true">warning</i> {{ validateCardCopy.expiresYear }}
        </div>
        <div class="row u-mt3">
          <div class="col-40">
            <span data-f7="label" class="subheader">{{ t('payments.sec_code') }} *</span>
            <el-input @keyup.enter.native="saveButtonClick" v-bind:disabled="readonly" v-model="card.cvv2" class="item-input-field credit-card__cvv" type="tel" auto-complete="off" placeholder="***"/>
          </div>
        </div>
        <div v-if="validateCardCopy.securityCode" class="u-warning item-inner">
          <i class="material-icons md-text" aria-hidden="true">warning</i> {{ validateCardCopy.securityCode }}
        </div>
        <!-- row ends -->
      </div>
    </div>
    <div data-f7="block" v-if="!readonly">
      <el-button  data-f7="button" type="primary" @click="saveButtonClick()">{{ t('payments.save_payment_method') }}</el-button>
    </div>
  </div>
</template>

<script>
import {deepClone} from '@shared_src/utils/misc.util'
import {getCreditCardType} from '@shared_src/utils/payments.utils'
import BcPaymentMethodIcon from './payment-method-icon.component.vue'
import _ from 'lodash'

export default {
  components: {BcPaymentMethodIcon},
  name: 'bc-credit-card-details',
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    existing_card: {
      type: Object,
      default () {
        return null
      },
    },
  },
  data () {
    let years = _.range(new Date().getFullYear(), new Date().getFullYear() + 10)
    let card =
      {
        number: '',
        name_on: '',
        expires_mm: '',
        expires_yy: '',
        cvv2: '',
      }
    if (this.existing_card) {
      let existing = this.existing_card
      card.number = existing.masked_number
      card.name_on = existing.name_on_account
      let expirationDate = new Date(existing.expire_date)
      let expiresMm = expirationDate.getMonth() + 1  // js month starts from 0
      if (expiresMm < 10) {
        expiresMm = '0' + expiresMm
      }
      card.expires_mm = expiresMm
      card.expires_yy = expirationDate.getFullYear() + ''
      card.cvv2 = '***'
    }
    return {
      years,
      card,
      validateCardCopy: {
        card: null,
        fullName: null,
        expiresMonth: null,
        expiresYear: null,
        securityCode: null,
      }
    }
  },
  computed: {
    masked: {
      get: function () {
        if (this.card.number) {
          let formatted = this.card.number.replace(/(\d{4}(?!\s))/g, '$1 ')
          if (formatted.length === 20) {
            formatted = formatted.trim()
          }
          return formatted
        }
        return ''
      },
      set: function (newValue) {
        let number = newValue.replace(/\s/g, '')
        this.card.number = number
      },
    },
    validateCard () {
      return {
        card: !this.card.number.trim() ? this.t('payments.credit_card_number_is_required') : null,
        fullName: !this.card.name_on.trim() ? this.t('payments.full_name_is_required') : null,
        expiresMonth: !this.card.expires_mm.trim() ? this.t('payments.month_is_required') : null,
        expiresYear: !this.card.expires_yy.trim() ? this.t('payments.year_is_required') : null,
        securityCode: !this.card.cvv2.trim() ? this.t('payments.security_code_is_required') : null,
      }
    },
    validateCardValues () {
      return Object.keys(this.validateCard).every(key => !this.validateCard[key])
    },
    paymentMethodForIcon () {
      let cardType = this.readonly ? this.existing_card.name : this.cardTypeFromNumber(this.card.number)
      return { type: 'Credit Card', name: cardType }
    },
  },
  methods: {
    validateExpirationDate () {
      let date = new Date()
      let currentYr = date.getFullYear()
      let currentMth = date.getMonth() + 1
      let ccYr = parseInt(this.card.expires_yy)
      let ccMth = parseInt(this.card.expires_mm)

      if (ccYr < currentYr) {
        return false
      }

      if (currentYr === ccYr && ccMth < currentMth) {
        return false
      }

      return true
    },
    saveButtonClick () {
      this.validateCardCopy = deepClone(this.validateCard)
      if (!this.validateCardValues) {
        this.$elAlert(this.t('enrollment.some_error_happened_notification'), this.t('global.notice'))
        return
      }
      if (!this.validateExpirationDate()) {
        this.$elAlert(this.t('payments.invalid_expiration_date'), this.t('global.notice'))
        return
      }

      console.log('saveBcard_cvv2uttonClick')
      let cardInformation = Object.assign({}, this.card)
      cardInformation.cardTypeFromNumber = this.cardTypeFromNumber(this.card.number)
      this.$emit('save', cardInformation)
    },
    cardTypeFromNumber (cardNumber) {
      return getCreditCardType(cardNumber)
    },
  },
}
</script>
<style>
  .credit-card__cvv {
    -webkit-text-security: disc;
  }

  .credit-card__month-year-separator {
    padding-top: 24px;
  }
</style>
