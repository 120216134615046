function install (Vue) {
  function bcAlert (message, title, callback, buttonText, dangerouslyUseHTMLString) {
    Vue.prototype.$alert(message, title, {
      confirmButtonText: buttonText || Vue.prototype.t('global.ok'),
      callback: callback,
      customClass: 'bc-alert',
      dangerouslyUseHTMLString: dangerouslyUseHTMLString
    })
  }
  Vue.prototype.$elAlert = function $elAlert (message, title, callback, buttonText) {
    bcAlert(message, title, callback, buttonText, false)
  }

  Vue.prototype.$elAlertHTML = function $elAlert (message, title, callback, buttonText) {
    bcAlert(message, title, callback, buttonText, true)
  }

  Vue.prototype.$elConfirm = function $elConfirm (message, title, options) {
    let okCallback = () => {}
    let cancelCallback = okCallback
    let type = options.type

    if (options.okCallback) {
      okCallback = options.okCallback
    }

    if (options.cancelCallback) {
      cancelCallback = options.cancelCallback
    }

    let elementConfirmOptions = {
      confirmButtonText: Vue.prototype.t('global.ok'),
      cancelButtonText: Vue.prototype.t('global.cancel')
    }

    if (type) {
      elementConfirmOptions.type = type
    }

    Vue.prototype.$confirm(message, title, elementConfirmOptions).then(okCallback).catch(cancelCallback)
  }

  Vue.prototype.$elPrompt = function $elPrompt (message, title, options) {
    let okCallback = () => {}
    let cancelCallback = okCallback
    if (options.okCallback) {
      okCallback = options.okCallback
    }

    if (options.cancelCallback) {
      cancelCallback = options.cancelCallback
    }

    let elementPromptOptions = {
      confirmButtonText: Vue.prototype.t('global.ok'),
      cancelButtonText: Vue.prototype.t('global.cancel')
    }

    if (options.inputPattern) {
      elementPromptOptions.inputPattern = options.inputPattern
    }

    if (options.defaultText) {
      elementPromptOptions.inputValue = options.defaultText
    }

    if (options.inputErrorMessage) {
      elementPromptOptions.inputErrorMessage = options.inputErrorMessage
    }

    Vue.prototype.$prompt(message, title, elementPromptOptions).then(okCallback).catch(cancelCallback)
  }
}

export default install
