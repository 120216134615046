<template>
  <img v-if="imageSource" :src="imageSource" />
  <div v-else-if="loading" v-loading="loading">...</div>
</template>

<script>

import {mapActions} from 'vuex'

export default {
  name: 'bc-general-image',
  props: {
    fileId: {type: String, default: null},
    source: {type: String, default: null},
  },
  methods: {
    ...mapActions('documents', ['getAttachmentFile']),
    disableLoading () {
      this.loading = false
    }
  },
  computed: {
    imageSource () {
      if (this.imageInternalSource !== null) {
        this.disableLoading()
        return this.imageInternalSource
      } else if (this.fileId) {
        this.getAttachmentFile([this.fileId, false]).then((data) => {
          this.$nextTick(() => {
             // this is to ensure that image was really loaded and rendered, call it in the next render cycle
            this.$emit('imageLoaded')
          })
          this.imageInternalSource = 'data:' + data.contentType + ';base64,' + data.data
        }).catch(
          data => { console.log('Error occurred while loading image', data) }
        ).finally(() => {
          this.disableLoading()
        })
      }
    }
  },
  data () {
    return {
      imageInternalSource: this.source,
      loading: true
    }
  }
}
</script>
