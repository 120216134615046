module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('nav', [_c('div', {
    staticClass: "c-header__upper-nav"
  }, [_c('div', {
    staticClass: "c-header__nav"
  }, [(_vm.isFeatureEnabled) ? _c('div', {
    staticClass: "c-notification",
    on: {
      "click": _vm.hideMobile
    }
  }, [_c('el-popover', {
    attrs: {
      "placement": "bottom",
      "popper-class": "popover-message-center"
    },
    model: {
      value: (_vm.visibleMessageDialog),
      callback: function($$v) {
        _vm.visibleMessageDialog = $$v
      },
      expression: "visibleMessageDialog"
    }
  }, [_c('div', {
    staticClass: "account-link"
  }, [(_vm.visibleMessageDialog) ? _c('bc-message-center') : _vm._e()], 1), _vm._v(" "), _c('el-button', {
    staticClass: "c-header__nav-link el-button--transparent u-no-border",
    attrs: {
      "slot": "reference",
      "type": _vm.getButtonType
    },
    slot: "reference"
  }, [_c('fa-icon', {
    attrs: {
      "icon": ['far', 'bell']
    }
  }), _vm._v(" "), (_vm.badge || _vm.badge > 0) ? _c('div', {
    staticClass: "c-notification__badge u-flex-vertical-center"
  }, [_c('span', [_vm._v(_vm._s(_vm.badge))])]) : _vm._e()], 1)], 1)], 1) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "c-account"
  }, [_c('el-popover', {
    attrs: {
      "placement": "top",
      "width": "200",
      "popper-class": "popover-account"
    },
    model: {
      value: (_vm.visibleAccountDialog),
      callback: function($$v) {
        _vm.visibleAccountDialog = $$v
      },
      expression: "visibleAccountDialog"
    }
  }, [_c('div', [_c('div', {
    staticClass: "account-link",
    on: {
      "click": _vm.hideMobile
    }
  }, [_c('router-link', {
    staticClass: "u-text--uppercase",
    attrs: {
      "active-class": "account-link__active",
      "to": '/account/settings'
    }
  }, [_vm._v("\n                " + _vm._s(_vm.t('dashboard.view_account')) + "\n              ")])], 1), _vm._v(" "), _c('div', {
    staticClass: "account-link",
    on: {
      "click": _vm.logOutClicked
    }
  }, [_c('a', {
    staticClass: "u-text--uppercase"
  }, [_vm._v("\n                " + _vm._s(_vm.t('global.logout')) + "\n              ")])])]), _vm._v(" "), _c('el-button', {
    staticClass: "c-header__nav-link el-button--transparent u-no-border",
    class: _vm.isActiveAccountView,
    attrs: {
      "slot": "reference",
      "type": _vm.getButtonType
    },
    slot: "reference"
  }, [_c('fa-icon', {
    attrs: {
      "icon": ['far', 'user']
    }
  }), _vm._v(" "), _c('fa-icon', {
    class: [_vm.visibleAccountDialog ? 'u-rotate-180' : '', 'link-icon-small'],
    attrs: {
      "icon": ['far', 'chevron-down']
    }
  })], 1)], 1)], 1)])]), _vm._v(" "), _c('div', {
    staticClass: "toggle-icon"
  }, [_c('a', {
    staticClass: "toggle-icon__link",
    on: {
      "click": _vm.toggleMobMenu
    }
  }, [_c('fa-icon', {
    attrs: {
      "icon": _vm.isToggled ? ['fal', 'times'] : ['far', 'bars'],
      "aria-hidden": "true"
    }
  })], 1)])])
},staticRenderFns: []}