<template>
    <div class="schedule-preview">
      <transition name="el-fade-in">
        <div v-show="visiblePopover" class="schedule-preview__popover">
          <div class="u-flex-horizontal-space-between schedule-info u-width100 u-mt2 u-mb2">
            <span>{{ t('payments.payment_schedule')  }}</span>
            <span class="schedule-preview__close-icon" @click="switchPopover( false, $event)">
               <fa-icon icon="times" aria-hidden="true"></fa-icon>
            </span>
          </div>
          <div v-for="installment in selectedBillingScheduleOptions" class="u-mb1">
            <div class="u-flex-horizontal-space-between u-width100" :class="{'u-danger' : installment.isPastDue }">
              <i v-if="installment.isPastDue" class="material-icons md-text u-warning u-pb1">warning</i>
              <div class="u-pl1">{{ installment.dueDate }}</div>
              <div class="u-ml-auto">${{ installment.amountDue | currency(2) }}</div>
            </div>
          </div>
        </div>
      </transition>
      <div class="schedule-preview__sign schedule-info"
           @click="switchPopover(!visiblePopover, $event)"
           @mouseover="switchPopover(true, $event)"
           @mouseleave="switchPopover(false, $event)"
      >
        <fa-icon icon="exclamation-circle" class="u-primary"/>
        <span class="u-pl1">{{ t('payments.payment_schedule') }}</span>
      </div>
    </div>
</template>

<script>
export default {
  props: ['selectedBillingScheduleOptions'],
  name: 'ba-schedule-preview',
  watch: {
    selectedBillingScheduleOptions: {
      handler () {
        this.visiblePopover = true
      },
      immediate: true
    }
  },
  data () {
    return {
      visiblePopover: false
    }
  },
  methods: {
    switchPopover (val, event) {
      if (window.innerWidth <= 1024 && (event.type === 'mouseover' || event.type === 'mouseleave')) {
        return false
      } else {
        this.visiblePopover = val
      }
    }
  },
}
</script>

<style scoped lang="scss">
@import '../../../sass/_variables.scss';

.schedule-info {
  font-size: 14px;
  cursor: pointer;
}

.schedule-preview {
  position: relative;
  &__sign {
    opacity: 1;
    transition: opacity .2s ease-out;
    &:hover {
      opacity: .7;
      transition: opacity .2s ease-out;
    }
  }
  &__popover {
    position: absolute;
    top: -25px;
    left: -250px;
    width: 220px;
    background: $color-white;
    color: #606266;
    border: 1px solid #ebeef5;
    padding: 10px 15px 15px;
    border-radius: 4px;
    font-size: 14px;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
    z-index: 2000;
    word-break: break-all;

    @media only screen and (max-width: $l-screen) {
      transition: all .2s ease-out;
      position: relative;
      left: 0;
      top: 0;
      width: 100%;
      margin-bottom: 20px;
    }
  }
}
</style>
