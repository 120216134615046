<template>
  <div>
    <div class="u-mt4 u-mb4">
      <h2 class="secondary-header">{{ t('account.account_settings') }}</h2>
    </div>
    <!-- Account settings -->
    <bc-edit-authentication/>
  </div>
</template>

<script>
  import BcEditAuthentication from './edit-authentication'
  
  export default {
    name: 'settings',
    components: {
      BcEditAuthentication
    }
  }
</script>
