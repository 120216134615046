module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    staticClass: "upload-photos-border u-flex-vertical-center",
    on: {
      "click": _vm.getFile
    }
  }, [_c('div', {
    staticClass: "upload-link u-text-center",
    on: {
      "drop": function($event) {
        $event.preventDefault();
        return _vm.handleDrop.apply(null, arguments)
      },
      "dragover": function($event) {
        $event.preventDefault();
      }
    }
  }, [_c('fa-icon', {
    staticClass: "link-icon-medium",
    attrs: {
      "icon": ['far', 'cloud-upload']
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "upload-link__sign u-text-capitalize u-text--center u-mt1"
  }, [_vm._v(_vm._s(_vm.t('claims.upload_photos')))]), _vm._v(" "), _c('div', {
    staticClass: "upload-link__description u-text--center u-mt1"
  }, [_vm._v("\n        " + _vm._s(_vm.t('policies.drop_file_here_or_click_to_upload')) + "\n      ")]), _vm._v(" "), _c('input', {
    ref: "uploadImageToClaimRef",
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "type": "file",
      "accept": "image/*; capture=camera"
    },
    on: {
      "change": _vm.performPictureUpload
    }
  })], 1)])
},staticRenderFns: []}