export default [
  {
    path: '*',
    redirect: '/dashboard'
  },
  {
    path: '/login/',
    component: require('./initial/login.vue'),
    meta: {
      title: 'Login',
    }
  },
  {
    path: '/login-token',
    component: require('./initial/login_token.vue'),
    meta: {
      title: 'Login By Token',
    }
  },
  {
    path: '/dashboard',
    component: require('./pages/dashboard.vue'),
    meta: {
      title: 'Dashboard',
    }
  },
  {
    path: '/password-update-required',
    component: require('./pages/account/password-update-required.vue'),
    meta: {
      title: 'Password Update Required',
    }
  },
  {
    path: '/payments',
    component: require('./pages/payments-redesign/all-payments.vue'),
    children: [
      {
        path: '',
        component: require('./pages/payments-redesign/make-payments/make-payments.page.vue'),
        meta: {title: 'Make Payments'}
      },
      {
        path: 'payment-methods',
        component: require('./pages/payments-redesign/payment-methods/payment-methods.page.vue'),
        meta: {title: 'Payment Methods'}
      },
      {
        path: 'payment-plans',
        component: require('./pages/payments-redesign/payment-plans/payment-plans.page.vue'),
        meta: {title: 'Payment Plans'}
      },
      {
        path: 'payment-history/:policyId',
        component: require('./pages/payments-redesign/payment-history/payment-history.page.vue'),
        meta: {title: 'Payment History'},
      },
      {
        path: 'invoice-view/:documentId',
        component: () => import(/* webpackChunkName: "documents" */'./pages/document-view.vue'),
        meta: {
          title: 'Invoice',
        }
      },
      {
        path: 'reminders',
        component: require('./pages/payments-redesign/payment-reminders.vue'),
        meta: {
          title: 'Reminder',
        }
      },
    ]
  },
  {
    path: '/account',
    component: require('./pages/account/overview.vue'),
    meta: {
      title: 'Account Overview',
    },
    children: [
      {
        path: 'phone-numbers',
        component: () => import(/* webpackChunkName: "intl-tel-input" */ './pages/account/phone-numbers.vue'),
        meta: {title: 'Phone Numbers'}
      },
      {
        path: 'settings',
        component: require('./pages/account/settings.vue'),
        meta: {title: 'Account Settings'}
      },
      {
        path: 'change-password',
        component: require('./pages/account/change-password.vue'),
        meta: {title: 'Change Password'}
      },
      {
        path: 'document-delivery',
        component: require('./pages/account/document-delivery.vue'),
        meta: {title: 'Document Delivery'}
      },
      {
        path: 'edit-authentication',
        component: require('./pages/account/edit-authentication.vue'),
        meta: {title: 'Edit Authentication'}
      },
      {
        path: 'emails',
        component: require('./pages/account/emails.vue'),
        meta: {title: 'Emails'}
      }
    ]
  },
  {
    path: '/documents/:policyId/folders/:folderId',
    component: require('./pages/documents/documents.page'),
    meta: {
      title: 'Documents List'
    },
    children: [
      {
        path: '',
        component: require('./pages/documents/documents.component.vue'),
        meta: {title: 'Documents'}
      },
      {
        path: '/document-view/:documentId',
        component: () => import(/* webpackChunkName: "documents" */'./pages/document-view.vue'),
        meta: {title: 'Document View'}
      },
    ]
  },
  {
    path: '/password-reset/search-account',
    component: require('./pages/password-reset/search-account.page.vue'),
    meta: {
      title: 'Search Account',
    }
  },
  {
    path: '/password-reset/code-delivery',
    component: require('./pages/password-reset/code-delivery.page.vue'),
    meta: {
      title: 'Code Delivery',
    }
  },
  {
    path: '/password-reset/new-password',
    component: require('./pages/password-reset/new-password.page.vue'),
    meta: {
      title: 'New Password'
    }
  },
  {
    path: '/enrollment/search-policies-step',
    component: require('./pages/enrollment/search-policies-step.vue'),
    meta: {
      title: 'Search Policies',
    }
  },
  {
    path: '/enrollment/setup-account-step',
    component: require('./pages/enrollment/setup-account-step.vue'),
    meta: {
      title: 'Setup Account',
    }
  },
  {
    path: '/enrollment/terms-of-service/:backLink',
    component: require('./pages/enrollment/terms-of-service.vue'),
    meta: {
      title: 'Terms of Service',
    }
  },
  {
    path: '/agents/list',
    component: require('./pages/agents/list.vue'),
    meta: {
      title: 'Agents List',
    }
  },
  {
    path: '/policies/:policyId',
    component: require('./pages/policies/list.vue'),
    meta: {
      title: 'Policies list',
    },
    children: [
      {
        path: '',
        component: require('./pages/policies/view.vue'),
        meta: {
          title: 'Policies View',
        }
      }
    ]
  },
  {
    path: '/insureds/:contactId',
    component: require('./pages/policies/insured.vue'),
    meta: {
      title: 'Policies Insured',
    }
  },
  {
    path: '/custom/:slug',
    component: require('./pages/custom/custom.page.vue'),
    meta: {
      title: 'Custom Page',
    }
  },
  {
    path: '/claims/new/:policyId',
    component: require('./pages/claims/file-claim.page.vue'),
    meta: {
      title: 'File Claim',
    }
  },
  {
    path: '/claims/:claimId',
    component: require('./pages/claims/claims-list.page.vue'),
    meta: {
      title: 'Claims List',
    },
    children: [
      {
        path: '',
        component: require('./pages/claims/claim-details.component.vue'),
        meta: {
          title: 'Claims View',
        }
      }
    ]
  },
  {
    path: '/testing-all-controls',
    component: require('./pages/theme/preview.vue'),
    meta: {
      title: 'Theme preview',
    }
  },
]
