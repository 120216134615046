import zxcvbn from 'zxcvbn'
import {localize as t, findInSettings} from '../utils/i18n'

function passwordStrengthColor (score) {
  let color = ''
  if (score <= 1) {
    color = 'danger'
  } else if (score === 2) {
    color = 'warning'
  } else {
    color = 'success'
  }
  return color
}

function passwordStrengthValue (score) {
  return score < 1 ? 25 : score * 25
}

export function validatePassword (newPassword) {
  let passwordAnalysis = newPassword === '' ? { score: 0 } : zxcvbn(newPassword)

  if (!newPassword.length) {
    return {
      valid: false,
      messages: [t('enrollment.password_empty_notification')],
      passwordAnalysis: passwordAnalysis,
      passwordStrengthColor: passwordStrengthColor(passwordAnalysis.score),
      passwordStrengthValue: passwordStrengthValue(passwordAnalysis.score),
      score: 0,
    }
  }
  let requiredScore = findInSettings('enrollment.required_password_strength')
  let result = passwordAnalysis
  let resultScore = result.score ? result.score : 0
  if (resultScore < requiredScore) {
    let validationMessages = []
    if (result.feedback.warning) {
      validationMessages.push(result.feedback.warning)
    }

    for (let i = 0; i < result.feedback.suggestions.length; i++) {
      validationMessages.push(result.feedback.suggestions[i])
    }

    if (validationMessages.length === 0) {
      validationMessages.push(t('enrollment.password_weak_notification'))
    }
    return {
      valid: false,
      messages: validationMessages,
      passwordAnalysis: passwordAnalysis,
      passwordStrengthColor: passwordStrengthColor(passwordAnalysis.score),
      passwordStrengthValue: passwordStrengthValue(passwordAnalysis.score),
      score: passwordAnalysis.score,
    }
  }

  return {
    valid: true,
    messages: [],
    passwordAnalysis: passwordAnalysis,
    passwordStrengthColor: passwordStrengthColor(passwordAnalysis.score),
    passwordStrengthValue: passwordStrengthValue(passwordAnalysis.score),
    score: passwordAnalysis.score,
  }
}

export function validatePhoneNumber (num) {
  let phoneFormat = '^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$'
  return num.match(phoneFormat)
}

export function validateUSFormatPhoneNumber (num) {
  let phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
  if (phoneRegex.test(num)) {
    num = num.replace(phoneRegex, '($1) $2-$3')
  } else {
    return
  }
  return num
}
