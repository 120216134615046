
/* styles */
require("!!../../node_modules/extract-text-webpack-plugin/dist/loader.js?{\"omit\":1,\"remove\":true}!vue-style-loader!css-loader?{\"minimize\":true,\"sourceMap\":true}!../../node_modules/vue-loader/lib/style-compiler/index?{\"id\":\"data-v-7dbea2ad\",\"scoped\":true,\"hasInlineConfig\":false}!sass-loader?{\"sourceMap\":true}!../../node_modules/vue-loader/lib/selector?type=styles&index=0!./alert.component.vue")

var Component = require("!../../node_modules/vue-loader/lib/component-normalizer")(
  /* script */
  require("!!babel-loader!../../node_modules/vue-loader/lib/selector?type=script&index=0!./alert.component.vue"),
  /* template */
  require("!!../../node_modules/vue-loader/lib/template-compiler/index?{\"id\":\"data-v-7dbea2ad\"}!../../node_modules/vue-loader/lib/selector?type=template&index=0!./alert.component.vue"),
  /* scopeId */
  "data-v-7dbea2ad",
  /* cssModules */
  null
)

module.exports = Component.exports
