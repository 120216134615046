module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    staticClass: "add-payment-button-root"
  }, [_c('el-button', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: (!_vm.insuredIds.length),
      expression: "!insuredIds.length"
    }],
    staticClass: "u-mt3 u-mb3",
    attrs: {
      "disabled": !_vm.insuredIds.length,
      "type": "primary",
      "size": "small"
    },
    on: {
      "click": _vm.openDialog
    }
  }, [_vm._v("\n    " + _vm._s(_vm.t('payments.add_payment_method')) + "\n  ")]), _vm._v(" "), _c('ba-add-payment-method-modal', {
    attrs: {
      "available-insureds": _vm.insuredIds
    }
  })], 1)
},staticRenderFns: []}