<template>
  <div class="agent-block">
    <div class="agent-block__image u-flex-vertical-center">
      <div v-if="agent.contact.photo_id">
        <bc-general-image :fileId="agent.contact.photo_id" class="u-img-responsive" />
      </div>
      <div v-if="!agent.contact.photo_id" class="agent-block__icon">
        <fa-icon :icon="['far', 'user']" />
      </div>
    </div>
    <div class="agent-block__data u-width100">
      <div class="contacts-block u-mb3">
        <div>
          <div><h2 class="u-mt1 u-mb2">{{ agent.contact.name }}</h2></div>
          <div v-if="!shouldHideEmails && agent.contact && agent.contact.emails && agent.contact.emails.length > 0" class="agent-email">
            <div v-for="data in agent.contact.emails">
              <span class="u-pr1">{{ data.email }}</span>
              <a :href="composeEmail(data.email)" target="_blank"><fa-icon :icon="['far', 'copy']" class="u-primary" /></a>
            </div>
          </div>
        </div>
        <div v-if="agent.policies && agent.policies.length > 0">
          <div v-for="policy in agent.policies">
            <router-link :to="'/policies/'+ policy.policy_id" class="agent-policy">
              {{ t('policies.header_policy_number', { policy_number: policy.policy_number }) }}
            </router-link>
          </div>
        </div>
      </div>
      <div class="agent-block__contacts u-width100">
        <div class="agent-block__phone u-pr2">
          <div class="agent-block__sub-title">{{t('account.phone_numbers') }}</div>
          <div class="u-mt2">
            <div v-for="phone in agent.contact.phones">
              <span v-if="phone.type">{{ phone.type }}: </span><span>{{ phone.phone }}</span>
            </div>
          </div>
        </div>
        <div class="agent-block__address">
          <div v-for="address in agent.contact.addresses" class="u-mt2">
            <div class="agent-block__sub-title">
              {{ getAddressLabel(address) }} {{ t('global.address') }}
            </div>
            <div class="u-mt2">
              <div>{{ address.address_line1 }}</div>
              <div>{{ address.address_city}}, {{ address.address_state }}</div>
              <div>{{ address.address_zip }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'agent-card',
    props: {
      agent: {
        type: Object,
        required: true,
      }
    },
    computed: {
      shouldUseTypeLabel () {
        return this.setting('global.use_type_label_for_other_addresses')
      },
      shouldHideEmails () {
        return this.setting('global.hide_agent_emails')
      },
    },
    methods: {
      composeEmail (email) {
        return `mailto: ${email}`
      },
      getAddressLabel (address) {
        if (address.type === 'Other' && address.type_label && this.shouldUseTypeLabel) {
          return address.type_label
        }
        return address.type
      }
    }
  }
</script>

<style scoped lang="scss">
  @import '../sass/_variables.scss';
  
  .agent-block {
    display: flex;
    border: 2px solid #E5E5E5;
    padding: 15px 20px;
    margin-top: 20px;
    
    .contacts-block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      
      @media only screen and (max-width: $l-screen) {
        flex-direction: column-reverse;
        align-items: normal;
      }
    }
    &__image {
      width: 140px;
      height: 140px;
      background: #F8F8F8;
      margin-right: 20px;
      
      @media only screen and (max-width: $m-screen) {
        display: none;
      }
    }
    &__data {
      width: fit-content;
      
      .agent-policy {
        white-space: nowrap;
      }
    }
    &__sub-title {
      font-size: 18px;
      color: #626262;
    }
    &__icon {
      & > svg[data-icon="user"] {
        font-size: 60px;
        & > path {
          fill: #C0C0C0;
        }
      }
    }
    &__contacts {
      display: flex;
    }
    &__phone, &__address {
      width: 50%;
    }
  }
</style>
