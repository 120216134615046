import axios from 'axios'
import moment from 'moment'
import config from '@/../config/britecore'
import {isFailed} from '@shared_src/utils/api.utils'
import {axiosWrapper, usDateToMomentJsDate, getEarliestDate} from '@shared_src/utils/misc.util'
import {defineSinglePaymentOptions, policyDue} from '@shared_src/utils/payments.utils'
import {paymentMethodToDict} from '@shared_src/store/common.module'
import { isPaymentAllowed } from '../utils/payments.utils'
import eventBus from '@shared_src/eventHub'

const paymentsModule = {
  namespaced: true,
  state: {
    acctHistory: [],
    policiesForPay: null,
    ownedPaymentMethods: [],
  },
  mutations: {
    setAccountHistory: function (state, payload) {
      let policyId = payload.policyId
      let newAcctHistory = payload.history

      let cached = state.acctHistory.find(p => p.policyId === policyId)
      if (!cached) {
        state.acctHistory.push({policyId: policyId, acctHistory: newAcctHistory})
      } else {
        cached.acctHistory = newAcctHistory
      }
    },
    setCheckedPolicies (state, payload) {
      // TODO method/variable need to be removed in future since it's redundant step
      let checkedPolicies = []
      for (let index in payload) {
        let policy = payload[index]
        if (isPaymentAllowed(policy)) {
          checkedPolicies.push(policy)
        }
      }
      state.policiesForPay = checkedPolicies
    },
    setOwnedPaymentMethods (state, payload) {
      state.ownedPaymentMethods = payload.map(paymentMethod => paymentMethodToDict(paymentMethod))
    },
  },
  actions: {
    makePayment: function (context, payload) {
      return new Promise((resolve, reject) => {
        let token = localStorage.token
        let requestData = {
          token,
          ...payload
        }
        axios.post(config.url + '/ajax-make-payment/', requestData)
          .then((response) => {
            if (isFailed(response)) {
              console.error('ajax-make-payment failed', response)
              reject()
            }
            resolve(response.data)
          })
          .catch((e) => {
            console.error('ajax-make-payment failed', e)
            reject()
          })
      })
    },
    getPolicyBillingInformation: function (context, payload) {
      return new Promise((resolve, reject) => {
        let token = localStorage.token
        let requestData = {
          token,
          ...payload
        }
        axios.post(config.get_policy_billing_information_url, requestData)
          .then((response) => {
            if (isFailed(response)) {
              console.error('getPolicyBillingInformation failed', response)
              reject()
            }
            resolve(response.data)
          })
          .catch((e) => {
            console.error('getPolicyBillingInformation failed', e)
            reject()
          })
      })
    },
    retrieveBillingScheduleOptions: function (context, payload) {
      return new Promise((resolve, reject) => {
        let token = localStorage.token
        let requestData = {
          token,
          ...payload
        }
        axios.post(config.retrieve_billing_schedule_options, requestData)
          .then((response) => {
            if (isFailed(response)) {
              console.error('retrieveBillingScheduleOptions failed', response)
              reject()
            }
            resolve(response.data.data)
          })
          .catch((e) => {
            console.error('retrieveBillingScheduleOptions failed', e)
            reject()
          })
      })
    },
    async getOwnedPaymentMethods (context, policyId) {
      let result = await axiosWrapper(
        axios.get(config.get_owned_payment_methods_url, {headers: {Authorization: localStorage.token}}))
      context.commit('setOwnedPaymentMethods', result.data)
    },
    async deletePaymentMethod (context, paymentMethodId) {
      return axiosWrapper(
        axios.post(config.remove_payment_method_url, {token: localStorage.token, payment_method_id: paymentMethodId})
      )
    },
    addPaymentMethod: function (context, payload) {
      return new Promise((resolve, reject) => {
        axios.post(config.ajax_add_payment_method_url, {
          token: localStorage.token,
          ...payload,
        }).then(
          (result) => {
            if (result.data.success && result.data.action_result === 'created') {
              // payment method was CREATED successfully
              context.dispatch('getOwnedPaymentMethods').then(
                (result) => {
                  resolve(result)
                },
                (error) => {
                  console.warn('Server error while retrieve payment methods...', error)
                  reject(error)
                }
              )
            } else { // call was successful BUT action_result != 'created' as expected
              console.warn('create payment method did not succeed completely...', result.data)
              reject(result)
            }
          },
          (error) => {
            console.warn('Server error while creating create payment...', error)
            reject(error)
          }
        )
      })
    },
    changeBillingSchedule: function (context, payload) {
      let token = localStorage.token

      let postThis = {
        token,
        ...payload
      }
      return new Promise((resolve, reject) => {
        axios.post(config.change_billing_schedule, postThis)
          .then((result) => {
            if (!isFailed(result)) {
              resolve()
            } else {
              console.warn('changeBillingSchedule failed', result)
              reject()
            }
          }, (e) => {
            console.warn('Error during changeBillingSchedule ', e)
            reject(e)
          })
      })
    },
    changeAutopaySingle: function (context, payload) {
      return new Promise((resolve, reject) => {
        axios.post(config.get_change_autopay_single_url, {
          token: localStorage.token,
          ...payload
        })
          .then(response => {
            if (!isFailed(response)) {
              resolve()
            } else {
              reject()
            }
          }, err => {
            console.warn('Error during changeAutopaySingle ', err)
            reject()
          })
      })
    },
    loadAccountHistory: function (context, payload) {
      return new Promise((resolve, reject) => {
        axios.post(config.get_account_history_url, {
          token: localStorage.token,
          account_params: {
            policy_id: payload.policyId,
            current_term_id: payload.currentTermId,
            next_term_id: payload.nextTermId,
            current_page: 1
          },
        })
          .then(response => {
            if (!isFailed(response)) {
              let history = response.data.data.records
              context.commit('setAccountHistory', { policyId: payload.policyId, history })
              resolve()
            } else {
              reject()
            }
          }, err => {
            let data = 'Error during loading account history '
            console.warn(data, err)
            reject(data)
          })
      })
    },
    async getPaymentReminders (context) {
      return await axiosWrapper(
        axios.get(
          config.payment_reminders_url,
          {
            headers: {Authorization: localStorage.token}
          }
        )
      )
    },
    async updatePaymentReminders (context, data) {
      if (data.enabled) {
        // we need to fix setup if invalid. This will prevent blind enabling without any reminders set
        if (!data.schedule.length) {
          data.schedule = [7]
        }
        if (!(data.push || data.email || data.text)) {
          data.email = true
        }
      }

      let result = await axiosWrapper(
        axios.put(
          config.payment_reminders_url,
          { ...data,
            token: localStorage.token
          }
        )
      )
      eventBus.$emit('payment-reminders-updated')
      return result
    },
  },
  getters: {
    acctHistoryByPolicyId: (state, getters) => (policyId) => {
      if (!state.acctHistory) {
        return null
      }
      let cached = state.acctHistory.find(i => i.policyId === policyId)
      if (cached) {
        return cached.acctHistory
      } else {
        return null
      }
    },
    allAcctHistory (state) {
      if (!state.acctHistory) {
        return null
      }
      return state.acctHistory
    },
    payOptions (state, getters) {
      const result = {
        currentDue: [],
        entireTerm: [],
        nextTerm: []
      }
      if (!state.policiesForPay.length) return result
      state.policiesForPay.forEach(policy => {
        if (policy.is_canceled) return
        let address = policy.properties && policy.properties.length ? policy.properties[0].address_line1 : ''
        // Entire Term
        if (policy.payoff_amount > 0) {
          let options = defineSinglePaymentOptions('full', policy, policy.payoff_amount, '', address)
          result.entireTerm.push(options)
        }
        // Current Due and Overdue
        if (policy.current_due > 0) {
          let options = defineSinglePaymentOptions(
            'min', policy, policy.current_due, policyDue(policy).cssClass, address)
          result.currentDue.push(options)
        }
        // Next Term
        if (!policy.payoff_amount && policy.next_due > 0) {
          let options = defineSinglePaymentOptions('next_term', policy, policy.next_due, '', address)
          result.nextTerm.push(options)
        }
      })
      return result
    },
    policyPaymentMethods: (state, getters, rootState, rootGetters) => (policyId) => {
      let policy = rootGetters['policies/policyById'](policyId)
      let revision = policy.revision
      let namedInsureds = revision.named_insureds
      let policyContactIds = namedInsureds.map(namedInsured => namedInsured.contact_id)

      let insureds = rootState.account.accountData.insureds
      let myContactIds = []
      for (let insuredId in insureds) {
        if (!insureds.hasOwnProperty(insuredId)) continue
        let contactId = insureds[insuredId].contact_id
        myContactIds.push(contactId)
      }

      let myPolicyContactIds = myContactIds.filter(contactId => policyContactIds.includes(contactId))

      return state.ownedPaymentMethods.filter(
          paymentMethod => myPolicyContactIds.includes(paymentMethod.contact_id))
    },
    policyPaymentState: (state, getters, rootState, rootGetters) => (policyId) => {
      // normalize and determine payment-related values
      let policy = rootGetters['policies/policyById'](policyId)
      let currentDate = moment()
      let overDue = false
      let dueValue = policy.current_due > 0 ? policy.current_due : 0  // not interested in negative values
      let dueDate = null
      if (dueValue) {
        if (policy.due_date !== '--') {
          dueDate = moment(usDateToMomentJsDate(policy.due_date))
          overDue = currentDate.isAfter(dueDate)
        }
      }
      let payOffAmount = policy.payoff_amount > 0 ? policy.payoff_amount : 0
      let nextDueValue = policy.next_due > 0 ? policy.next_due : 0
      let nextDueDate = null
      let nextOverDue = false
      let nextDue = false
      if (nextDueValue) {
        let policyAccountHistory = getters.acctHistoryByPolicyId(policy.id)
        if (!policyAccountHistory) return null // don't return anything if we have not enough data ATM
        if (policyAccountHistory) {
          let nextTermInvoices = policyAccountHistory.filter(
            item => item.term_type === 'next' && item.type === 'Invoice' && item.data.details.paidInFull === false)
          if (nextTermInvoices.length) {
            let firstInvoice = nextTermInvoices[nextTermInvoices.length - 1]
            let nextBillDate = firstInvoice.data.details.billDate
            nextBillDate = moment(usDateToMomentJsDate(nextBillDate))
            nextDueDate = firstInvoice.data.details.dueDate
            nextDueDate = moment(usDateToMomentJsDate(nextDueDate))
            nextOverDue = currentDate.isAfter(nextDueDate)
            nextDue = currentDate.isAfter(nextBillDate) && !nextOverDue
          }
        }
      }
      return {
        dueValue,
        dueDate,
        overDue,
        payOffAmount,
        nextDueValue,
        nextDueDate,
        nextDue,
        nextOverDue
      }
    },
    extendedPolicyPaymentState: (state, getters) => (policyId) => {
      let payState = getters.policyPaymentState(policyId)
      if (!payState) return null
      let simpleDueValue = 0
      let simpleDueDate = null
      let simpleOverDue = false
      if (payState.dueValue) {
        simpleDueValue = payState.dueValue
        simpleDueDate = payState.dueDate
        simpleOverDue = payState.overDue
      }
      if (payState.nextDueValue) {
        if (payState.nextDue && !simpleOverDue) {
          simpleDueDate = getEarliestDate(simpleDueDate, payState.nextDueDate)
          simpleDueValue += payState.nextDueValue
        } else if (payState.nextOverDue && simpleOverDue) {
          simpleDueDate = getEarliestDate(simpleDueDate, payState.nextDueDate)
          simpleDueValue += payState.nextDueValue
        } else if (payState.nextOverDue && !simpleOverDue) {
          simpleDueDate = payState.nextDueDate
          simpleDueValue = payState.nextDueValue
          simpleOverDue = true
        }
      }
      return {
        ...payState,
        simpleDueValue,
        simpleDueDate,
        simpleOverDue
      }
    }
  }
}

export default paymentsModule
