<template>
  <div class="init-screen" v-loading="loading">
    <init-sidebar/>
    <div class="init-screen__info">
      <div class="init-block">
        <div class="navigation">
          <div class="init-block__back" @click="$router.back()">
            <ba-icon-link icon_type="far" icon_name="arrow-left" icon_class="u-pl2" :label="t('documents.back')"/>
          </div>
        </div>
        <div>
          <h1 class="init-block--heading">{{ t('account.change_password') }}</h1>
          <div class="subheader u-mb3">{{ t('account.required_to_update_password_text') }}</div>
        </div>
        <ba-password-update @updatePassword="updatePassword" :showPassword = showPassword></ba-password-update>
      </div>
    </div>
    <div class="init-screen__logo-language">
      <bc-footer bgClass="bg-login"></bc-footer>
      <div class="init-screen__bc-language" v-if="displayLanguageLink">
        <ba-language-selector/>
      </div>
    </div>
  </div>
</template>

<script>
import BaPasswordUpdate from '@/components/password-update.component'
import {storeLoginTime} from '@shared_src/utils/timezone.utils'
import settings from '@/../config/settings'
import config from '@/../config/britecore'
import {mapActions, mapState, mapGetters} from 'vuex'
import axios from 'axios'
import initSidebar from '@/components/init-sidebar'
import iconLink from '@/ui/icon-link.component'
import BaLanguageSelector from '@/components/language-selector.component'

export default {
  name: 'ba-password-update-required',
  data () {
    // This parameter determins whether or not to show current password field.
    // We don't need it when we have a token from the URL
    let showPassword = this.$route.query && this.$route.query.show_password
      ? this.$route.query.show_password : '1'
    return {
      loading: false,
      showPassword: showPassword === '1'
    }
  },
  components: {
    initSidebar,
    BaLanguageSelector,
    'ba-icon-link': iconLink,
    'ba-password-update': BaPasswordUpdate,
  },
  computed: {
    ...mapState('login', ['usernameToForceUpdatePassword']),
    ...mapGetters('account', ['tos']),
    displayLanguageLink () {
      return this.setting('global.display_spanish_link') === true
    },
  },
  methods: {
    ...mapActions(['loadUserData']),
    ...mapActions('common', ['savePreferredLanguage']),
    ...mapActions('documents', ['getSecretKey']),
    async login (newPassword) {
      axios.post(config.ajax_login_url, {
        username: this.usernameToForceUpdatePassword,
        password: newPassword,
        company_id: settings.company_id,
      }).then((result) => {
        localStorage.removeItem('login__token-expired')
        localStorage.removeItem('last_location')
        localStorage.removeItem('last_user')
        storeLoginTime()
        localStorage.setItem('username_id', this.usernameToForceUpdatePassword)
        // getSecretKey first, otherwise we face issues with pictures/attachments
        this.getSecretKey().then(this.loadUserData).then(() => {
          this.loading = true
          this.savePreferredLanguage(null)
          if (this.tos) {
            /* If ToS is loaded, needs to be agreed */
            this.$router.push('/enrollment/terms-of-service/0')
            return
          }
          this.$router.push('/dashboard')
        })
      })
    },
    async updatePassword ({currentPassword, newPassword}) {
      this.loading = true
      try {
        let response = await axios.post(config.update_temporary_password, {
          company_id: settings.company_id,
          username: this.usernameToForceUpdatePassword,
          temporary_password: currentPassword,
          no_current_pw: !this.showPassword,
          new_password: newPassword,
        })
        if (response.data.success) {
          this.$elAlert(this.t('reset_password.password_changed_successfully'), this.t('global.notice'),
            () => this.login(newPassword))
        } else {
          this.loading = false
          this.$elAlert(
            response.data.cause ? this.causeToString(response.data.cause) : this.t('global.error'),
            this.t('global.error')
          )
        }
      } catch (e) {
        this.loading = false
        console.error(e)
        this.$elAlert(this.t('global.error'), this.t('global.error'))
      }
    },
  },
}
</script>
